/* COLORS */
.__color-negative {
  color: var(--color-negative);
}
.__error {
  color: var(--color-negative);
}
.__color-positive {
  color: var(--color-positive);
}
.__color-neutral {
  color: var(--color-neutral);
}
.color-white {
  color: var(--text-color-contrast);
}
.color-grey {
  color: var(--text-color-secondary);
}
.color-primary {
  color: var(--base-color);
}

.__card {
  border-radius: var(--border-radius-base);
  background: var(--background-color-contrast);
  box-shadow: var(--card-default-box-shadow);
}

/* TYPOGRAPHY ELEMENTS */
.__h1 {
  font-size: var(--font-size-lg);
  letter-spacing: 1px;
  text-transform: uppercase;
  // margin: var(--m-0);
  // padding: var(--p-0);
}

.__h2 {
  font-size: var(--font-size-md);
  letter-spacing: 1px;
  text-transform: uppercase;
  // margin: var(--m-0);
  // padding: var(--p-0);
}
.__h3 {
  font-size: var(--font-size-base);
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
}
.__h4 {
  font-size: var(--font-size-sm);
  letter-spacing: 1px;
  // text-transform: uppercase;
  font-weight: 600;
  color: var(--text-color-secondary);
}
.__sub-text {
  font-size: var(--font-size-sm);
  color: var(--text-color-secondary);
}
.__description-text {
  font-size: var(--font-size-base);
  letter-spacing: 1px;
}

/* PAGE ELEMENTS */
.__card {
  border-radius: var(--border-radius-base);
  background: var(--background-color-contrast);
  box-shadow: var(--card-default-box-shadow);
}

.__empty {
  padding: var(--p-10);
  text-align: center;
  color: var(--text-color-secondary);
}

.empty-text {
  color: var(--text-color-secondary);
  font-size: var(--font-size-sm);
  text-align: center;
}

.rs-modal-wrapper {
  background: var(--background-color-contrast-inverse-2);
}

/* FORM ELEMENTS */
.__form-row {
  display: flex;
  gap: 20px;
  width: 100%;
}

.__field {
  padding-bottom: var(--p-5);
}

.__field-fill {
  margin-left: auto;
}

/* FLEXBOX */
.__flex {
  display: flex;
  gap: 10px;
}
.__flex-wrap {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.__align-end {
  align-items: flex-end;
}
.__align-center {
  align-items: center;
}
.__justify-center {
  justify-content: center;
}
.__justify-end {
  justify-content: flex-end;
}
.__inline-flex {
  display: inline-flex;
  gap: 10px;
}
.__flex-column {
  flex-direction: column;
}
.__flex-1 {
  flex: 1 0;
}
.__flex-2 {
  flex: 2 0;
}
.__flex-3 {
  flex: 3 0;
}
.__flex-4 {
  flex: 4 0;
}

/* TEXT ALIGN */
.__text-align-left {
  text-align: left;
}
.__text-align-right {
  text-align: right;
}
.__text-align-center {
  text-align: center;
}

/* TEXT FIT */
.__text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* TEXT DECORATIONS */
.strike-through {
  text-decoration: line-through;
}

/* FONT WEIGHT */
.bold {
  font-weight: 700;
}
.weight-100 {
  font-weight: 100;
}
.weight-200 {
  font-weight: 200;
}
.weight-300 {
  font-weight: 300;
}
.weight-400 {
  font-weight: 400;
}
.weight-500 {
  font-weight: 500;
}
.weight-600 {
  font-weight: 600;
}
.weight-700 {
  font-weight: 700;
}
.weight-800 {
  font-weight: 800;
}
.weight-900 {
  font-weight: 900;
}

/* TRANSFORM */
.rotate-90 {
  transform: rotateX(90deg);
}
.rotate-180 {
  transform: rotateX(180deg);
}
.rotate-270 {
  transform: rotateX(270deg);
}

/* ANIMATIONS */
@keyframes shake {
  from {
    transform: translateY(-5%);
  }
  to {
    transform: translateY(5%);
  }
}

/* SPACING */
.no-padding {
  padding: var(--p-0);
}
.padding-6 {
  padding: var(--p-3);
}
.padding-10 {
  padding: var(--p-5);
}
.padding-16 {
  padding: var(--p-8);
}
.padding-20 {
  padding: var(--p-10);
}
.padding-30 {
  padding: var(--p-15);
}
.padding-40 {
  padding: var(--p-20);
}
.padding-50 {
  padding: var(--p-25);
}

.padding-top-6 {
  padding-top: var(--p-3);
}
.padding-top-10 {
  padding-top: var(--p-5);
}
.padding-top-20 {
  padding-top: var(--p-10);
}
.padding-top-30 {
  padding-top: var(--p-15);
}
.padding-top-40 {
  padding-top: var(--p-20);
}
.padding-top-50 {
  padding-top: var(--p-25);
}
.padding-bottom-6 {
  padding-bottom: var(--p-3);
}
.padding-bottom-10 {
  padding-bottom: var(--p-5);
}
.padding-bottom-20 {
  padding-bottom: var(--p-10);
}
.padding-bottom-30 {
  padding-bottom: var(--p-15);
}
.padding-bottom-40 {
  padding-bottom: var(--p-20);
}
.padding-bottom-50 {
  padding-bottom: var(--p-25);
}
.padding-left-6 {
  padding-left: var(--p-3);
}
.padding-left-10 {
  padding-left: var(--p-5);
}
.padding-left-20 {
  padding-left: var(--p-10);
}
.padding-left-30 {
  padding-left: var(--p-15);
}
.padding-left-40 {
  padding-left: var(--p-20);
}
.padding-left-50 {
  padding-left: var(--p-25);
}
.padding-right-6 {
  padding-right: var(--p-3);
}
.padding-right-10 {
  padding-right: var(--p-5);
}
.padding-right-20 {
  padding-right: var(--p-10);
}
.padding-right-30 {
  padding-right: var(--p-15);
}
.padding-right-40 {
  padding-right: var(--p-20);
}
.padding-right-50 {
  padding-right: var(--p-25);
}

.margin-6 {
  margin: var(--m-3);
}
.margin-10 {
  margin: var(--m-5);
}
.margin-16 {
  margin: var(--m-8);
}
.margin-20 {
  margin: var(--m-10);
}
.margin-26 {
  margin: var(--m-13);
}
.margin-30 {
  margin: var(--m-15);
}

.margin-left-0 {
  margin-left: var(--m-0);
}
.margin-left-6 {
  margin-left: var(--m-3);
}
.margin-left-10 {
  margin-left: var(--m-5);
}
.margin-left-20 {
  margin-left: var(--m-10);
}
.margin-right-6 {
  margin-right: var(--m-3);
}
.margin-right-10 {
  margin-right: var(--m-5);
}
.margin-right-20 {
  margin-right: var(--m-10);
}
.margin-top-6 {
  margin-top: var(--m-3);
}
.margin-top-10 {
  margin-top: var(--m-5);
}
.margin-top-20 {
  margin-top: var(--m-10);
}
.margin-bottom-6 {
  margin-bottom: var(--m-3);
}
.margin-bottom-10 {
  margin-bottom: var(--m-5);
}
.margin-bottom-20 {
  margin-bottom: var(--m-10);
}
.margin-bottom-30 {
  margin-bottom: var(--m-15);
}
.margin-bottom-40 {
  margin-bottom: var(--m-20);
}

.__text-primary {
  color: var(--text-color-primary);
}
.__text-secondary {
  color: var(--text-color-secondary);
}
