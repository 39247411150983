@import "../../styles/vars.scss";

.complete-registration-form {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .action-row {
    flex-wrap: wrap-reverse;
    button {
      margin-top: var(--m-8);
    }
    display: flex;
    width: 100%;
    .fill {
      flex-grow: 1;
    }
  }
  .error-container {
    display: flex;
    align-items: center;
    .icon {
      margin-right: var(--m-5);
    }
    color: var(--color-negative);
  }
}
