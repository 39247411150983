.log-entry {
  display: flex;

  padding: 10px 10px 10px 10px;
  align-items: flex-start;
  transition: background-color 0.2s;
  &:hover {
    background-color: var(--background-opacity-primary);
  }

  .left-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    .rs-icon {
      margin-top: var(--m-3);
      margin-right: var(--m-3);
      display: flex;
      justify-content: center;
    }
    .avatar {
      // margin-top: var(--m-3);
    }
  }
  .content {
    padding-left: var(--p-4);
    flex-grow: 1;
    color: var(--text-color-primary);
    .description {
      // white-space: normal;
      word-wrap: normal;
      white-space: pre-line;
      word-break: break-word;
      .user,
      .data,
      .target {
        font-weight: bold;
        display: inline-block;
      }
    }
    .timestamp {
      font-size: 0.9em;
      font-style: italic;
      color: var(--text-color-secondary);
    }
  }
  .actions {
  }
}
