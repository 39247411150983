.ap-document-store {
  // height of outfader bottom 20px
  max-height: calc(100% - 20px);
  overflow: auto;
  border-radius: var(--border-radius-base);

  &:not(.no-shadow) {
    box-shadow: var(--card-default-box-shadow);
  }
}
