.rs-list-entry {
  width: 100%;
  overflow: hidden;

  .rs-list-entry-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .rs-text-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: var(--m-5);
      overflow: hidden;
      text-align: left;
      .rs-title-row {
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
      .rs-detail-row {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }

    .rs-data-column {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    .rs-done-container {
      .rs-done-row {
        display: flex;
        justify-content: end;
      }
    }
  }
}
