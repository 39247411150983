.meta-row {
  display: flex;
  align-items: center;
  .text {
    font-size: var(--font-size-sm);
  }
  .user {
    padding-left: var(--p-3);
    padding-right: var(--p-3);
    display: flex;
    align-items: center;
    .username {
      padding-left: var(--p-3);
      font-size: var(--font-size-sm);
    }
  }
}
