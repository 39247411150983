.asset-loader {
  &.error {
    padding: var(--p-10);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-negative);
  }
  &.loading {
    padding: var(--p-10);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.inline {
    padding: var(--p-0);
  }
}
