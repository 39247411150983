.changelog-modal {
  height: 70vh;
  display: flex;
  flex-direction: column;
  .changelog-header {
    display: flex;
    font-size: var(--font-size-md);
    padding: var(--p-10);
    background: var(--background-color-primary);
    border-top-left-radius: var(--border-radius-base);
    border-top-right-radius: var(--border-radius-base);
    border-bottom: solid 1px var(--border-color-secondary);
    align-items: center;
    span.changelog-title {
      flex-grow: 1;
    }
  }
  .changelog-container {
    display: flex;
    flex-grow: 1;

    overflow: hidden;
    .changelog-list {
      .bf-chooser {
        height: 100%;
        width: 200px;
        .search-content {
          border: solid 1px var(--border-color-primary);
        }
      }
    }
    .changelog-content {
      flex-grow: 1;
      padding: var(--p-10);
      overflow: auto;
    }
  }
}
