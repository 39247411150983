.ap-create-activity {
  display: flex;
  overflow: hidden;

  &.mail-split-view {
    max-height: 90vh;
  }

  form {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .stacking-plan-container {
      height: 300px;
      margin-top: var(--m-5);
      margin-bottom: var(--m-5);
      border-radius: var(--border-radius-base);
      border: solid 1px var(--border-color-secondary);
    }
    .form-fields {
      display: flex;
      flex-direction: row;
      gap: 15px;
      overflow: hidden;

      .fields-container {
        flex: 2 0 0;
      }

      .mail-container {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex: 3 0 0;
        height: 100%;

        .comment-entry {
          height: 100%;
          display: flex;
          padding-bottom: var(--p-0);
          flex-direction: column;
          .comment-container {
            flex: 1 0 0;
            overflow: hidden;
            height: 100%;

            .bubble {
              height: 100%;
              display: flex;
              flex-direction: column;
              .content-wrapper {
                flex: 1 0 0;
                height: 100%;
                overflow: auto;
                .content {
                  max-height: initial;
                }
              }
            }
          }
        }
      }
    }
  }
}
