.cb-rental-agreement-entry {
  .first-row {
    display: flex;
    align-items: center;
    padding-bottom: var(--p-5);

    .id {
      font-size: var(--font-size-md);
      color: var(--text-color-secondary);
      padding-right: var(--p-5);
    }
    .name {
      font-size: var(--font-size-lg);
      color: var(--text-color-strong);
      flex-grow: 1;
      padding-right: var(--p-5);
    }
  }
  .data-row {
    display: flex;
    gap: 20px;

    .rent-data {
      .rent-entry {
        display: flex;

        .label {
          color: var(--text-color-secondary);
          flex-grow: 1;
          font-size: var(--font-size-base);
          padding-right: var(--p-5);
        }
        .value {
        }
      }
    }
    .__h3 {
      padding-bottom: var(--p-3);
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    padding-top: var(--p-5);
  }
}
