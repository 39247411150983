.comment-content-entry.loader-container {
  padding: var(--p-10);
  display: flex;
  justify-content: center;
  align-items: center;
}
.comment-content-entry.error-backup {
  .error-info {
    margin: var(--m-5) var(--m-5) var(--m-10) var(--m-5);
    padding: var(--p-5);
    background: var(--background-color-light-error);
    color: var(--color-negative);
  }
}
