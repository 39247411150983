.cb-supply-unit-card.__card {
  padding: var(--p-0);
  display: flex;
  margin-bottom: var(--m-10);

  .relations {
    margin-bottom: var(--m-5);
  }

  .gallery {
    width: 200px;
    align-self: stretch;
    border-top-left-radius: var(--border-radius-base);
    border-bottom-left-radius: var(--border-radius-base);
    background: var(--background-color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .contracts {
    .empty-text {
      padding: var(--p-0);
    }
    .activity-entry {
      border-bottom: solid 1px var(--border-color-primary);
      padding-bottom: var(--p-5);
      margin-bottom: var(--m-5);

      &:last-of-type {
        border-bottom: none;
        margin-bottom: var(--m-0);
        padding-bottom: var(--p-0);
      }

      .title-row {
        display: flex;
        gap: 10px;
        .activity-name {
          flex-grow: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .contract-data {
        td.label {
          font-size: var(--font-size-sm);
          color: var(--text-color-secondary);
          padding-right: var(--p-10);
        }
      }
    }
  }

  .card-content {
    padding: var(--p-5);
    flex-grow: 1;

    .header {
      display: flex;

      .id {
        align-self: flex-end;
        font-size: var(--font-size-base);
        color: var(--text-color-secondary);
      }

      .category {
        display: flex;
        align-items: center;
        .category-label {
          padding-left: var(--p-3);
          font-weight: 500;
        }
      }
    }
    .title {
      padding-top: var(--p-3);
      font-size: var(--font-size-md);
      padding-bottom: var(--p-5);
    }
    .note {
      padding-bottom: var(--p-5);
      .note-label {
        font-size: var(--font-size-sm);
        color: var(--text-color-secondary);
        // padding-bottom: var(--p-2);
      }
    }
    .custom-fields {
      .label {
        font-size: var(--font-size-sm);
        color: var(--text-color-secondary);
        padding-right: var(--p-5);
      }
      .value {
      }
    }
    hr {
      margin-top: var(--m-5);
      margin-bottom: var(--m-5);
    }

    .actions {
      display: flex;
    }
  }
}
