.context-container-component {
  position: relative;
  .context-position-reference {
    position: absolute;

    .faker {
      width: 0px;
      height: 0px;
    }
  }
}

.context-container-popover-container {
  padding: var(--p-0);
  .rs-popover-arrow {
    display: none;
  }
}
