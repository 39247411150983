.contact-basedata-view {
  height: 100%;
  display: flex;
  flex-direction: column;

  --actionrow-height: 60px;
  .action-row {
    padding-bottom: var(--p-10);
    height: var(--actionrow-height);
    display: flex;
    justify-content: flex-end;
    gap: var(--p-5);
  }
  .basedata-content {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1px;
    gap: var(--p-10);
    // min-height: calc(100% - var(--actionrow-height));
    .col {
      flex: 1 0;
      min-width: 400px;
      // min-height: 100%;
      .notes-section {
        // min-height: calc(100% - var(--actionrow-height));
        height: 100%;
      }
    }
  }
}
