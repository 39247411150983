.ra-approve-invoice-entry {
  .asset-loader.loading {
    min-height: 450px;
  }

  .correction {
    // display: flex;
    padding-bottom: var(--p-8);
    .rs-message {
      width: 100%;
      .description {
        display: flex;
        align-items: center;
      }
    }
    .text {
    }
  }
  .entry-content {
    padding: var(--p-5);
    position: relative;

    .header {
      min-height: 40px;
      display: flex;
      align-items: center;
      padding-bottom: var(--p-5);
      .id {
        color: var(--text-color-secondary);
      }
      .invoice-type {
        font-size: var(--font-size-md);
      }
      > .actions {
        display: flex;
        justify-content: flex-end;
        width: 180px;
        button.bf-button {
          padding: 4px 8px;
        }
        .rs-badge-content {
          right: 5px;
          top: 2px;
        }
        .divider {
          margin-left: var(--m-3);
          margin-right: var(--m-3);
          margin-top: var(--m-1);
          margin-bottom: var(--m-1);
          box-sizing: border-box;
          border-right: 1px var(--border-color-secondary) solid;
          height: 24px;
        }
      }
    }
    .content {
      position: relative;

      .costcenter-container {
        padding-top: var(--p-8);
      }
      .approve-form {
        border-top: solid 1px var(--border-color-secondary);
        padding-top: var(--p-8);
        margin-top: var(--m-8);
        .disable-hint {
          color: var(--text-color-secondary);
          font-size: var(--font-size-base);
          padding-bottom: var(--p-5);
          font-style: italic;
        }
        .bf-message {
          margin-bottom: var(--m-5);
        }
        .comment {
          textarea.autosize-textarea {
            padding: var(--p-3);
            border-radius: var(--border-radius-base);
          }
        }
        .actions {
          display: flex;
          justify-content: flex-end;
          padding-top: var(--p-5);
          .bf-button.decline {
            //   --base-color: var(--color-negative);
          }
          .bf-button.approve {
            margin-left: var(--m-5);
            //   --base-color: var(--color-positive);
          }
        }
      }

      .decline-overlay {
        position: absolute;
        top: -10px;
        left: -10px;
        right: -10px;
        bottom: -10px;
        display: none;
        z-index: 20;
        background: var(--background-color-contrast);
        border-bottom-left-radius: var(--border-radius-base);
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &.active {
          display: flex;
        }
        .ra-decline-invoice {
          width: 50%;
        }
      }
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--background-opacity-strong);
      z-index: 6;
      border-radius: var(--border-radius-base);
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--text-color-contrast);

      .overlay-content {
        display: flex;
        align-items: center;
      }
    }
  }
}
