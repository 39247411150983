.ez-group {
  transition: box-shadow 0.2s, padding-top 0.2s, padding-right 0.2s,
    padding-left 0.2s, padding-bottom 0.2s;
  border-radius: var(--border-radius-base);
  &.active {
    box-shadow: var(--card-default-box-shadow);
    padding: var(--p-5);
  }

  .action-row {
    padding-top: var(--p-5);
    display: flex;
    justify-content: flex-end;
    .bf-button {
      margin-left: var(--m-3);
    }
  }
}
