.heatmap-chart-component {
  height: 100%;
  width: 100%;
  display: flex;
  .year-selection {
    height: 100%;
    // width: 200px;
    overflow: auto;
  }
  .chart-component-wrapper {
    flex-grow: 1;
    height: 100%;
  }
}
