.cb-rental-agreement-deposit-form {
  padding-top: var(--p-10);
  .deposit-entry {
    .headline {
      display: flex;
      justify-content: flex-end;
    }
    border-bottom: 1px solid var(--border-color-primary);
    padding-bottom: var(--p-5);
    margin-bottom: var(--m-5);
    &:last-of-type {
      border-bottom: none;
      padding-bottom: var(--p-0);
      margin-bottom: var(--m-0);
    }
  }
}
