.cashbudget-hint-popup {
  position: absolute;
  z-index: 1021;
  background: #fff;
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.533);
  padding: 5px 10px;
}

.cashbudget-table {
  width: 100%;
  height: 100%;
  min-height: 0;
  border-radius: var(--border-radius-base);
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.533);
  display: flex;
  position: relative;

  &.remove-overscroll {
    .overscroll-container {
      display: none;
    }
  }
  .line {
    // height: 100%;
    position: absolute;
    top: -1px;
    bottom: -1px;
    width: 1px;
    background: rgb(190, 190, 190);

    .line-left {
      width: 7px;
      height: 1px;
      position: absolute;
      top: 50%;
      left: -7px;
      background: rgb(190, 190, 190);
    }

    &.end {
      bottom: 50%;

      .line-left {
        top: calc(100% - 1px);
      }
    }
  }
  .grouped-indicator {
    position: relative;
    width: 18px;

    height: 100%;
    .bf-icon {
      position: absolute;
      top: -13px;
      left: 3px;
      width: 15px;
      height: 15px;
    }
  }
  .row-wrapper {
    position: relative;

    .fixed-header-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 5;
      will-change: transform;
    }
  }
  .row-ident {
    position: relative;
    text-align: right;
    overflow: hidden;
    white-space: nowrap;
    .text {
      max-width: 100%;
      overflow: hidden;
      text-align: right;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .hints {
      display: flex;
      justify-content: flex-end;
      .hint {
        height: 5px;
        width: 5px;
        margin-right: var(--m-3);

        border-radius: var(--border-radius-base);
      }
    }

    .line {
      // height: 100%;
      position: absolute;
      top: -1px;
      bottom: -1px;
      width: 1px;
      background: rgb(190, 190, 190);

      .line-left {
        width: 7px;
        height: 1px;
        position: absolute;
        top: 50%;
        left: -7px;
        background: rgb(190, 190, 190);
      }

      &.end {
        bottom: 50%;

        .line-left {
          top: calc(100% - 1px);
        }
      }
    }
  }
  .cell-edit {
    box-sizing: border-box;
    > div.validation-popover {
      height: 100%;
      display: block;

      .wrapper {
        height: 100%;

        .bf-input {
          height: 100%;
          border-radius: var(--border-radius-none);
          input.rs-input {
            padding: 0px 6px;
          }
          .suffix {
            padding: 0px 8px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: var(--border-radius-none);
          }
        }
      }
    }
  }
  .cell {
    box-sizing: border-box;
  }
  button.collapsible-cell {
    background: transparent;
    display: flex;
    align-items: center;

    > .collapsible-indicator {
      padding-left: var(--p-3);
      i.bf-icon {
        transition: transform 0.2s;
        transform: scale(1.5);
      }
    }
    &.collapsed {
      > .collapsible-indicator {
        i.bf-icon {
          transform: rotate(180deg) scale(1.5);
        }
      }
    }
  }
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .row-highlight-container {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
    .cell {
      transition: background-color 0.2s;
      &.hover {
        background: #9aa7b45b;
      }
      // &:nth-child(odd) {
      // 	background: rgb(240, 240, 240);
      // 	&.hover {
      // 		background: #7f8a965b;
      // 	}
      // }
    }
  }
  .sub-head {
    font-weight: bold;
    margin-top: var(--m-5);
    &.cell {
      border-bottom: none !important;
    }
  }
  .bold {
    font-weight: bold;
  }
  .table-row-definition {
    width: 250px;
    border-right: solid 1px var(--cashbudget-color-border);
    min-height: 100%;
    box-shadow: 1px 0px 5px -2px #000;
    background: var(--cashbudget-color-menu);
    z-index: 2;
    overflow: hidden;
    .scrolling-content {
      will-change: transform;
    }
    .cell {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: var(--p-3);
      font-size: 0.9em;
    }
    .head {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .date-selection {
        display: flex;
        justify-content: center;
        align-items: center;

        .head-selection {
          .rs-btn.rs-dropdown-toggle {
            font-size: var(--font-size-2xl);
          }
          .rs-dropdown-menu {
            .rs-dropdown-item {
              font-size: var(--font-size-xl);
            }
          }
        }
      }
    }
  }
  .table-data-content {
    min-height: 0;
    overflow-x: auto;
    overflow-y: visible;
    flex: 1 0;
    z-index: 1;
    &.moving-active {
      .cell {
        pointer-events: none;
      }
    }
    .scrolling-content {
      cursor: all-scroll;
      height: 100%;
      display: flex;
      width: fit-content;
      .overscroll-container {
        width: 50px;
        height: 100%;
        overflow: hidden;
        position: relative;
        .switch {
          position: absolute;

          height: 100%;
          width: 50px;
          opacity: 0.95;
          transition: transform 0.2s;
          &.left {
            transform: translateX(-100%);
            left: 0;
          }
          &.right {
            right: 0;
            transform: translateX(100%);
          }
          &.active {
            transform: translateX(0);
          }
          .bf-button {
            height: 100%;
            width: 100%;

            .switch-content {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
            }
          }
          &.left {
            .switch-content {
              transform: rotate(-90deg);
            }
          }
          &.right {
            .switch-content {
              transform: rotate(90deg);
            }
          }
        }
      }

      .content-entry {
        &.highlight-sum {
          opacity: 1;
          font-weight: bold !important;
          background: rgba(172, 172, 172, 0.213) !important;
        }
        &.highlight-current {
          background: rgba(100, 102, 209, 0.075);
          .title {
            font-weight: bold;
            // transform: scale(1.2);
          }
        }
        &.highlight-future {
          background: var(--cashbudget-color-prognose);
          &.highlight-current {
            background: var(--cashbudget-color-today);
          }
        }
        &.end-of-week {
          border-right: dotted 4px rgba(96, 114, 134, 0.719);
        }
        // width: 200px;
        border-right: solid 1px var(--cashbudget-color-border);
        min-height: 100%;
        height: fit-content;
        transition: background-color 0.2s;
        &.comparison {
          border-right: solid 1px rgb(72, 72, 72);
          // width: 400px;
          &.sum-column {
            width: 450px;
          }
          .subheader {
            width: 100%;
            display: flex;
            .cell {
              flex: 1 1 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: var(--text-color-secondary);
              text-transform: uppercase;
              border-right: solid 1px var(--cashbudget-color-border);
              &:last-of-type {
                border-right: none;
              }
            }
          }
          .row-container {
            display: flex;
            .row-wrapper {
              flex: 1 1 100%;
              border-right: solid 1px var(--cashbudget-color-border);
              &:last-of-type {
                border-right: none;
              }
            }
          }
        }
        // &:hover {
        // 	background: #d4e3f360;
        // }
        .row-container {
          display: flex;
          .row-wrapper {
            width: 100%;
            .cell {
              border-bottom: solid 1px var(--cashbudget-color-border);
              display: flex;
              justify-content: flex-end;
              align-items: center;
              transition: border 0.2s;
              .icons {
                padding-left: var(--p-3);
                color: var(--text-color-secondary);
                display: flex;
                height: 100%;
                align-items: center;
                .rs-icon {
                  margin-right: var(--m-2);
                }
              }
              &:not(.sub-head) {
                border: solid 1px var(--cashbudget-color-border) 0;
                border-bottom: solid 1px var(--cashbudget-color-border);
              }
              &.selected {
                background: rgba(166, 187, 255, 0.4) !important;
              }
              &.clickable {
                &:hover {
                  border: dashed 1px rgb(87, 87, 87);

                  .cell-buttons {
                    opacity: 1;
                  }
                }
              }
              &.border-top-solid {
                border-top: solid 1px rgb(161, 161, 161);
              }
              .cell-content {
                position: relative;
                flex-grow: 1;
                height: 100%;
                padding: 4px 6px;
                text-align: right;
                pointer-events: none;
                display: flex;
                align-items: center;
                span {
                }
                .type {
                  display: none;
                }
                &.negative {
                  color: var(--color-negative);
                }
                .progress {
                  color: var(--text-color-secondary);
                  font-size: var(--font-size-sm);
                  // margin-left: var(--m-3);
                  &.overdone {
                    color: var(--color-prio-high);
                  }
                }
                .value {
                  flex-grow: 1;
                }
              }
              .cell-buttons {
                height: 100%;
                .bf-button {
                  height: 100%;
                  border-radius: var(--border-radius-none);
                }
                opacity: 0;
                transition: opacity 0.2s;
              }
            }
          }
        }
        .head {
          .date-header {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;

            .infos {
              width: 100%;
              padding: var(--p-2);
              font-size: 0.9em;
              color: var(--text-color-secondary);
              display: flex;
              height: 30px;

              .left {
                font-size: 0.9em;
                color: var(--text-color-secondary);
              }
              .center {
                font-size: var(--font-size-sm);
                font-weight: bold;
                color: var(--text-color-secondary);
              }
              .right {
                font-size: 0.7em;
                color: var(--text-color-secondary);
              }
            }
            .title {
              font-size: 1.4em;
              position: relative;
              width: fit-content;
              margin: auto;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 950px) {
    .table-row-definition {
      display: none;
    }
    .row-highlight-container {
      display: none;
    }
    .table-data-content {
      .scrolling-content {
        .overscroll-container {
          transform: none !important;
          width: 100%;
          height: 100px;
          overflow: visible;
          padding: var(--p-8);
          .switch.left,
          .switch.right {
            transform: none;
            position: relative;
            width: 100%;
            height: 100%;
            button.bf-button {
              .switch-content {
                transform: none;
              }
            }
          }
          .switch.right {
            button.bf-button {
              .switch-content {
                flex-direction: column-reverse;
                .indicator {
                  transform: scaleY(-1);
                }
              }
            }
          }
        }
        width: 100%;
        padding-bottom: var(--p-10);
        flex-direction: column;
        .content-entry {
          // border-bottom: solid 5px rgb(51, 51, 51);
          padding: var(--p-3);
          margin: var(--m-0);
          box-shadow: 0px 3px 10px #000;
          margin-bottom: var(--m-8);

          width: calc(100% - 10px);
          min-height: fit-content;
          .head {
            height: 60px !important;
          }
          .row-container {
            display: block;
            .row-wrapper {
              width: 100%;
              .cell {
                height: 30px !important;
                .cell-content {
                  display: flex;
                  justify-content: space-between;
                  .type {
                    display: inline-block;
                  }
                }
              }
            }
          }

          &.comparison {
            // border-right: none;
            width: calc(100% - 10px);
            &.sum-column {
              width: 100%;
            }
            .subheader {
              display: flex;
              justify-content: center;
              // display: none;

              // display: flex;
              // .cell {
              // 	flex: 1 1 100%;
              // 	display: flex;
              // 	justify-content: center;
              // 	align-items: center;
              // 	color: var(--text-color-secondary);
              // 	text-transform: uppercase;
              // 	border-right: solid 1px var(--cashbudget-color-border);
              // 	&:last-of-type {
              // 		border-right: none;
              // 	}
              // }
            }
            .row-container {
              display: block;
              .row-wrapper {
                // // display: none;
                // &.active {
                //   display: block;
                // }
                // width: 100%;
                // border-right: solid 1px var(--cashbudget-color-border);
                // &:last-of-type {
                //   border-right: none;
                // }
              }
            }
          }
        }
      }
    }
  }
}

.cashbudget-hover-container {
  pointer-events: none;
  position: absolute;
  z-index: 5;
}
