.bf-nav-view {
  width: 100%;

  display: flex;
  flex-direction: column;

  --tab-head-height: 60px;
  .tab-bar {
    height: var(--tab-head-height);
    padding: var(--p-5);
    background: var(--background-color-primary);
    border-top-left-radius: var(--border-radius-big);
    border-top-right-radius: var(--border-radius-big);
    border-bottom: solid 1px var(--border-color-secondary);
    display: flex;
    align-items: center;

    .tab-bar-nav-wrapper {
      padding-left: var(--p-5);
      padding-right: var(--p-5);
    }

    .fill {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 30%;
    }
    .tab-title {
      font-size: var(--font-size-lg);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      //   padding-right: var(--p-2);
    }
  }
  .tab-content {
    // padding: var(--p-10) var(--p-10) var(--p-10) var(--p-10);
    flex-grow: 1;
    height: calc(100% - var(--tab-head-height));
    // overflow: hidden;
  }
}
