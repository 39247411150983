.ap-content-card {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;

  .card-header {
    display: flex;
    border-bottom: solid 1px var(--border-color-secondary);
    font-size: var(--font-size-md);
    .title {
      padding: 6px 8px;
      flex: 1 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .actions {
      display: flex;
    }
  }
  .card-scroller {
    flex: 1 0;
    overflow: auto;

    .card-content {
    }
  }
}
