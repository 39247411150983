.overlay-multiple-selection {
  max-height: 500px;
  width: 320px;
  overflow: hidden;
  background: var(--background-color-contrast);
  z-index: 6;
  display: flex;
  flex-direction: column;

  &.embedded {
    width: 100%;
  }
  .search {
    .bf-input {
      border: none !important;
      outline: none !important;
    }
  }

  .content {
    padding: var(--p-5);
    flex-grow: 1;
    overflow: auto;
    border-top: solid 1px var(--border-color-secondary);
    border-bottom: solid 1px var(--border-color-secondary);
    .empty-text {
      text-align: center;
      color: var(--text-color-secondary);
      font-size: var(--font-size-sm);
      padding: var(--p-5);
    }
    .selection-element {
      width: 100%;
    }
  }
  .footer {
    padding: var(--p-2);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
