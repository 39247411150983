.document-directory-view {
  .document-directory-header {
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding-bottom: var(--p-3);
    .ds-upload-button {
      margin-left: auto;
    }

    .ds-back-button {
      .bf-icon {
        min-width: 26px;
      }
    }
    .unment-requirements-message {
      display: flex;
      align-items: center;
      gap: 6px;
      justify-content: space-evently;

      .bf-icon {
        min-width: 26px;
      }
    }
  }
  .ds-directory-list {
    position: relative;
    .drag-overlay {
      position: absolute;
      background-color: var(--background-opacity-tertiary-weak);
      width: 100%;
      height: 100%;
    }
  }

  .ds-document-list-container {
    .ds-document-list-dropzone {
      padding: 25px 0 25px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15px;
    }
  }
}
