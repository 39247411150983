.multiple-pdf-viewer {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color-secondary);
  border-radius: var(--border-radius-base);

  position: relative;

  .pdf-edit-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--background-opacity-primary);
    z-index: 999998;
  }
  .pdf-selection-bar {
    border-top-left-radius: var(--border-radius-base);
    border-top-right-radius: var(--border-radius-base);
    padding: 10px 10px 5px 10px;
    background: var(--background-color-deep);

    .bf-chooser-select {
      .labeled-input {
        .label {
          color: var(--text-color-contrast);
        }
      }
      .bf-button.bf-chooser-select-button {
        background: var(--background-color-deep);
        padding: 3px 10px;
        .select-value {
          .sub-label {
            color: var(--text-color-disabled);
          }
          color: var(--text-color-contrast);
        }
      }
    }
  }
  .pdf-outer-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .pdf-container {
      flex-grow: 1;
      overflow: hidden;

      .pdf-viewer {
        background: var(--background-color-secondary);
        .filename {
          opacity: 0;
        }
      }
    }
  }
}
