.cb-rental-agreement-form-wizard-step4-contract-data {
  width: 90vw;

  .label-header {
    display: flex;
    align-items: center;
    .label {
      margin-right: var(--m-5);
    }
    .section-title {
      margin-right: var(--m-5);
    }
  }

  .additional-actions {
    padding: 25px 20px 20px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    .bf-button {
      border-radius: var(--border-radius-extra-large);
    }
  }
}
