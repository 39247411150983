.comment-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  .pinned-messages {
    padding-bottom: var(--p-5);
    padding-top: var(--p-5);
    border-bottom: solid 1px var(--border-color-tertiary);
    .pinned-message-button {
      width: 100%;
      text-align: center;
      button.rs-dropdown-toggle {
        // width: 100%;
        background: var(--border-color-tertiary);
        color: var(--text-color-contrast);
        .button-content {
          display: flex;
          .bf-icon {
            margin-right: var(--m-2);
          }
        }
      }
      .comments-list-pinned-messages-container {
        max-height: 60vh;
        border: solid 1px var(--border-color-tertiary);
        border-radius: var(--border-radius-base);
        overflow: auto;
        background: var(--border-color-tertiary);
        text-align: initial;
        .bf-list {
          .list-scroll {
            padding: var(--p-5);
            .pinned-by {
              color: var(--text-color-disabled);
              display: flex;
              align-items: center;
              padding-bottom: var(--p-2);
              .label {
                padding-right: var(--p-3);
              }
              .date-of-pin {
                padding-left: var(--p-5);
              }
            }
            > div {
              &:last-of-type {
                padding-bottom: var(--p-0);
                margin-bottom: var(--m-0);
              }
            }
          }
        }
      }
    }
    .pinned-messages-container {
      max-height: 500px;
      position: relative;
      overflow: hidden;
    }
  }
  .list-wrapper {
    flex-grow: 1;
    overflow: hidden;
  }

  .bf-list.comments-view-as-list {
    .list-scroll {
      padding-top: var(--p-10);
      padding-bottom: var(--p-15);
    }
  }

  .table-view {
    margin-top: var(--m-5);
    height: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    .table-container {
      flex-grow: 1;

      .type-icon {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .s3-icon.size-xs {
          width: 20px;
          height: 20px;
        }
      }
      .sender-cell {
        overflow: hidden;
        text-overflow: ellipsis;
        .user-label {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;

          span {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .receiver-cell {
        overflow: hidden;
      }
    }

    .epost-description {
      display: flex;
      width: 100%;
      overflow: hidden;
      .description {
        flex-grow: 1;
        overflow: hidden;
        padding-left: var(--p-5);
        span {
          max-width: 100%;
        }
      }
    }
    .opened-email {
      height: 70%;
      .comment-entry {
        height: 100%;
        display: flex;
        padding-bottom: var(--p-0);
        flex-direction: column;
        .archived-info-container {
          background: var(--background-color-primary);
        }
        .comment-container {
          flex: 1 0 0;
          overflow: hidden;
          height: 100%;

          .bubble {
            height: 100%;
            display: flex;
            flex-direction: column;
            .content-wrapper {
              flex: 1 0 0;
              height: 100%;
              overflow: auto;
              .content {
                max-height: initial;
              }
            }
          }
        }
      }
    }

    .table-column.archived-entry {
      opacity: 0.5;
    }
  }
}
