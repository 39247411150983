.gf-label {
  &.text-style-h1 {
    font-size: 26px;
    margin-bottom: var(--m-4);
    font-weight: bold;
  }
  &.text-style-h2 {
    font-size: 22px;
    margin-bottom: var(--m-4);
    font-weight: bold;
  }
  &.text-style-h3 {
    font-size: 18px;
    margin-bottom: var(--m-4);
  }
  &.text-style-h4 {
    font-size: 16px;
    margin-bottom: var(--m-4);
  }
  &.text-style-h5 {
    font-size: 16px;
    margin-bottom: var(--m-4);
    text-transform: uppercase;
  }
  &.text-style-p {
    margin-bottom: var(--m-4);
  }
  &.text-style-label {
  }
}
