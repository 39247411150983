@import "../../styles/vars.scss";

.view-login-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: Flex;
  flex-direction: column;
  overflow: auto;
  .background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--login-background); //url("/img/background.jpg");
    transition: background-color 0.2s;
    background-size: cover;
    z-index: -1;
  }
  .powered-by {
    padding: 30px 0px 10px 0px;
    text-align: center;
  }
  &.left-aligned {
    .view-login {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: var(--background-color-contrast);
      .header {
        button {
          span {
            color: var(--text-color-strong);
          }
        }
      }
      .center {
        .login-center {
          box-shadow: none;
        }
      }
      .footer {
        color: var(--text-color-strong);
        button {
          color: var(--text-color-strong);
        }
      }
    }
  }
  &.clear {
    .view-login {
      .center {
        .login-center {
          background: transparent;
          box-shadow: none;
          img.logo {
            max-height: 80px;
          }

          .reset-password-form {
            color: var(--text-color-contrast) !important;
          }
          .login-form {
            .form-row {
              margin-bottom: var(--m-0);
              .bf-input {
                input {
                  border-bottom: none;
                }
              }
            }
            .mandator-field {
              border-top-left-radius: var(--border-radius-big);
              border-top-right-radius: var(--border-radius-big);
              border-bottom-left-radius: var(--border-radius-big);
              border-bottom-right-radius: var(--border-radius-big);
            }
            .mail-field {
              border-top-left-radius: 0px;
              border-top-right-radius: 0px;
              border-bottom-left-radius: 0px;
              border-bottom-right-radius: 0px;
            }
            .password-field {
              border-top-left-radius: 0px;
              border-top-right-radius: 0px;
              border-bottom-left-radius: var(--border-radius-big);
              border-bottom-right-radius: var(--border-radius-big);
            }
            &.no-mandator {
              .mail-field {
                border-top-left-radius: var(--border-radius-big);
                border-top-right-radius: var(--border-radius-big);
              }
            }
            &.collapsed {
              .mandator-field {
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
              }
            }
          }
          .reset-password-form,
          .complete-registration-form {
            .gf-label {
              color: var(--text-color-contrast) !important;
            }
            .labeled-input {
              .label {
                color: var(--text-color-contrast);
              }
            }
          }
          .trusted-device-login-form {
            .list {
              .trusted-device-entry {
                .displayname {
                  color: var(--text-color-contrast);
                }
              }
            }
          }
          .bf-button.primary {
            background: transparent;
            border: solid 1px var(--border-color-contrast);
          }
          .bf-button.clear-on-white {
            color: var(--text-color-contrast);
            &:active:focus {
              color: var(--text-color-contrast);
            }
          }
        }
      }
    }
  }

  .cookies-notification {
    padding: var(--p-10);
    background: var(--login-cookies-notification-background);
    color: var(--login-cookies-notification-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    box-shadow: var(--color-neutral) 0px 0px 20px;
    .text {
      margin-right: var(--m-8);
      max-width: 400px;
    }
    .buttons {
      position: relative;
      button {
        color: var(--login-cookies-notification-color);
        border: solid 1px var(--border-color-contrast-inverse);
      }

      .arrow-animation {
        position: absolute;
        left: 100%;
        top: -11px;
      }
    }
  }
  .view-login {
    flex-grow: 1;
    display: Flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .center {
      flex-grow: 1;
      display: Flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > .login-center {
        display: flex;
        margin-left: var(--m-15);
        margin-right: var(--m-15);
        justify-content: center;
        align-items: stretch;
        flex-direction: column;
        background: var(--login-center-background);
        padding: var(--p-13);
        box-shadow: var(--color-neutral) 0px 0px 20px;
        .logo {
          margin: auto auto var(--m-13) auto;
          text-align: center;
          max-width: 70%;
        }
      }
    }
    .footer {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding-left: var(--p-5);
      color: var(--login-footer-button-color);
      align-items: center;
    }
  }
}
@media screen and (max-width: 400px) {
  .view-login-wrapper {
    .cookies-notification {
      flex-direction: column;
      .text {
        max-width: unset;
        margin-bottom: var(--m-8);
      }
      .buttons {
        width: 100%;
        .bf-button {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .view-login-wrapper {
    &.left-aligned {
      .view-login {
        position: relative;
        top: initial;
        left: initial;
        bottom: initial;
        background: transparent;
        .header {
          button {
            span {
              color: var(--text-color-contrast);
            }
          }
        }
        .center {
          .login-center {
            box-shadow: var(--color-neutral) 0px 0px 20px;
          }
        }
        .footer {
          color: var(--text-color-contrast);
          button {
            color: var(--text-color-contrast);
          }
        }
      }
    }
  }
}
