.content_wrapper {
  display: flex;
}

.list_component_wrapper {
  width: 300px;
  flex-shrink: 0;
  max-height: 700px;
  overflow-y: auto;
}

.open_layer_container {
  width: 100%;
  height: auto;
  min-height: 500px;
  border: 1px solid var(--rs-border-primary);
  margin: var(--m-0) var(--m-8);

  display: flex;
  justify-content: center;
  align-items: center;

  .pick_floor_plan_text {
    font-size: var(--font-size-lg);
  }
}
