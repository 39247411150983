.asset-image-upload-dialog {
  .title {
    font-size: var(--font-size-md);
    padding-bottom: var(--p-10);
  }
  .images {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    .image-container {
      position: relative;
      .remove-button {
        position: absolute;
        top: -10px;
        right: -10px;
        padding: var(--p-3);
        border-radius: var(--border-radius-rounded);
        background: var(--background-color-contrast);
      }

      .uploading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--background-color-deep);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .finish-icon {
          color: var(--icon-color-contrast);
        }
        .progress {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          background: var(--background-color-accent);
        }
      }
    }
    .upload-button {
      width: 150px;
      height: 150px;
    }
  }

  .actions {
    padding-top: var(--p-10);
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
