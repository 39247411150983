body .bf-icon {
  font-family: "iberio" !important;
  font-style: normal;

  &.animate-transform {
    transition: transform 0.3s;
  }

  &.size-norm {
    font-size: 1.15em;
  }
  &.size-xxs {
    font-size: 0.55em;
  }
  &.size-xs {
    font-size: 0.75em;
  }
  &.size-sm {
    font-size: 1em;
  }

  &.size-lg {
    font-size: 1.2em;
  }

  &.size-xl {
    font-size: 1.3em;
  }

  &.size-2x {
    font-size: 2em;
  }

  &.size-3x {
    font-size: 3em;
  }

  &.size-4x {
    font-size: 4em;
  }

  &.size-5x {
    font-size: 5em;
  }
}

@import "font/css/icons.css";
