.cb-renter-list-card {
  .filters {
    display: flex;
    .actions {
    }
    .groups {
      flex-grow: 1;
      .bf-button-group:first-of-type {
        margin-right: var(--m-8);
      }
    }
  }
  .renter-list-table {
    height: 100%;
    display: flex;
    flex-direction: column;
    .table-wrapper {
      flex-grow: 1;
      box-shadow: var(--default-inset-shadow);
      .cb-renter-list-entry {
        width: 100%;
        padding: var(--p-5);
        display: flex;
        .left {
          flex-grow: 1;
          overflow: hidden;
          min-width: 40%;

          .id {
            font-size: var(--font-size-sm);
            color: var(--text-color-primary);
          }
          .name {
            font-size: var(--font-size-md);
            font-weight: 500;
            word-wrap: break-word;
            white-space: normal;
            &.vacant {
              color: var(--color-negative);
            }
          }
          .type {
          }
          .address {
            display: flex;
            word-wrap: break-word;
            white-space: normal;
            .object {
              padding-right: var(--p-5);
              font-weight: 500;
            }
          }
        }
        .right {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .data-entry {
            padding-left: var(--p-8);
            .label {
              font-size: var(--font-size-sm);
              color: var(--text-color-secondary);
            }
            .value {
              font-size: var(--font-size-md);
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
