.cb-booking-comment-form {
  .comment-form {
    padding: var(--p-5);
    .description {
      max-width: 100%;
      white-space: normal;
      margin-bottom: var(--m-3);
    }
    .comment-actions,
    .category-actions,
    .object-actions {
      display: flex;
      justify-content: flex-end;
      padding-top: var(--p-5);
      button {
        margin-left: var(--m-5);
      }
    }
  }
}
