.ap-construction-diary-entry-row {
  padding: var(--p-5);
  border-bottom: solid 1px var(--border-color-primary);
  box-sizing: border-box;
  align-items: stretch;
  &:last-of-type {
    border-bottom: none;
  }
  .image-container {
    width: 100%;
    height: 150px;
  }
  .images-container {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    padding-top: var(--p-5);
    > .image {
      position: relative;
      .info-indicator {
        position: absolute;
        top: 0;
        left: 0;
        background: var(--background-color-light-base);
        color: var(--text-color-contrast);
        border-bottom-right-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        .bf-icon {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
  .row-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    .row-head {
      display: flex;
      align-items: center;
      padding-bottom: var(--p-3);
      .status {
        font-size: var(--font-size-sm);
        color: var(--text-color-secondary);
      }
      .title {
        font-size: var(--font-size-md);
        padding-left: var(--p-3);
      }
      .progress-container {
        width: 120px;
        margin-left: var(--m-3);
      }
      //   .progress {
      //     margin-left: var(--m-3);
      //     border-radius: var(--border-radius-big);
      //     padding: var(--p-2);
      //     font-size: var(--font-size-sm);
      //   }
    }
    .row-remark {
    }
  }
}
