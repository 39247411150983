.contact-relations-view {
  height: 100%;
  --filter-bar-height: 80px;
  .relations-filter-bar {
    height: var(--filter-bar-height);
    border-bottom: solid 1px var(--border-color-secondary);
    padding: var(--p-10);
    display: flex;

    background: var(--background-color-primary);

    position: relative;
    .border-top-overlay {
      position: absolute;
      top: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background: var(--background-color-primary);
    }
  }

  .relations-content {
    height: calc(100% - var(--filter-bar-height));
    padding: var(--p-10);

    overflow: auto;

    .form-wrapper {
      padding: var(--p-10) var(--p-5) var(--p-5) var(--p-5);
    }
  }
}
