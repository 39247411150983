.ra-invoice-form {
  .form-head {
    display: flex;

    padding-bottom: var(--p-5);
    .urgend-field {
      height: fit-content;
      --rs-toggle-checked-bg: var(--background-color-toggle-urgent-checked);
      --rs-toggle-checked-hover-bg: var(--color-negative);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1px 12px 2px 6px;
      border-radius: 30px;
      transition: background-color 0.2s ease-in-out,
        border-color 0.2s ease-in-out;
      // border: solid 1px var(--border-color-contrast);
      .label {
        font-size: var(--font-size-sm);
        padding-top: var(--p-1);
      }
      &.urgent {
        background: var(--background-urgent);
        .label {
          // font-weight: 500;
          color: var(--color-negative);
        }
      }
    }
  }
  .urgent-message {
    background: var(--background-urgent);
    border-radius: var(--border-radius-base);
    padding: var(--p-5);
    margin-bottom: var(--m-5);
    .description {
      font-size: var(--font-size-sm);
      color: var(--color-negative);
      padding-bottom: var(--p-5);
    }
    textarea {
      &:focus-visible {
        outline-color: var(--color-negative) !important;
      }
    }
  }
  .form-row {
    display: flex;
    width: 100%;
    padding-bottom: var(--p-5);
    gap: 5px;

    .flex-container {
      display: flex;
      gap: 5px;
    }
  }
  .additional-tabs {
    margin-bottom: var(--m-10); //delete
  }
}

.ra-invoice-info-form {
  .description {
    color: var(--text-color-secondary);
    font-size: var(--font-size-sm);
    margin-bottom: var(--m-5);
  }
  .entries {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .entry {
      flex: 1 1 40%;
    }
  }
}
.ra-invoice-costcenter-form {
  .description {
    color: var(--text-color-secondary);
    font-size: var(--font-size-sm);
    margin-bottom: var(--m-5);
  }
  .entries {
    .cost-center-entry {
      border-bottom: solid 1px var(--border-color-primary);
      padding-bottom: var(--p-5);
      margin-bottom: var(--m-5);
      &:last-of-type {
        border-bottom: none;
        padding-bottom: var(--p-0);
        margin-bottom: var(--m-0);
      }

      .cost-center-entry-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .entry-title {
          font-size: var(--font-size-sm);
          font-weight: bold;
          color: var(--text-color-secondary);
        }
      }
      .form-row {
        gap: 10px;
        display: flex;
      }
    }
  }
  .array-actions {
    .add-action {
      margin-top: var(--m-5);
      display: flex;
      justify-content: center;
    }
    .rest-sum {
      margin-top: var(--m-5);
      display: flex;
      justify-content: flex-end;
    }
  }
}

.ra-invoice-payment-plan-form {
  .description {
    color: var(--text-color-secondary);
    font-size: var(--font-size-sm);
    margin-bottom: var(--m-5);
  }

  .payment-plan {
    .entries {
      .payment-plan-entry {
        padding: var(--p-3);
        border-bottom: solid 1px var(--border-color-primary);
        &:last-of-type {
          border-bottom: none;
        }
        .form-row {
          gap: 10px;
          display: flex;
          align-items: flex-end;

          .date,
          .amount {
            flex: 1;
          }
          .usage {
            flex: 3;
          }
          .activeDate {
            flex: 1;
          }
        }
      }
    }
  }
  .create-action {
    display: flex;
    justify-content: center;
    .create-plan-dropdown.bf-dropdown.rs-dropdown-menu {
      width: 450px;
    }
  }
}

.ra-invoice-payment-plan-creation-form {
  padding: var(--p-3);
}
