.user-textblocks-config {
  .user-textblocks-config__header {
    padding-bottom: var(--p-5);
    display: flex;
    align-items: center;
    .bf-button {
      margin-left: var(--m-5);
    }
  }
  .user-textblocks-config__description {
    padding-bottom: var(--p-5);
    max-width: 900px;
    color: var(--text-color-secondary);
  }
  .user-textblocks-config__content {
    .user-textblocks-config__content__empty {
      color: var(--text-color-secondary);
    }
    .textblock-entry {
      padding: 0px 0px 20px 0;

      .textblock-entry__header {
        display: flex;
        align-items: center;
        .textblock-entry__header__title {
          padding-right: var(--p-5);
          flex-grow: 1;
          font-size: var(--font-size-md);
        }
        .textblock-entry__header__actions {
          display: flex;
          align-items: center;
          .bf-button {
            margin-left: var(--m-5);
          }
        }
      }
      .textblock-entry__content {
        border: 1px solid var(--border-color-primary);
        max-height: 200px;
        padding: var(--p-10);
        overflow: auto;
      }
    }
  }
  .add-action-container {
    padding: var(--p-5);
  }
}

.user-textblock-form {
  .ck.ck-editor {
    overflow: hidden;
    .ck.ck-content {
      max-height: 400px;
      min-height: 160px;
    }
  }
}
