.taskListWrapper {
  display: table;
  border-bottom: var(--border-color-primary) 1px solid;
  border-left: var(--border-color-primary) 1px solid;
}

.taskListTableRow {
  display: table-row;
  text-overflow: ellipsis;
}

.taskListTableRow:nth-of-type(even) {
  background-color: var(--background-color-primary);
}

.taskListCell {
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.taskListNameWrapper {
  display: flex;
}

.taskListExpander {
  color: var(--text-color-primary);
  font-size: 0.6rem;
  padding: 0.15rem 0.2rem 0rem 0.2rem;
  user-select: none;
  cursor: pointer;
}
.taskListEmptyExpander {
  font-size: 0.6rem;
  padding-left: 1rem;
  user-select: none;
}
