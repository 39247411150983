.bf-pin-input {
  &.appearance-default {
    .pin-field {
      border: 1px solid var(--bf-input-primary-border);
      border-right: none;
      font-size: 2rem;
      height: 4rem;
      outline: none;
      text-align: center;
      transition-duration: 250ms;
      transition-property: color, border, box-shadow, transform;
      width: 4rem;
      color: var(--text-color-primary);
      background-color: var(--rs-input-bg);

      &:first-of-type {
        border-radius: 0.5rem 0 0 0.5rem;
      }

      &:last-of-type {
        border-radius: 0 0.5rem 0.5rem 0;
        border-right: 1px solid var(--bf-input-primary-border);
      }

      &:focus {
        border-color: var(--bf-input-focus-border);
        box-shadow: 0 0 0.25rem var(--bf-input-focus-box-shadow);
        outline: none;
        z-index: 1241242;

        & + .pin-field {
          border-left-color: var(--bf-input-focus-border);
        }
      }

      &:invalid {
        animation: shake 3 linear 75ms;
        border-color: var(--bf-input-invalid-border);
        box-shadow: 0 0 0.25rem var(--bf-input-invalid-box-shadow);

        & + .pin-field {
          border-left-color: var(--bf-input-invalid-border);
        }
      }

      &.complete {
        border-color: var(--bf-input-complete-color);
        color: var(--bf-input-complete-color);

        &[disabled] {
          background: var(--bf-input-complete-disabled-background);
          cursor: not-allowed;
          opacity: 0.5;
        }

        & + .pin-field {
          border-left-color: var(--bf-input-complete-color);
        }
      }
    }
  }
  .validation {
    margin-top: var(--m-3);
    &.error {
      color: var(--color-negative);
    }
    &.warning {
      color: var(--color-warning);
    }
  }
}
