.bf-app-permission {
  margin-bottom: var(--m-10);
  .app-header {
    // padding-bottom: var(--p-3);
    display: flex;
    align-items: center;
    .title {
      flex-grow: 1;
      font-size: var(--font-size-lg);
    }
  }

  .app-entries {
    border-top: solid 1px var(--border-color-secondary);
    border-bottom: solid 1px var(--border-color-secondary);

    .no-entries {
      padding: var(--p-8);
      color: var(--text-color-secondary);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center0;
    }

    .bf-app-permission-entry {
      button.entry-header {
        width: 100%;
        background: transparent;
        display: flex;
        align-items: center;
        padding-top: var(--p-5);
        padding-bottom: var(--p-5);
        &:hover {
          background: var(--background-color-primary);
        }
        .logo {
          margin-right: var(--m-5);
          width: 40px;
          height: 40px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50% 50%;
        }
        .app-name {
          font-size: var(--font-size-md);
          text-align: left;
          flex-grow: 1;
        }
        .indicator {
          margin-right: var(--m-3);
          .bf-icon {
            transition: transform 0.3s;
          }
        }
      }

      border-bottom: solid 1px var(--border-color-primary);
      &:last-of-type {
        border-bottom: none;
      }

      .app-permissions {
        padding: var(--p-5);
        .permission-field {
          padding-bottom: var(--p-5);
          &:last-of-type {
            padding-bottom: var(--p-0);
          }
          .field-label {
            font-size: var(--font-size-md);
            color: var(--text-color-secondary);
            padding-bottom: var(--p-1);
          }
          .field-value {
            font-size: var(--font-size-md);
          }
        }
        .no-permissions-available {
          padding: var(--p-8);
          color: var(--text-color-secondary);
          text-align: center;
        }
        .delete-action {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
