.overlay-project {
  width: 320px;
  overflow: visible;
  background: var(--background-color-contrast);
  z-index: 6;
  display: flex;
  flex-direction: column;
  .selected-project-list {
    padding: var(--p-5);
    gap: 5px;
    overflow: auto;
    max-height: 150px;
    display: flex;
    flex-wrap: wrap;
    .no-entries {
      color: var(--text-color-secondary);
      font-size: var(--font-size-sm);
      text-align: center;
    }
    .selected-project-entry {
      // border: 1px solid var(--border-color-secondary);
      border-radius: var(--border-radius-base);
      display: flex;
      align-items: center;
      .project-label {
        margin-right: var(--m-2);
      }
      .remove-project-button {
        opacity: 0;
        transition: opacity 0.2s;
        margin-right: var(--m-3);
      }
      &:hover {
        .remove-project-button {
          opacity: 1;
        }
      }
    }
  }
  .search {
    .bf-input {
      border: none !important;
      outline: none !important;
    }
  }

  .content {
    height: 400px;
    flex-grow: 1;
    overflow: hidden;
    border-top: solid 1px var(--border-color-secondary);
    border-bottom: solid 1px var(--border-color-secondary);

    .entry {
      .wrapper {
        width: 100%;

        .rs-checkbox {
          width: 100%;

          label {
            display: block;
            width: 100%;
            .project-label {
              display: block;
              width: 100%;
            }
          }
        }
      }
    }
    // .range {
    //   display: flex;
    //   .from,
    //   .to {
    //     flex: 1 0 50%;
    //   }
    //   .from {
    //     margin-right: var(--m-3);
    //   }
    //   .to {
    //     margin-left: var(--m-3);
    //   }
    // }
  }
  .footer {
    padding: var(--p-2);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
