.dropdown-component {
  font-size: initial;

  .rs-dropdown-menu {
    .rs-dropdown-item-panel {
      display: flex;
      justify-content: stretch;
    }
  }
  &:hover,
  &.rs-dropdown-open {
    background: var(--background-opacity-tertiary-weak);
  }
  color: var(--text-color-contrast);
  .rs-dropdown-toggle {
    height: 100%;
  }

  &.dark {
    .rs-dropdown-menu {
      background: var(--dropdown-background-dark);
    }
  }
}

.rs-dropdown.dropdown-component .rs-dropdown-menu {
  transform-origin: 100% 0%;
  animation-name: scale-out;
  animation-fill-mode: forwards;
  animation-duration: 0.15s;
  animation-iteration-count: 1;
}

.rs-dropdown-open.dropdown-component > .rs-dropdown-menu {
  animation-name: scale-in;
  animation-fill-mode: forwards;
  animation-duration: 0.15s;
  animation-iteration-count: 1;
}
