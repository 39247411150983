@mixin bf-checkbox-size(
  $width,
  $height,
  $fontsize,
  $checkWidth,
  $checkHeight,
  $checkMarginLeft,
  $checkMarginTop,
  $minHeight,
  $paddingLeft
) {
  .rs-checkbox-checker {
    min-height: $minHeight;
    padding-left: $paddingLeft;
    .rs-checkbox-wrapper {
      width: $width;
      height: $height;
      top: initial;
      // top: 6px;
      // left: 6px;

      &:before {
        width: $width;
        height: $height;
      }

      .rs-checkbox-inner {
        &:before {
          width: $width;
          height: $height;
        }
        &:after {
          width: $checkWidth; //6px;
          height: $checkHeight; //14px;
          margin-left: $checkMarginLeft; //9px;
          margin-top: $checkMarginTop; // 3px;
        }
      }
    }
    label {
      font-size: $fontsize;
      line-height: $minHeight;
    }
  }
}

.rs-checkbox {
  &.appearance-modern {
    overflow: visible;
    .rs-checkbox-checker {
      overflow: visible;
      .rs-checkbox-wrapper {
        width: 24px;
        height: 24px;
        top: 6px;
        left: 6px;
        overflow: visible;
        border-radius: var(--border-radius-rounded);
        &:before {
          width: 24px;
          height: 24px;
          border-radius: var(--border-radius-rounded);
        }

        .rs-checkbox-inner {
          &:before {
            width: 24px;
            height: 24px;
            border-radius: var(--border-radius-rounded);
          }
          &:after {
            width: 6px;
            height: 14px;
            margin-left: var(--m-5);
            margin-top: var(--m-2);
          }
        }
      }
    }
  }

  .rs-checkbox-checker {
    padding-top: var(--p-0);
    padding-bottom: var(--p-0);
    display: flex;
    align-items: center;
    label {
      white-space: normal;
      .rs-checkbox-wrapper {
        background: var(--background-color-contrast);
        border-radius: var(--border-radius-base);
      }
    }
  }

  &.size-xs {
    @include bf-checkbox-size(
      12px,
      12px,
      11px,
      5px,
      10px,
      4px,
      0px,
      16px,
      30px
    );
  }
  &.size-sm {
    @include bf-checkbox-size(14px, 14px, 13px, 5px, 9px, 5px, 1px, 16px, 32px);
  }
  &.size-lg {
    @include bf-checkbox-size(
      28px,
      28px,
      16px,
      10px,
      17px,
      9px,
      3px,
      30px,
      46px
    );
  }
}

// $width,
// $height,
// $fontsize,
// $checkWidth,
// $checkHeight,
// $checkMarginLeft,
// $checkMarginTop,
// $minHeight,
// $paddingLeft
