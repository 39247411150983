.bf-nav {
  &.appearance-bf {
    border: solid 1px var(--border-color-secondary);
    background: var(--background-color-contrast);

    .rs-nav-item {
      &.rs-nav-item-active {
        background: var(--base-color);
        color: var(--text-color-contrast);
      }
    }
  }
}
