.kpi-single-value {
  padding: var(--p-5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .label {
    color: var(--text-color-secondary);
  }
  .value {
    text-align: center;
    font-size: var(--font-size-3xl);
  }
  &.error {
    .label {
      color: var(--color-negative);
    }
    .bf-button {
      .bf-icon {
        color: var(--color-negative);
      }
    }
  }
}
