.trusted-device-login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.ignoreAnimation {
    .list {
      .trusted-device-entry {
        transition: none;
      }
    }
  }
  .list {
    max-width: 400px;
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
    align-items: center;
    .trusted-device-entry {
      cursor: pointer;
      padding: 5px 10px;
      transition: transform 0.3s, opacity 0.3s, width 0.3s, height 0.3s,
        padding 0.3s;
      width: 96px;
      height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      &.active {
        transform: scale(1.2);
      }
      &.smaller {
        transform: scale(0);
        width: 0px;
        height: 120px;
        padding: var(--p-0);
        opacity: 0;
      }
      .displayname {
        margin-top: var(--m-3);
        font-size: 0.9em;
        text-align: center;
      }
      .avatar-container {
        position: relative;
        .bf-button.remove-button {
          transform: scale(0);
          opacity: 0;
          transition: transform 0.3s, opacity 0.3s;
          display: none;
          position: absolute;
          top: -2px;
          right: -2px;
          width: 20px;
          height: 20px;
          border-radius: var(--border-radius-big);
          font-size: 0.7em;
          box-shadow: 0px 0px 2px 1px var(--color-neutral);
          padding: var(--p-0);
          justify-content: center;
          align-items: center;
        }
      }

      @media screen and (max-width: 400px) {
        width: 76px;
        height: 90px;
        padding: var(--p-3);
        .avatar.size-lg {
          width: 44px;
          height: 44px;
          max-width: 44px;
          max-height: 44px;
          min-width: 44px;
          min-height: 44px;
          font-size: var(--font-size-xl);
        }
        .displayname {
          font-size: 0.8em;
        }
        &.active {
          transform: scale(1.4);
        }
      }
    }
  }
  .pin-input-container {
    margin-top: var(--m-8);
    position: relative;
    &.loading {
      .bf-pin-input {
        opacity: 0.4;
      }
    }

    @media screen and (max-width: 400px) {
      .bf-pin-input {
        input.pin-field {
          font-size: 1rem;
          height: 2.4rem;
          width: 2.4rem;
        }
      }
    }
  }
  .user-actions {
    display: flex;
    justify-content: center;
    margin-top: var(--m-8);
    width: 100%;
  }

  .actions {
    margin-top: var(--m-5);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    &.center {
      justify-content: center;
    }
  }
  .actions-list-icons {
    width: 100%;
  }

  &.edit {
    .list {
      .trusted-device-entry {
        cursor: default;
        .avatar-container {
          animation: shake 3 linear 120ms;
          .bf-button.remove-button {
            display: flex;
            animation-name: trusted-device-login-delete-animate-in;
            animation-duration: 0.3s;
            animation-fill-mode: forwards;
            animation-delay: 0.2s;
          }
        }
      }
    }
  }
}

@keyframes trusted-device-login-delete-animate-in {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
