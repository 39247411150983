.bf-tree {
  height: 100%;
  width: 100%;

  .rs-tree {
    height: 100% !important;
    max-height: 100%;
  }
  .rs-tree-nodes,
  .rs-tree-root {
    padding: var(--p-3);

    .rs-tree-node {
      margin: var(--m-0);
      width: 100%;
      padding: var(--p-0);

      &.rs-tree-node-active {
        background: var(--table-row-color-hover);

        .rs-tree-node-label-content {
          background: var(--table-row-color-hover);
        }
      }

      &:hover {
        background: var(--background-color-primary);
        .rs-tree-node-label-content {
          background: var(--background-color-primary);
        }
      }

      &:has(.is-dragged-over) {
        background: var(--background-color-primary);
        .rs-tree-node-label-content {
          background: var(--background-color-primary);
        }
      }

      .rs-tree-node-label {
        width: 100%;
        padding: var(--p-0);
        padding-left: var(--p-4);
        display: flex;

        .bf-tree-entry-wrapper {
          width: 100%;
          padding: 8px 6px 8px 8px;
          color: var(--text-color-primary);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          .bf-icon {
            display: inline-block;
            vertical-align: middle;
            margin-right: 0.5rem;
          }
        }

        .rs-tree-node-label-content {
          width: 100%;
          border: 0;
          margin: var(--m-0);
          padding: var(--p-0);

          &:hover {
            background: var(--background-color-primary);
          }
        }
      }
    }

    .rs-tree-node-children {
      .rs-tree-node {
        .rs-tree-node-expand-icon-wrapper {
          .rs-tree-node-expand-icon {
            font-size: var(--font-size-lg);
            &:not(.rs-tree-node-expanded) {
              margin-top: var(--m-5);
            }
          }
        }
      }
    }
  }
}
