.fixed-header-container {
  .cb-fixed-header {
    transition: transform 0.2s ease;
    transform: translateY(-100%);
    background: var(--background-color-primary);
    border-bottom: solid 1px var(--border-color-tertiary);
    border-right: solid 1px var(--border-color-secondary);
    .head {
      display: flex;
      justify-content: center;
      align-items: center;
      .title-container {
        .title {
          font-size: var(--font-size-base);
          font-weight: 600;
        }
        .info {
          color: var(--text-color-primary);
          font-size: var(--font-size-xs);
          margin-left: var(--m-3);
        }
      }
    }

    .subheader {
      width: 100%;
      display: flex;
      .cell {
        flex: 1 1 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--text-color-secondary);
        text-transform: uppercase;
        font-size: var(--font-size-xs);
      }
    }
  }
  &.show {
    .cb-fixed-header {
      transform: translateY(0%);
    }
  }
}
