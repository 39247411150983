@import "./../../CashBudgetGlobalStyles.scss";

.tatar-cash-budget-table-view-root {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px 20px 10px 20px;
  overflow-y: auto;
  .green-positive {
    color: var(--color-positive);
  }
  @media screen and (max-width: 950px) {
    padding: var(--p-3);
  }
  .filter-header {
    display: flex;
    margin-bottom: var(--m-10);
    @media screen and (max-width: 950px) {
      margin-bottom: var(--m-3);
      .cashbudget-filter-header {
        .labeled-input {
          .label {
            display: none;
          }
        }
      }
      .bf-date {
        .rs-picker-date {
          width: fit-content !important;
          .rs-btn.rs-picker-toggle {
            width: 50px;
            display: flex;
            justify-content: center;
            padding-right: var(--p-5);
            padding-left: var(--p-5);
            .rs-picker-toggle-placeholder {
              width: 0px;
              overflow: hidden;
              display: block;
              padding: var(--p-0);
            }
            .rs-picker-toggle-caret {
              right: 20px;
            }
          }
        }
      }
    }
    .actions {
      display: flex;

      button {
        width: -webkit-fill-available;
        margin-left: var(--m-5);
        // min-width: 200px;
        padding-left: var(--p-5);
        padding-right: var(--p-5);
        @media screen and (max-width: 900px) {
          span {
            display: none;
          }
        }
      }
    }
  }
  .mobile-comparison-filter {
    display: none;
    margin-bottom: var(--m-5);
    margin-top: var(--m-3);
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .filter-type {
      .active {
        background: var(--base-color);
        color: var(--text-color-contrast);
      }
    }
  }
  .content-card {
    background: var(--background-color-contrast);
    flex-grow: 1;
    flex-basis: auto;
    min-height: 0;
    border-radius: var(--border-radius-base);
    box-shadow: 0px 3px 9px 0px var(--shadow-contrast-inverse-5);
    display: flex;
    overflow-y: hidden;
    position: relative;
    .view-type-selection {
      margin-bottom: var(--m-3);
    }
  }
  .status-bar {
    display: flex;
    padding-top: var(--p-5);
    .entry {
      border-right: var(--border-color-tertiary) 1px solid;
      display: flex;
      color: var(--text-color-strong);
      padding-right: var(--p-5);
      margin-right: var(--m-5);
      align-items: flex-end;
      .label {
        padding-right: var(--p-3);
        font-size: var(--font-size-base);
        color: var(--text-color-primary);
      }
      &:last-child {
        border-right: none;
        padding-right: var(--p-0);
        margin-right: var(--m-0);
      }
    }
  }
  @media screen and (max-width: 950px) {
    .mobile-comparison-filter {
      display: flex;
    }
    .status-bar {
      display: none;
    }
  }
}

.table-tooltip {
  padding: var(--p-3);
  background: var(--background-color-contrast);
  border-radius: var(--border-radius-base);
  box-shadow: 0px 3px 9px 0px var(--shadow-contrast-inverse-5);
}
