.ra-invoice-process-history-inline {
  display: inline-flex;
  align-items: center;
  .line {
    height: 2px;
    width: 2px;
    background: var(--background-color-secondary);
  }
  .ra-invoice-process-history-inline-entry {
    .status-wrapper {
      border-radius: var(--border-radius-big);
      display: flex;
      background: var(--background-color-secondary);
      .status-icon {
        z-index: 1;
        width: 26px;
        height: 26px;
        border: solid 1px var(--border-color-secondary);
        border-radius: var(--border-radius-rounded);
        overflow: hidden;
        flex-shrink: 0;
        background: var(--background-color-primary);
        display: flex;
        justify-content: center;
        align-items: center;

        .icon-container {
          width: 100%;
          height: 100%;
          background: var(--background-color-primary);
          display: flex;
          justify-content: center;
          align-items: center;
          .bf-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--icon-color-secondary);
            width: 16px;
            height: 16px;
          }
        }
        &.next_approval {
          border: dashed 1px var(--border-color-secondary);
        }
        &.current_approval {
          border: 2px solid var(--border-color-secondary);
        }
        .current-icon {
          background: var(--background-color-primary);
          color: var(--icon-color-secondary-accent);
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .bf-icon {
            width: 26px;
            height: 26px;
          }
        }
      }
      .label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: var(--p-2);
        padding-right: var(--p-4);
        height: 26px;
        .status {
          font-size: var(--font-size-sm);
          font-weight: 500;
          line-height: 12px;
        }
        .date {
          font-size: var(--font-size-xs);
          line-height: 10px;
        }
      }
    }
  }
}

.ra-invoice-process-history-inline-overlay {
  .ra-invoice-process-history {
    padding: var(--p-5);
  }
}
