.permissions-form-field-simple {
  margin-top: var(--m-15);
  .header {
    display: flex;
    align-items: center;
    // border-bottom: solid 1px var(--divider-color);
    padding-bottom: var(--p-3);
    margin-top: var(--m-8);
    .title {
      flex-grow: 1;
      // font-weight: 500;
      color: var(--text-color-strong);
      font-size: 1.1em;
      font-weight: 500;
    }
    .actions {
    }
  }

  .content {
    border-top: solid 1px var(--divider-color-dark);
    border-bottom: solid 1px var(--divider-color-dark);
    padding-left: var(--p-5);
    padding-right: var(--p-5);
    margin-bottom: var(--m-15);
    overflow: initial;

    .no-entries-label {
      padding: var(--p-5);
      display: flex;
      justify-content: center;
      color: var(--text-color-disabled);
    }
    &.max-height {
      max-height: 300px;
    }

    .rs-list {
      box-shadow: none;
    }
  }
}

.permission-field-simple-appselection {
  &.no-overflow {
    overflow-y: initial;
    overflow-x: initial;
  }
  .logo {
    margin-right: var(--m-5);
    width: 35px;
    height: 35px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  .name-with-options {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .name {
      flex: 1 1 0px;
      padding: 5px 0px 5px 0px;
    }
    .role-selection {
      flex: 1 1 0px;
      max-width: 300px;
      padding-right: var(--p-3);
    }
  }
}

@media screen and (max-width: 1200px) {
  .permission-field-simple-appselection {
    .logo {
      align-self: flex-start;
    }
    .name-with-options {
      flex-direction: column;
      align-items: flex-start;
      .role-selection {
        margin-top: var(--m-8);
        max-width: initial;
      }
    }
  }
}
