@import "../../../../styles/vars.scss";

.bf-button {
  transition: box-shadow 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  &.inline {
    display: inline-flex;
    line-height: 16px;
  }
  &.no-padding {
    padding: var(--p-0);
  }
  &.only-icon {
    .text-label {
      padding-left: var(--p-3);
    }
  }
  &.circle {
    border-radius: var(--border-radius-rounded);
    padding: var(--p-4);
  }
  &.primary {
    color: var(--text-color-contrast);
    fill: var(--icon-fill-contrast);
  }

  &.only-icon {
    .bf-icon {
      margin-right: var(--m-0);
    }
  }
  &.jump {
    padding: var(--p-0);
    &:hover {
      text-decoration: underline;
    }
  }
  .label {
    display: flex;
    align-items: center;

    &.icon-left {
      flex-direction: row;
      .bf-icon {
        margin-right: var(--m-3);
      }
    }

    &.icon-right {
      flex-direction: row-reverse;
      .bf-icon {
        margin-left: var(--m-3);
      }
    }
    &.icon-top {
      flex-direction: column;
      .bf-icon {
        margin-bottom: var(--m-3);
      }
    }
    &.icon-bottom {
      flex-direction: column-reverse;
      .bf-icon {
        margin-top: var(--m-3);
      }
    }
  }

  &:focus:not(.rs-btn-subtle) {
    box-shadow: var(--base-color) 0px 0px 5px;
    z-index: 1;
  }

  &.rs-btn-subtle {
    background: var(--background-opacity-tertiary-low);
    color: var(--text-color-contrast);
    fill: var(--icon-fill-contrast);

    &:disabled {
      color: var(--text-color-disabled);
      fill: var(--icon-fill-disabled);
    }

    &:hover {
      background: var(--background-opacity-tertiary-weak);
      color: var(--text-color-contrast);
      fill: var(--icon-fill-contrast);
    }

    &:focus {
      background: var(--background-opacity-tertiary-weak);
      color: var(--text-color-contrast);
      fill: var(--icon-fill-contrast);
    }

    &:active:focus {
      background: var(--background-opacity-tertiary-weak);
      color: var(--text-color-contrast);
      fill: var(--icon-fill-contrast);
    }
  }

  &.rs-btn-link {
    color: var(--button-color-link);
    text-decoration: none;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      color: var(--button-color-link-hover);
    }
  }
  &.outline.rs-btn-subtle {
    border: solid 1px var(--button-color-outline);
    color: var(--button-color-outline);
    fill: var(--button-color-outline);

    &:focus {
      box-shadow: var(--base-color) 0px 0px 5px;
    }
    &:hover {
      color: var(--button-color-outline-hover);
      fill: var(--button-color-outline-hover);
    }
    &.rs-btn-disabled {
      opacity: 0.5;
    }
  }
  &.outline-white.rs-btn-subtle {
    border: solid 1px var(--border-color-contrast);
    color: var(--text-color-contrast);
    fill: var(--icon-fill-contrast);

    &:focus {
      box-shadow: var(--shadow-contrast) 0px 0px 5px;
    }
    &:hover {
      color: var(--text-color-contrast);
      fill: var(--icon-fill-contrast);
      background: var(--background-color-deep);
    }
  }

  &.rs-btn-subtle.clear-highlight {
    background: var(--background-opacity-tertiary-low);
    color: var(--base-color-hover);
    fill: var(--base-color-hover);

    &:disabled {
      color: var(--text-color-secondary);
      fill: var(--icon-fill-secondary);
    }

    &:hover {
      background: var(--background-opacity-weak);
      color: var(--base-color-hover);
      fill: var(--base-color-hover);
    }

    &:focus {
      background: var(--background-opacity-weak);
      color: var(--base-color-hover);
      fill: var(--base-color-hover);
    }

    &:active:focus {
      background: var(--background-opacity-weak);
      color: var(--base-color-hover);
      fill: var(--base-color-hover);
    }
  }

  &.rs-btn-subtle.clear-on-white {
    background: var(--background-opacity-tertiary-low);
    color: var(--text-color-primary);
    fill: var(--icon-fill-primary);

    &:disabled {
      color: var(--text-color-secondary);
      fill: var(--icon-fill-secondary);
    }

    &:hover {
      background: var(--background-opacity-weak);
      color: var(--text-color-primary);
      fill: var(--icon-fill-primary);
    }

    &:focus {
      background: var(--background-opacity-weak);
      color: var(--text-color-primary);
      fill: var(--icon-fill-primary);
    }

    &:active:focus {
      background: var(--background-opacity-weak);
      color: var(--text-color-primary);
      fill: var(--icon-fill-primary);
    }
  }

  &.rs-btn-subtle.transparent-light {
    background: var(--background-opacity-tertiary-weak);
    color: var(--text-color-contrast);
    fill: var(--icon-fill-contrast);

    &:disabled {
      color: var(--text-color-secondary);
      fill: var(--icon-fill-secondary);
    }

    &:hover {
      background: var(--background-opacity-tertiary-weak);
      color: var(--text-color-contrast);
      fill: var(--icon-fill-contrast);
    }

    &:focus {
      background: var(--background-opacity-tertiary-weak);
      color: var(--text-color-contrast);
      fill: var(--icon-fill-contrast);
    }

    &:active:focus {
      background: var(--background-opacity-tertiary-weak);
      color: var(--text-color-contrast);
      fill: var(--icon-fill-contrast);
    }
  }

  &.rs-btn-subtle.transparent-dark {
    background: var(--background-opacity-secondary);
    color: var(--text-color-contrast);
    fill: var(--icon-fill-contrast);

    &:disabled {
      color: var(--text-color-secondary);
      fill: var(--icon-fill-secondary);
    }

    &:hover {
      background: var(--background-opacity-secondary);
      color: var(--text-color-contrast);
      fill: var(--icon-fill-contrast);
    }

    &:focus {
      background: var(--background-opacity-secondary);
      color: var(--text-color-contrast);
      fill: var(--icon-fill-contrast);
    }

    &:active:focus {
      background: var(--background-opacity-secondary);
      color: var(--text-color-contrast);
      fill: var(--icon-fill-contrast);
    }
  }
  &.rs-btn-subtle.debug {
    background: transparent;
    color: var(--text-color-secondary);
    fill: var(--text-color-secondary);
    transition: transform 0.2s;
    &:disabled {
      color: var(--text-color-disabled);
      fill: var(--text-color-disabled);
    }

    &:hover {
      background: transparent;
      color: var(--color-warning-strong);
      fill: var(--color-warning-strong);
      transform: scale(1.1);
    }

    &:focus {
      background: transparent;
      fill: var(--color-warning-strong);
      color: var(--color-warning-strong);
      transform: scale(1.1);
    }

    &:active:focus {
      background: transparent;
      color: var(--color-warning-strong);
      fill: var(--color-warning-strong);
      transform: scale(1.1);
    }
  }
}
