.cb-vacancy-distribution-card {
  .charts {
    display: flex;
    flex-wrap: wrap;
    .chart-entry {
      display: flex;
      //   flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      &.fixed {
        width: 400px;
        flex-shrink: 0;
      }
      &.full {
        flex-grow: 1;
        min-width: 300px;
      }

      .chart-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;

        .chart-action {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
