.contact-drawer-backdrop {
  background: var(--background-opacity-primary);
}

.contact-search-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  .header {
    padding: var(--p-4);
    border-bottom: solid 1px var(--border-color-primary);
    .title {
      padding-bottom: var(--p-4);
      .contact-drawer-dropdown {
        button.rs-dropdown-toggle {
          background: var(--background-color-contrast);
          font-size: var(--font-size-xl);
        }
      }
    }
  }
  .search-result {
    flex-grow: 1;
    overflow: hidden;

    button.contact-entry {
      padding: 5px 10px;
      border-bottom: solid 1px var(--border-color-primary);
      display: flex;
      overflow: hidden;
      border-radius: var(--border-radius-none);
      width: 100%;
      max-width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      text-overflow: ellipsis;
      white-space: initial;
      text-align: left;
      .left {
        flex-grow: 1;

        .contact-name {
          font-size: var(--font-size-base);
        }
        .info-line {
          color: var(--text-color-secondary);
          font-size: var(--font-size-sm);
        }
      }
      .right {
        .tags {
          display: flex;
          gap: 4px;
          flex-wrap: wrap;
          justify-content: flex-end;
        }
      }
    }
  }
}
