.user-security {
  padding: 0px 20px 20px 20px;
  height: 100%;
  overflow: auto;
  .user-security__header {
    padding-bottom: var(--p-10);
  }
  .description {
    padding-bottom: var(--p-10);
  }
  .password-hint-container {
    padding-bottom: var(--p-5);

    .pw-strength {
      font-weight: 500;
      &.reallyWeak {
        color: var(--color-negative);
      }
      &.weak {
        color: var(--color-prio-high);
      }
      &.medium {
        color: var(--color-prio-medium);
      }
      &.strong {
        color: var(--color-positive);
      }
    }
    .pw-hint-extra {
      font-size: var(--font-size-sm);
      padding-top: var(--p-3);
    }
    .pw-hint {
      color: var(--text-color-secondary);
      font-size: var(--font-size-sm);
      padding-top: var(--p-3);
    }
  }
  .form-fields {
    max-width: 400px;
    .action-row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-top: var(--p-10);
    }
  }
}
