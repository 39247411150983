.ap-rent-reduction {
  .no-rent-reduction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--p-10);
    .description {
      padding-bottom: var(--p-8);
    }
  }

  .rent-reduction-card {
    .card-content {
      padding: var(--p-5);
      .fields {
        display: flex;
      }
      .actions {
        display: flex;
        padding-bottom: var(--p-5);
        justify-content: flex-end;
      }
    }

    .attachments {
      border-top: solid 1px var(--border-color-secondary);
    }
  }
}
