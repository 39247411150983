.cb-rental-agreement-open-accounts {
  display: flex;
  flex-direction: column;
  height: 100%;

  &.no-debit-positions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .head-line {
    display: flex;
    font-size: var(--font-size-xl);
    border-bottom: 1px var(--border-color-secondary) solid;
    font-weight: 600;
    .label {
      flex-grow: 1;
      padding: 5px 40px 5px 5px;
    }
    .value {
      padding: 5px 10px 5px 5px;
      &.negative {
        color: var(--color-negative);
      }
      &.positive {
        color: var(--color-positive);
      }
    }
  }
  .info-container {
    display: flex;
    flex-grow: 1;
    position: relative;
    overflow: hidden;

    .graph {
      height: 100%;
      flex: 1 0 50%;
    }
    .sub-positions {
      padding-left: var(--p-5);
      min-height: 100%;
      height: 100%;
      padding-right: var(--p-5);
      overflow: auto;
    }
  }

  table {
    width: 100%;
    tr {
      .label {
        text-align: left;
      }
      .value {
        text-align: right;
        //   font-weight: 600;
        &.negative {
          color: var(--color-negative);
        }
        &.positive {
          color: var(--color-positive);
        }
      }

      &.sub-debit {
        font-size: var(--font-size-md);
        td {
          padding: 5px 0px;
        }
        .label {
          button.bf-button {
            padding: var(--p-3);

            .chevron {
              margin-left: var(--m-5);
              transition: transform 0.3s;
              display: inline-flex;
              &.open {
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      &.sub-debit-details {
        .container-debit-wrapper {
          padding: 5px 5px 20px 5px;
          .container-debit-details {
            padding: var(--p-5);
            border: solid 1px var(--border-color-tertiary);
            border-radius: var(--border-radius-base);
          }
        }
      }
    }
  }
}
