@import "../../styles/vars.scss";

.application-body {
  //   background: var(--header-background);
  display: flex;
  height: 100%;
  flex-direction: column;
  .application-content-container {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    .application-content {
      flex-grow: 1;
      overflow: hidden;
      position: relative;
      //   position: absolute;
      //   top: var(--header-height);
      //   left: 0;
      //   right: 0;
      //   bottom: 0;
      //overflow:hidden;
    }
  }
}
