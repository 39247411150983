.login-form {
  display: flex;
  flex-direction: column;

  .form-row {
    margin-bottom: var(--m-4);

    .rs-popover.validation-popover.error {
      left: 40px !important;
    }
  }
  .button-row {
    margin-top: var(--m-13);
    display: flex;
    justify-content: flex-end;
  }
  .rs-input-group.rs-input-group-lg {
    & > .rs-input-group-addon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: var(--p-0);
      height: 100%;
      .rs-icon {
        font-size: var(--font-size-2xl) !important;
      }
    }
  }
  .bf-input {
    overflow: hidden;
  }
  input.rs-input {
    padding-left: var(--p-3) !important;
  }
  .switch-to-pin-action {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--m-5);
  }
  .login-error {
    display: flex;
    padding-top: var(--p-8);
    justify-content: center;

    .bf-message {
      max-width: 300px;

      .bf-message-content {
        .text-content {
          padding-left: var(--p-0);
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .button-row {
    flex-direction: column-reverse;

    .login {
      margin-top: var(--m-5);
    }
    .reset-password {
      margin-top: var(--m-10);
    }
  }
}
