.table-page-layout {
  display: flex;
  flex-direction: column;

  .page-header {
    padding-bottom: var(--p-10);
  }

  .table-card {
    flex: 1;
    display: flex;
    display: flex;
    flex-direction: column;
    padding: var(--p-5);

    .table-card-header {
      .table-card-title {
        letter-spacing: 2px;
        font-size: var(--font-size-lg);
        padding-bottom: var(--p-5);
        font-weight: 500;
      }
    }

    .table-card-content {
      flex: 1;
      display: flex;
    }
  }
  &.no-oadding {
    .table-card {
      padding: var(--p-0);
    }
  }
}
