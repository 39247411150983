.object-detail-data {
  padding: 0px 10px 0px 10px;

  .field-values {
    tr {
      .field-label {
        padding-right: var(--p-10);
        color: var(--text-color-secondary);
      }
      .field-value {
      }
    }
  }
  .info-row {
    padding-bottom: var(--p-5);
    .entity-name {
    }
    .bank-account {
      color: var(--text-color-secondary);
      .iban {
        padding-left: var(--p-5);
        font-size: var(--font-size-sm);
      }
      .bic {
        padding-left: var(--p-5);
        font-size: var(--font-size-sm);
      }
    }
  }

  .rental-income-feature {
    .entry-container {
      display: flex;
      .entry {
        padding-right: var(--p-8);
        .label {
          font-size: var(--font-size-base);
          color: var(--text-color-secondary);
        }
        .value {
          font-size: var(--font-size-md);
        }
      }
    }
  }

  .asset-value-feature {
    .no-value-added {
      color: var(--text-color-secondary);
      // padding-left: var(--p-10);
    }

    .asset-value-entry {
      border-bottom: solid 1px var(--border-color-primary);
      display: flex;
      align-items: center;
      padding-bottom: var(--p-4);
      padding-top: var(--p-4);
      display: flex;
      .name {
        .value {
          font-size: var(--font-size-lg);
          padding-right: var(--p-3);
        }
        .date {
          font-size: var(--font-size-md);
          padding-right: var(--p-3);
          padding-left: var(--p-3);
        }
        .info {
          color: var(--text-color-secondary);
          padding-right: var(--p-5);
        }
      }

      &:last-of-type {
        border-bottom: none;
        padding-top: var(--p-0);
        padding-bottom: var(--p-0);
      }
      .action {
        button {
          opacity: 0;
          transition: opacity 0.2s;
          &:focus {
            opacity: 1;
          }
          &:active {
            opacity: 1;
          }
        }
      }

      &:hover {
        background: var(--background-color-primary);
        .action {
          button {
            opacity: 1;
          }
        }
      }
    }
  }
}

.asset-value-entry-deletion {
  .value {
    font-size: var(--font-size-lg);
    padding-right: var(--p-3);
  }
  .date {
    font-size: var(--font-size-md);
    padding-right: var(--p-3);
    padding-left: var(--p-3);
  }
  .info {
    color: var(--text-color-secondary);
    padding-right: var(--p-5);
  }
}
