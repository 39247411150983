.ra-update-invoice-cost-center {
  .title {
    font-size: var(--font-size-lg);
    padding-bottom: var(--p-8);
  }
  .entries {
    .empty-text {
      text-align: center;
      color: var(--text-color-secondary);
      padding: var(--p-10);
    }
    .cost-center-entry {
      display: flex;
      width: 100%;
      border-bottom: solid 1px var(--border-color-primary);
      padding-bottom: var(--p-10);
      margin-bottom: var(--m-10);
      flex-direction: column;
      .cost-center-entry-header {
        display: flex;
        align-items: center;
        .entry-title {
          flex-grow: 1;
          font-size: var(--font-size-sm);
          color: var(--text-color-secondary);
        }
        .entry-action {
        }
      }
      .form-row {
        display: flex;
        width: 100%;
        padding-bottom: var(--p-3);

        &:last-of-type {
          padding-bottom: var(--p-0);
        }
      }
      &:last-of-type {
        border-bottom: none;
      }
      .costCenterId {
        flex: 1 0 50%;
        padding-right: var(--p-3);

        .cost-center-menu-item {
          display: flex;
          justify-content: center;
          align-items: center;
          .label {
            flex-grow: 1;
          }
        }
      }
      .info {
        flex: 1 0 50%;
      }
      .invoiceCostCenterId {
        flex: 1 0 50%;
        padding-right: var(--p-3);
      }
      .objectId {
        flex: 1 0 50%;
        padding-right: var(--p-3);
      }
      .price-input {
        flex: 1 0 50%;
        .rs-input-group-addon.prefix {
          height: 34px;
          font-size: var(--font-size-md);
        }
        .rs-input-group-addon.suffix {
          background: transparent;
          //   padding: 10px 0px;
          height: 100%;
          padding: var(--p-0);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .array-actions {
    .add-action {
      display: flex;
      justify-content: center;
    }
    .rest-sum {
      text-align: right;
    }
  }
  .form-actions {
    padding-top: var(--p-8);
    display: flex;
    justify-content: flex-end;
    width: 100%;
    button {
      margin-left: var(--m-5);
    }
  }
}
