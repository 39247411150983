.display-custom-fields {
  .normal-fields {
    padding-top: var(--p-8);
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  .attachment-entry {
    padding-top: var(--p-8);
    .attachment-header {
      font-weight: bold;
    }
  }
}
