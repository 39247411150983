.ez-select {
  display: inline-block;

  .rs-picker-textbox {
    display: none;
  }
  .rs-picker.rs-picker-toggle-wrapper {
    .rs-picker-tag-wrapper {
      display: none;
    }
    .rs-picker-toggle.rs-btn {
      position: relative !important;
      background: var(--background-color-primary) !important;
      .render-value {
        color: var(--text-color-primary);
      }
    }
    .rs-picker-toggle.rs-btn {
      > .rs-stack {
        > .rs-stack-item {
          input.rs-picker-toggle-textbox {
          }
          .rs-picker-toggle-value {
          }
        }
      }
    }
  }

  .render-value {
    display: flex;
    .prefix-icon {
      padding-right: var(--p-3);
    }
  }

  &.error {
    .rs-picker.rs-picker-toggle-wrapper {
      .rs-picker-toggle.rs-btn {
        background: var(--background-color-light-error) !important;
        .render-value {
          color: var(--color-negative);
        }
      }
    }
  }
}
.ez-select-select {
  .rs-picker-select-menu-item:not(.rs-picker-select-menu-item-disabled):hover,
  .rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
    background-color: transparent;

    &:hover {
      background-color: var(--table-row-color-hover);
    }
  }

  .ez-select-select-tag-item {
    display: flex;
    align-items: center;
    min-width: 200px;
    .content {
      flex-grow: 1;
      .label {
        display: inline-block;
        padding: 4px 10px;
        border-radius: var(--border-radius-big);
        font-weight: 600;
      }
    }
    .selected-icon {
      padding-left: var(--p-5);
    }
  }
}
