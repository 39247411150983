.accounting-form-additional-data {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: var(--p-5);
  .group-display-name {
    // max-width: 600px;
  }
  .note {
    // min-width: 600px;
  }

  .connect-data-row {
    // width: 600px;
  }
}
