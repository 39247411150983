button.pdf-constructor--checkbox {
  padding: 0.5rem;
  border-radius: 0.25rem;
  color: var(--base-color);
  border: 1px solid var(--border-color-primary);
  transition: border-color 0.3s;
  background: transparent;

  &:hover {
    border-color: var(--base-color);
  }

  &[data-checked="true"] {
    border-color: var(--border-color-pdf-checkbox-checked);
  }
}
