.cb-rental-unit-form {
  .field-row {
    display: flex;
    gap: 10px;
    .__field {
      flex: 1 0;

      &.field-building {
        flex: 2 0;
      }
      &.display-name {
        flex: 2 0;
      }
    }
  }
}
