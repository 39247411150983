.rs-preview-tenant {
  .tenant-container {
    .entry-list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px 20px;
      padding-bottom: var(--p-5);
      .entry {
        .label {
          font-size: var(--font-size-sm);
          color: var(--text-color-secondary);
        }
        .value {
          font-size: var(--font-size-md);
        }
      }
    }
  }
}
