.s3-icon {
  width: 24px;
  height: 24px;

  svg {
    overflow: visible;
    width: 100%;
    height: 100%;
  }

  &.animate-transform {
    transition: transform 0.3s;
  }
  &.loading {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &.__fix-stroke {
    * {
      fill: transparent;
      stroke: currentColor;
    }
  }

  &.size-norm {
    width: 30px;
    height: 30px;
  }
  &.size-xxs {
    width: 16px;
    height: 16px;
  }
  &.size-xs {
    width: 19.5px;
    height: 19.5px;
  }
  &.size-xs {
    width: 19.5px;
    height: 19.5px;
  }
  &.size-sm {
    width: 26px;
    height: 26px;
  }
  &.size-lg {
    width: 31px;
    height: 31px;
  }
  &.size-xl {
    width: 34px;
    height: 34px;
  }
  &.size-2x {
    width: 52px;
    height: 52px;
  }
  &.size-3x {
    width: 78px;
    height: 78px;
  }
  &.size-4x {
    width: 104px;
    height: 104px;
  }
  &.size-5x {
    width: 130px;
    height: 130px;
  }
}
