.pdf-preview {
  position: relative;
  border: solid 1px var(--border-color-primary);
  box-sizing: content-box;
  .open-preview {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    border-radius: var(--border-radius-none);
    background: var(--background-opacity-strong);
    transition: opacity 0.4s;
    .bf-icon {
      transform: scale(0.25);
      transition: transform 0.4s 0.1s;
      color: var(--icon-color-contrast);
    }
    &:hover {
      opacity: 0.8;
      .bf-icon {
        transform: scale(1);
      }
    }
  }
}
