.object_floor_plan {
  height: 100%;

  display: flex;
  flex-direction: column;

  .header {
    padding-bottom: var(--p-10);
  }

  .content {
    flex: 1;
    overflow: hidden;
    position: relative;
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;

    background: var(--background-opacity-tertiary-main);
    z-index: 9999;
  }
}
