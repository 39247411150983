.ds-unavialable-directory {
  display: flex;
  padding-top: var(--p-13);
  padding-bottom: var(--p-20);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .message {
    display: flex;
    padding-top: var(--p-10);
    padding-bottom: var(--p-10);
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}
