.bf-tab-container {
  height: 100%;
  width: 100%;
  .rs-tabs {
    height: 100%;
    .rs-tabs-content {
      flex-grow: 1;
      .rs-tab-panel {
        height: 100%;
      }
    }
  }
}
