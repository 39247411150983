.cb-vacancy-soon-card {
  .filters {
    display: flex;
    flex-wrap: wrap;
    .actions {
    }
    .groups {
      flex-grow: 1;
      .bf-button-group:first-of-type {
        margin-right: var(--m-8);
      }
    }
    @media screen and (max-width: 1790px) {
      flex-direction: column;
      .groups {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .actions {
        padding-top: var(--p-5);
        display: flex;
        justify-content: flex-end;
      }
    }
    @media screen and (max-width: 1280px) {
      .groups {
        align-items: center;
        flex-direction: column;
        .bf-button-group {
          padding-bottom: var(--p-5);
        }
      }
    }
  }
  .vacancy-soon-table {
    height: 100%;
    display: flex;
    flex-direction: column;
    .table-wrapper {
      flex-grow: 1;
      box-shadow: var(--default-inset-shadow);
      .cb-vacancy-soon-entry {
        width: 100%;
        padding: var(--p-5);
        display: flex;

        .left {
          flex-grow: 1;
          overflow: hidden;
          min-width: 40%;

          .id {
            font-size: var(--font-size-sm);
            color: var(--text-color-primary);
          }
          .name {
            font-size: var(--font-size-md);
            font-weight: 500;
            word-wrap: break-word;
            white-space: normal;
          }
          .type {
          }
          .address {
            display: flex;
            word-wrap: break-word;
            white-space: normal;
            .object {
              padding-right: var(--p-5);
              font-weight: 500;
            }
          }
        }
        .right {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .data-entry {
            padding-left: var(--p-8);
            .label {
              font-size: var(--font-size-sm);
              color: var(--text-color-secondary);
            }
            .value {
              font-size: var(--font-size-md);
              font-weight: 400;
            }
          }
        }
        @media screen and (max-width: 800px) {
          flex-direction: column;
          .right {
            width: 100%;
            padding-top: var(--p-5);
            .data-entry {
              padding-left: var(--p-0);
              padding-right: var(--p-5);
            }
          }
        }
      }
    }
  }
}
