.cb-rental-unit-multiple-form {
  form {
    display: flex;
    flex-direction: column;
    height: 100vh;
    .form-content {
      position: relative;
      padding: var(--p-0);

      .SplitPane {
        .Pane {
          .plan {
            width: 100%;
          }
          .table {
            height: 100%;
            width: 100%;
            overflow: auto;
            table {
              overflow: auto;
              min-width: 100%;
              tr {
                td,
                th {
                  //   border-right: solid 1px var(--border-color-secondary);
                  &:last-of-type {
                    // border-right: none;
                  }
                }
                th.header {
                  div {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: var(--p-3);
                  }
                }
                td.building-header {
                  div {
                    border: solid 1px var(--border-color-secondary);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: var(--background-color-primary);
                    font-size: var(--font-size-lg);
                    padding: var(--p-3);
                    text-transform: uppercase;
                    font-weight: bold;
                    letter-spacing: 1px;
                  }
                }
                td.building-unittype-header {
                  div {
                    border-left: solid 1px var(--border-color-secondary);
                    border-right: solid 1px var(--border-color-secondary);
                    border-bottom: solid 1px var(--border-color-secondary);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: var(--background-color-contrast);
                    font-size: var(--font-size-base);
                    padding: var(--p-3);
                    text-transform: uppercase;
                    font-weight: bold;
                    letter-spacing: 1px;
                  }
                }
                td.add-entry-row {
                  > div {
                    padding: var(--p-3);
                    display: flex;
                    justify-content: center;
                  }
                }
              }
            }
            .add-building-button {
              display: flex;
              justify-content: center;
              padding-top: var(--p-5);
            }
          }
        }
      }
    }
  }
}
