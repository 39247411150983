.ez-select-query {
  max-width: 100%;
  display: inline-block;
  .rs-dropdown {
    max-width: 100%;
  }

  .readonly-tag {
    width: 100%;
    background: var(--background-color-primary);
    padding: 7px 11px;

    .rs-tag-text {
      .render-value {
        color: var(--text-color-primary);
        display: flex;
        flex-direction: row;
        gap: 6px;
      }
    }
  }

  .select-dropdown {
    border: solid 1px var(--border-color-primary);
    border-radius: var(--border-radius-base);
    button.rs-dropdown-toggle {
      max-width: 100%;
      background: var(--background-color-primary) !important;
      overflow: clip;

      .render-value {
        display: flex;
        color: var(--text-color-primary);
        overflow: clip;
        text-overflow: ellipsis;
        .prefix-icon {
          padding-right: var(--p-3);
        }
      }
    }
    &:hover {
      > button.rs-dropdown-toggle {
        border-color: var(--rs-input-focus-border);
      }
    }
    &.rs-dropdown-open {
      border-color: var(--rs-input-focus-border);
      button.rs-dropdown-toggle {
        box-shadow: var(--rs-state-focus-shadow);
      }
    }

    .select-dropdown-content {
      min-width: 300px;
      width: auto;
      height: 500px;
      display: flex;
      flex-direction: column;

      .search {
        border-bottom: solid 1px var(--border-color-secondary);
        input.rs-input {
          padding-left: var(--p-0);
        }
      }
      .select-content {
        flex-grow: 1;
        overflow: hidden;
        .selection-item {
          width: 100%;
          text-align: left;
          background-color: transparent;

          .label {
            padding: 8px 12px;
            color: var(--rs-text-primary);
            font-weight: 400;
          }

          &:hover {
            background-color: var(--table-row-color-hover);
            .label {
              color: var(--rs-listbox-option-hover-text);
            }
          }
          &.selected {
            background-color: var(--table-row-color-hover);

            .label {
              color: var(--rs-listbox-option-selected-text);
              font-weight: 700;
            }
          }
        }
      }
    }
    &.rs-dropdown-disabled {
      opacity: 0.5;
    }
  }
  &.appearance-bf {
    .select-dropdown {
      border: 1px solid var(--rs-border-primary);
      button.rs-dropdown-toggle {
        background: var(--background-color-contrast) !important;
      }
    }

    .readonly-tag {
      border: 1px solid var(--rs-border-primary);
      width: 100%;
      background: var(--background-color-contrast) !important;
      padding: 7px 11px;

      .rs-tag-text {
        .render-value {
          color: var(--text-color-primary);
          display: flex;
          flex-direction: row;
          gap: 6px;
        }
      }
    }
  }
}
.ez-select-query-container {
  overflow-x: clip;
}
