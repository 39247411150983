.object-detail-object-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  .header {
    .gallery-container {
      height: 350px;
      @media screen and (max-height: 1300px) {
        height: 300px;
      }
      @media screen and (max-height: 1200px) {
        height: 200px;
      }
      @media screen and (max-height: 750px) {
        height: 120px;
      }
    }

    // .background-image {
    //   filter: opacity(0.75);
    //   background-size: cover;
    //   background-position: 50% 50%;
    //   position: absolute;
    //   bottom: 0px;
    //   left: 0px;
    //   right: 0px;
    //   top: 0px;
    //   cursor: pointer;
    //   transition: filter 0.2s;
    //   &:hover {
    //     filter: opacity(1);
    //   }
    // }
  }
  .asset-data {
    border-top: solid 1px var(--border-color-secondary);
    .title-bar {
      padding: 6px 12px 6px 0px;
      display: flex;
      z-index: 4;
      align-items: center;
      .go-back-action {
        margin-right: var(--m-3);
        margin-left: var(--m-1);
      }
      .title {
        flex-grow: 1;
        font-size: var(--font-size-lg);
        font-weight: 300;
        .object-id {
          font-size: var(--font-size-base);
          color: var(--text-color-secondary);
        }
      }
      .actions {
        display: flex;
        .edit-button {
          .bf-icon {
            font-size: var(--font-size-base);
          }
        }
      }
    }
    flex-grow: 1;
    overflow: auto;
  }
  .map-container {
    min-height: 300px;
    border-top: solid 1px var(--border-color-secondary);
    border-bottom-left-radius: var(--border-radius-base);
    border-bottom-right-radius: var(--border-radius-base);
    overflow: hidden;

    @media screen and (max-height: 1300px) {
      min-height: 200px;
      .map-component {
        min-height: 200px;
      }
    }
    @media screen and (max-height: 1000px) {
      min-height: 120px;
      .map-component {
        min-height: 120px;
      }
    }
  }

  .flight-container {
    min-height: 500px;
    border-top: solid 1px var(--border-color-secondary);
    border-bottom-left-radius: var(--border-radius-base);
    border-bottom-right-radius: var(--border-radius-base);
    overflow: hidden;

    @media screen and (max-height: 1300px) {
      min-height: 350px;
      .map-component {
        min-height: 350px;
      }
    }
    @media screen and (max-height: 1000px) {
      min-height: 200px;
      .map-component {
        min-height: 200px;
      }
    }
  }

  .loading-container {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .object-accordion-entry.bf-accordion {
    margin-bottom: var(--m-0);
    border-bottom: solid 1px var(--border-color-primary);
    .title {
      font-size: var(--font-size-lg);
    }
    .cb-portfolio-object-view {
      padding: 0px 10px;
    }
  }
  .cb-portfolio-object-view {
    padding: var(--p-10);
    .cb-region {
      .region-content {
        .address-entry {
          font-size: var(--font-size-base);
          border-bottom: solid 1px var(--border-color-primary);
          padding-bottom: var(--p-5);
          margin-bottom: var(--m-5);
          &:last-of-type {
            border-bottom: none;
            margin-bottom: var(--m-0);
            padding-bottom: var(--p-0);
          }
        }
        .value-entry {
          display: flex;
          padding: 3px 0px;
          border-bottom: solid 1px var(--border-color-primary);
          .label {
            flex-grow: 1;
          }
          .value {
          }
          &:last-of-type {
            border-bottom: none;
          }
        }
      }
      &.rental-value {
        .entry-container {
          display: flex;
          .entry {
            padding-right: var(--p-8);
            .label {
              font-size: var(--font-size-base);
              color: var(--text-color-secondary);
            }
            .value {
              font-size: var(--font-size-md);
            }
          }
        }
      }
      &.immo-value {
        .no-value-added {
          color: var(--text-color-secondary);
          // padding-left: var(--p-10);
        }

        .entries {
          .immo-value-entry {
            border-bottom: solid 1px var(--border-color-primary);
            display: flex;
            align-items: center;
            padding-bottom: var(--p-4);
            padding-top: var(--p-4);
            display: flex;
            .name {
              .value {
                font-size: var(--font-size-lg);
                padding-right: var(--p-3);
              }
              .date {
                font-size: var(--font-size-md);
                padding-right: var(--p-3);
                padding-left: var(--p-3);
              }
              .info {
                color: var(--text-color-secondary);
                padding-right: var(--p-5);
              }
            }

            &:last-of-type {
              border-bottom: none;
              padding-top: var(--p-0);
              padding-bottom: var(--p-0);
            }
            .action {
              button {
                opacity: 0;
                transition: opacity 0.2s;
                &:focus {
                  opacity: 1;
                }
                &:active {
                  opacity: 1;
                }
              }
            }

            &:hover {
              background: var(--background-color-primary);
              .action {
                button {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}
.immo-value-entry-deletion {
  .value {
    font-size: var(--font-size-lg);
    padding-right: var(--p-3);
  }
  .date {
    font-size: var(--font-size-md);
    padding-right: var(--p-3);
    padding-left: var(--p-3);
  }
  .info {
    color: var(--text-color-secondary);
    padding-right: var(--p-5);
  }
}
