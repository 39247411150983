.ap-project-budget-drawer {
  display: flex;
  height: 100%;
  flex-direction: column;
  .drawer-header {
    padding: var(--p-10);
    border-bottom: solid 1px var(--border-color-secondary);
    .date {
      font-size: var(--font-size-md);
      padding-bottom: var(--p-3);
      text-align: center;
    }
    .header-data {
      display: flex;
      align-items: center;
      .left {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: var(--m-10);

        .title {
          font-size: var(--font-size-lg);
        }
        .sub-title {
          font-size: var(--font-size-base);
          color: var(--text-color-secondary);
        }
      }
      .right {
        .value {
          font-size: var(--font-size-md);
          font-weight: bold;
        }
      }
    }
  }
  .drawer-content {
    flex-grow: 1;
    overflow: hidden;
  }
}
