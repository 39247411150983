.bf-chooser-multiple-select {
  .bf-chooser-select-button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    border: 1px solid var(--rs-border-primary);
    background: var(--rs-input-bg);
    padding: 7px 12px;
    &.rs-btn-disabled {
      background: var(--background-color-primary);
    }
    .select-value {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      color: var(--rs-text-primary);
      &.placeholder {
        color: var(--text-color-secondary);
      }

      .sub-label {
        font-size: var(--font-size-sm);
        color: var(--text-color-secondary);
        padding-right: var(--p-3);
      }
    }

    .values {
      .value-entry {
        border-bottom: solid 1px var(--border-color-primary);
        padding-top: var(--p-2);
        padding-bottom: var(--p-2);
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
}
.bf-chooser-select-overlay {
  max-width: 500px;
  .bf-chooser {
    padding: var(--p-0);

    .search-content {
      margin-top: var(--m-3);
    }
  }

  .cleanable-action {
    display: flex;
    justify-content: flex-end;
    padding: 10px 10px 10px 50px;
    border-bottom: solid 1px var(--border-color-primary);
  }
}
