.bf-context-container {
  position: fixed;
}

.bf-context-container-overlay {
  position: fixed;
  background: var(--background-color-contrast);
  border-radius: var(--border-radius-base);
  box-shadow: var(--card-default-box-shadow);
  ul.dropdown-item-menu.rs-dropdown-menu {
    background: var(--rs-bg-overlay);
    background: var(--background-color-contrast);
    border-radius: var(--border-radius-base);
    box-shadow: var(--card-default-box-shadow);
  }
}
