body {
  --rs-text-primary: var(--text-color-primary);
  --rs-btn-default-text: var(--text-color-primary);
  --rs-navs-text: var(--text-color-primary);
  --rs-btn-subtle-text: var(--text-color-primary);
  --rs-btn-subtle-hover-text: var(--text-color-primary);
  --rs-navbar-default-text: var(--text-color-primary);
  --rs-navbar-default-hover-text: var(--text-color-primary);
  --rs-navbar-subtle-text: var(--text-color-primary);
  --rs-navbar-subtle-hover-text: var(--text-color-primary);
  --rs-navs-text-hover: var(--text-color-primary);
  --rs-sidenav-default-text: var(--text-color-primary);
  --rs-sidenav-default-hover-text: var(--text-color-primary);
  --rs-sidenav-subtle-text: var(--text-color-primary);
  --rs-sidenav-subtle-hover-text: var(--text-color-primary);
}
