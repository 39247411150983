.cashbudget-attachments {
  .linked-asset-container,
  .attachement-container {
    padding: var(--p-3);
    border-top: solid 1px var(--border-color-secondary);

    .cat-header {
      display: flex;
    }
    .uploading-container {
      display: flex;
      justify-content: center;
      padding: var(--p-3);
    }
    .title {
      // font-size: ;
      margin-bottom: var(--m-3);
      text-transform: uppercase;
      color: var(--text-color-secondary);
    }
    .bf-table {
      .table-component-header {
        display: none;
      }
      // min-height: 200px;
      .table {
        .rt-tbody {
          padding-top: var(--p-0);
        }
      }
      .table-loader {
        display: none;
      }
      .attachement-row {
        position: relative;
        cursor: pointer;
        transition: background-color 0.2s;
        width: 100%;
        display: flex;
        align-items: center;
        justify-items: center;
        .filetype {
          display: flex;
          align-items: center;
          justify-items: center;
          padding: 4px 5px 4px 5px;
        }
        .name {
          padding: 4px 5px;
          flex-grow: 1;
          display: flex;
          align-items: center;
          word-wrap: none;
          overflow: hidden;
          color: var(--base-color);
          span {
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .actions {
          button {
            padding: var(--p-3);
          }
        }
        &:hover {
          background: var(--background-color-primary);
        }

        .deleted-overlay {
          display: none;
        }
        &.deleted {
          .deleted-overlay {
            cursor: initial;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--background-opacity-primary);
            color: var(--text-color-contrast);
            button {
              background: none;
            }
          }
        }
      }
    }
  }
}
