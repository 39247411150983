.progress-loader-line {
  padding: var(--p-0);

  &.intermediate {
    .rs-progress-line-outer {
      position: relative;
      .rs-progress-line-bg {
        position: absolute;
        width: initial !important;
        animation: progress-loader-line-intermediate 3.5s
          cubic-bezier(0.805, 0.18, 0.595, 0.52) infinite;
      }
    }
  }
  &.query {
    .rs-progress-line-outer {
      position: relative;
      .rs-progress-line-bg {
        position: absolute;
        width: initial !important;
        animation: progress-loader-line-query 2.5s
          cubic-bezier(0.805, 0.18, 0.595, 0.52) infinite;
      }
    }
  }
}

@keyframes progress-loader-line-intermediate {
  0% {
    left: -10%;
    right: 100%;
  }
  60% {
    left: 50%;
    right: -180%;
  }
  80% {
    left: 100%;
    right: -180%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes progress-loader-line-query {
  0% {
    left: 100%;
    right: -50%;
  }
  90% {
    left: -50%;
    right: 100%;
  }
  100% {
    left: -20%;
    right: 100%;
  }
}
