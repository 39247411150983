.ActionSheetEntries {
  .action-sheet-row {
    display: flex;
    align-items: center;

    .name {
      flex-grow: 1;
    }

    .actions {
      margin-right: var(--m-3);
    }
  }
}
