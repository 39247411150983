.ap-advertisement {
  .vacancy-entries {
    .__empty {
      padding: var(--p-5);
    }
    .vacant-advertisement-entry {
      border-bottom: solid 1px var(--border-color-primary);
      padding-top: var(--p-5);
      padding-bottom: var(--p-5);
      .entry-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px 20px;
        padding-bottom: var(--p-5);
        .entry {
          .label {
            font-size: var(--font-size-sm);
            color: var(--text-color-secondary);
          }
          .value {
            font-size: var(--font-size-md);
            .plan {
              font-size: var(--font-size-sm);
              color: var(--text-color-secondary);
            }
          }
        }
        .actions {
          display: flex;
        }
      }
    }
  }

  .add-action {
    padding-bottom: var(--p-5);
    display: flex;
    justify-content: center;
  }
}
