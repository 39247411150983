.ap-construction-diaries-view {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: var(--p-10);
  overflow: hidden;
  .filter-header {
    display: flex;
  }
  .diaries {
    flex-grow: 1;
    overflow: hidden;
    padding: var(--p-5);
  }
  .notes-container {
    position: relative;
    .notes {
      border-top: solid 1px var(--border-color-primary);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
