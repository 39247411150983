.rs-dashboard-kpi-btn {
  .d-kpi-card {
    width: 100px;
  }

  &.selected {
    .d-kpi-card {
      background: var(--base-color);
      color: var(--text-color-contrast);
      .meta-text {
        color: var(--text-color-contrast);
      }
    }
  }
}
