.document-store-root {
  box-shadow: var(--card-default-box-shadow);
  max-height: 100%;
  overflow: auto;
  background-color: var(--rs-btn-default-bg);
  border-radius: var(--border-radius-base);
  padding: 10px 20px 10px 20px;

  &.clean {
    background: none;
    box-shadow: none;
  }
  &.content-scrollable {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .document-directory-view {
      flex: 1;
      overflow: auto;
    }
  }
  .breadcrumb-header {
    display: flex;
    align-items: center;
  }
}
