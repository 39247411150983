.ap-budget-invoice-subrow-info {
  border-top: solid 1px var(--border-color-primary);
  display: flex;
  color: var(--text-color-secondary);
  font-size: var(--font-size-sm);
  align-items: center;
  text-align: center;
}

.ap-budget-invoice-subrow {
  border-top: solid 1px var(--border-color-primary);
  .subrow-entry {
    position: relative;
    padding-left: var(--p-18);
    height: 30px;
    display: flex;
    align-items: center;
    .line {
      position: absolute;
      top: 0px;
      left: 20px;
      width: 2px;
      bottom: 0px;
      background: var(--background-color-primary);

      .line-right {
        position: absolute;
        height: 2px;
        left: 0px;
        top: 50%;
        width: 8px;
        background: var(--background-color-primary);
      }
    }
    &:last-of-type {
      .line {
        bottom: 50%;
        .line-right {
          top: 100%;
        }
      }
    }

    &.split-value {
      .group {
        background: var(--background-color-primary);
        color: var(--text-color-primary);
        padding: 0px 10px;
        border-radius: var(--border-radius-base);
        margin-right: var(--m-5);
      }

      .category {
        padding-right: var(--p-5);
      }
      .value {
        font-weight: bold;
        padding-right: var(--p-5);
      }
      .comment {
        padding-right: var(--p-5);
        font-size: var(--font-size-sm);
        color: var(--text-color-secondary);
        font-style: italic;
      }
      .edit-button {
        display: none;
      }
      &:hover {
        .edit-button {
          display: block;
        }
      }
    }
  }
}
