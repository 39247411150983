.linked-activities-list-tab {
  height: 100%;
  display: flex;
  flex-direction: column;
  .filter-row {
    display: flex;
    padding: var(--p-5);
    background-color: var(--background-color-primary);

    border-bottom: solid 1px var(--border-color-primary);
    border-top-left-radius: var(--border-radius-base);
    border-top-right-radius: var(--border-radius-base);
  }

  .activities-content {
    flex-grow: 1;
    overflow: auto;

    .activities-container {
      border-bottom: solid 1px var(--border-color-primary);
    }
  }
}
