.open_layers_demo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 100%;
  height: 100%;

  .controls_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: end;
    gap: 8px;
  }

  .select_wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .open_layer_wrapper {
    width: 600px;
    height: 600px;

    border: 2px solid var(--border-color-contrast-inverse);
  }
}
