.ds-document-move-to-directory-view {
  max-height: 50vh;
  overflow: auto;
  .ds-document-move-to-directory-button {
    width: 100%;
    background: var(--list-row-background);
    text-align: left;
    justify-content: flex-start;
    .directory-name {
      padding-left: var(--p-5);
    }
    &:hover {
      background: var(--list-row-background-selected);
    }
  }
}
