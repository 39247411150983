.success-modal {
  display: flex;
  align-items: center;
  .animation {
    display: flex;
    padding-right: var(--p-5);
  }
  .success-info {
    display: flex;
    flex-direction: column;

    .success-title {
      font-size: var(--font-size-lg);
      padding-bottom: var(--p-5);
    }
    .success-message {
      color: var(--text-color-primary);
    }
  }
}
