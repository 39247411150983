.accounting-rental-info {
  margin-bottom: var(--m-8);
  .base-data {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .rental-data {
    margin-top: var(--m-5);
    tr {
      th {
        font-weight: normal;
        font-size: var(--font-size-sm);
        color: var(--text-color-secondary);
        text-align: left;
      }
      td,
      th {
        padding-right: var(--p-10);
        &.right {
          text-align: right;
        }
      }
      &.sum-by {
        td {
          font-weight: 600;
        }
      }
    }
  }
}
