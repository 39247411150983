.cashbudget-filter-header {
  display: flex;
  flex-wrap: wrap;
  .labeled-input {
    margin-right: var(--m-5);
    width: 350px;
    @media screen and (max-width: 950px) {
      width: 200px;
    }
  }
  .selection-filter {
    // min-width: 200px;
    width: 100%;
  }

  .filter-sub-head {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: var(--text-color-primary);

    .bf-icon {
      margin-right: var(--m-4);
    }
  }
  .selected-value {
    display: flex;
    align-items: center;

    .bf-icon {
      margin-right: var(--m-4);
    }
  }
  .rs-picker-menu {
    // min-width: 200px !important;
  }

  .account-entry {
    .infos {
      font-size: 0.7em;
      color: var(--text-color-secondary);
      .account-id {
        margin-right: var(--m-3);
        font-weight: bold;
      }
    }
  }
}
