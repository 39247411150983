.tag-view-compact {
  display: inline-flex;
  margin-left: var(--m-3);
  .tag-color-indicator {
    width: 15px;
    height: 15px;
    border-radius: var(--border-radius-rounded);
    margin-left: -5px;
    border: solid 1px var(--border-color-primary);
  }
}
