.ap-project-budget-form {
  // height: 100%;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  form {
    // height: 100%;
    display: flex;
    flex-direction: column;
    .form-fields {
      flex: 1;
      .budget-plan {
        // height: 100%;
      }
    }
  }
  .send-to-approval {
    margin-bottom: var(--m-10);
    display: flex;
    justify-content: flex-end;
  }
  .bf-message {
    margin-bottom: var(--m-10);
  }
  .project-budget-progress-column {
    display: flex;
    align-items: center;
    .progress {
      flex-grow: 1;
    }
  }
  .gantt-card {
    .card-head {
      padding: var(--p-5);
      display: flex;

      .title {
      }
    }
  }
}
