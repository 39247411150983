.plan-table {
  height: 100%;
  .cashbudget-table {
    background: var(--background-color-contrast);
  }
  .overdone-budget {
    color: var(--color-positive) !important;
  }
  .plan-table-head {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .manage-button {
      text-align: center;
      button.rs-dropdown-toggle {
        padding: 2px 10px !important;
      }
      ul.bf-dropdown.rs-dropdown-menu {
        margin-left: -30px;
        .date-actions {
          display: flex;
          padding: 0px 10px;
          gap: 10px;
          button {
          }
        }
      }
    }
    .table-title {
      font-size: var(--font-size-md);
    }
  }
}
