.user-replacement-root {
  padding: var(--p-10);
  height: 100%;
  overflow: auto;
  .replacements-list-container {
    max-width: 800px;
  }

  .section {
    padding-bottom: var(--p-10);
  }

  // .section-title {
  //   font-size: var(--font-size-md);
  //   font-weight: bold;
  //   padding-top: var(--p-10);
  // }
}
