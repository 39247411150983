.comment-input-dropzone {
  > .content-wrapper > .overlay-drop-indicator {
    z-index: 7;
  }
}
.comment-input-wrapper {
  .comment-input {
    border-radius: var(--border-radius-big);
    background: var(--background-color-contrast);

    .email-address-fields {
      border: 1px solid var(--border-color-secondary);
      border-top-left-radius: var(--border-radius-big);
      border-top-right-radius: var(--border-radius-big);
    }
    .mail-button-container {
      position: absolute;
      right: 10px;
      top: 25%;
      z-index: 2;
    }
    .ck.ck-editor {
      overflow: hidden;
      blockquote {
        border-left: solid 4px var(--border-color-secondary);
        padding-left: var(--p-5);
        margin-left: var(--m-0);
        margin-right: var(--m-0);
        margin-top: var(--m-0);
        margin-bottom: var(--m-0);
        padding-bottom: var(--p-2);
        padding-top: var(--p-2);
        font-size: var(--font-size-base);
        color: var(--text-color-primary);
      }

      .ck.ck-editor__top {
        transition: margin-top 0.4s;
        margin-top: -40px;
        border-top: none;
        border-left: none;
        border-right: none;
        border-color: var(--border-color-secondary);
      }
      .ck.ck-editor__main {
        padding-top: 0.5px;
      }
      .ck.ck-content {
        border-color: var(--border-color-secondary);
        max-height: 300px;
        min-height: 70px;
        border-radius: var(--border-radius-big);
        transition: border-radius 0.4s;
      }
      .ck.ck-editor__top {
        border-color: var(--border-color-secondary);
        border-top-left-radius: var(--border-radius-big);
        border-top-right-radius: var(--border-radius-big);
        .ck.ck-toolbar.ck-toolbar_grouping {
          border-color: var(--border-color-secondary);
          border-top-left-radius: var(--border-radius-big);
          border-top-right-radius: var(--border-radius-big);
        }
      }
    }

    &.send-as-mail {
      .ck.ck-editor {
        .ck.ck-editor__top {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          .ck.ck-toolbar.ck-toolbar_grouping {
            border-top: none;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
    }

    .comment-actions-row {
      display: flex;
      border-left: solid 1px var(--border-color-secondary);
      border-bottom: solid 1px var(--border-color-secondary);
      border-right: solid 1px var(--border-color-secondary);
      border-bottom-left-radius: var(--border-radius-big);
      border-bottom-right-radius: var(--border-radius-big);

      .subactivity-selection {
        flex-grow: 1;
        padding-top: var(--p-1);
        display: flex;
        .label {
          padding: 6px 0px 6px 6px;
        }
        .ez-autocomplete {
          flex-grow: 1;

          textarea {
            color: var(--rs-btn-link-text);
            font-size: var(--font-size-base);
            &::placeholder {
              color: var(--base-color);
            }
          }
          &.error {
            textarea::placeholder {
              color: var(--color-negative);
            }
          }
        }
      }
    }

    &.active {
      .ck.ck-editor {
        .ck-editor__main {
          padding-top: var(--p-0);
        }
        .ck.ck-editor__top {
          margin-top: var(--m-0);
        }
        .ck.ck-content {
          border-radius: var(--border-radius-none);
        }
      }
    }
    &.disableOverflowHidden {
      .ck.ck-editor {
        overflow: visible;
      }
    }
  }

  &.fullscreen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--background-opacity-strong);
    z-index: 1049;
    padding: var(--p-10);

    .ck.ck-editor {
      overflow: visible;
      .ck.ck-editor__top {
        margin-top: var(--m-0);
      }
      .ck.ck-content {
        border-radius: var(--border-radius-none);
      }
    }

    .comment-input {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .text-editor {
        flex: 1 0 0;
        overflow: hidden;
        .ck-editor {
          height: 100%;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          .ck-editor__main {
            // height: 100%;
            flex: 1 0 0;
            overflow: auto;
            .ck-content {
              height: 100%;
              max-height: initial;
            }
          }
        }
      }
    }
  }
}
