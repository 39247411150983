.ap-activity-start-approval-dialog {
  .rs-modal-body {
    padding-bottom: var(--p-0);
  }
}
.ap-activity-start-approval {
  .description {
    padding-bottom: var(--p-5);
  }
  .name {
    padding-bottom: var(--p-5);
  }

  .urgent-feature {
    padding-bottom: var(--p-5);
    .urgend-field-container {
      display: flex;
      justify-content: flex-end;
      .urgend-field {
        height: fit-content;
        --rs-toggle-checked-bg: var(--background-color-toggle-urgent-checked);
        --rs-toggle-checked-hover-bg: var(--color-negative);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px 12px 2px 6px;
        border-top-right-radius: var(--border-radius-base);
        border-top-left-radius: var(--border-radius-base);
        transition: background-color 0.2s ease-in-out,
          border-color 0.2s ease-in-out;
        // border: solid 1px var(--border-color-contrast);
        .label {
          font-size: var(--font-size-sm);
          padding-top: var(--p-1);
        }
        &.urgent {
          background: var(--background-urgent);
          .label {
            // font-weight: 500;
            color: var(--color-negative);
          }
        }
      }
    }
    .urgent-message {
      background: var(--background-urgent);
      border-bottom-right-radius: var(--border-radius-base);
      border-bottom-left-radius: var(--border-radius-base);
      padding: var(--p-5);
      margin-bottom: var(--m-5);
      .description {
        font-size: var(--font-size-sm);
        color: var(--color-negative);
        padding-bottom: var(--p-5);
      }
      textarea {
        &:focus-visible {
          outline-color: var(--color-negative) !important;
        }
      }
    }
  }

  .offer-list {
    border-top: solid 1px var(--border-color-tertiary);
    border-bottom: solid 1px var(--border-color-tertiary);
    .ap-activity-offer {
      border-bottom: solid 1px var(--border-color-primary);
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
  .checkedForPriceComparison {
    padding-top: var(--p-5);
  }
  .actions {
    padding-top: var(--p-5);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .bf-button {
      margin-left: var(--m-5);
    }
    .status {
      font-size: var(--font-size-sm);
      color: var(--text-color-secondary);
      &.error {
        color: var(--color-negative);
      }
    }
  }
}
