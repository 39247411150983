.configurable-dashbaord-add-elements-bar {
  .title {
    color: var(--text-color-primary);
    font-size: var(--font-size-md);
    padding-bottom: var(--p-5);
    letter-spacing: 1px;
    padding-left: var(--p-10);
  }
  .elements {
    border-top: solid 1px var(--border-color-secondary);
    border-bottom: solid 1px var(--border-color-secondary);
    padding: var(--p-5);
    display: flex;
    overflow: auto;
    align-items: center;
    .no-elements {
      padding: var(--p-10);
      color: var(--text-color-secondary);
    }
    .available-component {
      margin-right: var(--m-5);
      flex: 0 0 auto;
    }
  }
  padding-bottom: var(--p-10);

  &.bright {
    .title {
      color: var(--text-color-contrast);
    }
    .elements {
      border-top: solid 1px var(--border-color-primary);
      border-bottom: solid 1px var(--border-color-primary);

      .no-elements {
        color: var(--text-color-disabled);
      }
    }
  }
}
