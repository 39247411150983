.bf-section {
  border: solid 1px var(--border-color-secondary);
  border-radius: var(--border-radius-base);
  position: relative;
  background: var(--background-color-contrast);
  .section-content {
    padding: var(--p-7) var(--p-5) var(--p-5) var(--p-5);
  }
  .section-header {
    padding-left: var(--p-2);
    padding-right: var(--p-2);
    position: absolute;
    left: 10px;
    top: -10px;
    background: var(--background-color-contrast);
    display: flex;
    align-items: center;

    .section-title {
      .collapse-icon {
        display: inline-block;
        padding-right: var(--p-3);
        transform: rotateX(180deg);
        transition: transform 0.3s;
        &.collapsed {
          transform: rotateX(0deg);
        }
      }
    }
    .section-actions {
      padding-left: var(--p-5);
    }
  }
  &.collapsible {
    .section-header {
      cursor: pointer;
    }
  }

  &.small {
    .section-header {
      .section-title {
        font-size: var(--font-size-md);
        font-weight: 500;
        text-transform: none;
      }
    }
  }
}
