.split-page-layout {
  display: flex;
  height: 100%;
  width: 100%;

  .main-page {
  }

  .detail-page {
    display: none;
    position: relative;

    &.loading {
      .loadmask {
        display: flex;
        background-color: var(--app-background-loadmask);
      }
    }

    .loadmask {
      position: absolute;
      z-index: 5;
      background-color: transparent;
      transition: background-color 0.1s;
      display: none;
      justify-content: center;
      align-items: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &.details-active {
    .detail-page {
      display: block;

      &.error {
        display: flex;
        justify-content: center;
        align-items: center;
        .errorText {
          padding: var(--p-10);
          color: var(--color-negative);
          font-size: 1.2em;
          display: flex;
          align-items: center;
          span {
            margin-right: var(--m-5);
          }
        }
      }
    }
  }

  &.break {
    .main-page {
      display: none;
    }
  }

  &.drawer {
    &.details-active {
      .main-page {
        display: block;
      }
    }
  }
}

.split-page-drawer {
  max-width: 90%;
  .drawer-container {
    position: relative;
    height: 100%;

    .detail-page {
      position: relative;
      height: 100%;

      &.loading {
        .loadmask {
          display: flex;
          background-color: var(--app-background-loadmask);
        }
      }

      .loadmask {
        position: absolute;
        z-index: 5;
        background-color: transparent;
        transition: background-color 0.1s;
        display: none;
        justify-content: center;
        align-items: center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}
