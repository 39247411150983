.cb-rental-unit-detail-view {
  .main-page {
    overflow-y: auto;
    height: 100%;
    position: relative;
    .top-content-wrapper {
      height: 100%;
      width: 100%;
      border-bottom: solid 1px var(--border-color-primary);
      display: flex;
      flex-direction: column;
      .tab-content {
        flex-grow: 1;
        overflow: hidden;
      }
    }
    .page-content {
      height: 100%;
      width: 100%;
      overflow-y: auto;
      .header {
        display: flex;
        align-items: center;
        .bf-button.back-button {
        }
        .title {
          flex: 1;
          padding-left: var(--p-2);
          .id {
            color: var(--text-color-secondary);
            padding-top: var(--p-5);
            padding-left: var(--p-2);
            padding-right: var(--p-3);
            font-size: var(--font-size-md);
          }
          .name {
            color: var(--text-color-strong);
            padding-left: var(--p-2);
            padding-right: var(--p-3);
            font-size: var(--font-size-lg);
          }
          .object {
          }
        }
      }
      .content {
        padding: var(--p-5);

        .__h3 {
          padding-bottom: var(--p-3);
        }
        .sub-head {
          display: flex;
          align-items: center;
          padding-bottom: var(--p-3);
          .__h3 {
            padding-bottom: var(--p-0);
            padding-right: var(--p-8);
          }
        }

        .rental-agreement {
          padding-bottom: var(--p-8);
        }
        .entry-list {
          display: flex;
          flex-wrap: wrap;
          gap: 10px 20px;
          padding-bottom: var(--p-5);
          .entry {
            .label {
              font-size: var(--font-size-sm);
              color: var(--text-color-secondary);
            }
            .value {
              font-size: var(--font-size-md);
            }
          }
        }
      }
    }
  }

  // .object-detail-payplan {
  //   height: calc(100% - 20px);
  //   padding: var(--p-5);
  //   position: relative;
  //   overflow: hidden;
  //   .bf-loan-data {
  //     height: 100%;
  //     .values {
  //       height: 100%;
  //       .bf-virtualized {
  //         height: calc(100% - 35px) !important;
  //       }
  //     }
  //   }
  // }
}
