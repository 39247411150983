.ap-construction-diary-card {
  margin-bottom: var(--m-5);
  overflow: hidden;

  .head-line {
    padding: var(--p-5);
    display: flex;
    align-items: center;
    .date {
      font-size: var(--font-size-md);
    }
    .diary-type,
    .status {
      font-size: var(--font-size-sm);
      color: var(--text-color-secondary);
    }
    .status {
      padding-right: var(--p-3);
    }
  }
  .weather-row {
    display: flex;
    padding-left: var(--p-5);
    padding-right: var(--p-5);
    gap: 6px;
    .weather-item {
      padding: 6px 12px;
      background: var(--background-color-primary);
      border-radius: var(--border-radius-big);
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
  .section {
    .section-title {
      padding-top: var(--p-5);
      border-bottom: solid 1px var(--border-color-primary);
      font-weight: 500;
      padding-left: var(--p-5);
      padding-right: var(--p-5);
    }
  }

  .actions {
    padding-top: var(--p-5);
    display: flex;
  }
}
