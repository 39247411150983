@keyframes oscillate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0.3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes skewStuff {
  0% {
    transform: skewX(0deg);
  }
  25% {
    transform: skewX(0.3deg);
  }
  50% {
    transform: skewX(0deg);
  }
  75% {
    transform: skewX(-0.3deg);
  }
  100% {
    transform: skewX(0deg);
  }
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  29% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.01);
  }
  31% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes whirl {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(3600deg);
  }
}

.bs_oscillate {
  .app-layout > .app-layout-content {
    animation: oscillate 30s infinite;
  }
}

.bs_scale {
  .app-layout > .app-layout-content {
    animation: scale 60s infinite;
  }
}
.bs_skew {
  .app-layout > .app-layout-content {
    animation: skewStuff 60s infinite;
  }
}

.bs_whirl {
  .app-layout > .app-layout-content {
    animation-duration: 3s;
    animation-name: whirl;
    animation-delay: 3s;
    animation-fill-mode: forwards;
  }
}
