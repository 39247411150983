.bf-markdown {
  h1 {
    font-size: var(--font-size-xl);
  }
  h2 {
    font-size: var(--font-size-lg);
  }
  h3 {
    font-size: var(--font-size-md);
  }
  img {
    max-width: 100%;
  }
}
