.bf-table-filter {
  display: flex;
  .bf-dropdown.rs-dropdown-menu {
    margin-top: var(--m-3);
  }
  .active-filters {
    display: flex;
    .bf-table-filter-entry {
      margin-right: var(--m-5);
    }
  }
  .add-new-filter {
    .dropdown-item-button {
      padding-right: var(--p-13);
      min-width: 130px;
      &.favorite-filter-button {
        padding-right: var(--p-3);
      }
    }
    .no-favorite-filters,
    .no-filters {
      text-align: center;
      min-width: 200px;
      padding: 16px 16px 16px 16px;
      color: var(--text-color-secondary);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
