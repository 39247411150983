.cb-damage-claim-card {
  .filters {
    display: flex;
    .actions {
    }
    .groups {
      flex-grow: 1;
      .bf-button-group:first-of-type {
        margin-right: var(--m-8);
      }
    }
  }
  .damage-claim-table {
    height: 100%;
    display: flex;
    flex-direction: column;
    .table-wrapper {
      flex-grow: 1;
      box-shadow: var(--default-inset-shadow);
      .cb-damage-claim-entry {
        width: 100%;
        padding: var(--p-5);
        display: flex;
        .left {
          flex-grow: 1;
          overflow: hidden;

          .status {
            display: flex;
            padding-bottom: var(--p-5);
            .status-entry {
              min-width: 185px;
              padding-right: var(--p-10);
              display: flex;
              flex-direction: column;
              .label {
                font-size: var(--font-size-sm);
              }
              .value {
                font-weight: 500;
                font-size: var(--font-size-md);
              }
            }
            // white-space: ;
          }
          .name {
            word-wrap: break-word;
            white-space: normal;
            font-size: var(--font-size-md);
          }
          .id {
            font-size: var(--font-size-sm);
            color: var(--text-color-primary);
          }
          .tags {
            display: flex;
            padding-top: var(--p-5);
            .tag {
              padding: 4px 8px;
              border-radius: var(--border-radius-big);
              color: var(--text-color-contrast);
            }
          }
        }
        .right {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          .data-entry {
            padding-left: var(--p-8);
            .label {
              font-size: var(--font-size-sm);
              color: var(--text-color-secondary);
            }
            .value {
              font-size: var(--font-size-md);
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
