.tab-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .tab-items {
    display: flex;
    background: var(--table-titlebar-background);

    .rs-nav {
      flex-grow: 1;

      ul {
        display: flex;
        justify-content: stretch;

        .rs-nav-item {
          //flex-grow: 1;
          // min-width: 100px;

          & > .rs-nav-item-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: var(--border-radius-none);

            .bf-icon {
              margin-top: var(--m-3);
              margin-right: var(--m-0);
              margin-bottom: var(--m-2);
              //font-size: 1.4em;
            }
          }
        }

        .rs-nav-item:not(.rs-nav-item-active):not(.rs-nav-item-disabled)
          > .rs-nav-item-content:hover {
          color: var(--text-color-disabled);
          background-color: var(--background-opacity-tertiary-main);
        }

        .rs-nav-item-active > .rs-nav-item-content {
          background-color: var(--background-opacity-tertiary-weak);

          border-radius: var(--border-radius-none);
        }
      }
    }
  }

  .tab-content {
    flex-grow: 1;
    height: 0;
  }
}
