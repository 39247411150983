.bf-object-kind-permission {
  .object-kind-entry {
    border-bottom: solid 1px var(--border-color-primary);
    &:last-of-type {
      border-bottom: none;
    }
    .entry-head {
      .rs-checkbox-checker {
        label {
          font-size: var(--font-size-lg);
          .unit-label {
            font-size: var(--font-size-base);
            color: var(--text-color-secondary);
            padding-right: var(--p-5);
          }
        }
      }
    }
    .entry-body {
      margin-left: var(--m-10);
      padding: 5px 10px 5px 5px;
      //   border-left: solid 1px var(--border-color-primary);
      //   border-bottom: solid 1px var(--border-color-primary);
      .permission-entry {
        &.multipe-checkboxes {
          .permission-entry-label {
            min-width: 200px;
          }
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
      .quick-actions {
        width: 100%;
        padding-top: var(--p-5);
        justify-content: center;
        display: flex;
      }
    }
  }
}
