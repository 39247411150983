.swiper.bf-swiper-component {
  .swiper-pagination {
    height: 30px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .swiper-pagination-bullet {
      background: var(--background-color-primary);
      border: solid 1px var(--border-color-secondary);
      border-radius: var(--border-radius-rounded);

      width: 6px;
      height: 6px;
      margin: var(--m-1);
      &.swiper-pagination-bullet-active {
        background: var(--base-color);
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    transition: opacity 0.2s;
    color: var(--base-color);
    &.swiper-button-disabled {
      opacity: 0;
    }
  }
}
