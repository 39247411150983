.cb-region {
  margin-bottom: var(--m-10);
  .region-title {
    display: flex;
    margin-bottom: var(--m-4);
    align-items: center;
    .text {
      font-size: var(--font-size-lg);
      font-weight: 400;
    }
  }
}
