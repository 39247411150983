.modal-component {
  max-width: 100%;
  &.fluid {
    width: fit-content;
  }
  .rs-modal-header {
    font-size: var(--font-size-lg);
    font-weight: 500;
  }
  //   &.rs-modal {
  //     .rs-modal-dialog {
  //       .rs-modal-content {
  //         overflow-y: auto;
  //       }
  //     }
  //   }
  .footer {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap-reverse;
  }
  //   .rs-modal-body {
  //     overflow: visible !important;
  //     // overflow: visible !important;

  //     .selection-default {
  //       .message {
  //         padding-bottom: var(--p-5);
  //       }
  //     }
  //   }
  &.no-padding {
    .rs-modal-content {
      padding: var(--p-0);
      .rs-modal-body {
        margin: var(--m-0);
        padding: var(--p-0);
      }
    }
  }
}

.rs-modal-wrapper {
  max-width: initial !important;
}
