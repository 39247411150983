.bf-chooser {
  display: flex;
  flex-direction: column;
  padding: var(--p-3);
  max-height: 100%;
  .description {
    padding-bottom: var(--p-5);
  }
  .no-entries {
    text-align: center;
    color: var(--text-color-secondary);
    padding: 6px 12px;
  }
  .search-content {
    background: var(--background-color-contrast);
    border-radius: var(--border-radius-base);
    margin-top: var(--m-10);
    flex-grow: 1;
    overflow: auto;
    &.no-search {
      margin-top: var(--m-0);
    }

    button.bf-button {
      background: transparent;
      border-radius: var(--border-radius-none);
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;

      &:hover {
        background: var(--table-row-color-hover);
      }
    }

    .bf-chooser-group {
      .bf-chooser-group-button {
        background: transparent;
        gap: 10px;

        .bf-chooser-group-label {
          text-transform: uppercase;
          letter-spacing: 1px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .bf-chooser-group-icon {
          transform: rotate(0deg);
          transition: 0.2s ease;
          &.open {
            transform: rotate(180deg);
          }
        }
      }
    }
    .bf-chooser-entry {
      width: 100%;
      border-bottom: solid 1px var(--border-color-primary);
      &:last-of-type {
        border-bottom: none;
      }
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      .bf-chooser-entry-label {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .bf-chooser-entry-sub-label {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: var(--font-size-sm);
        color: var(--text-color-secondary);
      }

      &.selected {
        background: var(--table-row-color-hover);
      }
      &.bold {
        font-weight: bold;
      }
    }
  }
}
