.ez-select-dropdown-content {
  min-width: 300px;
  width: auto;
  height: 500px;
  display: flex;
  flex-direction: column;

  .asset-selection {
    padding: var(--p-3);
  }
  .cleanable-action {
    display: flex;
    justify-content: flex-end;
    padding-bottom: var(--p-3);
  }
  .search {
    border-bottom: solid 1px var(--border-color-secondary);

    input.rs-input {
      padding-left: var(--p-0);
    }
  }
  .no-type-selection {
    padding: var(--p-5);
    text-align: center;
    color: var(--text-color-secondary);
  }
  .select-content {
    flex-grow: 1;
    overflow: hidden;
    .selection-item {
      width: 100%;
      text-align: left;
      background-color: transparent;

      .asset-select-label {
        overflow: hidden;
        padding: 8px 12px;
        color: var(--rs-text-primary);
        font-weight: 400;
      }

      &:hover {
        background-color: var(--table-row-color-hover);
        .label {
          color: var(--rs-listbox-option-hover-text);
        }
      }
      &.selected {
        background-color: var(--table-row-color-hover);

        .asset-select-label {
          color: var(--rs-listbox-option-selected-text);
          font-weight: 700;
        }
      }
    }
  }
}
