.accounting-should-position-form {
  .accounting-form-additional-data {
    flex-direction: column;
  }

  .bookings-table {
    width: 100%;
    tr {
      th {
        text-align: left;
      }
    }
  }
}
