.comment-entry-footer {
  display: flex;
  border-top: solid 1px var(--border-color-secondary);
  background: var(--background-color-primary);
  border-bottom-left-radius: var(--border-radius-base);
  border-bottom-right-radius: var(--border-radius-base);

  .attachment-button {
    display: flex;
    align-items: center;
    .bf-icon.attachment-icon {
      margin-right: var(--m-2);
    }
    .bf-icon.dropdown-ident {
      margin-left: var(--m-4);
      width: 12px;
      height: 12px;
      display: flex;
      align-items: center;
      transition: transform 0.2s ease-in-out;
    }
    &.collapsed {
      .bf-icon.dropdown-ident {
        transform: rotate(180deg);
      }
    }
  }
}
