.ap-active-project-budget-charts {
  --dashboard-height: 300px;
  padding-top: var(--p-10);

  display: flex;
  gap: 20px;

  .progress-card {
    width: var(--dashboard-height);
    height: var(--dashboard-height);
  }
  .comparison-card {
    flex-grow: 1;
    height: var(--dashboard-height);
  }
}
