@import "./vars.scss";

body {
  --text-color-primary: #444;
  --text-color-secondary: #888;
  --text-color-disabled: #aaa;
  --text-color-contrast: #fff;
  --text-color-strong: #000;
  --text-color-placeholder: #aaa;

  --ck-z-modal: 10000;
  --ez-hover-color: #e2e5f3;

  --overlay-background: rgba(0, 0, 0, 0.5);

  --rs-menuitem-active-text: var(--base-color);
  --swiper-theme-color: var(--base-color);
  --rs-text-link: var(--base-color);
  --rs-text-link-hover: var(--base-color-hover);
  --rs-text-link-active: var(--base-color-hover);

  --rs-navs-selected: var(--base-color);
  --rs-btn-primary-bg: var(--base-color);
  --rs-listbox-option-hover-text: var(--base-color);
  --rs-listbox-option-selected-text: var(--base-color);
  --rs-picker-value: var(--base-color);
  --color-negative: rgb(166, 0, 0);
  --color-negative-hover: rgb(100 0 0);
  --color-positive: rgb(0, 115, 0);
  --color-warning: rgb(115, 86, 0);
  --color-warning-strong: rgb(49, 38, 0);
  --color-neutral: #000;

  --button-color-base: var(--base-color);
  --button-color-link: var(--base-color);
  --button-color-link-hover: var(--base-color-darken-13);
  --button-color-outline: var(--base-color);
  --button-color-outline-hover: var(--base-color-darken-13);
  // The priority colors will also be defined in the following file:
  // Constants.ts
  // export const PriorityColorCodes
  --color-prio-low: #198c62;
  --color-prio-medium: #879300;
  --color-prio-high: #d26405;

  --default-inset-shadow: inset 0 0 4px #000;
  --border-radius-none: 0px;
  --border-radius-base: 6px;
  --border-radius-big: 12px;
  --border-radius-large: 16px;
  --border-radius-extra-large: 20px;
  --border-radius-rounded: 50%;
  --card-default-box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.5);

  --app-background: rgba(190, 190, 190, 1);
  --app-background-loadmask: rgba(190, 190, 190, 0.58);
  /* BASE VARIABLES */
  --base-color: #003468;
  --base-color-lighten-8: rgb(0, 72.4, 144.8);
  --base-color-lighten-65: rgb(180.5, 217.75, 255);
  --base-color-lighten-75: rgb(231.5, 243.25, 255);
  --base-color-darken-5: rgb(0, 39.25, 78.5);
  --base-color-darken-13: rgb(0, 18.85, 37.7);
  --base-color-desaturated-70: rgb(36.4, 52, 67.6);
  --base-color-shadow: rgba(0, 52, 104, 0.3);

  --base-color-hover: var(--base-color-lighten-8);
  --base-color-foreground: #fff;

  --divider-color-dark: rgb(182, 182, 182);
  --divider-color: rgb(223, 223, 223);
  /* Application Layouts */
  --header-height: 45px;
  --header-background: rgb(0, 13.75, 27.5);
  --header-background-development: rgb(123, 0, 0);
  --header-background-test-server: rgb(145, 45, 2);
  --header-color: #fff;
  --sidenav-background: linear-gradient(
    to top,
    rgb(0, 26.5, 53) 0%,
    rgb(0, 13.75, 27.5) 100%
  );
  --sidenav-color: #fff;
  --sidenav-border: #aaa;

  --sidenav-background-grey: rgb(211, 211, 211);
  --sidenav-color-grey: #222;

  /* LoginView */
  --login-background: linear-gradient(
    to bottom,
    rgb(0, 59.65, 119.3) 0%,
    rgb(0, 16.3, 32.6) 100%
  );
  --login-cookies-notification-background: #fff;
  --login-cookies-notification-color: #000;
  --login-center-background: #fff;
  --login-footer-button-color: #aaa;

  /* ApplicationDrawer */
  --app-drawer-background: rgba(0, 0, 0, 0.4);

  --dropdown-background-dark: var(--base-color-desaturated-70);
  /* Table variables */
  --table-titlebar-height: 35px;
  --table-titlebar-background: var(--base-color-darken-13);
  --table-titlebar-search-background: var(--base-color-desaturated-70);
  --table-titlebar-search-foreground: #fff;
  --table-titlebar-color: #fff;
  --table-footer-background: var(--table-titlebar-background);
  --table-footer-color: var(--table-titlebar-color);
  --table-header-background: #cecece;
  --table-header-height: 29px;
  --table-header-background-hover: #bcbcbc;
  --table-header-column-resize-spanner-color: #aaa;
  --table-row-color: #fff;
  --table-row-color-striped: #eee;
  --table-row-color-hover: var(--base-color-lighten-75);
  --table-row-color-selected: var(--base-color);
  --table-row-color-selected-hover: var(--base-color-darken-5);
  --table-row-color-selected-color: #fff;
  --table-row-foreground: var(--text-color-primary);
  --table-row-foreground-selected: #fff;
  --table-row-deleted-color: #aaa;
  --table-row-changed-color: rgb(54, 54, 54);
  --plan-data-table-border-color: #d6d6d6;

  --list-row-background: #fff;
  --list-row-background-hover: var(--base-color-lighten-75);
  --list-row-background-selected: var(--base-color-lighten-65);

  /* help drawer variables */
  --help-drawer-header-background: #000102;
  --help-drawer-header-color: #fff;

  /* Form-Element variables */
  --form-field-view-table-row-background: #fff;
  --form-field-view-table-subtitle-background: #dbebff;
  --form-field-view-table-subtitle-color: #000;

  /* Font-size variables */
  --font-size-xs: 10px;
  --font-size-sm: 12px;
  --font-size-base: 14px;
  --font-size-md: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 20px;
  --font-size-2xl: 22px;
  --font-size-3xl: 24px;
  --font-size-4xl: 28px;
  --font-size-5xl: 30px;
  --font-size-6xl: 32px;
  --font-size-7xl: 60px;
  --font-size-8xl: 80px;
  .__font-size-xs {
    font-size: var(--font-size-xs);
  }
  .__font-size-sm {
    font-size: var(--font-size-sm);
  }
  .__font-size-base {
    font-size: var(--font-size-base);
  }
  .__font-size-md {
    font-size: var(--font-size-md);
  }
  .__font-size-lg {
    font-size: var(--font-size-lg);
  }
  .__font-size-xl {
    font-size: var(--font-size-xl);
  }
  .__font-size-2xl {
    font-size: var(--font-size-2xl);
  }
  .__font-size-3xl {
    font-size: var(--font-size-3xl);
  }
  .__font-size-4xl {
    font-size: var(--font-size-4xl);
  }
  .__font-size-5xl {
    font-size: var(--font-size-5xl);
  }
  .__font-size-6xl {
    font-size: var(--font-size-6xl);
  }
  .__font-size-7xl {
    font-size: var(--font-size-7xl);
  }
  .__font-size-8xl {
    font-size: var(--font-size-8xl);
  }

  /* Margin variables */
  --m-0: 0px;
  --m-1: 2px;
  --m-2: 4px;
  --m-3: 6px;
  --m-4: 8px;
  --m-5: 10px;
  --m-6: 12px;
  --m-7: 14px;
  --m-8: 16px;
  --m-9: 18px;
  --m-10: 20px;
  --m-11: 22px;
  --m-13: 26px;
  --m-15: 30px;
  --m-16: 32px;
  --m-18: 36px;
  --m-20: 40px;
  --m-21: 42px;
  --m-50: 100px;

  /* Padding variables */
  --p-0: 0px;
  --p-1: 2px;
  --p-2: 4px;
  --p-3: 6px;
  --p-4: 8px;
  --p-5: 10px;
  --p-6: 12px;
  --p-7: 14px;
  --p-8: 16px;
  --p-9: 18px;
  --p-10: 20px;
  --p-11: 22px;
  --p-12: 24px;
  --p-13: 26px;
  --p-15: 30px;
  --p-16: 32px;
  --p-17: 34px;
  --p-18: 36px;
  --p-20: 40px;
  --p-23: 46px;
  --p-25: 50px;
  --p-28: 56px;
  --p-45: 90px;

  /* Border color variables */
  --border-color-primary: #eee;
  --border-color-secondary: #aaa;
  --border-color-tertiary: #888;
  --border-color-accent: #464646;
  --border-color-contrast: #fff;
  --border-color-contrast-inverse: #000;

  --declined-comment-border: rgb(162, 114, 114);
  --border-color-pdf-checkbox-checked: #3498ff;
  --border-cash-budget-sub-head: #eee0;

  /* Icon color variables */
  --icon-color-contrast: #fff;
  --icon-color-contrast-inverse: #000;
  --icon-color-secondary: #888;
  --icon-color-secondary-accent: #5a5a5aff;

  /* Icon fill variables */
  --icon-fill-primary: #444;
  --icon-fill-secondary: #999;
  --icon-fill-disabled: #aaa;
  --icon-fill-contrast: #fff;

  /* Background color variables */
  --background-color-contrast: #fff;
  --background-color-primary: #eee;

  --rs-bg-overlay: var(--background-color-contrast);

  --background-urgent: rgb(253, 235, 235);
  --background-color-secondary: #bdbdbd;
  --background-color-contrast-inverse: #000;
  --background-color-contrast-inverse-2: rgba(0, 0, 0, 0.2);
  --background-color-contrast-inverse-0: rgba(0, 0, 0, 0);
  --background-color-accent: #8b8d8e;
  --background-color-deep: #3b3b3b;
  --background-color-light-base: rgba(0, 114, 163, 0.824);
  --background-color-light-error: rgb(255, 189, 189);
  --background-color-light-positive: rgb(151, 210, 164);
  --background-color-focus: #8abbb9;
  --background-color-toggle-urgent-checked: rgb(222, 125, 125);
  --background-diagram-tree-common: rgba(90, 105, 192, 0.1);
  --background-diagram-tree-object: rgba(13, 149, 140, 0.2);

  /* Opacity background */
  --background-opacity-primary: rgba(0, 0, 0, 0.5);
  --background-opacity-secondary: rgba(0, 0, 0, 0.25);
  --background-opacity-strong: rgba(0, 0, 0, 0.8);
  --background-opacity-weak: rgba(0, 0, 0, 0.1);
  --background-opacity-tertiary-main: rgba(255, 255, 255, 0.5);
  --background-opacity-tertiary-weak: rgba(255, 255, 255, 0.2);
  --background-opacity-tertiary-low: rgba(255, 255, 255, 0);

  /* Scroll background */
  --scroll-background-color: rgba(38, 38, 38, 0.5);
  --scroll-background-hover: rgba(38, 38, 38, 0.7);

  /* Drag overlay background */
  --drag-overlay-background: rgba(3, 48, 61, 0.299);
  --drag-overlay-border: #03303d;

  /* Invoice indicator */
  --invoice-indicator-incoming-icon-background: rgb(202, 230, 241);
  --invoice-indicator-outgoing-icon-background: rgb(190, 227, 198);
  --invoice-indicator-incoming-icon-foreground: rgb(1, 62, 86);
  --invoice-indicator-outgoing-icon-foreground: rgb(0, 80, 17);

  /* BF Input variables */
  --bf-input-primary-border: #d3d3d3;
  --bf-input-focus-border: #686de0;
  --bf-input-focus-box-shadow: rgba(104, 109, 224, 0.5);
  --bf-input-complete-color: #6ab04c;
  --bf-input-complete-disabled-background: rgba(106, 176, 76, 0.1);
  --bf-input-invalid-border: #dc3545;
  --bf-input-invalid-box-shadow: rgba(220, 53, 69, 0.5);

  /* Cash Budget variables */
  --cashbudget-color-border: #eee;
  --cashbudget-color-menu: #e9e8e88f;
  --cashbudget-color-today: rgba(195, 196, 255, 0.261);
  --cashbudget-color-prognose: rgba(202, 255, 204, 0.481);
  --cashbudget-color-planned-booking: rgba(251, 255, 172, 0.556);
  --cashbudget-color-sharepoint: rgba(172, 196, 255, 0.556);
  --cashbudget-color-fixed-comparison: rgba(255, 236, 172, 0.556);

  /* Box Shadow variables */
  --shadow-contrast-inverse-1: rgba(0, 0, 0, 0.1);
  --shadow-contrast-inverse-2: rgba(0, 0, 0, 0.2);
  --shadow-contrast-inverse-3: rgba(0, 0, 0, 0.3);
  --shadow-contrast-inverse-4: rgba(0, 0, 0, 0.4);
  --shadow-contrast-inverse-5: rgba(0, 0, 0, 0.5);
  --shadow-contrast-inverse-6: rgba(0, 0, 0, 0.6);
  --shadow-numeric-slider-hover: rgba(52, 152, 255, 0.25);
  --shadow-restrict-component-add-form: #444;
  --shadow-labeled-input-highlight: #637381;
  --shadow-contrast: #fff;
  --shadow-contrast-7: rgba(255, 255, 255, 0.7);

  /* Outline colors */
  --object-gallery-item-outline: #3498ff;

  /* Chart colors */
  --chart-axis-label-primary: #6f7079;
  --chart-color-secondary: #c3c3c3;
  --chart-color-positive: #00bb00;
  --chart-color-positive-weak: #3e7a2e;
  --chart-color-negative: #a10318;
  --chart-color-prio-medium: #b5b509;
  --chart-color-prio-high: #b56509;
  --chart-color-prio-low: #2f2b2b;
  --chart-color-focus: #3481a5;
  --chart-border-contrast: var(--background-color-contrast);
  --chart-shadow-primary: rgba(0, 0, 0, 0.5);

  --cb-portfolio-immo-vacant: #c4504f;
  --cb-portfolio-immo-non-vacant: var(--chart-color-positive);

  /* By Dunning Level Chart */
  --chart-by-dunning-level-1: #39a0ba;
  --chart-by-dunning-level-2: #ddb223;
  --chart-by-dunning-level-3: #ff8000;
  --chart-by-dunning-level-4: #ba0202;
  --chart-by-dunning-level-5: #3c2a2a;
  --chart-by-dunning-level-6: #3b1717;

  /* Opos Month Chart */
  --chart-opos-month-rent: #bf5119;
  --chart-opos-month-operating-cost: #14989f;
  --chart-opos-month-dunning: #749e09;
  --chart-opos-month-default-interest: #099e71;
  --chart-opos-month-deposit: #569e09;
  --chart-opos-month-other: #24099e;

  /* Rent Data Chart */
  --chart-rent-data-candlestick-up: #00a676;
  --chart-rent-data-candlestick-down: #d9534f;
  --chart-rent-data-median: #800080;
  --chart-rent-data-min: #1f4e79;
  --chart-rent-data-max: #b565a7;
  --chart-rent-data-current-rent: #ff6f00;
  --chart-rent-data-planned-rent: #007fff;

  /* Risk Scenario Chart */
  --chart-risk-scenario-current-balance-area: rgba(132, 142, 41, 0.294);
  --chart-risk-scenario-current-balance-color: rgba(164, 190, 46, 0.5);
  --chart-risk-scenario-current-balance-line-color: #000;
  --chart-risk-scenario-planned-balance-area: rgba(73, 73, 182, 0.375);
  --chart-risk-scenario-planned-balance-color: rgba(67, 73, 180, 0.5);
  --chart-risk-scenario-accumulated-interest-color: #94700c;
  --chart-risk-scenario-interest-area: rgba(177, 137, 37, 0.412);
  --chart-risk-scenario-interest-color: #b18a26;
  --chart-risk-scenario-interest-line-color: #000;
  --chart-risk-scenario-repayment-area: rgba(13, 156, 48, 0.471);
  --chart-risk-scenario-repayment-color: #0d9b31;
  --chart-risk-scenario-other-cost-area: rgba(0, 186, 183, 0.463);
  --chart-risk-scenario-other-cost-color: #00bab7;
  --chart-risk-scenario-vacancy-loss-area: rgba(156, 0, 0, 0.333);
  --chart-risk-scenario-vacancy-loss-color: #9c0000;
  --chart-risk-scenario-rental-plan-color: #00319c;
  --chart-risk-scenario-rental-plan-label: #9c0000ff;
  --chart-risk-scenario-rental-plan-item-color: rgba(255, 0, 0, 0.174);
  --chart-risk-scenario-rental-plan-item-border: rgba(255, 0, 0, 0.174);

  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-dropdown-menu
    > li.rs-dropdown-item-active:not(.rs-dropdown-item-submenu)
    > .rs-dropdown-item-content,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-dropdown-menu
    > li.rs-dropdown-item-active:not(.rs-dropdown-item-submenu)
    > .rs-dropdown-item-content,
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-dropdown-menu
    > li.rs-dropdown-item-active:not(.rs-dropdown-item-submenu)
    > .rs-dropdown-item-content:hover,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-dropdown-menu
    > li.rs-dropdown-item-active:not(.rs-dropdown-item-submenu)
    > .rs-dropdown-item-content:hover,
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-dropdown-menu
    > li.rs-dropdown-item-active:not(.rs-dropdown-item-submenu)
    > .rs-dropdown-item-content:focus,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-dropdown-menu
    > li.rs-dropdown-item-active:not(.rs-dropdown-item-submenu)
    > .rs-dropdown-item-content:focus {
    background-color: var(--base-color);
  }

  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-dropdown-menu
    > li
    > .rs-dropdown-item-content:hover,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-dropdown-menu
    > li
    > .rs-dropdown-item-content:hover,
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-dropdown-item-content
    > .rs-dropdown-menu-toggle:hover,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-dropdown-item-content
    > .rs-dropdown-menu-toggle:hover,
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-dropdown-menu
    > li
    > .rs-dropdown-item-content:focus,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-dropdown-menu
    > li
    > .rs-dropdown-item-content:focus,
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-dropdown-item-content
    > .rs-dropdown-menu-toggle:focus,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-dropdown-item-content
    > .rs-dropdown-menu-toggle:focus {
    background-color: var(--base-color-hover);
  }

  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-dropdown-menu
    > li
    > .rs-dropdown-item-content:active,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-dropdown-menu
    > li
    > .rs-dropdown-item-content:active,
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-dropdown-item-content
    > .rs-dropdown-menu-toggle:active,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-dropdown-item-content
    > .rs-dropdown-menu-toggle:active {
    background-color: var(--base-color-hover);
  }

  .rs-btn-primary {
    background-color: var(--base-color);

    &:hover {
      background-color: var(--base-color-hover);
    }
  }

  .rs-nav:not(.rs-nav-subtle)
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle,
  .rs-nav:not(.rs-nav-subtle) .rs-nav-item-active > .rs-nav-item-content,
  .rs-nav:not(.rs-nav-subtle)
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle:hover,
  .rs-nav:not(.rs-nav-subtle) .rs-nav-item-active > .rs-nav-item-content:hover,
  .rs-nav:not(.rs-nav-subtle)
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle:focus,
  .rs-nav:not(.rs-nav-subtle) .rs-nav-item-active > .rs-nav-item-content:focus,
  .rs-nav:not(.rs-nav-subtle)
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle:active,
  .rs-nav:not(.rs-nav-subtle)
    .rs-nav-item-active
    > .rs-nav-item-content:active {
    color: var(--base-color-foreground);
    background-color: var(--base-color-hover);
  }

  .rs-nav.rs-nav-subtle
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle,
  .rs-nav.rs-nav-subtle .rs-nav-item-active > .rs-nav-item-content,
  .rs-nav.rs-nav-subtle
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle:hover,
  .rs-nav.rs-nav-subtle .rs-nav-item-active > .rs-nav-item-content:hover,
  .rs-nav.rs-nav-subtle.rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle:focus,
  .rs-nav.rs-nav-subtle .rs-nav-item-active > .rs-nav-item-content:focus,
  .rs-nav.rs-nav-subtle
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle:active,
  .rs-nav.rs-nav-subtle .rs-nav-item-active > .rs-nav-item-content:active {
    color: var(--base-color);
    &:before {
      background-color: var(--base-color);
    }
    // background-color: var(--base-color-hover);
  }

  .rs-sidenav-inverse
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle,
  .rs-sidenav-inverse .rs-nav-item-active > .rs-nav-item-content,
  .rs-sidenav-inverse .rs-sidenav-nav .rs-dropdown .rs-dropdown-toggle:active,
  .rs-sidenav-inverse
    .rs-sidenav-nav
    .rs-nav-item:not(.rs-nav-item-active)
    > .rs-nav-item-content:active {
    background-color: var(--base-color);
  }

  .rs-btn-primary:focus,
  .rs-btn-primary.rs-btn-focus {
    background-color: var(--base-color-hover);
  }

  .rs-btn-primary:active,
  .rs-btn-primary.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary {
    background-color: var(--base-color-hover);
  }

  .rs-btn-primary:not(.rs-btn-disabled):hover {
    background-color: var(--base-color-hover);
  }

  .rs-btn-primary:active:hover,
  .rs-btn-primary.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary:hover,
  .rs-btn-primary:active:focus,
  .rs-btn-primary.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary:focus,
  .rs-btn-primary:active.focus,
  .rs-btn-primary.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.focus {
    background-color: var(--base-color-hover);
  }

  .rs-navbar.rs-navbar-inverse
    .rs-navbar-nav
    .rs-nav-item
    > .rs-nav-item-content:active,
  .rs-navbar.rs-navbar-inverse
    .rs-navbar-nav
    .rs-dropdown
    .rs-dropdown-toggle:active {
    background-color: var(--base-color-hover);
  }

  .rs-btn-primary.rs-btn-loading:hover,
  .rs-btn-primary.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-primary.rs-btn-loading:focus,
  .rs-btn-primary.rs-btn-loading:active,
  .rs-btn-primary.rs-btn-loading:hover:active {
    background-color: var(--base-color-hover);
  }

  .rs-navbar.rs-navbar-inverse
    .rs-navbar-nav
    .rs-nav-item
    > .rs-nav-item-content:hover,
  .rs-navbar.rs-navbar-inverse
    .rs-navbar-nav
    .rs-dropdown
    .rs-dropdown-toggle:hover,
  .rs-navbar.rs-navbar-inverse
    .rs-navbar-nav
    .rs-nav-item
    > .rs-nav-item-content:focus,
  .rs-navbar.rs-navbar-inverse
    .rs-navbar-nav
    .rs-dropdown
    .rs-dropdown-toggle:focus {
    background-color: var(--base-color-hover);
  }

  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle,
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-nav-item-active
    > .rs-nav-item-content,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-nav-item-active
    > .rs-nav-item-content,
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle:hover,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle:hover,
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-nav-item-active
    > .rs-nav-item-content:hover,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-nav-item-active
    > .rs-nav-item-content:hover,
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle:focus,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle:focus,
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-nav-item-active
    > .rs-nav-item-content:focus,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-nav-item-active
    > .rs-nav-item-content:focus,
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle:active,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle:active,
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-nav-item-active
    > .rs-nav-item-content:active,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-nav-item-active
    > .rs-nav-item-content:active {
    background-color: var(--base-color);
  }

  .rs-sidenav-inverse
    .rs-sidenav-nav
    .rs-nav-item:not(.rs-nav-item-active)
    > .rs-nav-item-content:hover,
  .rs-sidenav-inverse .rs-sidenav-nav .rs-dropdown .rs-dropdown-toggle:hover,
  .rs-sidenav-inverse
    .rs-sidenav-nav
    .rs-nav-item:not(.rs-nav-item-active)
    > .rs-nav-item-content:focus,
  .rs-sidenav-inverse .rs-sidenav-nav .rs-dropdown .rs-dropdown-toggle:focus {
    background-color: var(--base-color-hover);
  }

  .rs-pagination > li.rs-pagination-btn-active > a:active,
  .rs-pagination > li.rs-pagination-btn-active > a:active:hover,
  .rs-pagination > li.rs-pagination-btn-active > a:active:focus,
  .rs-pagination > li.rs-pagination-btn-active > a:active.focus {
    color: var(--base-color);
  }

  .rs-btn-toggle-checked {
    background-color: var(--base-color);
  }

  .rs-btn-toggle-checked:hover {
    background-color: var(--base-color-hover);
  }

  .rs-checkbox-wrapper::before {
    border: 1px solid var(--base-color);
  }

  .rs-checkbox label:hover .rs-checkbox-inner::before {
    border-color: var(--base-color-hover);
  }
  .rs-checkbox-checker:hover .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: var(--base-color-hover);
  }
  :hover.rs-checkbox-wrapper
    [type="checkbox"]:focus
    ~ .rs-checkbox-inner::before {
    box-shadow: 0 0 0 2px var(--base-color-shadow);
  }

  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
  .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: var(--base-color);
    background-color: var(--base-color);
  }

  :hover.rs-checkbox:not(.rs-checkbox-disabled)
    .rs-checkbox-wrapper:focus
    .rs-checkbox-inner::before {
    box-shadow: 0 0 6px 0px var(--base-color);
  }

  .rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
    border-color: var(--base-color);
    background-color: var(--base-color);
  }

  .rs-radio-wrapper::before {
    border: 1px solid var(--base-color);
  }

  .rs-radio label:hover .rs-radio-inner::before {
    border-color: var(--base-color);
  }

  :hover.rs-radio:not(.rs-radio-disabled)
    .rs-radio-wrapper:focus
    .rs-radio-inner::before {
    box-shadow: 0 0 6px 0px var(--base-color);
  }

  .rs-picker-check-menu-item-active
    .rs-picker-check-menu-item-wrapper
    .rs-picker-check-menu-item-inner::before,
  .rs-picker-check-menu-item-indeterminate
    .rs-picker-check-menu-item-wrapper
    .rs-picker-check-menu-item-inner::before {
    border-color: var(--base-color);
    background-color: var(--base-color);
  }

  .rs-picker-value-count {
    background: var(--base-color);
  }

  .rs-picker-date {
    .rs-picker-toggle {
      .rs-picker-toggle-value {
        color: var(--text-color-primary);
      }
    }
  }
  .rs-picker-menu {
    &.rs-picker-date-menu {
      .rs-calendar-header-title.rs-calendar-header-title-date {
        color: var(--base-color);
      }
      .rs-calendar-month-dropdown-year.rs-calendar-month-dropdown-year-active {
        color: var(--base-color);
      }
      .rs-calendar-month-dropdown-cell.rs-calendar-month-dropdown-cell-active {
        .rs-calendar-month-dropdown-cell-content {
          background: var(--base-color);
        }
      }

      .rs-calendar-table-cell-selected {
        .rs-calendar-table-cell-content {
          background: var(--base-color);
        }
      }

      .rs-picker-toolbar {
        .rs-picker-toolbar-option {
          color: var(--base-color);
        }
      }
      .rs-picker-toolbar-right-btn-ok {
        background-color: var(--base-color);
      }
    }
  }
}
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active,
.rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled):hover,
.rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled).rs-input-group-focus {
  border-color: var(--base-color);
}
