.bf-checkbox-list__labeled-input {
  .bf-checkbox-list {
    border: 1px solid var(--rs-border-primary);
    border-radius: var(--border-radius-base);
    padding: 5px 20px 5px 5px;
    max-height: 500px;
    overflow: auto;
    &:focus-within,
    &:focus,
    &:active {
      outline: 3px solid var(--rs-color-focus-ring);
      border-color: var(--rs-input-focus-border);
    }
    .__empty {
      padding: var(--p-3);
      font-size: var(--font-size-sm);
    }
  }
  > .label {
    align-items: center;

    > .suffix {
      align-items: center;
      display: flex;
    }
  }
}
.bf-checkbox-list__additional-actions {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}
