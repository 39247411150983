@media screen and (max-width: 576px) {
  body {
    .rs-notification.rs-notification-alert {
      top: 0 !important;
      left: 0;
      right: 0;

      .rs-notification-notice-wrapper {
        margin-left: var(--m-0);
        width: 100%;
        &:not(.rs-notification-fade-exited) + .rs-notification-notice-wrapper {
          margin-top: 1px;
        }

        .rs-notification-notice {
          width: 100%;

          .rs-notification-notice-content {
            //padding:10px;
          }
        }
      }
    }
  }
}
