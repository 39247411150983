.bf-list {
  position: relative;
  width: 100%;
  height: 100%;
  .list-scroll {
    overflow: auto;
    width: 100%;
    height: 100%;
  }
  .empty-text {
    padding: var(--p-10);
  }
  .loading-overlay {
    z-index: 2;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background: var(--background-opacity-primary);
  }
  .loading-bottom-container {
    pointer-events: none;
    z-index: 2;

    position: absolute;
    bottom: 10px;
    left: calc(50% - 20px);

    .loading-bottom {
      width: 40px;
      height: 40px;
      border-radius: var(--border-radius-base);
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--background-opacity-primary);
    }
  }

  .show-new-data-warning-wrapper {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    z-index: 4;
    pointer-events: none;

    .show-new-data-warning {
      position: relative;
      max-width: 500px;
      background: var(--background-opacity-strong);
      backdrop-filter: blur(10px);
      color: var(--text-color-contrast);
      display: flex;
      padding: 20px 30px;
      align-items: center;
      border-radius: var(--border-radius-base);
      pointer-events: all;
      button {
        pointer-events: all;
      }
      .text {
        padding-right: var(--p-8);
      }
      button.bf-button {
        flex: 1 0 100px;
      }
      .close-button {
        position: absolute;
        top: 3px;
        right: 3px;
        padding: 1px;
        .bf-icon {
          width: 11px;
          height: 11px;
          font-size: 0;
        }
      }
    }
  }

  &.flow {
    .list-scroll {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      height: fit-content;
      max-height: 100%;
    }
  }
}
