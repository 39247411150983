@import "../../../styles/vars";

.grid-layout {
  display: grid;

  .grid-layout-item {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    // overflow: hidden;
  }
}

@media screen and (max-width: $XL) {
  .grid-layout.grid-layout-break-on-lg {
    display: block !important;
    flex-direction: column;
    height: initial;
    width: 100%;

    .grid-layout-item {
      margin-bottom: var(--m-5);
    }
  }
}

@media screen and (max-width: $LG) {
  .grid-layout.grid-layout-break-on-md {
    display: block !important;
    flex-direction: column;
    height: initial;
    width: 100%;

    .grid-layout-item {
      margin-bottom: var(--m-5);
    }
  }
}

@media screen and (max-width: $MD) {
  .grid-layout.grid-layout-break-on-sm {
    display: block !important;
    flex-direction: column;
    height: initial;
    width: 100%;

    .grid-layout-item {
      margin-bottom: var(--m-5);
    }
  }
}

@media screen and (max-width: $SM) {
  .grid-layout {
    padding: 8px !important;
  }
  .grid-layout.grid-layout-break-on-xs {
    display: block !important;
    flex-direction: column;
    height: initial;
    width: 100%;

    .grid-layout-item {
      margin-bottom: var(--m-5);
    }
  }
}
