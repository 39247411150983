.export-dialog {
  .description {
    margin-bottom: var(--m-10);
  }
  .fields-to-export {
    max-height: 60vh;
    overflow: auto;
    border-top: solid 1px var(--border-color-secondary);
    border-bottom: solid 1px var(--border-color-secondary);
  }
  .export-type {
    padding-top: var(--p-10);
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--m-10);
    gap: 10px;
  }
}
