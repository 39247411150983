.pdf-constructor--text-input--textarea {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 0.25rem;

  .pdf-constructor--text-input--textarea--toolbar {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;

    & > * {
      flex-shrink: 1;
      flex-grow: unset !important;
      width: fit-content;
      gap: 0.5rem;
    }
  }

  .pdf-constructor--color-picker--trigger {
    border-radius: 0.25rem;
    padding: 0.25rem;
  }

  .pdf-constructor--checkbox {
    width: fit-content;
  }
}
