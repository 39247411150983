.rs-preview-modal {
  .rs-preview-modal-content {
  }
  .rs-preview-modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--m-10);
    gap: 10px;
  }
}
