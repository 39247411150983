.load_overlay {
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--background-opacity-tertiary-main);

  z-index: 99999;
}
