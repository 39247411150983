.preview--container {
  max-width: 100%;
  overflow: auto;
  border: 1px solid var(--border-color-primary);
  border-radius: 0.25rem;
  aspect-ratio: 16 / 9;

  display: flex;
  align-items: center;
  justify-content: center;
}
