.bf-dropzone > .content-wrapper,
.bf-dropzone-tablerow {
  position: relative;
  height: 100%;
  --error-upload-color: var(--color-negative);
  > .overlay-drop-indicator {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    opacity: 0;
    padding: var(--p-5);
    z-index: 2;

    &.active {
      background-color: var(--background-opacity-tertiary-weak);
      &:not(.suppress-drag) {
        opacity: 1;
      }
    }

    > .border {
      // padding: var(--p-10);
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      border-radius: var(--border-radius-extra-large);
      box-sizing: content-box;
      border: dashed 2px var(--border-color-contrast);
      display: flex;
      justify-content: center;
      align-items: center;
      > .drag-info {
        display: flex;
        align-items: center;
        .text {
          padding-left: var(--p-5);
        }
        .bf-icon,
        .text {
          color: var(--text-color-contrast);
        }
      }
    }
  }
}
