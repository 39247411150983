.cb-tenant-form-main {
  .section-title {
    display: flex;
    align-items: center;
  }
  .field-row {
    display: flex;
    gap: 10px;
  }

  .add-actions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
    padding-bottom: var(--p-5);
    padding-top: var(--p-10);
    .bf-button {
      border-radius: var(--border-radius-extra-large);
    }
  }
  .section {
    border: solid 1px var(--border-color-secondary);
    border-radius: var(--border-radius-base);
    margin-top: var(--m-15);
    padding: var(--p-8) var(--p-5) var(--p-5) var(--p-5);
    position: relative;

    .section-title {
      position: absolute;
      left: 15px;
      top: -10px;
      padding-left: var(--p-2);
      background: var(--background-color-contrast);

      background: var(--background-color-contrast);
    }

    .addresses,
    .contactPersons,
    .bankAccounts {
      .address-entry,
      .contactPersons-entry,
      .bankAccount-entry {
        border-bottom: solid 1px var(--border-color-primary);
        padding-bottom: var(--p-8);
        margin-bottom: var(--m-8);
        &:last-of-type {
          border-bottom: 0px;
          padding-bottom: var(--p-0);
          margin-bottom: var(--m-0);
        }
        .action-row {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  .contact-entries {
    .actions {
      display: flex;
      justify-content: center;
      padding-top: var(--p-5);
      padding-bottom: var(--p-5);
    }
    .contact-entries-wrapper {
      .contact-entry {
        padding-bottom: var(--p-5);
        margin-bottom: var(--m-5);
        border-bottom: solid 1px var(--border-color-secondary);
        .entry-actions {
          display: flex;
          align-items: center;
          gap: 5px;
          .title {
            flex: 1 0;
          }
          .validation-popover {
            flex-grow: initial !important;
          }
        }
        &:last-of-type {
          margin-bottom: var(--m-0);
          padding-bottom: var(--p-0);
          border-bottom: 0px;
        }
      }
    }
  }
}
