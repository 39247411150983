.load-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background-opacity-tertiary-main);
  pointer-events: none;
  z-index: 99999;
}
