.rs-creation-form-component {
  .rs-creation-form {
    .fields {
      .row {
        display: flex;
        gap: 20px;
        width: 100%;

        .rs-remove-additional-field-btn {
          margin-left: auto;
        }

        .__field {
          &.fill {
            flex: 1;
          }

          &.right {
            margin-left: auto;
          }

          &.additional-field-remove {
            align-self: center;
          }
        }
      }
    }

    .add-actions {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 5px;
      padding-bottom: var(--p-5);
      padding-top: var(--p-10);
      .bf-button {
        border-radius: var(--border-radius-extra-large);
      }
    }
  }
}
