.object-related-label {
  .object-related-label__type {
    font-size: var(--font-size-sm);
    color: var(--text-color-secondary);
  }
}
.object-related-list-tooltip {
  padding: var(--p-5);
  text-align: left;
  .object-related-label-container {
    .object-related-label__type {
      color: var(--text-color-disabled);
    }
  }
}
