@import "../../styles/vars.scss";

.application-header {
  position: relative;
  height: var(--header-height);
  // background: var(--header-background);
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // height: var(--header-height);
  background: var(--header-background);

  display: flex;
  flex-direction: row-reverse;

  &.mobile {
    .appDrawerButton {
      .logo {
        width: auto;
        height: 25px;
        margin-right: var(--m-0);
      }

      .applicationName {
        margin-left: var(--m-5);
        font-size: 1em;
      }
    }
  }
  .app-drawer-dropdown {
    .header-dropdown {
      background: transparent;
    }
  }

  .profile-panel {
    display: flex;
    padding: 12px 8px 8px 8px;
    align-items: center;
    .profile-image {
      display: flex;
      justify-content: left;
      align-items: flex-start;
      padding-right: var(--p-3);
    }
    .data {
      flex-grow: 1;
      .name {
        // font-weight: 500;
        font-size: var(--font-size-md);
      }
    }
  }

  .mobile-header {
    padding-left: var(--p-0);
    color: var(--text-color-contrast);
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: center;
    font-size: 1em;
    .icon {
      padding-right: var(--p-5);
    }
  }

  &.transparent {
    background: var(--background-opacity-primary);
  }

  .appDrawerButton {
    padding-left: var(--p-5);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(0%);
    transition: transform 0.2s;

    &.initializing {
      transform: translateY(-100%);
    }

    .logo {
      display: inline-block;
      width: auto;
      height: 30px;
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      margin-left: var(--m-1);
      margin-right: var(--m-4);
    }

    .applicationName {
      font-size: 1.3em;
      display: inline-block;
      margin-left: var(--m-5);
      letter-spacing: 2px;
      text-transform: uppercase;
      color: var(--header-color);
    }
  }

  & > .bf-button.rs-btn-subtle {
    color: var(--header-color);
    padding-top: var(--p-0);
    padding-bottom: var(--p-0);

    &:hover {
      background: var(--background-opacity-tertiary-weak);
      color: var(--header-color);
    }

    &:focus {
      background: var(--background-opacity-tertiary-weak);
      color: var(--header-color);
    }
  }
  .profile-button {
    z-index: 1001;
  }
  .home-button {
    z-index: 1001;
    .bf-icon {
      width: 18px;
      height: 18px;
    }
  }

  .language-option {
    display: flex;
    width: 100%;
    min-width: 30px;
    align-items: center;
    .name {
      flex-grow: 1;
    }
  }

  .rs-dropdown-open {
    .header-dropdown {
      z-index: 1001;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .rs-dropdown-item-content {
        display: flex;
        align-items: center;
        .rs-icon {
          margin-right: var(--m-8);
        }
        img {
          margin-right: var(--m-8);
        }
      }
      .user-profile {
        padding: 8px 10px;
        color: var(--text-color-strong);
        display: flex;
        align-items: center;
        .username {
          font-size: 1em;
          padding-left: var(--p-3);
        }
      }
    }
  }

  ul.rs-dropdown-menu {
    li.rs-dropdown-item {
      user-select: none;
      font-size: 1.15em;
      width: 100%;
      display: flex;
      align-items: center;
      i {
        margin-right: var(--m-6);
      }
      .bf-icon {
        margin-right: var(--m-3);
      }
      img {
        margin-right: var(--m-6);
      }
      a {
        user-select: none;
      }
    }
  }

  button.rs-dropdown-toggle {
    padding-top: var(--p-0);
    padding-bottom: var(--p-0);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    .font-color-white {
      color: var(--header-color);
      display: flex;
    }

    &:hover {
      background: var(--background-opacity-tertiary-weak);
    }

    &:focus {
      background: var(--background-opacity-tertiary-weak);
    }
  }
  .rs-dropdown .rs-dropdown-menu {
    transform-origin: 100% 0%;
  }

  .rs-dropdown-open > .rs-dropdown-menu {
    animation-name: scale-in;
    animation-fill-mode: forwards;
    animation-duration: 0.15s;
    animation-iteration-count: 1;
  }
}
