.empty_content_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: var(--p-3);

  width: 100%;
  height: 100%;
}

.empty_content_text {
  max-width: 750px;
  text-align: center;
}

.empty_content_title {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: var(--m-2);
}

.empty_content_description {
  font-size: 1.25rem;
}

.empty_animation_wrapper {
  height: 175px;
  width: 175px;
}
