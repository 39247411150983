.reconnect-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;
  background: var(--background-opacity-strong);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--p-10);
  .text {
    color: var(--text-color-contrast);
    font-size: 1.5rem;
  }
}
