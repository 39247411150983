.pdf-constructor {
  display: flex;
  flex-direction: column;
  width: 90vw;
  height: 90vh;
  max-height: 90vh;
  background: transparent;
  overflow-x: auto;
  background: var(--background-color-primary);

  .form-title {
    position: sticky;
    padding: 1.25rem;
    background: var(--background-color-primary);
    border-top-left-radius: var(--border-radius-base);
    border-top-right-radius: var(--border-radius-base);
    border-bottom: solid 1px var(--border-color-secondary);
    top: 0px;
    z-index: 1000;
  }

  .form-action-row {
    position: sticky;
    padding: 1.25rem;
    background: var(--background-color-primary);
    border-top: solid 1px var(--border-color-secondary);
    border-bottom-left-radius: var(--border-radius-base);
    border-bottom-right-radius: var(--border-radius-base);
    bottom: 0px;
    z-index: 1000;
  }

  & > .pdf-constructor--wrapper {
    display: flex;
    gap: 2rem;
    flex: 1;

    & > div {
      background: var(--background-color-contrast);
      border-radius: 0.25rem;
      padding: 1rem;
      flex: 1;
      overflow-y: scroll;
      max-height: 75vh;

      .pdf-constructor--configuration {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      &.pdf-constructor--elements {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        max-width: 40rem;

        .pdf-constructor--asset-information {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }

        ul {
          list-style: none;
          gap: 1rem;
          padding: 0;
          margin: 0.5rem 0px;

          li {
            padding: 0;
          }
        }

        .pdf-constructor--template-menu {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
      }

      &.pdf-constructor--preview {
        flex: 1;
        padding: 0;
      }
    }
  }

  .tab-content {
    background-color: var(--background-color-contrast);
  }
}
