.rental-unit-history {
  height: calc(100% - 20px);
  &.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--p-10);
    text-align: center;
    color: var(--color-negative);
  }
  .vacancy-header {
    color: var(--color-negative);
  }
}
