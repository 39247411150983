.comment-email-upload-dialog {
  .files-description {
    ul {
      list-style: none;
      padding-left: var(--p-0);
      margin-top: var(--m-3);
      margin-bottom: var(--m-3);
      li {
        font-weight: bold;
        margin-bottom: var(--m-1);
        font-size: var(--font-size-sm);
      }
    }
  }
}
