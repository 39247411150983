.ra-invoice-details {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .comments-container {
    flex-grow: 1;
    height: calc(100% - 100px);
  }
  .history-container {
    flex-grow: 1;
    height: calc(100% - 100px);
  }

  .ra-payments-document-container {
    padding-top: var(--p-5);
  }
}

.ra-payments-container {
  .payment-entry {
    padding: var(--p-5);
    border-bottom: solid 1px var(--border-color-primary);
    .main-row {
      display: flex;
      font-weight: bold;
      .pay-state {
        flex-grow: 1;
      }
      .amount {
        font-size: var(--font-size-md);
      }
    }
    .field {
      display: flex;
      .field-label {
        flex-grow: 1;
        color: var(--text-color-primary);
      }
      .field-value {
        padding-left: var(--p-5);
        text-align: right;
      }
    }
  }
}
