.avatar {
  text-transform: uppercase;
  &.size-flex {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    padding-top: 100%;
    font-size: 100%;
    position: relative;
    .generated {
      padding-bottom: 1px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  &.size-xs {
    min-width: 16px;
    max-width: 16px;
    width: 16px;
    height: 16px;
    max-height: 16px;
    min-height: 16px;
    font-size: var(--font-size-xs);
    .generated {
      // padding-bottom: 1px;
    }
  }
  &.size-sm {
    max-width: 24px;
    max-height: 24px;
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
    font-size: var(--font-size-md);
    .generated {
      // padding-bottom: var(--p-0);
      // padding-top: 1px;
    }
  }
  &.size-md {
    max-width: 32px;
    max-height: 32px;
    min-width: 32px;
    min-height: 32px;
    width: 32px;
    height: 32px;
    font-size: var(--font-size-xl);
    .generated {
      // padding-bottom: var(--p-1);
    }
  }
  &.size-lg {
    width: 64px;
    height: 64px;
    max-width: 64px;
    max-height: 64px;
    min-width: 64px;
    min-height: 64px;
    font-size: 30px;
    .generated {
      // padding-bottom: var(--p-1);
    }
  }
  &.size-xl {
    width: 128px;
    height: 128px;
    max-width: 128px;
    max-height: 128px;
    min-width: 128px;
    min-height: 128px;
    font-size: 80px;

    .generated {
      // padding-bottom: var(--p-1);
    }
  }

  .generated {
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius-rounded);
    color: var(--text-color-contrast);
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
  }
  .image {
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius-rounded);
    background-position: 50% 50%;
    background-size: cover;
  }
}
