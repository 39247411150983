.ez-tags {
  display: flex;
  border-radius: var(--border-radius-base);
  .tag-icon {
    padding-right: var(--p-3);
    padding-top: var(--p-6);
    padding-left: var(--p-4);
  }

  .rs-plaintext {
    padding-top: var(--p-3);
    padding-left: var(--p-3);
    flex-grow: 1;
  }
  .rs-picker {
    flex-grow: 1;
    background: transparent;
    border: none;
    .rs-picker-toggle {
      z-index: 0;
      padding-right: var(--p-4);
      background: transparent !important;
      .rs-picker-toggle-caret {
        display: none;
      }
    }
    .rs-picker-tag-wrapper {
      margin-right: var(--m-4);
      z-index: 5;
    }

    &.rs-picker-focused {
      box-shadow: none !important;
      border: none !important;
    }
    .rs-picker-textbox {
      z-index: 0;
    }
  }

  &:not(.readonly) {
    &:hover {
      background: var(--ez-hover-color);
    }
    &:focus-within,
    &:focus-visible,
    &:focus {
      box-shadow: 0px 0px 1px 1px var(--base-color);
    }
    transition: box-shadow 0.2s, background-color 0.2s;
  }
}

// .ez-tags-menu {
//   min-width: initial !important;
// }
