.ez-text-tag {
  background: var(--background-color-primary) !important;
  display: inline-flex;
  padding: 8px 12px;
  border-radius: var(--border-radius-base);
  transition: background-color 0.2s;
  border: solid 1px transparent;

  .editable-text {
    outline: none !important;
  }
  .prefix-icon {
    padding-right: var(--p-3);
    display: flex;
    align-items: center;
    justify-content: center;
    .bf-icon {
    }
  }
  &.size-xs {
    padding: 3px 8px;
    .prefix-icon {
      .bf-icon {
        width: 15px;
        height: 15px;
      }
    }
  }

  &.editable {
    &:hover {
      background: var(--ez-hover-color) !important;
    }
    &:focus-within {
      background: var(--background-color-contrast) !important;
      border: solid 1px var(--base-color);
    }
  }
}
