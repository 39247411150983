.asset-assign {
  padding-bottom: var(--p-8);
  border-bottom: solid 1px var(--border-color-primary);
  .label {
    padding-bottom: var(--p-3);
  }
  .values {
    border-top: solid 1px var(--border-color-primary);
    padding: 5px 0px 15px 0px;
    overflow: auto;
    &.inset {
      box-shadow: var(--default-inset-shadow);
    }
    .value-entry {
      display: flex;

      .content {
        flex-grow: 1;
        padding: 5px 5px;
      }
      &:hover {
        background: var(--background-opacity-weak);
      }
      width: 100%;
      display: flex;
      border-bottom: solid 1px var(--border-color-primary);
      .action {
        .bf-button {
          .bf-icon {
            font-size: 1em;
            width: 18px;
            height: 18px;
          }
        }
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  .asset-assign-add-component {
    margin-top: var(--m-5);
    .action-header {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-bottom: var(--p-3);

      .right {
        position: absolute;
        top: 0;
        right: 0;
      }

      .left {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .content {
      .bf-table {
        .rt-table {
          .rt-tbody.scroll-box {
            .rt-tr-group {
              .element {
                &:hover {
                  background: var(--background-color-primary);
                }
                width: 100%;
                display: flex;
                border-bottom: solid 1px var(--border-color-primary);
                .element-content {
                  flex-grow: 1;
                  padding: 5px 10px;

                  &.selected {
                    opacity: 0.5;
                  }
                }
                .action {
                  .bf-button {
                    .bf-icon {
                      font-size: 1em;
                      width: 18px;
                      height: 18px;
                    }
                  }
                }
              }
            }
          }
          &:last-of-type {
            .element {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
  .error-message {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--p-5);
    &.error {
      color: var(--color-negative);
    }
  }
  &.error {
    border-bottom-color: var(--color-negative);
    .label {
      color: var(--color-negative);
    }
    .values {
      border-top-color: var(--color-negative);
    }
  }
}
