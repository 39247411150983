.ra-invoice-process-history {
  overflow: auto;

  .ra-process-history-entry {
    display: flex;
    align-items: flex-start;
    position: relative;
    padding-bottom: var(--p-10);

    .status-icon {
      .system-avatar {
        width: 100%;
        height: 100%;
        background: var(--background-color-primary);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &.declined {
      .content {
        .type {
          color: var(--color-negative);
        }

        .comment-container {
          background-color: var(--background-color-primary);
          border-color: var(--declined-comment-border);
          .comment-bubble {
            background-color: var(--background-color-primary);

            border-color: var(--declined-comment-border);
          }
          .comment {
            background-color: var(--background-color-primary);
            font-weight: 500;
          }
        }
      }
    }

    .status-icon {
      z-index: 1;
      margin-top: var(--m-5);
      width: 40px;
      height: 40px;
      border: solid 1px var(--border-color-secondary);
      border-radius: var(--border-radius-rounded);
      overflow: hidden;
      flex-shrink: 0;

      .icon-container {
        width: 100%;
        height: 100%;
        background: var(--background-color-primary);
        display: flex;
        justify-content: center;
        align-items: center;
        .bf-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--icon-color-secondary);
          width: 22px;
          height: 22px;
        }
      }
      &.next_approval {
        border: dashed 1px var(--border-color-secondary);
      }
      &.current_approval {
        border: 2px solid var(--border-color-secondary);
      }
      .current-icon {
        background: var(--background-color-primary);
        color: var(--icon-color-secondary-accent);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .bf-icon {
          width: 22px;
          height: 22px;
        }
      }
    }
    .line {
      z-index: 0;
      bottom: -10px;
      left: 20px;
      top: 40px;
      position: absolute;
      border-right: solid 1px var(--border-color-secondary);
    }
    &:last-of-type {
      .line {
        display: none;
      }
    }

    &.current_approval {
      .content {
        padding-top: var(--p-5);
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      padding-left: var(--p-5);
      .type {
        font-weight: 600;
      }
      .date {
        font-size: var(--font-size-sm);
        color: var(--text-color-secondary);
        padding-top: var(--p-1);
      }

      .comment-container {
        width: fit-content;
        border: solid 1px var(--border-color-secondary);
        border-radius: var(--border-radius-base);
        padding: var(--p-2);
        font-size: var(--font-size-base);
        position: relative;

        background: var(--background-color-contrast);
        .comment-bubble {
          position: absolute;
          top: 4px;
          left: -7px;
          width: 12px;
          height: 12px;
          background: var(--background-color-contrast);
          transform: skewX(45deg);
          // border-bottom-left-radius: 50%;
          z-index: 0;
          border-left: solid 1px var(--border-color-secondary);
          border-top: solid 1px var(--border-color-secondary);
        }
        .comment {
          z-index: 1;
          position: relative;
          background: var(--background-color-contrast);
        }
      }
    }

    &.current {
      .line {
        border-right-style: dashed;
      }
      .status-icon {
        border-style: dashed;
      }
      .content {
        padding-top: var(--p-10);
      }
      &.has-assignments {
        .content {
          padding-top: var(--p-5);
        }
      }
    }
  }
}
