.asset-images-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .bf-dropzone {
    width: 100%;
    height: 100%;
  }

  .asset-images {
    width: 100%;
    height: 100%;
    .gallery-container {
      width: 100%;
      height: 100%;
      position: relative;
      .gallery-actions {
        opacity: 0;
        position: absolute;
        transition: 0.3s ease-in-out;
        top: 0px;
        left: 0px;
        right: 0px;
        background: var(--background-opacity-weak);
        z-index: 2;
        padding: var(--p-2);
        display: flex;
        .bf-button {
          color: var(--text-color-contrast);
          border-color: var(--border-color-contrast);
        }
      }
      &:hover {
        .gallery-actions {
          opacity: 1;
        }
      }
    }
    .no-image-yet {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: var(--p-10);
      text-align: center;
      .no-image-yet-description {
        color: var(--text-color-secondary);
      }
    }
  }
  .swiper.bf-swiper-component .swiper-pagination {
    z-index: 1;
  }
}
