.configurable-dashboard {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  .dashboard-header-row {
    display: flex;
    gap: 10px;
  }
  .react-grid-layout {
    flex-grow: 1;
  }
  .grid-item {
    .invalid-config {
      height: 100%;
      padding: var(--p-4);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .bf-icon {
      }
      .description {
        padding-top: var(--p-4);
        text-align: center;
      }
    }
    .edit-overlay {
      z-index: 1;
      border-radius: var(--border-radius-base);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--background-opacity-strong);
      color: var(--text-color-contrast);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: move;
      flex-direction: column;
      .delete-button {
        position: absolute;
        right: 0;
        top: 0;
      }
      .move-text {
        padding-top: var(--p-5);
        font-size: var(--font-size-sm);
        text-align: center;
        max-width: 180px;
        padding-left: var(--p-10);
        padding-right: var(--p-10);
      }
      .resize-text {
        position: absolute;
        bottom: 7px;
        right: 7px;
        padding-left: var(--p-5);
        text-align: right;
        font-size: var(--font-size-sm);
      }
    }
    .react-resizable-handle {
      z-index: 2;
      color: var(--text-color-contrast);
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjkwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiNmZmYiLz48L2c+PC9zdmc+);
    }
  }
}
