.horizontal-loader {
  text-align: center;

  & > div {
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  &.size-xs {
    width: 42px;
    & > div {
      width: 5px;
      height: 5px;
    }
  }
  &.size-md {
    width: 52px;
    & > div {
      width: 8px;
      height: 8px;
    }
  }
  &.size-lg {
    width: 72px;
    & > div {
      width: 12px;
      height: 12px;
    }
  }
  &.size-xl {
    width: 100px;
    & > div {
      width: 19px;
      height: 19px;
    }
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
