@import "../../styles/vars";

$border-color: none;

.application-drawer-app-outer-container {
  height: 100%;
  width: 100%;
  .application-drawer-app-container {
    height: 100%;
    width: 100%;
    display: flex;
    // flex-wrap:wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    border-radius: var(--border-radius-big);
    overflow: hidden;
    background: var(--background-color-contrast);
    box-shadow: var(--shadow-contrast-inverse-6) 0px 0px 15px;
    // padding:50px;
    .header {
      color: var(--text-color-strong);
      border-bottom: solid 1px $border-color;
      display: flex;

      .title {
        padding: var(--p-5);
        flex-grow: 1;
        font-size: 1.2em;
      }
      .actions {
        display: flex;
        button {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .apps {
      flex: 1 0;
      overflow: hidden;
      min-height: 250px;
      position: relative;
      .empty-page {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--text-color-secondary);
      }
      .app-page {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
      }

      .swiper-container {
        position: initial;
      }
    }
    .search {
      // display:none;
      padding: var(--p-5);
      border-top: solid 1px $border-color;
      position: relative;
      color: var(--text-color-strong);
      .search-icon {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: var(--p-5);
        padding-right: var(--p-5);
      }
      input {
        width: 100%;
        background: transparent;
        color: var(--text-color-strong);
        border: none;
        outline: none;
        padding-left: var(--p-15);
        font-size: 1.2em;
      }
    }
  }
}

@keyframes application-drawer-fade-in {
  0% {
    z-index: -1;
    opacity: 0;
  }
  100% {
    z-index: 1000;
    opacity: 1;
  }
}

@keyframes application-drawer-fade-out {
  0% {
    z-index: 1000;
    opacity: 1;
  }
  99% {
    z-index: 1000;
    opacity: 0;
  }
  100% {
    z-index: -1000;
    opacity: 0;
  }
}
