.option {
  display: flex;
  width: 100%;
  min-width: 30px;
  align-items: center;
}

.name {
  flex-grow: 1;
}
