.contact-base-notes {
  .note-info {
    display: flex;
    padding-bottom: var(--p-3);
  }
  .note-content {
    background: var(--background-color-primary);
    padding: var(--p-5);
    border-radius: var(--border-radius-base);
  }
  .note-form {
    padding: var(--p-5);

    .note-input {
      textarea {
        min-height: 74px;
        max-height: 200px;
      }
    }
    .actions {
      padding-top: var(--p-10);
      padding-bottom: var(--p-10);
      display: flex;
      gap: var(--p-5);
      justify-content: flex-end;

      border-bottom: solid 1px var(--border-color-secondary);
    }
  }
}
