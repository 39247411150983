@import "./styles/vars.scss";
@import "./styles/css-variables.scss";
@import "./styles/dark-theme-css-variables.scss";
@import "./utils/CSSUtilsClasses.scss";
@import "./modules/ez-form/ez-styles.scss";
@import "./styles/rs-variables-overrides.scss";

// fix box-sizing, was done from rsuite before, now we need it here
*,
:after,
:before {
  box-sizing: border-box;
}

body {
  position: absolute;
  margin: var(--m-0);
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
  width: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div,
p,
h2,
h3,
h4,
h5,
span,
button,
img {
  user-select: none;
}

.text-selection {
  user-select: text;
}

.text-selection-all {
  user-select: text;
  * {
    user-select: text;
  }
}

.fill {
  flex-grow: 1;
}

.font-color-white {
  color: var(--text-color-contrast);
}

.page-change-enter {
  opacity: 0.6;
  z-index: 1;
}

.page-change-enter.page-change-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

// *::-webkit-scrollbar {
//   width: 5px;
//   height: 5px;
// }

// *::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px var(--shadow-contrast-inverse-3);
// }

// *::-webkit-scrollbar-thumb {
//   background-color: rgba(51, 51, 51, 0.5);
//   outline: 1px solid slategrey;

//   &:hover {
//     background-color: rgba(38, 38, 38, 0.7);
//     transform: scale(1.5);
//   }
// }

// .rounded {
//   &::-webkit-scrollbar-track {
//     border-top-right-radius: var(--border-radius-base);
//     border-bottom-right-radius: var(--border-radius-base);
//   }
//   &::-webkit-scrollbar-thumb {
//     border-top-right-radius: var(--border-radius-base);
//     border-bottom-right-radius: var(--border-radius-base);
//     outline: none;
//   }
// }

// .scrollbar-invert {
//   *::-webkit-scrollbar {
//     width: 5px;
//     height: 5px;
//   }

//   *::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 0 0 6px var(--shadow-contrast-inverse-3);
//   }

//   *::-webkit-scrollbar-thumb {
//     background-color: rgba(38, 38, 38, 0.5);
//     outline: 1px solid slategrey;

//     &:hover {
//       background-color: rgba(38, 38, 38, 0.7);
//     }
//   }
// }

// * {
//   &::-webkit-scrollbar {
//     width: 8px;
//     height: 8px;
//     background-color: rgba(0, 0, 0, 0);
//   }
//   &::-webkit-scrollbar-thumb {
//     background-color: rgba(0, 0, 0, 0);
//     border-radius: var(--border-radius-big);
//     transition: background-color 0.4s ease-in-out;
//   }
//   &::-webkit-scrollbar-track {
//     background: rgba(0, 0, 0, 0);
//     border-radius: var(--border-radius-big);
//   }
//   &:focus-within,
//   :hover {
//     &::-webkit-scrollbar-thumb {
//       background-color: rgba(0, 0, 0, 0.2);
//     }
//   }
// }

// .__scrollbar {
//   &::-webkit-scrollbar {
//     width: 8px;
//     height: 8px;
//     background-color: var(--shadow-contrast-inverse-1);
//   }
//   &::-webkit-scrollbar-thumb {
//     background-color: rgba(0, 0, 0, 0.2);
//     border-radius: var(--border-radius-big);
//   }
//   &::-webkit-scrollbar-track {
//     background: rgba(0, 0, 0, 0);
//     border-radius: var(--border-radius-big);
//   }
// }

.app-layout {
  background: var(--app-background);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .sidenav {
    height: 100%;
  }

  .app-layout-content {
    -webkit-overflow-scrolling: touch;

    flex-grow: 1;
    overflow: auto;
    height: 100%;
    position: relative;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px var(--shadow-contrast-inverse-3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--scroll-background-color);
      outline: 1px solid slategrey;
      &:hover {
        background-color: var(--scroll-background-hover);
      }
    }
  }
}

.page {
  padding: var(--p-10);
}

@keyframes scale-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  99% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.__center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.__error {
  color: var(--color-negative);
  font-size: var(--font-size-sm);
}
