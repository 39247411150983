.user-signature-component {
  .user-signature-component__header {
    padding-bottom: var(--p-5);
    display: flex;
    align-items: center;
    .bf-button {
      margin-left: var(--m-5);
    }
  }
  .user-signature-component__default-selection {
    display: flex;
    padding-top: var(--p-5);
    padding-bottom: var(--p-10);
    width: 700px;
  }
  .user-signature-component__content {
    .user-signature-component__content__empty {
      color: var(--text-color-secondary);
    }
    .signature-entry {
      padding: 0px 0px 20px 0;

      .signature-entry__header {
        display: flex;
        align-items: center;
        .signature-entry__header__title {
          padding-right: var(--p-5);
          flex-grow: 1;
          font-size: var(--font-size-md);
        }
        .signature-entry__header__actions {
          display: flex;
          align-items: center;
          .bf-button {
            margin-left: var(--m-5);
          }
        }
      }
      .signature-entry__content {
        border: 1px solid var(--border-color-primary);
        max-height: 200px;
        padding: var(--p-10);
        overflow: auto;
      }
    }
  }
  .add-action-container {
    padding: var(--p-5);
  }
}

.user-signature-form {
  .ck.ck-editor {
    overflow: hidden;
    .ck.ck-content {
      max-height: 400px;
      min-height: 160px;
    }
  }
}
