.comments-modal-wrapper {
  .rs-modal-content {
    padding-bottom: var(--p-0);
    .rs-modal-body {
      padding-bottom: var(--p-0);
    }
  }
  .comments-modal {
    height: 90vh;
    overflow: hidden;
  }
}
