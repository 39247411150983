.bf-tag-input {
  padding: var(--p-2);
  background: var(--background-color-contrast);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  border-radius: var(--border-radius-base);
  border: 1px solid var(--rs-border-primary);

  &:has(input:focus-visible) {
    outline: 3px solid var(--rs-color-focus-ring);
    border-color: var(--base-color);
  }

  &:hover {
    border-color: var(--base-color);
  }

  .tag-container {
    overflow: hidden;
    position: relative;
    .bf-tag-wrapper {
      .BFTag {
        border-radius: var(--border-radius-base);
        .text {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
  .input-container {
    position: relative;
    flex-grow: 1;

    input {
      max-width: 100%;
      min-width: 40px;
      border-radius: var(--border-radius-base);
      border: none;
      &:hover {
        border: none;
      }
      &:focus-visible {
        border: none;
        outline: none;
      }
    }
    .bf-tag-overlay-wrapper {
      position: absolute;

      top: 100%;
      width: var(--search-width-expanded);
      z-index: 6;
      background: var(--background-color-contrast);
      .bf-tag-overlay {
        background: var(--background-color-contrast);
        max-height: 400px;
        min-height: 100px;
        overflow: auto;
        box-shadow: var(--card-default-box-shadow);
        border-bottom-left-radius: var(--border-radius-base);
        border-bottom-right-radius: var(--border-radius-base);
      }
    }
  }
}
