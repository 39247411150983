@import "../../../../styles/vars.scss";

.input-hint {
  text-align: right;
  font-size: var(--font-size-xs);
  color: var(--text-color-secondary);
}
.bf-input {
  transition: box-shadow 0.2s;
  display: flex;
  align-items: center;
  position: relative;
  &.error {
    border-color: var(--color-negative);
  }
  .suffix,
  .prefix {
    width: initial;
    display: block;
  }
  input {
    flex-grow: 1;

    &.text-align-left {
      text-align: left;
    }
    &.text-align-center {
      text-align: center;
    }
    &.text-align-right {
      text-align: right;
    }

    &::placeholder {
      color: var(--text-color-placeholder);
    }
  }

  .rs-input-group-addon {
    padding: 9px 12px;
  }

  &.remove-prefix-padding {
    .prefix {
      padding: var(--p-0);
      background: transparent;
    }
  }

  &.remove-suffix-padding {
    .suffix {
      padding: var(--p-0);
      background: transparent;
    }
  }

  &.rs-input-group {
    background: var(--background-color-contrast);
    // overflow: hidden;
    .rs-input-group-addon {
      .rs-icon {
        font-size: initial;
      }
    }
  }
  .rs-input-group {
    background: var(--background-color-contrast);
    // overflow: hidden;
  }

  &.rs-input-group-focus {
    box-shadow: var(--base-color) 0px 0px 5px;
  }
  .rs-input-group.rs-input-number {
    display: flex;
  }
  input {
    border-radius: var(--border-radius-none);
  }

  .rs-input-number-btn-group-vertical {
    .rs-input-number-touchspin-up,
    .rs-input-number-touchspin-down {
      height: 16px;
    }
  }

  &.number.rs-input-group {
    //border:none;
    box-sizing: border-box;
    overflow: visible;
    .rs-input-number.rs-input-group {
      display: flex;
      box-sizing: border-box;
      //border:1px solid var(--border-color-contrast);

      &.rs-input-group-focus {
        border: solid 1px;
        box-shadow: var(--base-color) 0px 0px 5px;
      }
    }
  }

  &.appearance-clear {
    box-shadow: none;
    border: none;
    background: transparent;
    .rs-input-group-addon.prefix {
      background: transparent;
    }
    .rs-input-group-addon.suffix {
      background: transparent;
    }
    input {
      background: transparent;
    }
    &.rs-input-group-focus {
      box-shadow: none;
      background: transparent;
    }
  }
}
