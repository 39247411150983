.standard-page {
  padding: var(--p-10);
  width: 100%;
  height: 100%;
  &.no-padding {
    padding: var(--p-0);
  }
  &.can-overflow {
    min-height: 100%;
    height: fit-content;
  }
  &.default-background {
    background: var(--app-background);
  }
}
