.rs-preview-object {
  width: 100%;
  .rs-asset-details {
    .object-container {
      display: flex;
      flex-direction: column;
      .object-row {
        display: flex;
        flex-direction: row;
        justify-content: stretch;
      }

      .type-info {
        margin-top: var(--m-5);
      }
    }
  }
}
