.overlay-date-selection {
  max-height: 500px;
  width: 320px;
  overflow: visible;
  background: var(--background-color-contrast);
  z-index: 6;
  display: flex;
  flex-direction: column;
  .search {
    .bf-input {
      border: none !important;
      outline: none !important;
    }
  }

  .content {
    padding: var(--p-5);
    flex-grow: 1;
    overflow: visible;
    border-top: solid 1px var(--border-color-secondary);
    .range {
      .from {
        padding-bottom: var(--p-5);
      }
      .quick-actions {
        padding-top: var(--p-5);
        margin-top: var(--m-8);
        border-top: 1px var(--border-color-primary) solid;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .row {
          display: flex;
          width: 100%;
          justify-content: space-evenly;
        }
      }
    }
    // .range {
    //   display: flex;
    //   .from,
    //   .to {
    //     flex: 1 0 50%;
    //   }
    //   .from {
    //     margin-right: var(--m-3);
    //   }
    //   .to {
    //     margin-left: var(--m-3);
    //   }
    // }
  }
  .footer {
    padding: var(--p-2);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
