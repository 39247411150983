.ra-invoice-details-component {
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;
  &.split-view {
    .invoice-details {
      max-width: 700px;
    }
  }

  .invoice-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    .nav-container {
      display: flex;
      .rs-nav {
        overflow: auto;
        flex-grow: 1;
      }
      .action {
        border-bottom: 2px solid var(--rs-navs-subtle-border);
        .bf-dropdown {
          button {
            background: transparent;
          }
        }
      }
    }
    .content {
      flex-grow: 1;
      overflow: auto;
      padding-top: var(--p-10);

      .id-label {
        padding-left: var(--p-5);
        padding-bottom: var(--p-5);
        color: var(--text-color-secondary);
        font-size: var(--font-size-sm);
      }
      .comments-container {
        max-height: 100%;
        height: 100%;
        padding-right: var(--p-5);
      }
      .ra-payments-container {
        height: 100%;
      }
    }
    .history-entries {
      padding-right: var(--p-5);
    }
  }
  &.__card {
    .invoice-details {
      .content {
        padding: 20px 10px 10px 10px;
      }
    }
  }
  .ra-update-invoice-entry {
    padding-right: var(--p-8);
  }

  .invoice-pdf {
    flex-grow: 1;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    .inset-shadow {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: var(--default-inset-shadow);
      pointer-events: none;
    }
  }
}
