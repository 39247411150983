.ez-textfield {
  &.error {
    textarea {
      background: var(--background-color-primary);
    }
  }
  .autosize-textarea {
    textarea.textarea {
      border: none;
      transition: background-color 0.2s;

      &.readonly {
        &:focus-visible {
          outline: none;
        }
      }

      &:not(.readonly) {
        &:hover {
          background: var(--ez-hover-color);
        }
        &:focus {
          background: transparent;
        }
      }
    }
  }

  &.type-default {
    .autosize-textarea {
      .label {
        padding-bottom: 1px;
        padding-left: var(--p-3);
        color: var(--text-color-secondary);
        font-size: var(--font-size-sm);
        letter-spacing: 1px;
      }
    }
  }
  &.type-header {
    .autosize-textarea {
      .label {
        padding-bottom: 1px;
        padding-left: var(--p-3);
        color: var(--text-color-secondary);
        font-size: var(--font-size-sm);
        letter-spacing: 1px;
      }
      textarea.textarea {
        font-size: var(--font-size-2xl);
      }
    }
  }
  &.type-subHeader {
    .autosize-textarea {
      .label {
        padding-bottom: 1px;
        padding-left: var(--p-3);
        color: var(--text-color-secondary);
        font-size: var(--font-size-sm);
        letter-spacing: 1px;
      }
      textarea.textarea {
        font-size: var(--font-size-md);
      }
    }
  }
  &.type-description {
    .autosize-textarea {
      textarea.textarea {
        color: var(--text-color-secondary);
        font-size: var(--font-size-sm);
      }
    }
  }

  &.appearance-bf {
    .autosize-textarea {
      textarea.textarea {
        border: 1px solid var(--rs-border-primary);
      }
    }
  }
}
