.ap-budget-invoice-manage-positions {
  .form-wrapper {
    display: flex;

    .pdf {
      flex: 2;
      height: 80vh;
      border: solid 1px var(--border-color-secondary);
    }
    .form {
      flex: 1;
      height: 80vh;
      overflow: auto;
      display: flex;
      flex-direction: column;
      padding-left: var(--p-10);
      gap: 20px;

      .field-row {
        border-bottom: solid 1px var(--border-color-primary);
        margin-bottom: var(--m-5);
        padding-bottom: var(--p-5);

        &:last-of-type {
          border-bottom: none;
          margin-bottom: var(--m-0);
          padding-bottom: var(--p-0);
        }

        .name {
          padding-bottom: var(--p-1);
        }
        .values {
          display: flex;
          gap: 10px;
          .value {
            flex: 1;
          }
          .comment {
            flex: 2;
          }
        }
      }
      .summary {
        .assigned-amount,
        .open-amount {
          padding-bottom: var(--p-5);
          text-align: center;
          .label {
            color: var(--text-color-secondary);
          }
          .value {
            font-size: var(--font-size-md);
          }
          &.negative {
            .value {
              color: var(--color-negative);
            }
          }
        }
      }
    }
  }
}
