@import "../../../styles/vars";

.field-view-table {
  // border:solid 1px var(--border-color-primary);
  .row-entry {
    padding: 10px 5px 10px 5px;
    border-bottom: solid 1px var(--divider-color);
    // background:var(--form-field-view-table-row-background);
    display: flex;
    flex-direction: row;
    width: 100%;
    &:last-of-type {
      border-bottom: none;
    }

    &.subtitle {
      padding: 10px 10px 10px 0px;
      // background: var(--form-field-view-table-subtitle-background);
      // color: var(--form-field-view-table-subtitle-color);
      font-weight: 600;
      font-size: 1.2em;
    }
  }
  .field-label {
    width: 40%;
    max-width: 275px;
    font-weight: 600;
    text-overflow: ellipsis;
    word-break: break-word;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .field-value {
    flex-grow: 1;
    text-overflow: ellipsis;
    word-break: break-word;
    // overflow: hidden;
  }
}
