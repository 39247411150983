.rs-summary-card {
  padding: 5px 5px 10px 5px;
  .rs-summary-card-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;

    .rs-summary-card-content {
      min-width: 0;
      width: 100%;
      flex: 1 0;
      padding: 3px 8px 3px 3px;
      margin-left: var(--m-1);
      text-align: left;
      .first-row {
        display: flex;
        font-weight: bold;
      }
      .main-row {
        width: 100%;
        font-size: var(--font-size-sm);
        margin-left: var(--m-3);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .sub-row {
        font-size: var(--font-size-sm);
        display: flex;
        align-items: center;
        .asset-detail-container {
          margin-left: var(--m-2);
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .assignment-label {
          margin-left: auto;
        }
      }
    }

    .icon-button-column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: var(--p-3);
      width: 40px;
      align-items: center;

      .rs-app-icon {
        height: 30px;
        width: 30px;
        border-radius: var(--border-radius-large);
        background: var(--background-color-secondary);
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover;
      }
      .dashboard-rs-preview-btn {
        flex-shrink: 0;
        margin-top: auto;
        &.bf-button {
          padding: 1px;
        }
      }
    }
  }
}
