.contact-select {
  width: 100%;
  .asset-loader.error {
    padding: var(--p-0);
  }
}
.contact-select-menu {
  width: 400px;
  .action-container {
    display: flex;
    justify-content: center;
    padding: var(--p-3);
    border-top: solid 1px var(--border-color-primary);
  }
  .rs-picker-select-menu {
  }
  .rs-picker-select-menu-item {
    padding: 0px 10px;
    height: 100%;
    .contact-menu-item {
      display: flex;

      .left {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;

        white-space: nowrap;
        text-overflow: ellipsis;
        .name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
        .info-line {
          font-size: var(--font-size-sm);
          color: var(--text-color-secondary);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
      .right {
        opacity: 0;
      }
      &:hover {
        .right {
          opacity: 1;
        }
      }
    }
  }

  .contact-load-mask {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    background: var(--background-opacity-primary);
    color: var(--text-color-contrast);
    z-index: 5;
  }
}
