.object-form {
  .layout {
    display: flex;
    gap: 20px;

    .label-until {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .field-row {
      display: flex;
      gap: 10px;
      // padding-bottom: var(--p-5);
    }
    .field {
      padding-bottom: var(--p-5);
    }
    .common-form {
      flex: 1 0;
    }
    .bank-actions {
      display: flex;
      justify-content: center;
      gap: 10px;
    }
    .feature-forms {
      flex: 2 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
