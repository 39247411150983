.ra-relations-view {
  .ez-asset-select-multiple {
    border: none;
  }
  .no-relations {
    width: 100%;
    font-size: var(--font-size-sm);
    text-align: center;
    color: var(--text-color-secondary);
    // padding: var(--p-5);
  }
}
