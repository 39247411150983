.bf-tag-input-search {
  .bf-tag-input {
    .bf-tag-overlay-wrapper {
      .bf-tag-overlay {
        .bf-list {
          min-width: 250px;
          min-height: 100px;
          background: var(--list-row-background);
        }
      }
    }
  }
}
