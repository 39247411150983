.risk-scenario-view {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    padding-bottom: var(--p-10);
  }
  .content {
    flex: 1;
    overflow: hidden;
  }
}
