.ap-supply-unit-meter-readings {
  .no-entry-yet {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: var(--p-5);
    .text {
      text-align: center;
      font-size: var(--font-size-sm);
      color: var(--text-color-secondary);
    }
  }
  .reading-header {
    display: flex;
    margin-bottom: var(--m-5);
    justify-content: center;
    .reading-title {
      font-size: var(--font-size-base);
      font-weight: 500;
      color: var(--text-color-secondary);
      text-align: center;
    }
    .action {
      margin-left: var(--m-3);
    }
  }
  .meter-reading {
    display: flex;
    justify-content: center;
    .data {
      display: inline-flex;
      align-items: flex-end;
      justify-content: center;
      position: relative;
      .reading-value {
        font-size: var(--font-size-base);
      }
      .reading-date {
        font-size: var(--font-size-sm);
        color: var(--text-color-secondary);
        padding-left: var(--p-5);
      }

      .action {
        position: absolute;
        left: 100%;
        opacity: 0;
        padding-left: var(--p-5);
      }
      &:hover {
        .action {
          opacity: 1;
        }
      }
    }
  }
}
