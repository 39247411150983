.bf-icon-details {
  padding: var(--p-5);
  .icon-container {
    padding: var(--p-5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .name {
    text-align: center;
    font-size: var(--font-size-md);
  }

  .category {
    margin-top: var(--m-5);
    button.bf-button {
      word-wrap: break-word;
      max-width: 100%;
      overflow: hidden;
      white-space: normal;
      text-align: left;
      justify-content: flex-start;
    }
  }
  .tags {
    margin-top: var(--m-5);
    .tag-list {
      padding-top: var(--p-3);
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}
