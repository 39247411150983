.cashbudget-detail-entry-card-content {
  display: flex;
  flex-direction: column;
  .planned-by {
    display: flex;
    justify-content: center;
    padding-top: var(--p-3);
    color: var(--text-color-secondary);
  }
  .head {
    display: flex;

    .date {
      color: var(--text-color-secondary);
      padding: var(--p-3);
    }
    .entity-object-ident {
      color: var(--text-color-disabled);
      padding: var(--p-3);
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      overflow: hidden;
      .entity {
        font-weight: bold;
        text-overflow: ellipsis;
      }
      .object {
        color: var(--text-color-disabled);
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
        text-align: right;
      }
    }
  }
  .show-assetlink {
    padding-bottom: var(--p-5);
  }
  .data-row-0 {
    padding-left: var(--p-3);
    padding-right: var(--p-3);
    padding-bottom: var(--p-3);
    display: flex;
    .category {
      font-size: var(--font-size-sm);

      &.with-info {
        color: var(--base-color);
      }
    }
    .entries {
      .entry-row {
        display: flex;
        .unit {
          font-size: var(--font-size-sm);
          color: var(--text-color-secondary);
          padding-right: var(--p-3);
        }
        .entity {
          font-size: var(--font-size-sm);
        }
      }
      .object {
        font-size: var(--font-size-sm);
        text-align: right;
      }
    }
  }
  .data-row-1 {
    padding: var(--p-3);
    display: flex;
    justify-content: center;
    max-width: 100%;
    overflow: hidden;
    .date {
      padding-right: var(--p-3);
    }
    .infos {
      color: var(--text-color-secondary);
      overflow: hidden;
      .recipe,
      .recipe-text {
        overflow: hidden;
        text-overflow: ellipsis;
        // display: flex;
        // justify-content: flex-start;
        // align-items: center;
      }
    }
    .amount {
      font-weight: bold;
      font-size: 1.25em;
      padding-left: var(--p-3);
      &.negative {
        color: var(--color-negative);
      }
    }
  }
  .transferReason {
    display: flex;
    flex-direction: column;
    .title {
      padding-bottom: var(--p-3);
    }
    .content {
      position: relative;
      border-color: var(--border-color-primary);
      resize: vertical;
      width: 100%;
      color: var(--text-color-secondary);
      &:focus {
        outline: none;
      }
      span {
        max-width: 100%;
        white-space: break-spaces;
        word-break: break-word;
      }
    }
    .toggle-lines {
      display: flex;
      justify-content: flex-end;
    }
  }
  .additional-infos {
    display: none;
    font-size: 0.7em;
    color: var(--text-color-secondary);
  }
  .comments {
    padding-top: var(--p-3);
    padding-left: var(--p-3);
    padding-right: var(--p-3);
    padding-bottom: var(--p-3);
    border-top: solid 1px var(--border-color-secondary);
    .comments-header {
      font-size: 1.1em;
      margin-bottom: var(--m-3);
      text-transform: uppercase;
      color: var(--text-color-secondary);
    }
    .comment-entry {
      padding: var(--p-3);
      display: flex;
      border-bottom: solid 1px var(--border-color-primary);
      cursor: pointer;
      &:hover {
        background: var(--background-color-primary);
      }
      &:last-of-type {
        border-bottom: none;
      }
      .comment-date {
        font-size: 0.8em;
        color: var(--text-color-secondary);
        margin-right: var(--m-3);
        min-width: 90px;
        flex-shrink: 0;
      }
      .comment-text {
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: var(--p-3);
      }
      .comment-user {
        color: var(--text-color-secondary);
        min-width: 100px;
        font-size: 0.8em;
        flex-shrink: 0;
      }

      &.collapsed {
        .comment-text {
          word-wrap: break-all;
          white-space: normal;
        }
      }
    }
  }
  .bank-account {
    padding: var(--p-3);
    .main {
      display: flex;
      .bank-account-id {
        font-weight: bold;
        padding-right: var(--p-3);
      }
      .bank-account-name {
      }
    }
    .sub {
      .bank-account-extra {
        font-size: var(--font-size-sm);
        color: var(--text-color-secondary);
      }
    }
    .objects {
      font-size: var(--font-size-sm);
      color: var(--text-color-secondary);
    }
  }
  &.simple {
    .data-row-1 {
      font-weight: bold;
      .amount {
        font-size: var(--font-size-md);
      }
    }
    .bank-account {
      display: flex;
      align-items: center;
      padding-top: var(--p-0);
      .main {
        font-size: var(--font-size-sm);
      }
      .sub {
        padding-left: var(--p-5);
      }
    }
    .transferReason {
      padding-bottom: var(--p-3);
    }
  }
}

.cb-budget-entry-history-overlay {
  padding: var(--p-3);

  .label {
    padding-bottom: var(--p-3);
  }

  .date {
    font-size: var(--font-size-sm);
    color: var(--text-color-secondary);
  }
}

.cashbudget-entry-history-overlay {
  z-index: 2010 !important;

  .rule-deleted {
    color: var(--color-warning);
    font-size: var(--font-size-sm);
    font-style: italic;
  }
}
