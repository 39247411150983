.user-replacement-entry {
  .status {
    margin-bottom: var(--m-5);
  }
  .timeframe {
    display: flex;
    align-items: center;
    font-size: var(--font-size-lg);
    gap: 6px;
  }
  .replacement-for,
  .replacement-from {
    display: flex;
    align-items: center;
    font-size: var(--font-size-md);
    gap: 10px;
  }
}
