@import "../../../../styles/vars";

.bf-table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: solid 1px var(--header-background);
  position: relative;

  .show-new-data-warning-wrapper {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    z-index: 4;
    pointer-events: none;

    .show-new-data-warning {
      position: relative;
      max-width: 500px;
      background: var(--background-color-deep);
      color: var(--text-color-contrast);
      display: flex;
      padding: 20px 30px;
      align-items: center;
      border-radius: var(--border-radius-base);
      pointer-events: all;
      button {
        pointer-events: all;
      }
      .text {
        padding-right: var(--p-8);
      }
      button.bf-button {
        flex: 1 0 100px;
      }
      .close-button {
        position: absolute;
        top: 3px;
        right: 3px;
        padding: 1px;
        .bf-icon {
          width: 11px;
          height: 11px;
          font-size: 0;
        }
      }
    }
  }
  .rt-table {
    overflow-x: hidden;
    overflow-y: hidden;
    .rt-thead {
      max-width: 100%;
    }
    .rt-tbody {
      max-width: 100%;
      overflow-x: auto;
      overflow-y: auto;
      min-width: initial !important;
      .rt-tr-group {
        width: fit-content;
        min-width: 100%;
      }
    }
  }
  .ReactTable.table.hide-column-headers {
    &.ignore-selection {
      .rt-tbody {
        .rt-tr-group {
          .rt-tr:hover {
            background: transparent !important;
          }
          wawad .rt-tr.selected:hover {
            background: transparent !important;
          }
        }
      }
    }

    .rt-table {
      .rt-thead.-header {
        display: none;
      }
    }
    .table-loader {
      top: 0px;
    }
  }

  .rt-tbody {
    .rt-tr-group {
      .rt-tr {
        .id-ref-link {
          margin-left: var(--m-2);
          opacity: 0;
          transition: opacity 0.2s;
          &.show-always {
            opacity: 1;
          }
        }

        &:hover {
          .id-ref-link {
            opacity: 1;
          }
        }
      }
    }
  }
  .rt-noData {
    background: transparent;
  }
  .rs-divider.rs-divider-vertical {
    display: none;
  }
  .rs-table-pagination-page-info {
    padding-left: var(--p-3);
  }
  .rt-tbody {
    -webkit-overflow-scrolling: touch;
  }
  &.inset-shadow {
    .rt-table {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        box-shadow: var(--default-inset-shadow);
        pointer-events: none;
      }
    }
  }
  &.card {
    background: var(--background-color-contrast);
    border: none;
    box-shadow: var(--shadow-contrast-inverse-5) 0px 4px 6px;
    .table-component-header {
      background: var(--table-header-background);
      z-index: 2;
      border-bottom: none;
      box-shadow: 0 3px 4px -2px grey;

      .title {
        color: var(--text-color-primary);
        padding: var(--p-5);
        font-size: 1.4em;
        flex-grow: 1;
      }
    }
    .table {
      .rt-thead.-header {
        background: var(--background-color-primary);
        font-size: 1em;
        padding-top: var(--p-3);
        padding-bottom: var(--p-3);
        font-weight: 500;
        // background: transparent;
        z-index: 1;
        box-shadow: 0 3px 4px -2px grey;
      }
    }

    .rs-table-pagination-toolbar {
      background: var(--background-color-contrast);
      box-shadow: var(--shadow-contrast-inverse-5) 0px -1px 4px;
      z-index: 2;
      .rs-pagination-btn > a {
        color: var(--text-color-primary);
        font-weight: 100;
      }

      .rs-pagination-btn.rs-pagination-btn-active > a {
        color: var(--text-color-strong);
        font-weight: bold;
      }
    }
  }

  &.clear {
    border: none;
    .table {
      background: transparent;
      border: none;
      .rt-thead.-header {
        background: transparent;
        box-shadow: var(--color-neutral) 0px 0px 10px;
      }
      .rt-tbody {
        padding-top: var(--p-4);
        .rt-tr-group {
          border-bottom: none;
        }
      }
    }

    .table-component-header {
      background: transparent;
      border-bottom: none;
      .title {
        color: var(--text-color-strong); //
      }
      button.rs-btn.bf-button.clear {
        color: var(--text-color-strong);
        &:hover {
          background: var(--background-color-primary);
        }
      }
    }

    .rs-table-pagination-toolbar {
      border-top: 1px solid var(--border-color-primary);
      background: transparent;
      .rs-pagination-btn > a {
        color: var(--text-color-primary);
      }

      .rs-pagination-btn.rs-pagination-btn-active > a {
        color: var(--text-color-strong);
        font-weight: bold;
      }
    }
  }

  &.clear-white {
    border: solid 1px var(--border-color-primary);
    .table {
      .rt-thead.-header {
        background: transparent;
        box-shadow: var(--color-neutral) 0px 0px 10px;
      }
    }

    .table-component-header {
      background: transparent;
      border-bottom: none;
      .title {
        color: var(--text-color-strong); //
      }
      button.rs-btn.bf-button.clear {
        color: var(--text-color-strong);
        &:hover {
          background: var(--background-color-primary);
        }
      }
    }

    .rs-table-pagination-toolbar {
      border-top: 1px solid var(--border-color-primary);
      background: transparent;
      .rs-pagination-btn > a {
        color: var(--text-color-primary);
      }

      .rs-pagination-btn.rs-pagination-btn-active > a {
        color: var(--text-color-strong);
        font-weight: bold;
      }
    }
  }

  .table {
    border: none;
    background: var(--background-color-contrast);
    overflow-x: auto;
    overflow-y: auto;

    .rt-thead.-header {
      background: var(--background-color-secondary);
      box-shadow: var(--color-neutral) 0px 0px 10px;

      font-weight: bold;

      & > div {
        text-align: left;
      }

      .rt-tr {
        .rt-th.-sort-desc,
        .rt-th.-sort-asc {
          box-shadow: none;
        }

        .rt-th {
          .column-header {
            display: flex;
          }
        }
      }
    }

    .rt-tbody,
    .rt-tfoot {
      .rt-tr-group,
      .rt-tr {
        flex-grow: initial;

        .rt-td {
          padding: var(--p-0);
          align-items: center;
          //justify-content: center;
          display: flex;
          overflow-x: hidden;
          overflow-y: hidden;

          &.align-right {
            justify-content: flex-end;
          }

          &.align-center {
            justify-content: center;
          }

          .show-when-hover,
          .show-when-selected {
            opacity: 0;
            transition: opacity 0.2s;
          }

          .data-column {
            max-width: 100%;
            display: flex;
            align-items: center;
            min-height: 36px;
            padding: 5px 7px;
            word-break: break-word;
            white-space: normal;
          }
          .action-column {
            height: 100%;
            button {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .rt-tr {
          transition: background 0.2s;

          &.deleted {
            color: var(--table-row-deleted-color);
            text-decoration: line-through;
            cursor: not-allowed;
          }
          &.selected {
            background: var(--table-row-color-selected);
            color: var(--table-row-color-selected-color);

            .show-when-selected {
              opacity: 1;
            }
          }

          &.-flagged-deleted {
            .rt-td {
              .data-column {
                color: var(--text-color-disabled);
              }
            }
          }
          &.-flagged-added {
            .rt-td {
              .data-column {
                color: var(--color-positive);
              }
            }
          }
        }
      }

      .select-input-checkbox {
        display: flex;
        justify-items: center;
        align-items: center;

        .rs-checkbox-checker {
          padding: var(--p-0);
          height: 16px;
          width: 16px;
          min-height: 16px;

          label {
            .rs-checkbox-wrapper {
              top: 0;
            }
          }
        }

        &.rs-checkbox-checked {
          .rs-checkbox-checker {
            label {
              .rs-checkbox-wrapper {
                .rs-checkbox-inner {
                  &::before {
                    background-color: var(--background-color-contrast);
                    border-color: var(--border-color-contrast);
                  }

                  &::after {
                    border-color: var(--base-color);
                  }
                }
              }
            }
          }
        }
      }

      .select-input-radio {
        display: flex;
        justify-items: center;
        align-items: center;

        .rs-radio-checker {
          padding: var(--p-0);
          height: 16px;
          width: 16px;
          min-height: 16px;

          label {
            .rs-radio-wrapper {
              top: 0;

              &::before {
                padding: var(--p-0);
                border: 0;
              }

              &::after {
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
              }
            }
          }
        }

        &.rs-radio-checked {
          .rs-radio-checker {
            label {
              .rs-radio-wrapper {
                .rs-radio-inner {
                  &::before {
                    background-color: var(--background-color-contrast);
                    border-color: var(--border-color-contrast);
                  }

                  &::after {
                    background: var(--base-color);
                    border-color: var(--base-color);
                    color: var(--base-color);
                    transform: scale(1.6);
                  }
                }
              }
            }
          }
        }
      }
    }

    &.-highlight {
      .rt-tbody {
        .rt-tr-group {
          .rt-tr:hover {
            background: var(--table-row-color-hover);

            .show-when-hover {
              opacity: 1;
            }
          }

          .rt-tr.selected:hover {
            background: var(--table-row-color-selected-hover);
          }
        }
      }
    }
  }

  .table-component-header {
    // height: var(--table-titlebar-height);
    background: var(--table-titlebar-background);
    display: flex;
    flex-direction: row;
    align-items: center;

    border-bottom: 1px var(--table-titlebar-background) solid;

    .title {
      padding-left: var(--p-5);
      padding-right: var(--p-5);
      display: flex;
      align-items: center;
      color: var(--table-titlebar-color);
    }
  }

  .rs-table-pagination-toolbar {
    background: var(--table-footer-background);
    padding: var(--p-3);

    .rs-pagination-btn > a {
      color: var(--text-color-disabled);
      // font-weight: bold;
    }
    .rs-pagination-btn.rs-pagination-btn-active > a {
      color: var(--text-color-disabled);
      // font-weight: bold;
    }

    .rs-pagination-btn.rs-pagination-btn-active > a {
      color: var(--table-footer-color);
    }
  }
}
