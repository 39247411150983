.array-form-field {
  > .label {
    padding-bottom: var(--p-5);
    font-size: var(--font-size-lg);
    font-weight: 500;
  }
  .array-form-field-add {
    display: flex;
    justify-content: center;
  }
  .validation {
    &.error {
      color: var(--color-negative);
    }
  }
}
