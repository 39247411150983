.object-form-template-selection {
  .description {
    margin-bottom: var(--m-5);
    color: var(--text-color-secondary);
  }
  table {
    width: 100%;
    margin-top: var(--m-10);
    tr {
      border-bottom: solid 1px var(--border-color-primary);
      th {
        font-size: var(--font-size-sm);
        color: var(--text-color-secondary);
        font-weight: normal;
        text-align: left;
        vertical-align: bottom;
        padding-right: var(--p-5);
        &:last-of-type {
          padding-right: var(--p-0);
        }
        &.center {
          text-align: center;
        }
      }
      td {
        padding-right: var(--p-5);
        padding-top: var(--p-3);
        padding-bottom: var(--p-3);
        &:last-of-type {
          padding-right: var(--p-0);
        }
        &.center {
          text-align: center;
        }
      }
    }
  }
}
