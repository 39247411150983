.searchfield {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1px;
  padding-left: var(--p-2);
  padding-right: var(--p-2);
  position: relative;
  min-height: 30px;

  &.animate-left {
    .input-wrapper {
      right: 0;
      left: initial;
    }
  }
  &.animate-right {
    .input-wrapper {
      left: 0;
      right: initial;
    }
  }
  &.animate-expand {
    .input-wrapper {
      left: 0;
      right: 0;
    }
  }
  .input-wrapper {
    overflow: hidden;
    transition: right 0.3s, left 0.3s, width 0.3s, box-shadow 0.3s;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: var(--m-2);
    display: flex;
    flex-direction: row;
    box-shadow: none;
    z-index: 2;
    .search-icon {
      pointer-events: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: var(--p-3);
      padding-right: var(--p-3);
    }

    input {
      flex-grow: 1;
      background: transparent;
      border: none;
      outline: unset;
      padding-left: var(--p-13);
      padding-right: var(--p-10);

      &::-webkit-input-placeholder {
        opacity: 0;
        transition: opacity 0.35s ease-in-out;
      }
      &:-moz-placeholder {
        opacity: 0;
        transition: opacity 0.35s ease-in-out;
      }
      &:-ms-input-placeholder {
        opacity: 0;
        transition: opacity 0.35s ease-in-out;
      }

      &:focus {
        &::-webkit-input-placeholder {
          opacity: 1;
        }
        &:-moz-placeholder {
          opacity: 1;
        }
        &:-ms-input-placeholder {
          opacity: 1;
        }
      }
    }

    .clear-action {
      position: absolute;
      width: 20px;
      top: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      transition: opacity 0.4s;
      pointer-events: none;
      &.active {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &.default {
    .input-wrapper {
      background: var(--table-titlebar-search-background);
      color: var(--table-titlebar-search-foreground);

      &.active {
        box-shadow: var(--color-neutral) 0px 0px 10px;
      }
    }
  }

  &.on-white {
    .input-wrapper {
      background: var(--background-color-contrast);
      color: var(
        --text-color-strong
      ); //var(--table-titlebar-search-background);
      border-bottom: solid 1px var(--border-color-secondary);
      transition: width 0.3s, box-shadow 0.3s, border 0.2s;
      &.active {
        // box-shadow: #aaa 0px 0px 5px;
        border-bottom: solid 1px var(--border-color-tertiary);
      }
    }
  }

  &.on-white-as-button {
    .input-wrapper {
      background-color: var(--background-color-contrast);
      color: var(
        --text-color-strong
      ); //var(--table-titlebar-search-background);
      border-bottom: solid 1px transparent;
      transition: background-color 0.3s, width 0.3s, box-shadow 0.3s,
        border 0.2s;

      &:hover {
        background-color: var(--background-opacity-tertiary-weak);
      }

      input {
        cursor: pointer;
      }
      &.active {
        &:hover {
          background-color: var(--background-color-contrast);
        }
        input {
          cursor: text;
        }
        // box-shadow: #aaa 0px 0px 5px;
        border-bottom: solid 1px var(--border-color-tertiary);
      }
    }
  }
}
