.cb-renter-distribution-card {
  .charts {
    display: flex;
    flex-wrap: wrap;
    .chart-entry {
      flex-grow: 5;
      min-width: 200px;
      display: flex;
      //   flex-direction: column;
      align-items: flex-end;
      justify-content: stretch;
      &.main {
        min-width: 250px;
        flex-grow: 6;
      }

      .chart-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;

        .chart-action {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
