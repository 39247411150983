.user-profile {
  padding: 0px 20px 20px 20px;
  height: 100%;
  overflow: auto;

  .user-profile__header {
    padding-bottom: var(--p-10);
  }
  .user-profile__content {
    display: flex;
    padding-bottom: var(--p-20);
    .user-avatar-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .user-form {
      padding-left: var(--p-5);
      min-width: 300px;
    }
  }
}
