.object-resubmission-view {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  .cb-resubmission-view-list-container {
    padding: var(--p-5);
    height: 100%;
    .cb-resubmission-view-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .cb-resubmission-view-details-container {
    margin-top: var(--m-8);
    .rs-documents-details {
      width: 100%;
    }
  }
}
