.ap-activity-invoices {
  height: 100%;
  display: flex;
  flex-direction: column;

  .invoice-entry-card {
    margin-top: var(--m-7);
    padding-top: var(--p-5);
    .action-row {
      display: flex;
      justify-content: flex-end;
    }
  }
  .list-container {
    overflow: hidden;
    flex-grow: 1;
    .list-scroll {
      padding-bottom: var(--p-10);
    }
  }
  .actions {
    border-bottom: solid 1px var(--border-color-secondary);
    display: flex;
    justify-content: flex-end;
    padding-bottom: var(--p-5);
    .bf-button {
      margin-left: var(--m-3);
    }
  }
}
