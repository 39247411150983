.asset-list-assign {
  height: 100%;
  position: relative;
  > .validation-popover {
    height: 100%;
    > .wrapper {
      height: 100%;
      .list-selection {
        display: flex;
        width: 100%;
        flex-direction: column;
        .info-text {
          padding-bottom: var(--p-10);
          font-size: var(--font-size-sm);
          color: var(--text-color-primary);
        }
        .filter-row {
          padding-bottom: var(--p-5);
        }
        .table-container {
          flex-grow: 1;
        }
        .list-actions {
          padding-top: var(--p-5);
          padding-bottom: var(--p-5);
          display: flex;
          justify-content: flex-end;
        }
      }

      .assigned-selection {
        display: flex;
        padding-top: var(--p-10);
        height: 100%;
        width: 100%;
        flex-direction: column;
        .selection-title {
          font-size: var(--font-size-md);
          padding-bottom: var(--p-5);
        }
        .selection-table {
          flex-grow: 1;
        }
        .selection-actions {
          padding-top: var(--p-5);
          padding-bottom: var(--p-5);
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
