.ap-create-budget-form {
  .plan-table-group-timespan {
  }
}

.fix-year-plan-form {
  padding: var(--p-5);
  .title {
    font-weight: bold;
    font-size: var(--font-size-lg);
    margin-bottom: var(--m-5);
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--m-5);
    gap: 10px;
  }
}
