.pdf-viewer {
  display: flex;
  flex-direction: column;

  &.border {
    border: solid 1px var(--border-color-tertiary);
    border-radius: var(--border-radius-base);
  }
  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .error {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--p-15);
    .error-text {
      color: var(--color-negative);
      text-align: center;
    }
  }
  .controls {
    display: flex;
    background: var(--background-color-deep);

    button.simple-action {
      background: transparent;
      color: var(--text-color-contrast);
      font-size: var(--font-size-sm);
    }

    .search {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: var(--p-5);
      padding-left: var(--p-5);
      color: var(--text-color-contrast);
      .bf-icon {
        margin-right: var(--m-3);
      }
      input {
        background: var(--background-color-deep);
        border: solid 1px var(--border-color-contrast);
        border-radius: var(--border-radius-base);
      }
      .results {
        padding-left: var(--p-3);
        display: flex;
        align-items: center;
        .counter {
          padding-right: var(--p-3);
        }
        .buttons {
          display: flex;
        }
      }
    }
    .filename {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--text-color-contrast);
      padding-left: var(--p-8);
      font-size: var(--font-size-sm);
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .pages {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--text-color-contrast);
      font-size: var(--font-size-sm);
      border-right: 1px var(--border-color-accent) solid;
      padding-right: var(--p-8);
      padding-left: var(--p-8);
    }
    .action {
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px var(--border-color-accent) solid;

      .zoom-level {
        button {
          background: transparent;
          color: var(--text-color-contrast);
          font-size: var(--font-size-sm);
          svg {
            // top: 3px;
          }
        }
        .rs-dropdown-menu {
          background: var(--background-color-deep);
          li.rs-dropdown-item {
            .rs-text {
              color: var(--text-color-contrast);
            }
          }
        }
      }
      button.zoom-button {
        background: transparent;
        color: var(--text-color-contrast);
        padding: var(--p-3);
        height: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        .bf-icon {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
  .editmode-hint {
    background: var(--background-color-primary);
    padding: 8px 10px;
    .hint {
    }
    .actions {
      padding-top: var(--p-5);
      display: flex;
      gap: 10px;
      justify-content: flex-end;
    }
  }
  .viewmode-hint {
    display: flex;
    background: var(--background-color-secondary);
    padding: 8px 10px;
    .hint {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }
    .step-through {
      display: flex;
      align-items: center;
      justify-content: center;
      .count {
        padding: var(--p-3);
        font-size: var(--font-size-md);
      }
    }
  }
  .content {
    background: var(--background-color-secondary);
    flex-grow: 1;
    overflow: auto;
    .pdf-page {
      display: block;
      // justify-content: center;
      width: 100%;
      .wrapper {
        position: relative;
        text-align: center;
        .center-position {
          position: relative;
          display: inline-block;

          canvas {
            // width: 100%;
            display: inline-block;
          }

          .text-layer {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            // position: relative;
            > span {
              color: transparent;
              position: absolute;
              white-space: pre;
              cursor: text;
              transform-origin: 0% 0%;
              user-select: text;
            }
          }
          .pdf-highlight-component {
            position: absolute;
            border: solid var(--color-negative);
            border-width: 3px;
            border-radius: var(--border-radius-base);
            opacity: 0;

            animation-name: pdf-highlight-component-init;
            animation-fill-mode: forwards;
            animation-duration: 0.6s;
            animation-timing-function: ease-in;
            // animation-delay: 0.3s;
          }
        }
      }
    }
  }
}

@keyframes pdf-highlight-component-init {
  0% {
    opacity: 0;
    border-width: 8px;
    box-shadow: 0px 0px 6px 10px var(--color-negative);
  }

  50% {
    transform: scale(1);
    border-width: 8px;
  }
  75% {
    transform: scale(1.2);
    border-width: 5px;
    box-shadow: 0px 0px 6px 10px var(--color-negative);
  }

  100% {
    opacity: 1;
    transform: scale(1);
    border-width: 4px;
    box-shadow: 0px 0px 6px 3px var(--color-negative);
  }
}
