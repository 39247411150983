.bf-text-editor {
  border: 1px solid var(--rs-border-primary);
  border-radius: var(--border-radius-base);
  overflow: auto;
  &:focus-within {
    border: var(--ck-focus-ring);
    // box-shadow: var(--ck-inner-shadow), 0 0;
  }
  .text-editor {
    .ck.ck-editor__editable {
      border: none;
    }
    .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }
}
