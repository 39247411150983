.bf-upload {
  & > div:focus {
    outline: none;
  }
  .dropzone {
    margin-bottom: var(--m-3);
    padding: 5px 5px 5px 5px;
    transition: background-color 0.4s, border-color 0.4s;
    border-radius: var(--border-radius-big);
    border: solid 1px var(--border-color-contrast);
    border-style: dashed;
    &.dragging {
      background-color: var(--background-opacity-primary);
      border-color: var(--border-color-contrast-inverse);
    }
    .empty-text {
      padding: var(--p-5);
      text-align: center;
      border-radius: var(--border-radius-big);
      border-style: dashed;
      border: solid 1px var(--border-color-accent);
      margin-bottom: var(--m-3);
    }
    .entries {
      .entry {
        border: solid 1px var(--border-color-secondary);
        border-radius: var(--border-radius-base);
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: var(--m-3);
        overflow: hidden;
        transition: background-color 0.2s, opacity 0.4s;
        opacity: 1;
        &.errored {
          .loading-progres {
            background: var(--background-color-light-error);
          }
        }

        &.removed {
          opacity: 0;
        }

        &:hover {
          background-color: var(--background-color-primary);
        }
        .prefix {
          z-index: 1;
          .preview {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;

            &.img {
              background-size: cover;
              background-position: center center;
            }
            .cdn-image {
              width: 40px;
              height: 40px;
            }
          }
        }
        .name {
          flex-grow: 1;
          z-index: 1;
          padding: var(--p-5);
          overflow: hidden;
          word-break: break-word;
          white-space: nowrap;
          .error-indicator {
            color: var(--color-negative);
            font-size: 0.75em;
          }
        }
        .postfix {
          z-index: 1;
          display: flex;

          button {
            width: 35px;
            height: 40px;
          }
        }
        &.finished {
          .loading-progres {
            background-color: var(--background-opacity-weak);
            left: 100%;
            width: 0% !important;
            transition: width 0.5s, background-color 0.5s, left 0.5s;
          }
        }
        .loading-progres {
          z-index: 0;
          position: absolute;
          top: 0;
          left: 0%;
          bottom: 0;
          width: 0px;
          background-color: var(--background-opacity-tertiary-weak);
          transition: width 0.1s, background-color 0.5s, left 0.5s;
        }
      }
    }
    .action-row {
      margin-top: var(--m-3);
      display: flex;
      justify-content: center;
    }
  }
}
