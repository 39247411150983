.ez-assignment {
  .readonly-tag {
    background: var(--background-color-primary);
    padding: var(--p-0);
    .rs-tag-text {
      .user-entry {
        padding: 8px 12px;
        display: flex;
        flex-direction: row;
        gap: 4px;
      }
    }
  }
  .bf-assignment-search-field {
    .bf-dropdown {
      & > button.bf-button {
        background: var(--background-color-primary) !important;
        .not-assigned {
          color: var(--text-color-primary);
        }
        .user-entry {
          display: flex;
          .avatar {
            margin-right: var(--m-2);
          }
          .asset-loader.error {
            padding: var(--p-0);
          }
        }
      }
    }
  }
  &.block {
    .bf-assignment-search-field {
      .bf-dropdown {
        & > button.bf-button {
          width: 100%;
        }
      }
    }
  }
}
