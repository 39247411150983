.ap-offer-entry {
  .action-row {
    display: flex;
    justify-content: flex-end;
    padding-bottom: var(--p-5);
  }
  .main-row {
    display: flex;
    .name {
      flex-grow: 1;
      padding-right: var(--p-10);
      font-size: var(--font-size-lg);
    }
    .value {
      font-size: var(--font-size-lg);
    }
  }
  .user-selections {
    display: flex;
    > .description {
      padding-right: var(--p-2);
    }
    // flex-wrap: wrap;
    .selection-entry {
      margin: var(--m-0) var(--m-0) var(--m-0) var(--m-2);
    }
  }
}
