.application-sidenav-layout.app-layout.content {
  .sidenav {
    .app-layout-content {
      .rs-sidenav {
        height: 100%;
        .rs-sidenav-body {
          height: 100%;
        }
      }
    }
  }
}
.application-sidenav-layout {
  .sidenav {
    flex-shrink: 0;

    .rs-sidenav {
      .sidenav-item,
      .sidenav-dropdown > .rs-dropdown-toggle,
      .subpage-action {
        background: transparent;
        &:hover,
        :focus {
          background: var(--base-color-hover);
        }
      }
      .sidenav-dropdown.active > .rs-dropdown-toggle {
        background: var(--base-color);
      }
      .sidenav-dropdown {
        svg.rs-dropdown-toggle-caret {
          left: initial;
          right: 17px;
          top: 17px;
        }
      }
      .sidenav-item,
      .subpage-action {
        &.active,
        &.rs-dropdown-item-active {
          background: var(--base-color);
        }
      }
      .subpage-action {
        text-align: left;
      }
      .rs-dropdown {
        width: 100%;
        // padding-left: var(--p-28);
        font-size: var(--font-size-md);
        white-space: normal;
        line-height: 1.25;
        text-align: left;
        position: relative;

        .bf-icon {
          position: absolute;
          top: 14px;
          left: 17px;
        }
      }
      .sidenav-navitem {
        width: 100%;
        padding-left: var(--p-28);
        font-size: var(--font-size-md);
        white-space: normal;
        line-height: 1.25;
        text-align: left;
        position: relative;

        .bf-icon {
          position: absolute;
          top: 14px;
          left: 17px;
        }
      }
    }
  }
  // .sidenav {
  //   .rs-nav-item.active {
  //     background: var(--base-color);
  //   }
  //   .rs-dropdown.active {
  //     > .rs-dropdown-toggle {
  //       background: var(--base-color);
  //     }
  //   }
  //   .rs-dropdown-item.subpage-action {
  //     .rs-dropdown-item-content {
  //       color: var(--sidenav-color);
  //     }
  //     &.rs-dropdown-item-active {
  //       background: var(--base-color);
  //     }
  //   }
  // }
}

.sidenav-drawer.rs-drawer.rs-drawer-xs {
  .sidenav-item,
  .sidenav-dropdown > .rs-dropdown-toggle,
  .subpage-action {
    background: transparent;
    &:hover,
    :focus {
      background: var(--base-color-hover);
    }
  }
  .sidenav-dropdown.active > .rs-dropdown-toggle {
    background: var(--base-color);
  }
  .sidenav-dropdown {
    svg.rs-dropdown-toggle-caret {
      left: initial;
      right: 17px;
      top: 17px;
    }
  }
  .sidenav-item,
  .subpage-action {
    &.active,
    &.rs-dropdown-item-active {
      background: var(--base-color);
    }
  }
  .subpage-action {
    text-align: left;
  }
  .sidenav-navitem {
    width: 100%;
    padding-left: var(--p-28);
    font-size: var(--font-size-md);
    white-space: normal;
    line-height: 1.25;
    text-align: left;
    position: relative;
  }
  .bf-icon {
    position: absolute;
    top: 12px;
    left: 17px;
  }
}
