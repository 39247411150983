.scrollWrapper {
  overflow: auto;
  max-width: 100%;
  /*firefox*/
  scrollbar-width: thin;
  /*iPad*/
  height: 1.2rem;
  /* margin-top: -40px !important; */
}
.scrollWrapper::-webkit-scrollbar {
  width: 1.1rem;
  height: 1.1rem;
}
.scrollWrapper::-webkit-scrollbar-corner {
  background: transparent;
}
.scrollWrapper::-webkit-scrollbar-thumb {
  border: 6px solid transparent;
  background: var(--background-opacity-secondary);
  background: var(--palette-black-alpha-20, var(--background-opacity-secondary));
  border-radius: var(--border-radius-big);
  background-clip: padding-box;
}
.scrollWrapper::-webkit-scrollbar-thumb:hover {
  border: 4px solid transparent;
  background: var(--background-opacity-secondary);
  background: var(--palette-black-alpha-30, var(--background-opacity-secondary));
  background-clip: padding-box;
}
@media only screen and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
}
.scroll {
  height: 1px;
}
