.bf-long-lat {
  .map-container {
    border-radius: var(--border-radius-base);

    &.has-info {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    overflow: hidden;
    height: 300px;
    position: relative;
    .actions {
      position: absolute;
      top: 10px;
      right: 10px;
      background: var(--background-color-contrast);
      button {
        width: 40px;
        height: 40px;
        background: transparent;
        color: var(--text-color-primary);
        &:hover {
          color: var(--text-color-strong);
        }
      }
    }

    .suggestions {
      position: absolute;
      top: 2%;
      left: 2%;
      height: 96%;
      width: 96%;
      background: var(--background-color-contrast);
      display: flex;
      flex-direction: column;
      border: solid 1px var(--border-color-secondary);
      .header {
        display: flex;
        align-items: center;
        .title {
          padding-left: var(--p-5);
          flex-grow: 1;
          font-size: var(--font-size-md);
          font-weight: 400;
        }
        .close-action {
          .bf-icon {
            width: 16px;
            height: 16px;
          }
        }
      }
      .content {
        flex-grow: 1;
        box-shadow: var(--default-inset-shadow);
        overflow: auto;
        &.loading {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .no-entries {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--text-color-secondary);
        }

        .suggestion-button {
          background: transparent;
          display: flex;
          width: 100%;

          &:hover {
            background: var(--background-opacity-weak);
          }
          width: 100%;
          border-bottom: 1px var(--border-color-secondary) solid;
          align-items: center;
          .button-content {
            padding: 12px 8px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            > div {
              text-align: left;
            }
          }
          .arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: var(--p-3);
            height: 100%;
            .bf-icon {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
  }
  .info {
    width: 100%;
    border: solid 1px var(--border-color-primary);
    background: var(--background-color-primary);
    padding: 4px 8px;
    display: flex;
    align-items: center;
    border-bottom-left-radius: var(--border-radius-base);
    border-bottom-right-radius: var(--border-radius-base);

    .bf-icon {
      margin-right: var(--m-5);
      width: 20px;
      height: 20px;
    }
    .text {
      font-size: var(--font-size-md);
    }
  }
  .validation {
    display: flex;
    justify-content: center;
    padding: 5px 7px;
    &.error {
      color: var(--color-negative);
    }
  }
}
