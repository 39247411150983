.cash-budget-details-drawer {
  max-width: 100%;
  .bf-table .table .rt-tbody .rt-tr-group .rt-td {
    overflow: visible;
  }
  .rs-drawer-header {
    .details-drawer-header {
      display: flex;
      padding: 20px 0px 10px 20px;

      .header-left {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .category {
          font-size: 1.4em;
          font-weight: bold;
        }
        .date {
          color: var(--text-color-secondary);
          font-weight: normal;
          margin-top: var(--m-3);

          .comparison-identifier {
            margin-right: var(--m-5);
            font-weight: bold;
            font-size: 1.2em;
          }
        }
      }
      .header-right {
        margin-right: var(--m-20);
        display: flex;
        justify-content: center;
        align-items: center;
        .amount {
          font-size: 1.2em;
          &.negative {
            color: var(--color-negative);
          }
        }
      }
    }
  }
  .details-toolbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: var(--p-5);
    flex-wrap: wrap;
    @media screen and (max-width: 440px) {
      & > * {
        flex-grow: 1;
      }
    }

    .booking-count {
      margin-right: var(--m-5);
      padding-left: var(--p-5);
      color: var(--text-color-secondary);
    }
    .order-by {
      .order-label {
        display: flex;
        align-items: center;
      }
    }
  }
  .rs-drawer-dialog {
    .rs-drawer-header {
      flex-direction: column;
      margin: var(--m-0);
      padding: 0px 20px 0px 0px;
      box-shadow: 0px 2px 5px var(--shadow-contrast-inverse-4);

      .rs-drawer-header-close {
        top: 36px;
        right: 20px;
        left: initial;
      }
    }
    .rs-drawer-content {
      display: flex;
      flex-direction: column;
      .rs-drawer-body {
        margin: var(--m-0);
        flex-grow: 1;
      }
    }
  }
}
