.contact-relation-form-fields {
  display: flex;
  gap: var(--p-10);
  flex-wrap: wrap;
  &.integrated {
    flex-direction: column;
  }
  .contact-fields {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 25%;
  }
  .invoice-fields {
    flex-grow: 2;
    flex-shrink: 1;
    flex-basis: 35%;
  }
}
