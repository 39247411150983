.bf-table {
  .ReactTable.table.hide-column-headers {
    .no-data-component {
      top: 0px;
    }
  }
}

.no-data-component {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--p-10);
  top: var(--table-header-height);
  bottom: 0;
  left: 0;
  right: 0;
  .error {
    color: var(--color-negative);
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    .icon {
      margin-right: var(--m-5);
    }
  }
  //   background-color: var(--shadow-contrast-inverse-3);
  //   opacity: 0;
  //   transition: opacity 0.5s, transform 0.1s ease-in-out;
  // transform: translateY(5px);
}
