.App {
  text-align: center;
}

.portal-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
  z-index: 1051;
}
.view-modal-route.user-profile-modal.rs-modal.rs-modal-full {
  .rs-modal-dialog {
    height: 90%;
  }
}

// color changes for different environments, so we don't accidently make changes to production
body.development {
  --header-background: var(--header-background-development) !important;
}
body.testserver {
  --header-background: var(--header-background-test-server) !important;
}

body > div#root {
  height: 100%;
}
