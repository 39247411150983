.rs-modal-wrapper {
  .rs-modal-backdrop.document-viewer-backdrop.in {
    opacity: 0.8;
  }
  .document-viewer-modal.rs-modal {
    .rs-modal-dialog {
      height: 90vh;
      padding-top: 5vh;
      max-width: 1200px;
      position: relative;
      margin-left: auto;
      margin-right: auto;
      .rs-modal-content {
        padding: var(--p-0);
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: transparent;
        box-shadow: none;
        .action-bar {
          display: flex;
          justify-content: flex-end;
          padding-bottom: var(--p-3);

          button {
            // border-color: var(--border-color-contrast);
            margin-left: var(--m-5);
            // color: #fff;
          }
        }
        .img-viewer-container {
          flex-grow: 1;
          max-height: 85vh;
          max-width: 90vw;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        .pdf-viewer-container {
          flex-grow: 1;

          border-radius: var(--border-radius-large);
          overflow: hidden;

          iframe {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    @media screen and (max-width: 800px) {
      max-width: 95%;
      width: 95%;
      margin-top: var(--m-5);
      height: 100%;
      .rs-modal-dialog {
        margin: var(--m-0);
        height: 95vh;
        padding-top: var(--p-0);
      }
    }
  }
}

.document-viewer-backdrop {
  background: var(--background-opacity-strong);

  &.rs-modal-backdrop {
    z-index: 1050;
  }
}
