.ap-activity-maintenance-next-appointments-list {
  table.table-of-appointments {
    width: 100%;
    > tr {
      text-align: left;
      vertical-align: top;
      > th {
        padding: var(--p-2);
        font-weight: 400;
        font-size: var(--font-size-sm);
        color: var(--text-color-secondary);
      }
      > td {
        padding: var(--p-2);
        border-bottom: solid 1px var(--border-color-primary);

        &.due-date {
          &.soon {
            color: var(--color-prio-high);
          }
          &.overdue {
            color: var(--color-negative);
          }
        }
      }
    }
    .technical-unit-id {
      padding-right: var(--p-3);
      font-size: var(--font-size-sm);
    }
  }
  .add-action {
    display: flex;
    justify-content: center;
    padding-top: var(--p-3);
  }
}
