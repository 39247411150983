body.apple {
  .asset-details__backdrop {
    backdrop-filter: blur(6px);
  }
}
body:not(.apple) {
  .asset-details__backdrop {
    background: var(--background-opacity-strong);
  }
}

.asset-details__modal {
  .rs-modal-content {
    padding: var(--p-0);
    background-color: transparent;
    border: none;
    box-shadow: none;
    .asset-details-actions {
      display: flex;
      justify-content: flex-end;
      padding: var(--p-5);
    }
    .rs-modal-content {
      padding: var(--p-0);
    }
  }
}
