.contact-invoice-view {
  height: 100%;
  --filter-bar-height: 80px;
  .invoice-filter {
    height: var(--filter-bar-height);
    padding: var(--p-10);
    padding-right: 45px;
    display: flex;
    border-bottom: solid 1px var(--border-color-secondary);
    gap: var(--p-10);

    background: var(--background-color-primary);
    position: relative;

    .border-top-overlay {
      position: absolute;
      top: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background: var(--background-color-primary);
    }

    .search-container {
      display: flex;
    }
    .fill {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 30%;
    }
  }

  .invoice-content-wrapper {
    height: calc(100% - var(--filter-bar-height));
    width: 100%;
    // padding: var(--p-10);
    position: relative;

    .invoice-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      display: flex;
      overflow: hidden;

      .invoice-list {
        flex-grow: 1;
        flex-shrink: 1;
        height: 100%;
        overflow: hidden;
      }
      .invoice-details {
        flex-grow: 1;
        flex-shrink: 1;
        height: 100%;
        display: flex;
        flex-direction: column;

        --details-header-bar-height: 60px;
        .invoice-details-header {
          height: var(--details-header-bar-height);
          padding: var(--p-5);
          border-bottom: solid 1px var(--border-color-secondary);
          gap: var(--p-10);
          display: flex;
          align-items: center;

          background: var(--background-color-primary);
        }
        .invoice-details-content {
          //   padding: var(--p-10);
          height: calc(100% - var(--details-header-bar-height));
          border-left: solid 1px var(--border-color-secondary);
        }
      }
    }
  }
}
