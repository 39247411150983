.cb-validation-indicator {
  width: 14px;
  height: 14px;

  border-radius: var(--border-radius-rounded);
  &.partially {
    background: var(--color-prio-high);
  }
  &.full {
    background: var(--color-positive);
  }
  &.warn {
    background: var(--color-negative);
  }
}
.cb-validation-indicator-validated-by {
  position: relative;

  .cb-validation-indicator {
    margin-right: var(--m-0);
    width: 10px;
    height: 10px;
    position: absolute;
    top: -2px;
    left: -4px;
  }
}

.cb-validation-indicator-overlay {
  min-width: 100px;
  min-height: 80px;
  .validated-header {
    background: var(--background-color-primary);
    border-bottom: solid 1px var(--border-color-secondary);
    padding: var(--p-5);
    display: flex;

    .validated-by {
      .description {
        // font-size: var(--font-size-sm);
        padding-bottom: var(--p-2);
      }
      .date {
        // font-size: var(--font-size-sm);
        color: var(--text-color-secondary);
      }
    }
    .validation-action {
    }
  }
  .suggestions {
    max-height: 60vh;
    overflow: auto;
    .suggestion {
      padding: var(--p-5);
      border-bottom: solid 1px var(--border-color-secondary);
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}
