// TODO Remove dark theme class from index.html and create switcher
body.dark-theme {
  color-scheme: dark;

  background-color: var(--app-background);

  /* Text variables */
  --text-color-primary: #e0e0e0;
  --text-color-secondary: #a8a8b3;
  --text-color-disabled: #6e6e7a;
  --text-color-contrast: #ffffff;
  --text-color-strong: #f0f0f0;
  --rs-text-secondary: var(--text-color-primary);
  --text-color-placeholder: #6a6a73;
  /* Library variables */
  --ck-color-toolbar-background: var(--rs-input-bg);
  --ck-color-input-background: var(--rs-input-bg);
  --ck-color-button-default-hover-background: var(--base-color-darken-5);
  --ck-color-button-on-active-background: var(--base-color-darken-5);
  --ck-color-button-default-background: var(--rs-btn-default-hover-bg);
  --ck-color-button-on-hover-background: var(--rs-btn-default-hover-bg);
  --ck-color-button-on-background: var(--base-color-darken-5);
  --ck-color-button-default-active-background: var(--rs-btn-default-hover-bg);
  --ck-color-dropdown-panel-background: var(--rs-input-bg);
  --ck-color-panel-background: var(--rs-input-bg);
  --ck-color-labeled-field-label-background: var(--rs-input-bg);
  --rs-calendar-today-bg: var(--background-color-light-base);
  --ck-color-base-background: var(--rs-input-bg);
  --ck-color-button-on-color: var(--text-color-secondary);
  --ez-hover-color: var(--rs-btn-default-hover-bg);
  --ck-color-text: var(--text-color-primary);
  --rs-message-error-bg: rgb(146 68 68);
  --rs-shadow-overlay: var(--card-default-box-shadow);
  --rs-picker-value: var(--text-color-primary);

  --rs-menuitem-active-text: var(--text-color-contrast);
  --swiper-theme-color: var(--base-color);
  --rs-text-link: var(--base-color);
  --rs-text-link-hover: var(--base-color-hover);
  --rs-text-link-active: var(--base-color-hover);

  --rs-navs-selected: var(--text-color-primary);
  --rs-btn-primary-bg: var(--base-color);
  --rs-listbox-option-hover-text: var(--text-color-secondary);

  /* Common variables */
  --color-negative: rgb(255 162 162);
  --color-negative-hover: rgb(160, 20, 20);
  --color-positive: rgb(84, 210, 84);
  --color-warning: rgb(180, 130, 40);
  --color-warning-strong: rgb(90, 60, 30);
  --color-neutral: rgb(224, 224, 225);

  /* Priority variables */
  // The priority colors will also be defined in the following file:
  // Constants.ts
  // export const PriorityColorCodes
  --color-prio-low: #198c62;
  --color-prio-medium: #879300;
  --color-prio-high: #d26405;

  /* BASE VARIABLES */
  --base-color: #45497e;
  --base-color-lighten-8: rgb(140, 142, 185);
  --base-color-lighten-65: rgb(200, 202, 230);
  --base-color-lighten-75: rgb(190, 192, 220);
  --base-color-darken-5: rgb(80, 82, 115);
  --base-color-darken-13: rgb(70, 72, 95);
  --base-color-desaturated-70: rgb(110, 112, 150);
  --base-color-shadow: rgba(138, 141, 178, 0.3);
  --base-color-hover: var(--base-color-lighten-8);

  --button-color-link: #91c7ec;
  --button-color-link-hover: #6ea2c5;
  --button-color-outline: #7278c3;
  --button-color-outline-hover: #5d619f;

  --base-color-foreground: #f0f0ff;

  /* Divider variables */
  --divider-color-dark: var(--background-color-secondary);
  --divider-color: rgb(223, 223, 223);

  /* Header variables */
  --header-background: rgb(34, 36, 54);
  --header-background-development: rgb(70, 15, 15);
  --header-background-test-server: rgb(84, 35, 20);
  --header-color: var(--text-color-primary);

  /* Sidenav variables */
  --sidenav-background: linear-gradient(
    to top,
    rgb(43, 45, 66) 0%,
    rgb(30, 32, 50) 100%
  );
  --sidenav-color: #d1d1e0;
  --sidenav-border: #3a3b4a;
  --sidenav-background-grey: rgb(58, 60, 78);
  --sidenav-color-grey: var(--text-color-secondary);

  /* LoginView */
  --login-background: linear-gradient(
    to bottom,
    rgb(43, 45, 66) 0%,
    rgb(34, 36, 54) 50%,
    rgb(30, 32, 50) 100%
  );
  --login-cookies-notification-background: #2b2d42;
  --login-cookies-notification-color: #d1d1e0;
  --login-center-background: #1e1e2e;
  --login-footer-button-color: var(--text-color-secondary);

  /* ApplicationDrawer */
  --app-drawer-background: rgba(0, 0, 0, 0.4);

  /* Dropdown variables */
  --dropdown-background-dark: var(--base-color-desaturated-70);

  /* Table variables */
  --table-titlebar-background: var(--base-color-darken-13);
  --table-titlebar-search-background: var(--base-color-desaturated-70);
  --table-titlebar-search-foreground: #d1d1e0;
  --table-titlebar-color: var(--text-color-primary);
  --table-footer-background: var(--table-titlebar-background);
  --table-footer-color: var(--table-titlebar-color);
  --table-header-background: var(--background-color-contrast);
  --table-header-background-hover: rgb(50, 52, 68);
  --table-header-column-resize-spanner-color: #6e6e7a;
  --table-row-color: rgb(44 46 65);
  --table-row-color-striped: rgb(34, 36, 54);
  --table-row-color-hover: var(--base-color-lighten-8);
  --table-row-color-selected: var(--base-color);
  --table-row-color-selected-hover: var(--base-color-darken-5);
  --table-row-color-selected-color: var(--text-color-primary);
  --table-row-deleted-color: var(--text-color-disabled);
  --table-row-changed-color: rgb(50, 50, 50);
  --table-row-foreground: var(--text-color-primary);
  --table-row-foreground-selected: var(--text-color-primary);
  --rs-listbox-option-hover-bg: var(--table-row-color-selected);
  --plan-data-table-border-color: rgb(58, 60, 78);

  /* List variables */
  --list-row-background: var(--background-color-contrast);
  --list-row-background-hover: var(--base-color-darken-5);
  --list-row-background-selected: var(--base-color-darken-13);

  /* help drawer variables */
  --help-drawer-header-background: #000102;
  --help-drawer-header-color: #fff;

  /* Form-Element variables */
  --form-field-view-table-row-background: rgb(50, 52, 68);
  --form-field-view-table-subtitle-background: rgb(80, 100, 140);
  --form-field-view-table-subtitle-color: rgb(230, 230, 230);

  /* Border color variables */
  --border-color-primary: rgb(60, 62, 78);
  --border-color-secondary: rgb(50, 52, 68);
  --border-color-tertiary: rgb(40, 42, 58);
  --border-color-accent: rgb(74, 77, 106);
  --border-color-contrast: rgb(240, 240, 240);
  --border-color-contrast-inverse: rgb(15, 17, 25);
  --rs-navs-tab-border: var(--border-color-accent);
  --rs-border-primary: var(--border-color-primary);

  --declined-comment-border: rgb(162, 114, 114);
  --border-color-pdf-checkbox-checked: #3498ff;
  --border-cash-budget-sub-head: rgb(100, 102, 120);

  /* Icon color variables */
  --icon-color-contrast: #fff;
  --icon-color-contrast-inverse: #000;
  --icon-color-secondary: #888;
  --icon-color-secondary-accent: #5a5a5aff;

  /* Icon fill variables */
  --icon-fill-primary: #eee;
  --icon-fill-secondary: #999;
  --icon-fill-disabled: #aaa;
  --icon-fill-contrast: #fff;

  /* Background color variables */
  --app-background: rgb(28, 30, 42);
  --background-color-contrast: rgb(40, 42, 58);
  --background-color-primary: rgb(32 34 48);
  --background-urgent: rgb(74, 40, 40);
  --background-color-secondary: rgb(60, 62, 78);
  --background-color-contrast-inverse: rgb(240, 240, 240);
  --background-color-contrast-inverse-2: rgba(255, 255, 255, 0.2);
  --background-color-contrast-inverse-0: rgba(255, 255, 255, 0);
  --background-color-accent: rgb(100, 102, 120);
  --background-color-deep: rgb(30, 32, 42);
  --background-color-light-base: #305a81cc;
  --background-color-light-error: #734848;
  --background-color-light-positive: #004a0e;
  --background-color-focus: #02403e;
  --background-color-toggle-urgent-checked: #5c3939;
  --background-diagram-tree-common: rgba(90, 105, 192, 0.1);
  --background-diagram-tree-object: rgba(13, 149, 140, 0.2);

  --overlay-background: rgba(0, 0, 0, 0.5);

  /* Opacity background */
  --app-background-loadmask: rgba(93, 88, 88, 0.58);
  --background-opacity-primary: rgba(0, 0, 0, 0.5);
  --background-opacity-secondary: rgba(0, 0, 0, 0.25);
  --background-opacity-strong: rgba(0, 0, 0, 0.8);
  --background-opacity-weak: rgba(0, 0, 0, 0.1);
  --background-opacity-tertiary-main: rgba(75, 71, 71, 0.5);
  --background-opacity-tertiary-weak: rgba(32, 39, 65, 0.287);
  --background-opacity-tertiary-low: var(--background-color-contrast-inverse-0);

  /* Scroll background */
  --scroll-background-color: rgba(38, 38, 38, 0.5);
  --scroll-background-hover: rgba(38, 38, 38, 0.7);

  /* Drag overlay background */
  --drag-overlay-background: rgba(20, 48, 61, 0.3);
  --drag-overlay-border: #03303d;

  /* Invoice indicator */
  --invoice-indicator-incoming-icon-background: rgb(202, 230, 241);
  --invoice-indicator-outgoing-icon-background: rgb(190, 227, 198);
  --invoice-indicator-incoming-icon-foreground: rgb(1, 62, 86);
  --invoice-indicator-outgoing-icon-foreground: rgb(0, 80, 17);

  /* BF Input variables */
  --bf-input-focus-border: rgb(88, 166, 255);
  --bf-input-focus-box-shadow: rgba(88, 166, 255, 0.5);
  --bf-input-complete-color: #6ab04c;
  --bf-input-complete-disabled-background: rgba(106, 176, 76, 0.1);
  --bf-input-invalid-border: #dc3545;
  --bf-input-invalid-box-shadow: rgba(220, 53, 69, 0.5);

  /* Cash Budget variables */
  --cashbudget-color-border: #eeeeee29;
  --cashbudget-color-menu: #13151e;
  --cashbudget-color-menu: #13151e;
  --cashbudget-color-today: rgba(195, 196, 255, 0.261);
  --cashbudget-color-prognose: #304a317b;
  --cashbudget-color-planned-booking: #888b5a8c;
  --cashbudget-color-sharepoint: #46516f8c;
  --cashbudget-color-fixed-comparison: #574f338c;

  /* Box Shadow variables */
  --shadow-contrast-inverse-1: rgba(88, 88, 100, 0.1);
  --shadow-contrast-inverse-2: rgba(88, 88, 100, 0.2);
  --shadow-contrast-inverse-3: rgba(60, 60, 68, 0.3);
  --shadow-contrast-inverse-4: rgba(40, 40, 46, 0.4);
  --shadow-contrast-inverse-5: rgba(50, 50, 56, 0.5);
  --shadow-contrast-inverse-6: rgba(0, 0, 0, 0.8);
  --shadow-numeric-slider-hover: rgba(120, 160, 230, 0.25);
  --shadow-restrict-component-add-form: rgb(100, 102, 120);
  --shadow-labeled-input-highlight: rgb(120, 122, 140);
  --shadow-contrast: rgba(255, 255, 255, 0.8);
  --shadow-contrast-7: rgba(255, 255, 255, 0.7);
  --default-inset-shadow: inset 0 0 6px rgba(88, 88, 100, 0.5);
  --card-default-box-shadow: 0px 4px 12px 0px rgb(0 0 0 / 45%);

  /* Outline colors */
  --object-gallery-item-outline: #3498ff;

  /* RS overrides */
  --rs-btn-default-bg: rgb(53, 56, 72);
  --rs-btn-default-hover-bg: rgb(63, 66, 82);
  // --rs-bg-overlay: rgb(53, 56, 72);
  --rs-dropdown-item-bg-active: rgb(64, 68, 84);
  --rs-dropdown-item-text-active: var(--base-color-foreground);
  --rs-input-bg: rgb(50, 52, 68);
  --rs-btn-default-active-bg: var(--background-color-contrast);
  --rs-btn-default-active-text: var(--base-color-foreground);
  --rs-btn-link-text: rgb(88, 166, 255);
  --rs-text-heading: var(--base-color-foreground);
  --rs-message-error-bg: var(--background-urgent);
  --rs-message-error-text: rgb(255, 204, 204);
  --rs-checkbox-checked-bg: rgb(74, 77, 106);
  --rs-listbox-option-selected-bg: var(--background-color-secondary);
  --rs-listbox-option-selected-text: rgb(240, 240, 240);
  --rs-navbar-inverse-hover-bg: rgb(64, 68, 84);
  --rs-btn-default-disabled-bg: rgb(70, 72, 90);
  --rs-bg-card: rgb(50, 52, 68);
  --rs-navs-bg-hover: var(--rs-btn-default-hover-bg);
  --rs-navs-text-active: var(--rs-btn-default-active-text);
  --rs-tag-bg: var(--rs-btn-default-disabled-bg);
  --rs-toggle-thumb: rgb(88, 166, 255);
  --rs-toggle-bg: var(--background-color-secondary);
  --rs-toggle-hover-bg: rgb(78, 80, 100);
  --rs-input-disabled-bg: var(--rs-input-disabled-bg);
  --rs-btn-subtle-hover-bg: var(--base-color-darken-5);

  /* Chart colors */
  --chart-axis-label-primary: #a6a6b0;
  --chart-color-secondary: #c3c3c3;
  --chart-color-positive: #00bb00;
  --chart-color-positive-weak: #3e7a2e;
  --chart-color-negative: #a10318;
  --chart-color-prio-medium: #b5b509;
  --chart-color-prio-high: #b56509;
  --chart-color-prio-low: #594949;
  --chart-color-focus: #3481a5;
  --chart-border-contrast: var(--background-color-contrast);
  --chart-shadow-primary: rgba(255, 255, 255, 0.5);

  --cb-portfolio-immo-vacant: #c4504f;
  --cb-portfolio-immo-non-vacant: #4bd14e;

  /* By Dunning Level Chart */
  --chart-by-dunning-level-1: #39a0ba;
  --chart-by-dunning-level-2: #ddb223;
  --chart-by-dunning-level-3: #ff8000;
  --chart-by-dunning-level-4: #ba0202;
  --chart-by-dunning-level-5: #3c2a2a;
  --chart-by-dunning-level-6: #3b1717;

  /* Opos Month Chart */
  --chart-opos-month-rent: #bf5119;
  --chart-opos-month-operating-cost: #14989f;
  --chart-opos-month-dunning: #749e09;
  --chart-opos-month-default-interest: #099e71;
  --chart-opos-month-deposit: #569e09;
  --chart-opos-month-other: #24099e;

  /* Rent Data Chart */
  --chart-rent-data-candlestick-up: #2eb231;
  --chart-rent-data-candlestick-down: #ea5651;
  --chart-rent-data-median: #ef3fef;
  --chart-rent-data-min: #4a9be7;
  --chart-rent-data-max: #f556d7;
  --chart-rent-data-current-rent: #ff6f00;
  --chart-rent-data-planned-rent: #007fff;

  /* Risk Scenario Chart */
  --chart-risk-scenario-current-balance-area: rgba(132, 142, 41, 0.57);
  --chart-risk-scenario-current-balance-color: rgba(164, 190, 46, 0.84);
  --chart-risk-scenario-current-balance-line-color: #fff;
  --chart-risk-scenario-planned-balance-area: rgba(73, 73, 182, 0.57);
  --chart-risk-scenario-planned-balance-color: rgba(67, 73, 180, 0.78);
  --chart-risk-scenario-accumulated-interest-color: #b28509;
  --chart-risk-scenario-interest-area: rgba(177, 137, 37, 0.58);
  --chart-risk-scenario-interest-color: #bd9226;
  --chart-risk-scenario-interest-line-color: #fff;
  --chart-risk-scenario-repayment-area: rgba(13, 156, 48, 0.75);
  --chart-risk-scenario-repayment-color: #0bab34;
  --chart-risk-scenario-other-cost-area: rgba(0, 186, 183, 0.63);
  --chart-risk-scenario-other-cost-color: #02c9c5;
  --chart-risk-scenario-vacancy-loss-area: rgba(156, 0, 0, 0.6);
  --chart-risk-scenario-vacancy-loss-color: #a60101;
  --chart-risk-scenario-rental-plan-color: #013ec4;
  --chart-risk-scenario-rental-plan-label: #9c0000ff;
  --chart-risk-scenario-rental-plan-item-color: rgba(255, 0, 0, 0.35);
  --chart-risk-scenario-rental-plan-item-border: rgba(255, 0, 0, 0.35);

  /* Tag overrides */
  textarea {
    background-color: var(--rs-input-bg);
  }

  .input-container input {
    background-color: var(--rs-input-bg);
  }
}
