.ds-entry-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  &:hover {
    text-decoration: underline;
  }

  .directory-icon-container {
    margin-right: var(--m-5);
  }
  .entry-text {
    min-width: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .postfix-content {
    margin-left: auto;
    padding-left: var(--p-5);
  }
}
