.activity-label {
  white-space: normal;

  .activity-label__type {
    font-size: var(--font-size-sm);
    color: var(--text-color-secondary);
  }
  &.no-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.activities-tooltip {
  padding: var(--p-5);
  text-align: left;
  .activity-label-container {
    .activity-label__type {
      color: var(--text-color-disabled);
    }
  }
}
.open-activities-modal {
  padding: var(--p-5);
  .activity-label-container {
    margin-top: var(--m-8);
    .activity-label {
      margin-bottom: var(--m-5);
      white-space: normal;
      text-align: left;
      &:last-of-type {
        margin-bottom: var(--m-0);
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--m-8);
  }
}
