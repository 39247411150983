.user-avatar-editor {
  .controls {
    .label {
      padding-bottom: var(--p-3);
      padding-top: var(--p-8);
    }
  }
  .actions {
    display: flex;
    padding-top: var(--p-8);
    justify-content: flex-end;
  }
}
