.ds-document-upload-dialog {
  .description {
    padding-bottom: var(--p-5);
  }
  .file-list {
    .entries {
      border-top: solid 1px var(--border-color-secondary);
      border-bottom: solid 1px var(--border-color-secondary);
    }
    .add-action {
      display: flex;
      justify-content: center;
      padding-top: var(--p-5);
      padding-bottom: var(--p-8);
    }
  }
  .form-actions {
    display: flex;
    justify-content: flex-end;
    padding-top: var(--p-5);
    .bf-button {
      margin-left: var(--m-5);
    }
  }
}
