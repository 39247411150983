.app-drawer-dropdown {
	.rs-btn.rs-btn-subtle.rs-dropdown-toggle {
		overflow: visible;
		position: relative;

		.font-color-white {
		}

		.async-task-loader {
			position: absolute;
			bottom: 0px;
		}
	}

	.rs-dropdown-menu {
		position: fixed;
		right: 0px !important;
		top: var(--header-height) !important;
		max-height: calc(100% - var(--header-height));
	}

	.wrapper {
		width: 500px;
		height: 300px;
	}
}

@media screen and (max-width: 550px) {
	.app-drawer-dropdown {
		.wrapper {
			width: 320px;
		}
	}
}

@media screen and (max-width: 350px) {
	.app-drawer-dropdown {
		.wrapper {
			width: 220px;
		}
	}
}
