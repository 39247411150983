.open_layer_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 700px;
}

.hint {
  font-weight: bold;
  font-size: var(--font-size-lg);
  text-align: center;

  color: var(--text-color-primary);
  background-color: var(--ez-hover-color);

  border: 2px solid var(--color-warning);
  border-radius: var(--border-radius-base);

  white-space: nowrap;
  max-width: min-content;

  padding: 14px 20px;
}
