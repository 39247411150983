.ra-urgent-indicator {
  &.indicator {
    display: inline-flex;
    margin-right: var(--m-2);
    width: 24px;
    height: 24px;
    border-radius: var(--border-radius-big);
    background: var(--background-urgent);
    color: var(--color-negative);
    padding: 3px 2px 2px 3px;
  }
  &.bubble {
    background: var(--background-urgent);
    padding: var(--p-3);
    border-radius: var(--border-radius-base);
    color: var(--color-negative);
    .title {
      font-size: var(--font-size-xs);
      //   font-weight: 500;
      padding-bottom: var(--p-1);
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .comment {
    }
    .comment-info {
      color: var(--color-warning);
      font-weight: bold;
      padding-top: var(--p-3);
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      .date {
        font-size: var(--font-size-sm);
      }
    }
  }
}
