.cb-linked-item {
  padding: 2px 6px;
  border-bottom: solid 1px var(--border-color-primary);
  &.outfaded {
    opacity: 0.5;
  }
  &:hover {
    background: var(--background-color-primary);
    .main {
      .unlink-button {
        opacity: 1;
      }
      .cb-linked-item-assigned-by {
        opacity: 1;
      }
    }
  }
  .main {
    overflow: hidden;
    display: flex;
    align-items: center;

    .cb-linked-item-assigned-by {
      opacity: 0;
      margin-right: var(--m-2);
    }
    .unlink-button {
      opacity: 0;
      padding: var(--p-2);
      display: flex;
      justify-content: center;
      align-items: center;
      .bf-icon {
        width: 12px;
        height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .type {
      color: var(--text-color-secondary);
      padding-right: var(--p-3);
      font-size: var(--font-size-sm);
      min-width: 90px;
    }
    .link-button {
    }
    .post {
      padding-left: var(--p-3);
      .document-from-object {
        display: flex;
        align-items: center;
        padding-left: var(--p-4);
        .from-object-label {
          font-size: var(--font-size-sm);
          color: var(--text-color-secondary);
          padding-right: var(--p-2);
        }
        .open-object-button {
          font-size: var(--font-size-sm);
        }
      }
    }
  }
  .dependencies {
    padding-left: var(--p-45);
    .invoice-history {
      padding-top: var(--p-2);
    }
  }
}

.link-created-by-info-overlay {
  padding: var(--p-3);
  .text {
    color: var(--text-color-secondary);
    font-size: var(--font-size-sm);
  }
  .date {
    color: var(--text-color-secondary);
    font-size: var(--font-size-sm);
  }
}
