.ap-rating-view-button {
  .button-inline-rating {
    .rs-rate-character {
      margin-right: 1px;
    }
  }

  &.rendered-in-list {
    .button-rate {
      background: transparent;
    }
  }
}
.ap-rating-overlay {
  padding: var(--p-5);
  width: 400px;

  .edit-action {
    display: flex;
    justify-content: flex-end;
  }
  .rate {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .comment {
    textarea.textarea.readonly {
      max-height: 100px;
      overflow: auto;
    }
    padding-bottom: var(--p-5);
  }
  .label {
    font-weight: bold;
    font-size: var(--font-size-sm);
  }
  .potential-saving {
    .value {
      padding: var(--p-3);
    }
  }
  .rated-assignee {
    .value {
      padding: var(--p-3);
    }
  }
  .meta-row {
    padding-top: var(--p-5);
    justify-content: center;
  }
}
