.cdn-image-gallery {
  height: 100%;
  .empty-page {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--text-color-secondary);
    background: var(--background-color-primary);
  }
  .cdn-image {
    height: 100%;
    width: 100%;
  }
  &.has-shadow {
    .swiper-wrapper {
      padding-top: var(--p-3);
      padding-bottom: var(--p-3);
      .swiper-slide {
        height: calc(100% - 10px);
      }
    }
  }
}
