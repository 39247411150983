.pdf--constructor--object-gallery--pick-list {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;

  .object-gallery--item {
    background: transparent;
    border: 0px;
    transition: outline-color 0.3s;
    border-radius: 0.25rem;
    outline: 2px solid transparent;

    overflow: hidden;
    padding: 0;
    margin: 0;
    width: 200px;
    aspect-ratio: 16 / 9;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }

  .object-gallery--item__error {
    cursor: default;
    outline-color: silver;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .object-gallery--item[data-selected="true"] {
    outline-color: var(--object-gallery-item-outline);
  }
}
