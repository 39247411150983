.rs-repitition-day-options {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;

  .bf-button {
    border-radius: var(--border-radius-extra-large);
    padding: 4px 6px;
    .text-label {
      padding: 0 2px;
    }
  }

  &.error {
    .bf-button {
      border-color: var(--color-negative);
      color: var(--color-negative);
    }
  }
}
