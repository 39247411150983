.ap-assign-activity-to-invoice {
  height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .modal-title {
    padding: var(--p-10);
    display: flex;
    .__h1 {
      flex-grow: 1;
    }
  }
  .filterbar {
    display: flex;
    padding: 0px 20px;
  }
  .invoices-content {
    flex-grow: 1;
    overflow: hidden;
    .list-view-pdf {
      height: 100%;
      overflow: hidden;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px var(--border-color-secondary);
        .title {
          font-size: var(--font-size-lg);
        }
      }
      .list-scroll {
        padding-top: var(--p-10);
      }
      .list-view-pdf-entry {
        opacity: 0.6;
        margin-left: var(--m-3);
        cursor: pointer;

        &:hover {
          background: var(--table-row-color-hover) !important;
        }
        &.selected {
          opacity: 1;
        }
      }
    }
    .pdf-footer-actions {
      display: flex;
      padding-top: var(--p-10);
      gap: 10px;
    }
  }
}
