.cb-rental-agreement-saldo-graph {
  height: 100%;
}

.cb-rental-agreement-saldo-graph-tooltip {
  .value {
    &.negative {
      color: var(--color-negative);
    }
    &.positive {
      color: var(--color-positive);
    }
  }
  .saldo-line {
    display: flex;
    padding-bottom: var(--p-4);
    font-weight: 600;
    .label {
      padding-right: var(--p-4);
      flex-grow: 1;
    }
    .value {
    }
  }
  .entry {
    .date {
      font-size: var(--font-size-sm);
      color: var(--text-color-secondary);
    }
    .name {
    }
    .value {
      width: 100%;
      text-align: right;
    }
  }
}
