.gf-avatar {
  position: relative;
  z-index: 4;
  cursor: pointer;

  &:hover {
    .options {
      opacity: 1;
    }
  }
  .options {
    transition: opacity 0.2s;
    border-radius: var(--border-radius-rounded);
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: var(--background-opacity-primary);
    z-index: 2;
    color: var(--text-color-contrast);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.gf-grid-check {
  .grid-row {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    min-height: 100px;

    .grid-option {
      flex: 1 1 0px;
      width: 0;
      overflow: hidden;
      position: relative;
      .checked-over {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 5;
        pointer-events: none;
        background: var(--base-color);
        opacity: 0;
        transition: opacity 0.5s;

        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        color: var(--text-color-contrast);
        .bf-icon {
          padding: var(--p-5);
        }
        &.active {
          opacity: 0.3;
        }
      }
      button {
        margin: var(--m-0);
        width: 100%;
        height: 100%;
        .label {
          white-space: normal;
          max-width: 100%;
          word-break: break-word;
        }
      }
    }
  }
}
