.risk-scenario-chart {
  .header {
    display: flex;
    justify-content: space-between;
    .title {
      font-size: var(--font-size-md);
    }
  }
  .chart-main {
    height: 520px;
  }
}

.risk-scenario-tooltip {
  .color-indicator {
    display: inline-block;

    width: 12px;
    height: 12px;
    border-radius: var(--border-radius-rounded);
    margin-right: var(--m-2);
  }
  .selected-date {
  }
  .section {
    padding-bottom: var(--p-5);
    .section-header {
      display: flex;
      .section-title {
        font-size: var(--font-size-base);
        font-weight: bold;
        flex: 1;
      }
      .section-value {
        // font-weight: bold;
        &.negative {
          color: var(--color-negative);
        }
        &.positive {
          color: var(--color-positive);
        }
      }
    }

    .values {
      display: flex;
      gap: 10px;
      .sub-value {
        .label {
          color: var(--text-color-secondary);
          font-size: var(--font-size-sm);
        }
        .value {
        }
      }
    }
  }
}
