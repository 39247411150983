.plan-table-creation {
  .load-template-as-whole {
    background: var(--background-color-primary);
    margin-top: var(--m-5);
    padding: var(--p-5);
    display: flex;
    align-items: center;
    border-radius: var(--border-radius-base);
    .text {
      flex-grow: 1;
    }
  }
}
