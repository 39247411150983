.dropzone {
  width: 250px;
  height: 250px;
}

.preview_container,
.upload_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  width: 100%;
  height: 100%;

  cursor: pointer;
  position: relative;
}

.upload_container {
  border: 2px dashed var(--rs-border-primary);
  border-radius: var(--border-radius-base);
}

.preview_container :global(.file-preview-component .image) {
  border-radius: var(--border-radius-base);
}
