.focused-edit-component {
  position: relative;
  width: 100%;
  height: 100%;
  .edit-component-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    &.to-top {
      z-index: 999999;
    }
  }
  .focus-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--background-opacity-primary);
    z-index: 999998;
  }
}
