.TrustedDevices {
  .introText {
    color: var(--text-color-secondary);
    max-width: 90%;
  }
  .devices-list-header {
    display: flex;
    border-bottom: solid 1px var(--border-color-tertiary);
    margin-right: var(--m-3);
    margin-left: var(--m-3);

    .devices-list-header-title {
      flex-grow: 1;
      font-size: 1.4em;
      padding: 10px 10px 5px;
    }
    .actions {
      .sub-icon {
        a {
          i.bf-icon {
            margin-right: var(--m-6);
          }
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
  }
  .devices-list {
    .no-devices {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: var(--p-13);
      color: var(--text-color-secondary);
    }

    .category-wrapper {
      .category-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.2em;
        padding: 20px 10px 10px 10px;
        i.bf-icon {
          margin-right: var(--m-3);
        }
      }
      .category-content {
        .trusted-device-entry {
          padding: 8px 15px;
          display: flex;
          align-items: center;
          border-bottom: solid 1px var(--border-color-primary);
          &:last-of-type {
            border-bottom: none;
          }
          &:hover {
            background: var(--background-color-primary);
          }
          .device-name-wrapper {
            flex-grow: 1;

            .deviceName {
              .local-identifier {
                display: inline-flex;
                align-items: flex-end;
                font-size: 0.7em;
                background: var(--background-color-secondary);
                border-radius: var(--border-radius-large);
                padding: 5px 8px;
                margin-right: var(--m-3);
                i.bf-icon {
                  font-size: 1.6em;
                  margin-right: var(--m-3);
                }
              }
              font-size: 1.2em;
            }
            .sub-lines {
              padding-top: var(--p-2);
              color: var(--text-color-secondary);
              display: flex;
              div {
                padding-right: var(--p-5);
                .ident {
                  padding-right: var(--p-2);
                }
              }
            }
          }
        }
      }
    }
  }
}
