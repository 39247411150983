$height-of-entry: 66px;

.bf-icon-tag-card {
  .bf-button {
    &.bf-icon-tag-btn {
      background: var(--background-color-contrast);
      width: 100%;
      border-top-left-radius: $height-of-entry/2;
      border-bottom-left-radius: $height-of-entry/2;
      position: relative;
      box-shadow: 0px 3px 9px 0px var(--shadow-contrast-inverse-3);

      &:hover {
        background: var(--background-color-contrast);
        cursor: default;
      }

      &:focus {
        background: var(--background-color-contrast);
      }

      &:active:focus {
        background: var(--background-color-contrast);
      }
      &:not(.disable-button) {
        &:hover {
          background: var(--background-color-primary);
          cursor: pointer;
        }

        &:focus {
          background: var(--background-color-primary);
        }

        &:active:focus {
          background: var(--background-color-primary);
        }
      }
      .bf-tag-card-container {
        width: 100%;
        padding: var(--p-0);
        display: flex;
        align-items: center;
        z-index: 1;
        position: relative;
        justify-content: flex-start;

        .circle-container {
          border-top-left-radius: calc($height-of-entry/2);
          border-bottom-left-radius: calc($height-of-entry/2);
          .icon-container {
            width: $height-of-entry;
            height: $height-of-entry;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: $height-of-entry/2;
            background: var(--background-color-secondary);
            background-position: center; /* Center the image */
            background-repeat: no-repeat; /* Do not repeat the image */
            background-size: cover;
          }
        }
        .bf-tag-card-content {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          width: 100%;
          overflow: hidden;
        }
      }
    }
  }
}
