@import "../../styles/vars";

.application-sidenav {
  height: 100%;
  overflow-y: auto;
  background: var(--sidenav-background);
  color: var(--sidenav-color);

  display: flex;
  flex-direction: column;

  &.appearance-grey {
    background: var(--sidenav-background-grey);
    color: var(--sidenav-color-grey);
  }

  .rs-dropdown {
    img.bf-icon.img {
      position: absolute;
      top: 12px;
      left: 17px;
    }
  }
  .rs-sidenav.rs-sidenav-collapse-out.rs-sidenav-inverse {
    .rs-sidenav-body {
      .rs-dropdown.rs-dropdown-placement-right-start.rs-dropdown-open {
        .rs-dropdown-menu {
          position: fixed;
          top: var(--header-height);
          left: 56px;
          bottom: 0px;
          background: var(--sidenav-background);
          border-radius: var(--border-radius-none);
          .rs-dropdown-item {
            width: 100%;
            .rs-dropdown-item-content {
              &:hover {
                color: var(--sidenav-color);
                background: var(--base-color);
              }
            }
          }
        }
      }
    }
  }

  .rs-sidenav-inverse
    .rs-sidenav-nav
    .rs-nav-item:not(.rs-nav-item-active)
    > .rs-nav-item-content,
  .rs-sidenav-inverse .rs-sidenav-nav .rs-dropdown .rs-dropdown-toggle {
    color: var(--sidenav-color);
  }
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle,
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-nav-item-active
    > .rs-nav-item-content,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-nav-item-active
    > .rs-nav-item-content,
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle:hover,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle:hover,
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-nav-item-active
    > .rs-nav-item-content:hover,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-nav-item-active
    > .rs-nav-item-content:hover,
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle:focus,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle:focus,
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-nav-item-active
    > .rs-nav-item-content:focus,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-nav-item-active
    > .rs-nav-item-content:focus,
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle:active,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-dropdown
    .rs-dropdown-menu-active
    ~ .rs-dropdown-toggle:active,
  .rs-sidenav-inverse.rs-sidenav-collapse-in
    .rs-nav-item-active
    > .rs-nav-item-content:active,
  .rs-sidenav-inverse.rs-sidenav-collapsing
    .rs-nav-item-active
    > .rs-nav-item-content:active {
    color: var(--sidenav-color);
  }
  .rs-sidenav-inverse
    .rs-sidenav-nav
    .rs-nav-item:not(.rs-nav-item-active)
    > .rs-nav-item-content:hover,
  .rs-sidenav-inverse .rs-sidenav-nav .rs-dropdown .rs-dropdown-toggle:hover,
  .rs-sidenav-inverse
    .rs-sidenav-nav
    .rs-nav-item:not(.rs-nav-item-active)
    > .rs-nav-item-content:focus,
  .rs-sidenav-inverse .rs-sidenav-nav .rs-dropdown .rs-dropdown-toggle:focus {
    color: var(--sidenav-color);
  }

  &.noTransition {
    .rs-sidenav.rs-sidenav-inverse {
      transition: none;
    }
  }
  .content {
    flex-grow: 1;
    overflow-y: auto;
  }

  .footer {
    border-top: solid 1px var(--sidenav-border);
  }

  .rs-sidenav-collapse-out.rs-sidenav.rs-sidenav-inverse {
    //background: var(--sidenav-background);
    color: var(--sidenav-color);

    .rs-sidenav-body {
      min-width: initial;
    }

    .rs-dropdown-menu {
      // max-height: 400px;
      overflow-y: auto;

      // &::-webkit-scrollbar {
      //   width: 12px;
      // }

      // &::-webkit-scrollbar-track {
      //   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      // }

      // &::-webkit-scrollbar-thumb {
      //   background-color: rgba(0,0,0,0.5);
      //   outline: 1px solid slategrey;
      // }
    }
    .rs-dropdown.rs-dropdown-open.rs-dropdown-collapse {
      .rs-dropdown-menu {
        position: fixed;
        top: var(--header-height);
        left: 56px;
        bottom: 0;
        max-height: none !important;
        background-color: transparent; //var(--sidenav-background);
        * {
          color: var(--sidenav-color);
        }
      }
    }
    .rs-dropdown.rs-dropdown-open.rs-dropdown-expand {
      .rs-dropdown-menu {
        position: fixed;
        top: var(--header-height);
        left: 56px;
        bottom: 0;
        max-height: none !important;
        background-color: transparent; //var(--sidenav-background);
        * {
          color: var(--sidenav-color);
        }
      }
    }
  }
  .rs-dropdown-menu.rs-dropdown-menu-collapse-in {
    z-index: 0;
  }

  .rs-navbar.rs-navbar-inverse.nav-toggle {
    color: var(--sidenav-color);
    background-color: transparent;
  }

  .rs-sidenav.rs-sidenav-inverse {
    background: transparent;
    //transition: none;

    .rs-sidenav-body {
      min-width: 230px;

      .rs-nav.rs-nav-default.rs-sidenav-nav {
        .ts-nav-item {
        }
      }
    }
  }
}

.rs-drawer.rs-drawer-xs.rs-drawer-left.sidenav-drawer {
  max-width: 250px;

  &.appearance-grey {
    .rs-drawer-dialog {
      .rs-drawer-content {
        background: var(--sidenav-background-grey);
        color: var(--sidenav-color-grey);

        .rs-sidenav.rs-sidenav-inverse {
          .sideNavHeader {
            .applicationName {
              color: var(--sidenav-color-grey);
            }
          }
        }
      }
    }
  }
  .rs-drawer-dialog {
    .rs-drawer-content {
      background: var(--sidenav-background);
      color: var(--sidenav-color);

      .rs-sidenav.rs-sidenav-inverse {
        background: transparent;
        color: var(--sidenav-color);

        .sideNavHeader {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: var(--p-8);
          .logo {
            width: auto;
            max-width: 80%;
            max-height: 50px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50% 50%;
          }
          .applicationName {
            padding: var(--p-3);
            color: var(--sidenav-color);
            font-size: 1.2em;
          }
          border-bottom: solid 1px var(--border-color-accent);
        }
      }
    }
  }
}
