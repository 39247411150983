.cb-rental-object-change-plandata-form {
  .plandata-wrapper {
    display: flex;

    .object-stacking {
      width: 600px;
    }
    .form-data {
      flex: 1;

      .form-data-table {
        width: 100%;
        tr {
          &.head-line {
            border-bottom: solid 1px var(--plan-data-table-border-color);
          }
          &.content-line {
            border-bottom: solid 1px var(--border-color-primary);
            &:hover {
              background: var(--list-row-background-hover);
            }
            &.not-selected {
              opacity: 0.5;
            }
            td {
              padding-top: var(--p-2);
              padding-bottom: var(--p-2);
            }
            &:nth-of-type(2) {
              td {
                padding-top: var(--p-3);
              }
            }
          }
          &.footer-line {
            border-top: solid 2px var(--border-color-secondary);
          }
          th {
            text-align: left;
            padding-right: var(--p-2);
            padding-left: var(--p-2);
            border-right: solid 1px var(--plan-data-table-border-color);

            &.no-border {
              border-right: none;
            }
            &:first-of-type {
              //   padding-left: var(--p-0);
            }
            &:last-of-type {
              //   padding-right: var(--p-0);
              border-right: none;
            }
          }
          td {
            padding-right: var(--p-2);
            padding-left: var(--p-2);
            border-right: solid 1px var(--plan-data-table-border-color);

            &.no-border {
              border-right: none;
            }
            &:first-of-type {
              //   padding-left: var(--p-0);
            }
            &:last-of-type {
              //   padding-right: var(--p-0);
              border-right: none;
            }
          }
          .floor,
          .quantity {
            text-align: center;
          }
          .unit {
            .id {
              color: var(--text-color-secondary);
              font-size: var(--font-size-sm);
            }
          }
        }

        .info-overlay-icon {
          color: var(--color-prio-high);
          padding-right: var(--p-3);
        }

        td.rentNet {
          padding-right: var(--p-0);
          .rent-direct {
            max-width: 140px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            .rs-input-group-addon {
              padding: 10px 6px;
            }
            input {
              padding: 7px 6px;
            }
          }
        }
        td.rentNetPerQuantity {
          padding-left: var(--p-0);

          .rent-by-quantity {
            max-width: 160px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            .rs-input-group-addon {
              padding: 10px 6px;
            }
            input {
              padding: 7px 6px;
            }
            .suffix {
              min-width: 78px;
            }
          }
        }
        .rentGross,
        .operatingCostNet,
        .operatingCostGross {
          .bf-input {
            max-width: 160px;
          }
        }
        .entry {
          padding-right: var(--p-2);
          .label {
            font-size: var(--font-size-sm);
            color: var(--text-color-secondary);
            overflow: hidden;
            text-wrap: nowrap;
            white-space: none;
            text-overflow: ellipsis;
          }
          .value {
            font-size: var(--font-size-base);
            color: var(--text-color-primary);
          }
        }
      }
    }
  }
}

.rental-plandata-info-overlay {
  max-width: 400px;
  padding: var(--p-3);
  .action {
    display: flex;
    justify-content: center;
  }
}
