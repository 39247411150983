.business-unit-selection-component.bf-dropdown.sidenav-dropdown {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  border-bottom: 1px var(--border-color-primary) solid;
  width: 100%;
  font-size: var(--font-size-md);
  white-space: normal;
  line-height: 1.25;
  text-align: left;
  position: relative;

  .rs-dropdown-toggle.rs-sidenav-item {
    padding-left: var(--p-5) !important;
    text-align: center;
    font-size: var(--font-size-md);
    line-height: 1.25;
    padding-left: var(--p-28);
    white-space: normal;
    width: 100%;
    height: auto;
    padding-right: var(--p-20);
    background: transparent;

    svg.rs-dropdown-toggle-caret {
      left: initial;
      right: 17px;
      top: 17px;
    }
  }

  //   margin-bottom: var(--m-5);
}
