.application-sidebar {
  background: var(--background-color-contrast);
  height: 100%;
  position: relative;
  border-left: solid 1px var(--border-color-secondary);
  --sidebar-header-height: 60px;

  .application-sidebar-header {
    height: var(--sidebar-header-height);
    background: var(--background-color-primary);
    border-bottom: solid 1px var(--border-color-secondary);
    padding: var(--p-5);
    display: flex;
    align-items: center;

    .application-sidebar-title {
      font-size: var(--font-size-md);
      flex-grow: 1;
    }
    .application-sidebar-close {
      display: flex;
    }
  }
  .application-sidebar-content {
    height: 100%;
  }
  &.with-header {
    .application-sidebar-content {
      height: calc(100% - var(--sidebar-header-height));
    }
  }
}
