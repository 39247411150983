.cb-idly-search-further {
  .head {
    display: flex;

    padding-bottom: var(--p-5);
    border-bottom: solid 1px var(--border-color-primary);

    .search-input {
      flex: 1;
      .bf-input {
        margin-bottom: var(--m-3);
      }
      .helper-tags {
        padding-top: var(--p-1);
        font-size: var(--font-size-sm);
        .describer {
          font-weight: bold;
          color: var(--text-color-disabled);
          margin-right: var(--m-3);
        }
        .token {
          color: var(--rs-btn-link-text);
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .search-result {
    height: 60vh;
    overflow: hidden;
  }
}
