.contact-label {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  .business-type {
    font-size: 0.7em;
    color: var(--text-color-secondary);
    padding-right: var(--p-3);
  }
}

.contact-view-container {
  padding: var(--p-5);
  z-index: 9999999;
}
