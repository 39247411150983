.bf-table-filter-entry {
  z-index: 7;
  .button-wrapper {
    display: flex;
    > .bf-dropdown {
      > button.bf-button {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;

        .filter-entry-label {
          display: flex;
          align-items: center;

          .type-label {
            color: var(--text-color-secondary);
            font-size: var(--font-size-sm);
            font-weight: 500;
            margin-right: var(--m-3);
          }
          .value-label {
            display: inline-block;
            max-width: 140px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: var(--font-size-sm);
            // color: var(--text-color-primary);
            // font-size: var(--font-size-sm);
            // font-weight: 500;
            // margin-right: var(--m-3);
          }
        }
      }
    }
    > button.delete-button {
      border-left: solid 1px var(--border-color-primary);
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      padding: var(--p-4);
      &:focus {
        box-shadow: none;
      }
      .bf-icon {
        width: 10px;
        height: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
