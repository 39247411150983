.home-view {
  min-height: 100%;
  width: 100%;
  height: 100%;
  overflow: auto;

  .background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--login-background); //url("/img/background.jpg");
    transition: background-color 0.2s;
    background-size: cover;
    z-index: -1;
  }
}
