.connect-document-form {
  border: 1px solid var(--rs-border-primary);

  .actions {
    display: flex;
    justify-content: center;
  }
  .element {
    padding: var(--p-3);
    border-bottom: solid 1px var(--border-color-primary);
    display: flex;
    align-items: center;
    .data {
      padding-left: var(--p-5);
      flex-grow: 1;
    }
  }
}
