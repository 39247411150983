.ra-update-invoice-entry {
  .invoice-row {
    display: flex;
    padding-bottom: var(--p-5);

    .invoiceType {
      .add-costcenter-button-container {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        padding-right: var(--p-15);
      }
      .invoice-type-menu-item {
        display: flex;
        justify-content: center;
        align-items: center;
        .label {
          flex-grow: 1;
        }
      }
    }

    .invoice-field {
      padding-left: var(--p-5);
      @media screen and (max-width: 1550px) {
        padding-left: var(--p-0);
        padding-bottom: var(--p-5);
      }

      &:first-of-type {
        padding-left: var(--p-0);
      }
    }
    &.row-0 {
      .invoiceType {
        flex: 1 0 42%;
      }
      .amountToPay {
        width: calc(58% - 165px);
      }
      .documentDate {
        width: 165px;
      }
    }
    &.row-1 {
      .paymentType {
        flex: 1 0 42%;
      }
      .invoiceId {
        flex: 1 0 58%;
      }
    }
    &.row-2 {
      .entity {
        flex: 1 0 42%;
      }
      .paymentRecipient {
        flex: 1 0 58%;
      }
    }
    &.row-3 {
      .objectId {
        flex: 1 0 42%;
      }
      .projectId {
        flex: 1 0 58%;
        padding-right: var(--p-15);
        overflow-x: clip;
        .label {
          font-weight: 600;
        }
        .ez-asset-select.activity-select {
          width: 100%;
          max-width: 100%;
          .select-dropdown.rs-dropdown.rs-dropdown-placement-bottom-start {
            width: 100%;
            max-width: 100%;
            > button.rs-dropdown-toggle.rs-btn.rs-btn-default {
              width: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
    &.row-6 {
      .usage {
        width: 42%;
      }
      .iban {
        flex: 1;
      }
      .paymentDueDate {
        width: 165px;
      }
    }

    @media screen and (max-width: 1550px) {
      flex-direction: column;
      > div {
        width: 100% !important;
      }
    }
  }

  .customfields {
    .action-container {
      display: flex;
      justify-content: center;
      align-items: center;
      .add-button {
        .bf-icon {
          margin-right: var(--m-3);
        }
      }
    }
    .customfields-header {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      button.bf-button {
        .bf-icon {
          margin-right: var(--m-3);
          width: 16px;
          height: 16px;
          transition: transform 0.4s ease;
        }
        &.collapsed {
          .bf-icon {
            transform: scaleY(-1);
          }
        }
      }
    }
    .additional-fields {
      padding-top: var(--p-5);
      .empty-text {
        display: flex;
        padding: var(--p-10);
        justify-content: center;
        align-items: center;
        text-align: center;
        color: var(--text-color-secondary);
      }
      .additional-field {
        display: flex;
        align-items: center;
        padding-bottom: var(--p-3);
        .label {
          min-width: 175px;
          text-align: right;
          padding-right: var(--p-5);
        }
        .value-field {
          flex-grow: 1;
        }
      }
    }
  }
  .tags {
    border-top: solid 1px var(--border-color-primary);
    padding-top: var(--p-3);
    margin-top: var(--m-3);
  }
}
