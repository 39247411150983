.comment-attachments {
  border-top: solid 1px var(--border-color-secondary);

  .comment-attachment-entry {
    width: 100%;
    background: transparent;
    align-items: center;
    justify-content: flex-start;
    .icon {
      margin-right: var(--m-5);
    }
    border-bottom: solid 1px var(--border-color-primary);
    &:last-child {
      border-bottom: none;
    }
  }
}
