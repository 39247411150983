.contact-base-address {
  .iban {
    font-size: var(--font-size-md);
  }
  .info-label {
    font-size: var(--font-size-sm);
    padding-right: var(--p-5);
    color: var(--text-color-secondary);
  }
}
