.rs-details-component {
  padding: var(--p-5);

  .rs-details-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--p-3);
    align-items: center;
    .rs-details-title {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 10px;

      .rs-details-status {
        &.positive {
          .BFTag {
            background: var(--color-positive);
            color: var(--text-color-contrast);
          }
        }
        &.warning {
          .BFTag {
            background: var(--color-warning);
            color: var(--text-color-contrast);
          }
        }
        &.negative {
          .BFTag {
            background: var(--color-negative);
            color: var(--text-color-contrast);
          }
        }
      }
    }

    .rs-details-actions {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
  }

  .rs-details-row {
    margin-bottom: var(--m-5);
    display: flex;
    gap: 10px;

    &.asset-details {
      .labeled-input {
        width: 100%;
      }
    }

    &:last-of-type {
      margin-bottom: var(--m-0);
    }

    .rs-details-title {
      width: 40%;
    }

    .weekday-tags {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;

      .BFTag {
        .text {
          padding: 5px 8px;
        }
      }
    }

    .priority-select {
      .label {
        color: var(--text-color-contrast);
      }
    }
  }
}
