.permissions-form-field {
  margin-top: var(--m-15);
  .header {
    display: flex;
    align-items: center;
    // border-bottom: solid 1px var(--divider-color);
    padding-bottom: var(--p-3);
    margin-top: var(--m-8);
    .title {
      flex-grow: 1;
      // font-weight: 500;
      color: var(--text-color-strong);
      font-size: 1.1em;
      font-weight: 500;
    }

    .actions {
    }
  }

  .content {
    border-top: solid 1px var(--divider-color-dark);
    border-bottom: solid 1px var(--divider-color-dark);
    padding-left: var(--p-5);
    padding-right: var(--p-5);
    margin-bottom: var(--m-15);
    overflow: auto;

    .no-entries-label {
      padding: var(--p-5);
      display: flex;
      justify-content: center;
      color: var(--text-color-disabled);
    }
    &.max-height {
      max-height: 300px;
    }

    .rs-list {
      box-shadow: none;
    }
  }
}

.PermissionFormField-Modal-Appselection {
  .logo {
    margin-right: var(--m-5);
    width: 35px;
    height: 35px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}
