.mail-viewer-container {
  flex-grow: 1;
  background-color: var(--base-color-foreground);
  border-radius: var(--border-radius-base);
  max-height: 85vh;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: center;
    min-height: 35px;
    flex-direction: column;
    background-color: var(--background-color-primary);
    border-radius: var(--border-radius-base);
    border-bottom: solid 1px var(--border-color-secondary);
  }

  .print-button {
    position: absolute;
    right: 10px;
  }

  .print {
    display: none;
    @media print {
      display: block;
    }
  }

  .sender,
  .recipients,
  .date,
  .cc,
  .bcc,
  .subject,
  .attachments {
    display: flex;
    align-items: center;
    background-color: var(--background-color-primary);
    padding-left: var(--p-5);
    padding-right: var(--p-5);

    .label {
      min-width: 90px;
      text-align: right;
      color: var(--text-color-secondary);
      padding-right: var(--p-5);
    }
    .value {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .bf-status {
      font-weight: 400;
      color: var(--text-color-primary);
    }
  }

  .sender {
    padding-top: var(--p-5);
    border-radius: var(--border-radius-base);
  }
  .subject {
    padding-bottom: var(--p-5);
  }

  .htmlBody {
    flex-grow: 1;
    overflow: auto;
  }

  .attachment-button {
    display: flex;
    align-items: center;
    .bf-icon.attachment-icon {
      margin-right: var(--m-2);
    }
    .bf-icon.dropdown-ident {
      margin-left: var(--m-4);
      width: 12px;
      height: 12px;
      display: flex;
      align-items: center;
      transition: transform 0.2s ease-in-out;
    }
  }

  .attachments-container {
    border-top: solid 1px var(--border-color-secondary);
  }

  .attachments {
    border-top: solid 1px var(--border-color-secondary);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-radius: var(--border-radius-base);

    .attachments-list {
      border-top: solid 1px var(--border-color-secondary);
      padding: 0 20px;
      width: 100%;

      &.collapsed {
        .attachment-entry {
          display: none;
        }
      }
    }

    .attachment-entry {
      width: 100%;
      display: flex;
      background: transparent;
      align-items: center;
      justify-content: flex-start;
      padding: 5px 0;
      .bf-icon {
        margin-right: var(--m-8);
      }
      border-bottom: solid 1px var(--border-color-primary);
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
