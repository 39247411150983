.flex-grow-container {
  position: relative;

  .flex-grow-container__inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
