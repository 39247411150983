.bf-value-display {
  .bf-value-display__label {
    color: var(--text-color-secondary);
    font-size: var(--font-size-sm);
  }
  .bf-value-display__value {
  }

  &.bf-value-display--negative {
    .bf-value-display__value {
      color: var(--color-negative);
    }
  }

  &.bf-value-display--positive {
    .bf-value-display__value {
      color: var(--color-positive);
    }
  }
}
