.ra-decline-invoice {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .decline-content {
    display: flex;
    flex-wrap: wrap;

    .process {
      width: 300px;
      border-right: solid 1px var(--border-color-secondary);
      margin-right: var(--m-10);
      @media screen and (max-width: 1000px) {
        display: none;
      }
    }
    .decline-form {
      min-width: 300px;
      flex-grow: 1;
      .title {
        font-size: var(--font-size-md);
        margin-bottom: var(--m-5);
      }
      .reason {
        margin-bottom: var(--m-5);
      }
      .additional-form {
        margin-bottom: var(--m-5);
        .assignment-wrapper {
          margin-top: var(--m-5);
        }
      }
      .comment {
        padding-bottom: var(--p-3);
      }
      .helper-tags {
        display: flex;
        flex-wrap: wrap;

        .bf-button.tag {
          border-radius: var(--border-radius-large);
          margin: var(--m-2);
          --base-color: #333;
        }
      }
    }
  }
  .actions {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-top: var(--p-5);
    button.bf-button {
      margin: var(--m-3);
    }
  }
}
