.kpi-detail-card {
  padding: var(--p-5);
  .head {
    display: flex;
    .title {
      font-size: var(--font-size-xl);
      font-weight: 300;
      flex-grow: 1;
      padding-right: var(--p-8);
    }
    .kpis {
      display: flex;
      flex-wrap: wrap;
      .kpi-entry {
        display: flex;
        flex-direction: column;
        padding-left: var(--p-5);
        .label {
          font-size: var(--font-size-sm);
          color: var(--text-color-secondary);
        }
        .value {
          font-size: var(--font-size-lg);
          font-weight: 400;
        }
      }
    }
    &[max-width~="1000px"] {
      .title {
        // font-size: var(--font-size-md);
      }
      .kpis {
        .kpi-entry {
          .label {
            font-size: var(--font-size-xs);
          }
          .value {
            font-size: var(--font-size-md);
          }
        }
      }
    }
    &[max-width~="700px"] {
      .title {
        // font-size: var(--font-size-base);
      }
      .kpis {
        .kpi-entry {
          .label {
            font-size: var(--font-size-xs);
          }
          .value {
            font-size: var(--font-size-base);
          }
        }
      }
    }
  }
  .sub-head {
    padding-top: var(--p-5);
  }
  .center {
    padding-top: var(--p-5);
  }
  .error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .message {
      color: var(--color-negative);
    }
    .bf-button {
      margin-top: var(--m-5);
    }
  }
  .foot {
    padding-top: var(--p-5);
  }
}
