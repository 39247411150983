.bf-gol_bf-grouped-order-list {
  padding: var(--p-5);

  .add-group-button {
    // margin-top: var(--m-5);
    display: flex;
    justify-content: center;
  }
}
.bf-gol_order-list-group {
  //   padding: var(--p-5);
  margin-bottom: var(--m-10);
  background: var(--background-color-contrast);
  border: solid 1px var(--border-color-secondary);
  border-radius: var(--border-radius-base);

  &.clone {
    box-shadow: 0 1px 3px 0px var(--color-neutral);
  }
  .list-group-header {
    padding: var(--p-5);
    width: 100%;
    display: flex;
    align-items: center;
    .icon {
      padding-left: var(--p-4);
    }
    .ez-textfield {
      flex: 1;
    }
    border-bottom: solid 1px var(--border-color-secondary);
  }
  .group-edit-form {
    padding: var(--p-5);
    border-top: solid 1px var(--border-color-secondary);
  }
}

.bf-gol_order-list-entry-list {
  //   padding: var(--p-5);
  .drop-zone {
    position: relative;
    min-height: 40px;
    .empty-indicator {
      z-index: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: var(--p-4);
      color: var(--text-color-secondary);
    }

    .bf-gol_item-entry {
      z-index: 1;
      position: relative;
    }
  }
}

.bf-gol_item-entry {
  padding: var(--p-5);
  background: var(--list-row-background);
  border-bottom: solid 1px var(--border-color-primary);

  &.clone {
    box-shadow: 0 1px 3px 0px var(--color-neutral);
  }

  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  .entry-content {
    flex: 1 0;
  }
  .icon {
    padding-left: var(--p-4);
  }
}
