.cb-idly-create-rule-component {
  .cb-idly-create-rule {
    .form-preview-container {
      display: flex;
      flex-direction: column;
      .cash-budget-entry {
        padding-bottom: var(--p-5);
        border-bottom: 1px solid var(--border-color-primary);
      }

      .fields {
        padding-top: var(--p-5);
        flex: 1 0;
        .__form-row {
          .__field {
            &.usage-input {
              width: 100%;
              .label {
                .suffix {
                  padding-left: var(--p-2);
                }
              }
            }

            .recipient-error {
              color: var(--color-negative);
            }
          }

          .remove-additional-field-btn {
            margin-left: auto;
          }
        }
        .add-actions {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: 5px;
          padding-bottom: var(--p-5);
          padding-top: var(--p-10);
          .bf-button {
            border-radius: var(--border-radius-extra-large);
          }
        }
      }
    }
  }
}
