.contact-search-tag-input {
  .bf-tag-input {
    border: none;
    &:has(input:focus-visible) {
      outline: none;
    }
  }

  .contact-search-result-entry {
    .contact-name-container {
      display: flex;
      padding: 5px 0;
      margin: var(--m-0) var(--m-3);
      background: var(--list-row-background);
      border-bottom: solid 1px var(--border-color-secondary);

      .contact-name {
        margin-left: var(--m-3);
        font-weight: 600;
      }
    }

    .list-entry-component {
      &.mail-added {
        background: var(--background-color-primary);
        .bf-button {
          cursor: not-allowed;
        }

        &:has(.selected) {
          background: hsl(207, 30%, 80%);
        }
      }
      .mail-address-container {
        display: flex;

        .mail-address {
          margin-left: var(--m-5);
        }
      }

      &:last-child {
        border-bottom: solid 2px var(--border-color-secondary);
      }
    }
  }
}
