.damange-claim-upload-offer-form {
  height: 85vh;
  max-height: 100%;
  overflow: hidden;

  form {
    height: 100%;
    .form-wrapper {
      display: flex;
      height: 100%;

      .pdf-viewer {
        flex: 1;
        height: 100%;
        overflow: hidden;
        border: solid 1px var(--border-color-secondary);
      }

      .data-form {
        overflow: auto;
        max-height: 100%;
        padding-left: var(--p-10);
        padding-right: var(--p-5);
        width: 300px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        .field {
          padding-bottom: var(--p-5);
        }
        .info-box {
          padding: var(--p-5);
          background: var(--background-color-primary);
          color: var(--color-prio-low);
          font-size: var(--font-size-sm);
          border-radius: var(--border-radius-base);
        }
        .comparisons {
          .comparison-entries {
            .row {
              display: flex;
              gap: 10px;
            }
            .comparison-entry {
              border-bottom: 1px solid var(--border-color-secondary);
              margin-bottom: var(--m-5);

              &:last-of-type {
                border-bottom: none;
              }
              .remove-action {
                padding-bottom: var(--p-5);
                display: flex;
                justify-content: flex-end;
              }
            }
          }
          .add-action {
            display: flex;
            justify-content: center;
          }
        }

        .actions {
          display: flex;
          justify-content: flex-end;
          .bf-button {
            margin-left: var(--m-5);
          }
        }
      }
    }
  }
}
