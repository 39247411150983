.bf-breadcrumb {
  display: flex;
  align-items: center;
  margin-bottom: var(--m-8);

  .breadcrumb-pre-icon {
    color: var(--icon-color-secondary);
    margin-right: var(--m-3);
  }
  .rs-breadcrumb {
    margin-bottom: var(--m-0);

    .rs-breadcrumb-item {
      font-size: var(--font-size-md);
      font-weight: 600;
      color: var(--rs-btn-link-text);
      &.rs-breadcrumb-item-active {
        color: var(--text-color-primary);
      }
    }
    .rs-breadcrumb-separator {
      font-size: var(--font-size-md);
      font-weight: 600;
      color: var(--text-color-primary);
      margin: var(--m-0) var(--m-3);
    }
  }
}
