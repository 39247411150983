.risk-scenario-card {
  padding: var(--p-10);
  margin-bottom: var(--m-10);
  .head-line {
    display: flex;
    justify-content: space-between;
    padding-bottom: var(--p-3);
    .title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      font-size: var(--font-size-md);
    }
    .actions {
      flex-shrink: 0;
    }
  }
  .description {
  }
  .data {
    padding-bottom: var(--p-10);
    padding-top: var(--p-10);
    display: flex;
    flex-wrap: wrap;
  }
}
