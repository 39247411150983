.ap-list {
  .ap-card-header {
    display: flex;
    padding-bottom: var(--p-5);
  }
  .ap-list-header {
    display: flex;
    .ap-list-header-left {
      flex-grow: 1;
      padding-left: var(--p-10);
    }
    .ap-list-header-right {
      display: flex;
      button {
        margin-left: var(--m-3);
      }
    }
  }
  .insurance-positions {
    display: flex;
    max-width: 100%;
    gap: 10px;
    .insurance-position {
      .label {
        font-weight: 500;
        font-size: var(--font-size-xs);
        color: var(--text-color-secondary);
      }
      .value {
        font-size: var(--font-size-sm);
      }
    }
  }
}

.ap-list-relation-wrapper {
  width: 100%;
  overflow: hidden;
  .object-related-label,
  .object-related-list-label {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
