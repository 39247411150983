.debug-data-button {
	display: inline-flex;
	.debug-icon {
		animation-name: debug-icon-anim;
		animation-duration: 4s;
		transform-origin: 75% 50%;
	}
}

@keyframes debug-icon-anim {
	from {
		transform: translateX(-25%) rotateZ(0deg);
	}
	to {
		transform: translateX(0%) rotateZ(360deg);
	}
}
