.file-preview-component {
  display: flex;
  justify-content: center;
  align-items: center;

  .bf-button.pdf-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .label {
      padding-top: var(--p-3);
    }
  }
  .image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }
  .file-preview-component {
    .pdf-preview {
      border: solid 1px var(--border-color-secondary);
    }
  }
}
