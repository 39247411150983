.ganttVerticalContainer {
  overflow: hidden;
  font-size: 0;
  margin: var(--m-0);
  padding: var(--p-0);
}

.horizontalContainer {
  margin: var(--m-0);
  padding: var(--p-0);
  /* overflow-y: clip; */
  /* padding-bottom: var(--p-20); */
}

.wrapper {
  display: flex;
  padding: var(--p-0);
  margin: var(--m-0);
  list-style: none;
  outline: none;
  position: relative;
}
