.pdf-constructor--templates-manager {
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      .template-actions {
        display: flex;
        gap: 0.5rem;
      }
    }
  }
}
