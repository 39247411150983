.linked-activities-list {
  padding: var(--p-5);
  margin-bottom: var(--m-10);

  .activity-title {
    font-size: var(--font-size-lg);
    // font-weight: bold;
    padding-bottom: var(--p-5);
    display: flex;
    align-items: center;
    .title {
      flex-grow: 1;
    }
    .actions {
    }
  }
  .activity-entry {
    padding: var(--p-5);

    display: flex;
    border-bottom: solid 1px var(--border-color-primary);
    &:hover {
      background-color: var(--table-row-color-hover);
    }
    .left {
      flex-grow: 1;
      .id {
        font-size: var(--font-size-base);
        color: var(--text-color-secondary);
      }
      .title {
        font-size: var(--font-size-md);
        padding-bottom: var(--p-3);
      }
      .description {
        color: var(--text-color-primary);
        font-size: var(--font-size-sm);
      }
      .sub-data {
        padding-top: var(--p-5);
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }
    }
    .right {
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
    }
  }
}
