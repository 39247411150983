.cell.cell-value {
  overflow: hidden;
  text-overflow: ellipsis;
  &.bold {
    font-weight: 600;
  }
}

.cell.asset-cell {
  max-width: 100%;
  button.bf-button {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    .link-text {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.progress-cell {
  display: flex;
  width: 100%;
  .progress {
    padding-right: var(--p-3);
    min-width: 80px;
    text-align: right;
    &.error {
      color: var(--color-negative);
      font-weight: 500;
    }
  }
  .progress-bar {
    background: var(--background-color-secondary);
    flex-grow: 1;
    height: 20px;
    position: relative;
    border-radius: var(--border-radius-base);
    overflow: hidden;
    .progress-inner {
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      background: var(--color-positive);
    }
    .progress-inner-overflow {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      background: var(--color-negative);
    }
  }
}
