.list-view-pdf-drawer {
  .rs-drawer-header {
    .rs-drawer-header-close {
      top: 5px;
    }
  }
  .pdf-view-container {
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }
}
.list-view-pdf {
  position: relative;
  display: flex;
  height: 100%;

  --pdf-viewer-max-width: 900px;
  --pdf-viewer-width: 50%;

  &.drawer-view {
    --pdf-viewer-max-width: 100%;
    --pdf-viewer-width: 100%;
    .form-wrapper {
      padding: var(--p-10);
    }
  }
  &.split-view {
    --pdf-viewer-max-width: 900px;
    --pdf-viewer-width: 50%;

    > .form-wrapper > .forms-table > .bf-list > .list-scroll > .empty-text {
      width: var(--pdf-viewer-width);
      max-width: var(--pdf-viewer-max-width);
    }
    > .form-wrapper > .forms-table > .bf-list > .loading-overlay {
      width: var(--pdf-viewer-width);
      max-width: var(--pdf-viewer-max-width);
    }
    > .form-wrapper > .forms-table > .bf-list > .loading-bottom-container {
      left: calc(450px - 20px);
    }
  }
  > .form-wrapper {
    width: 100%;
    flex-grow: 2;
    padding: 20px 0px 0px 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    > .header {
      width: var(--pdf-viewer-width);
      max-width: var(--pdf-viewer-max-width);
      padding-bottom: var(--p-5);
    }

    .forms-table {
      flex-grow: 1;
      overflow: hidden;
      & > .bf-list {
        .show-new-data-warning-wrapper {
          right: initial;
          width: var(--pdf-viewer-width);
          left: 0;
          max-width: var(--pdf-viewer-max-width);
        }
        & > .no-data-component,
        & > .table-loader {
          width: var(--pdf-viewer-width);
          max-width: var(--pdf-viewer-max-width);
          z-index: 2;
        }
        .entry-wrapper {
          &.split-view {
            width: var(--pdf-viewer-width);
            max-width: var(--pdf-viewer-max-width);
          }

          .list-view-pdf-entry {
            position: relative;
            width: 100%;
            margin-bottom: var(--m-10);
            background: var(--list-row-background);

            &.selected {
              z-index: 1;
            }
            &.type-highlight {
              cursor: pointer;
              :hover {
                background: var(--background-color-contrast);
              }
              &.selected {
                background: var(--table-row-color-hover);
                :hover {
                  background: var(--table-row-color-hover);
                }
              }
            }
            > .transition-to-pdf {
              position: absolute;
              left: calc(100% - 10px);
              width: 40px;
              z-index: 2;
              top: 0px;
              bottom: 0px;
              background: var(--background-color-contrast);
            }
          }
        }
      }
    }
  }
  > .pdf-wrapper {
    position: absolute;
    margin-right: var(--m-5);
    top: 0px;
    right: 0px;
    bottom: 0px;
    min-width: calc(50% - 10px);
    width: calc(100% - 930px);
    padding: var(--p-10);
    display: flex;
    flex-direction: column;
    .pdf-card {
      padding: var(--p-10);
      display: flex;
      flex-grow: 1;
      height: 0;
      justify-content: center;
      align-items: center;
      .scroll-wrapper {
        width: 100%;
        height: 100%;
        border: solid 1px var(--border-color-tertiary);

        .empty-text-container {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20%;
          .message {
            font-size: var(--font-size-lg);
            color: var(--text-color-secondary);
            text-align: center;
          }
        }
      }
    }
  }
}
