@use "sass:math";
$height: 30px;
$height-sm: 20px;

@mixin bf-tag-size($height) {
  min-height: $height;
  height: $height;
  border-radius: math.div($height, 2);
  line-height: $height;
  .icon {
    border-radius: math.div($height, 2);
    height: $height;
    width: $height;
  }
}

@mixin close-button-size($height) {
  height: $height;
  &.bf-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: math.div($height, 2);
    border-bottom-right-radius: math.div($height, 2);
    .bf-icon {
      width: calc(math.div($height, 2) - 2px);
      height: calc(math.div($height, 2) - 2px);
    }
  }
}

.BFTag {
  display: flex;
  background: var(--background-color-secondary);
  color: var(--text-color-primary);
  align-items: center;

  &.success {
    background: var(--color-positive);
    * {
      color: var(--text-color-contrast);
    }
  }
  &.warning {
    background: var(--color-warning);
    * {
      color: var(--text-color-contrast);
    }
  }
  &.error {
    background: var(--color-negative);
    * {
      color: var(--text-color-contrast);
    }
  }
  .icon {
    background: var(--background-color-secondary);
    color: var(--icon-color-contrast-inverse);
    padding: var(--p-3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text {
    flex-grow: 1;
    padding: 5px 10px 5px 10px;
    white-space: nowrap;
    &.has-icon {
      padding: 5px 10px 5px 5px;
    }

    &.has-close-icon {
      padding-right: var(--p-4);
    }
  }

  @include bf-tag-size($height);

  .close-button {
    @include close-button-size($height);
    .bf-button {
      padding: 2px 6px;
    }
  }

  &.sm {
    align-items: center;
    .icon {
      padding: 3px 1px 3px 5px;
      align-items: flex-start;
    }

    .text {
      padding: 0 8px;
      font-size: var(--font-size-sm);
      &.has-icon {
        padding: 0 8px 0 5px;
      }

      &.has-close-icon {
        padding-right: var(--p-2);
      }
    }

    @include bf-tag-size($height-sm);

    .close-button {
      @include close-button-size($height-sm);
      .bf-button {
        align-items: start;
        padding: 0 4px;
      }
    }
  }
}
