.bf-assignment-search-field,
.bf-assignment-search-field-as-overlay {
  .rs-dropdown-menu {
    width: fit-content !important;
  }
  .bf-dropdown {
    min-width: 100%;
    .add-assignment {
      padding: var(--p-0);
      width: 100%;
    }
  }
  &.has-value {
    .bf-dropdown {
      .add-assignment {
        padding: 8px 0px 2px 0px;
      }
    }
  }
  .search-overlay {
    min-width: 300px;
    border-radius: var(--border-radius-base);
    overflow: hidden;
    .search-input {
      .bf-input {
        border: none !important;
        outline: none !important;
      }
      .prefix {
        padding-right: var(--p-0);
      }
    }
    .search-result {
      border-top: solid 1px var(--border-color-secondary);
      .no-result {
        padding: var(--p-5);
        text-align: center;
        color: var(--text-color-secondary);
      }
      .bf-button.list-element {
        width: 100%;
        border-radius: var(--border-radius-none);
        justify-content: flex-start;
        background: var(--list-row-background);
        box-shadow: none;
        &.selected {
          background: var(--table-row-color-hover);
        }
      }
    }
    .section {
      .section-title {
        font-size: var(--font-size-md);
        border-bottom: solid 1px var(--border-color-primary);
        padding: 5px 10px;
      }
      .entries {
        // padding: var(--p-5);
      }
    }
  }
}

.assignment-entry {
  padding: var(--p-3);
  display: flex;
  align-items: center;
  .prefix {
    padding-right: var(--p-5);
    width: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .assignment-label {
    flex-grow: 1;
  }
}
