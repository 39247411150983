.bf-pdf-marker-section {
  --border-color: var(--border-color-secondary);
  position: relative;

  .bf-pdf-marker {
    z-index: 1;
    .action-container {
      .marker-title {
        z-index: 1;
        margin-left: var(--m-5);
        background: var(--background-color-contrast);
        padding-left: var(--p-3);
        padding-top: var(--p-2);
        padding-bottom: var(--p-2);
        padding-right: var(--p-3);
      }
      .pdf-marker-comp {
        background: var(--background-color-contrast);
      }
      padding-right: var(--p-5);
    }
  }
  .section-content-wrapper {
    padding: 0px 10px 10px 10px;
  }

  .border-line {
    position: absolute;
    top: 14px;
    left: 0px;
    width: 100%;
    bottom: 0;
    border-radius: var(--border-radius-base);
    border: solid 2px var(--border-color);
    pointer-events: none;
    z-index: 0;
  }
}
