.progress-modal {
  padding: var(--p-10);
  .animation-container {
    display: flex;
    justify-content: center;
  }
  .info {
    margin-bottom: var(--m-5);
    max-width: 400px;
    text-align: center;
  }
  .progress-count {
    text-align: center;
  }
  .actions {
    display: flex;
    gap: 10px;
    padding-top: var(--p-5);
  }
}
