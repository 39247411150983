.cb-rental-agreement-rent-adjustment {
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .card-header {
    display: flex;
    padding: var(--p-5);
    .title {
      flex-grow: 1;
    }
    .button-container {
    }
  }
  .card-content {
    flex-grow: 1;
  }

  .relation-wrapper {
    width: 100%;
    overflow: hidden;
    .object-related-label,
    .object-related-list-label {
      display: block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
