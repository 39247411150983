.ap-construction-diaries-entries {
  margin-bottom: var(--m-5);
  .entry-title {
    font-weight: 500;
    border-bottom: solid 1px var(--border-color-primary);
  }
  .entries-content {
    .empty-text {
      padding: var(--p-5);
    }
    .add-action {
      padding-top: var(--p-3);
      display: flex;
      justify-content: center;
    }

    .documentation-entry {
      border: solid 1px var(--border-color-primary);
      border-radius: var(--border-radius-base);
      margin-top: var(--m-5);
      // padding-top: var(--p-5);
      // padding-bottom: var(--p-5);
      // border-bottom: solid 1px var(--border-color-primary);

      .head {
        background: var(--background-color-contrast);

        padding: var(--p-5);
        .group {
          display: flex;
          gap: 10px;

          .more {
            flex-grow: 1;
          }
        }

        .actions {
          padding-top: var(--p-5);
          display: flex;
          justify-content: flex-end;
        }
      }
      .content {
        padding: var(--p-5);

        .progress-input {
          padding-bottom: var(--p-5);
          display: flex;
          gap: 10px;
          .slider {
            flex-grow: 1;
            padding-top: var(--p-18);
          }
        }
      }
      .image-section {
        padding-top: var(--p-5);
        .images {
          border: solid 1px var(--border-color-primary);
          overflow: hidden;
          border-radius: var(--border-radius-base);
          .empty-text {
            padding-bottom: var(--p-0);
          }

          .images-container {
            display: flex;
            flex-wrap: wrap;
            .image-preview {
              position: relative;

              .info-indicator {
                position: absolute;
                top: 0;
                left: 0;
                background: var(--background-color-light-base);
                color: var(--text-color-contrast);
                border-bottom-right-radius: 50%;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                .bf-icon {
                  width: 10px;
                  height: 10px;
                }
              }
            }
          }
          .image-entry {
            display: flex;
            padding-top: var(--p-3);
            padding-left: var(--p-3);
            padding-right: var(--p-3);
            padding-bottom: var(--p-3);
            border-bottom: solid 1px var(--border-color-primary);
            gap: 10px;
            .image-data {
              flex-grow: 1;

              .image-actions {
                display: flex;
                justify-content: flex-end;
              }
            }
          }
          .upload-action {
            display: flex;
            justify-content: center;
            padding-top: var(--p-3);
          }
        }
      }
    }
  }
}
