.notifications-dropdown {
  .rs-btn.rs-btn-subtle.rs-dropdown-toggle {
    overflow: visible;
    position: relative;

    .font-color-white {
    }

    .async-task-loader {
      position: absolute;
      bottom: 0px;
    }
  }

  .rs-dropdown-menu {
    border-radius: var(--border-radius-big);
    position: fixed;
    right: 0px !important;
    top: var(--header-height) !important;
    max-height: calc(100% - var(--header-height));
    overflow: auto;
    @media screen and (max-width: 450px) {
      width: 95%;
    }
  }
}
