.comment-entry {
  padding-bottom: var(--p-10);

  blockquote {
    border-left: solid 4px var(--border-color-secondary);
    padding-left: var(--p-5);
    margin-left: var(--m-0);
    margin-right: var(--m-0);
    margin-top: var(--m-0);
    margin-bottom: var(--m-0);
    padding-bottom: var(--p-2);
    padding-top: var(--p-2);
    font-size: var(--font-size-base);
    color: var(--text-color-primary);
  }
  h1 {
    font-size: var(--font-size-lg);
    line-height: 20px;
  }

  .system-comment {
    display: flex;
    align-items: center;
    .line {
      flex-grow: 1;
      height: 1px;
      background: var(--background-color-secondary);
    }
    .comment-content {
      padding: var(--p-5);

      .comment-text {
        color: var(--text-color-primary);
        text-align: center;
        font-weight: 600;
        .state-change {
          display: flex;
          align-items: center;

          .label {
            padding-right: var(--p-4);
          }
        }
      }

      .meta {
        display: flex;
        justify-content: center;
        color: var(--text-color-primary);
        padding-top: var(--p-2);
        font-size: var(--font-size-sm);
        system {
          padding-right: var(--p-3);
        }
        .user {
          padding-right: var(--p-3);
          display: flex;
          .avatar {
            margin-right: var(--m-3);
          }
        }
        .timestamp {
          padding-left: var(--p-3);
        }
      }
    }
  }
}
