.comments-module {
  display: flex;
  flex-direction: column;

  height: 100%;

  .sort-label {
    display: flex;
    align-items: center;
    .bf-icon {
      margin-right: var(--m-2);
      width: 18px;
      height: 18px;
    }
  }
  .additional-actions {
    padding-bottom: var(--p-10);
    display: flex;
    justify-content: flex-end;
  }

  .new-comment-input {
    // height: 400px;
    padding-bottom: var(--p-5);
    border-bottom: solid 1px var(--border-color-secondary);
  }
  .comment-list-wrapper {
    flex-grow: 1;
    overflow: hidden;
    .bf-dropzone {
      height: 100%;
    }
  }
}
