.bf-loan-confirm-dialog {
  .__field {
    .rs-checkbox {
      opacity: 0.5;
      transition: opacity 0.2s ease-in-out;
      .current {
        padding-left: var(--p-5);
        color: var(--text-color-secondary);
        text-decoration: line-through;
      }
      .value-field {
        padding-left: var(--p-5);
        font-weight: 500;
      }

      &.rs-checkbox-checked {
        opacity: 1;
      }
    }
  }
}
