.ap-offer-list {
  display: flex;
  height: calc(100% - 20px);
  flex-direction: column;
  overflow: hidden;

  .list-header {
    padding-bottom: var(--p-10);
  }
  .list-accordion-wrapper {
    flex-grow: 1;
    overflow: auto;
    .section-title {
      .count {
        padding-left: var(--p-5);
      }
    }

    .offer-entry {
      border: solid 1px var(--border-color-secondary);
      border-radius: var(--border-radius-base);
      margin-bottom: var(--m-8);
      padding: var(--p-8);
      &:last-of-type {
        margin-bottom: var(--m-0);
      }
    }
  }
}
