.contact-view {
  padding: 10px 15px 10px 10px;
  min-width: 350px;

  .contact-view-label {
    font-size: 14pt;
  }

  .actions {
    padding-bottom: var(--p-3);
    display: flex;
    justify-content: flex-end;
  }
  .name {
    font-size: var(--font-size-md);
  }

  .bank-account,
  .contact-person,
  .address {
    padding-bottom: var(--p-3);
    &:last-of-type {
      padding-bottom: var(--p-0);
    }
  }

  .contact-view-actions {
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
  }

  .contact-view-section {
    padding-bottom: var(--p-2);
    .section-title {
      font-size: var(--font-size-sm);
      color: var(--text-color-secondary);
      padding-bottom: var(--p-0);
    }
  }
}
