.email-address-fields {
  border-bottom: solid 1px var(--border-color-tertiary);
  .recipient {
    border-bottom: solid 1px var(--border-color-primary);
  }
  .cc,
  .bcc {
    border-bottom: solid 1px var(--border-color-primary);
  }
  .subject {
    &:focus-within {
      .label {
        color: var(--text-color-strong);
      }
    }
    .rs-input-group {
      border: none !important;
      border-radius: var(--border-radius-none) !important;
      box-shadow: none !important;
      outline: none !important;

      .rs-input-group-addon {
        color: var(--text-color-secondary);
        font-size: var(--font-size-sm);
        letter-spacing: 1px;
        padding: 11px 5px;
        min-width: 90px;
        .label {
          text-align: right;
          width: 100%;
        }
      }
      .rs-input {
        padding-left: var(--p-3) !important;
        outline: none !important;
      }
    }
  }
  .email-field {
    &:focus-within {
      .label {
        color: var(--text-color-strong);
      }
    }
    display: flex;
    align-items: center;
    .label {
      color: var(--text-color-secondary);
      min-width: 90px;
      text-align: right;
      font-size: var(--font-size-sm);
      letter-spacing: 1px;
      padding: 10px 5px;
    }
    .field-input {
      flex-grow: 1;

      .email-tag {
        &.invalid {
          background: var(--background-color-contrast);
          color: var(--color-negative);
        }
      }
      .rs-picker-search {
        .rs-picker-search-input {
          input {
            max-width: none !important;
          }
        }
      }
      > .rs-picker.rs-picker-tag {
        border: none;
        border-radius: var(--border-radius-none);
        box-shadow: none;
      }
    }
  }
}
