.tooltipDefaultContainer {
  background: var(--background-color-contrast);
  padding: var(--p-6);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.2);
}

.tooltipDefaultContainerParagraph {
  font-size: var(--font-size-sm);
  margin-bottom: var(--m-3);
  color: var(--text-color-primary);
}

.tooltipDetailsContainer {
  position: absolute;
  display: flex;
  flex-shrink: 0;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tooltipDetailsContainerHidden {
  visibility: hidden;
  position: absolute;
  display: flex;
  pointer-events: none;
}
