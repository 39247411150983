.history-viewer {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  .filters {
    display: flex;
    justify-content: center;
    border-bottom: solid 1px var(--border-color-primary);
    button.bf-button {
      padding-top: var(--p-4);
      padding-bottom: var(--p-4);
    }
  }
  .history-entries-scroll {
    flex-grow: 1;
    overflow: auto;

    .history-entries {
      position: relative;
      padding-bottom: var(--p-15);

      // .history-baseline {
      //   position: absolute;
      //   top: 40px;
      //   bottom: 30px;
      //   left: 29px;
      //   border-left: solid 3px var(--border-color-primary);
      // }

      .day-entry {
        padding-top: var(--p-8);
        padding-bottom: var(--p-3);
        padding-left: 70px;
        font-size: var(--font-size-sm);
        color: var(--text-color-secondary);
        position: relative;
        .line-vertical {
          position: absolute;
          top: 0px;
          left: 29px;
          border-right: solid 3px var(--border-color-primary);
          bottom: -10px;
        }
      }
      .history-entry {
        padding-left: var(--p-7);
        margin-bottom: var(--m-5);
        display: flex;
        position: relative;
        &:last-of-type {
          .line-vertical {
            display: none;
          }
        }

        .line-horizontal {
          position: absolute;
          top: 16px;
          left: 46px;
          border-bottom: solid 3px var(--border-color-primary);
          width: 22px;
        }
        .line-vertical {
          position: absolute;
          top: 34px;
          left: 29px;
          border-right: solid 3px var(--border-color-primary);
          bottom: -10px;
        }

        .icon-container {
          background: var(--background-color-primary);
          width: 34px;
          height: 34px;
          border-radius: var(--border-radius-rounded);
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          .bf-icon {
            width: 18px;
            height: 18px;
          }
        }

        .message-container {
          margin-left: var(--m-10);
          background: var(--background-color-contrast);
          border: solid 1px var(--border-color-primary);
          border-radius: var(--border-radius-base);
          padding: 5px 10px;
          .message {
          }
          .message-footer {
            display: flex;
            align-items: center;
            padding-top: var(--p-3);
            .user {
              display: flex;

              .name {
                color: var(--text-color-secondary);
                padding-left: var(--p-4);
                font-size: var(--font-size-sm);
              }
            }
            .time {
              color: var(--text-color-secondary);
              padding-left: var(--p-10);
              font-size: var(--font-size-sm);
            }
          }
        }
      }
    }
    .empty-text {
      padding: var(--p-10);
      text-align: center;
      color: var(--text-color-secondary);
    }
  }
}
