.cb-idly-connect-document {
  .document-data {
    .data-row {
      display: flex;
      gap: 15px;
      padding-bottom: var(--p-3);
    }

    .entry {
      .label {
        font-size: var(--font-size-sm);
        color: var(--text-color-secondary);
      }
      .value {
        white-space: normal;
        button {
          white-space: normal;
          text-align: left;
          justify-content: flex-start;
        }
      }
    }
    > .selected-documents {
      .no-documents-yet {
        text-align: center;
        padding: var(--p-3);
        color: var(--text-color-secondary);
        font-size: var(--font-size-sm);
      }
      .remove-document-container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    > .actions {
      display: flex;
      justify-content: center;
      padding-top: var(--p-3);
    }
  }
}
