.comment-input-attachments {
  max-height: 150px;
  overflow: auto;
  border-left: 1px solid var(--border-color-secondary);
  border-right: 1px solid var(--border-color-secondary);
  border-bottom: 1px solid var(--border-color-secondary);
  .added-files {
  }
  .attachment-entry {
    border-bottom: solid 1px var(--border-color-secondary);
    display: flex;
    .name {
      padding: 4px 8px;
      flex: 1 1 0;
    }
    .loader-container {
      padding-left: var(--p-2);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.upload {
      position: relative;
      .progress {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background: var(--base-color);
        opacity: 0.5;
      }
    }
  }
}
