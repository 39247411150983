.rs-rental-unit {
  .rs-asset-not-found {
    color: var(--color-negative);
  }
  .entry-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
    padding-bottom: var(--p-5);
    .entry {
      .label {
        font-size: var(--font-size-sm);
        color: var(--text-color-secondary);
      }
      .value {
        padding-left: var(--p-2);
        font-size: var(--font-size-md);
      }
    }
  }
}
