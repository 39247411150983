.cb-rental-agreement-form-wizard-step3-rental-units {
  width: 90vw;
  .form-center {
    display: flex;
    flex-direction: column;

    .form-fields {
      flex-grow: 1;
      .stacking-plan {
        height: 50%;
        border: solid 1px var(--border-color-tertiary);
        border-radius: var(--border-radius-base);
        overflow: hidden;
        margin-bottom: var(--m-10);
      }

      .bf-form-section {
        min-height: 100%;
      }
    }
  }
}
