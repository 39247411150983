.object-export-dialog {
  .sub-title {
    margin-left: var(--m-16);
    color: var(--text-color-secondary);
  }
  .sub-list {
    margin-left: var(--m-16);
    border: solid 1px var(--border-color-secondary);
    border-radius: var(--border-radius-base);
    padding: var(--p-5);
    .risk-entry {
      .risk-container {
        .description {
          color: var(--text-color-secondary);
          font-size: var(--font-size-sm);
          padding-top: var(--p-2);
        }
      }
    }
  }
  .risk-management-error {
    margin-left: var(--m-16);
    display: inline-block;
    background: var(--background-color-light-error);
    border-radius: var(--border-radius-base);
    padding: var(--p-5);
    margin-left: var(--m-16);
    margin-top: var(--m-5);
    color: var(--color-negative);
    font-size: var(--font-size-sm);
  }
}
