@import "../../../../styles/vars";

.bf-date {
  .rs-picker-date {
    background: var(--background-color-contrast);

    .rs-picker-toggle.active {
      box-shadow: var(--base-color) 0px 0px 5px;
    }
  }
  .rs-picker-menu {
    margin-bottom: var(--m-10);
  }
  .rs-picker-default .rs-picker-toggle {
    z-index: 1;
  }
  &.no-value {
    .rs-picker-menu.rs-picker-date-menu
      .rs-calendar-table-cell-selected
      .rs-calendar-table-cell-content {
      background: transparent;
      color: var(--text-color-strong);
    }
  }
  &.error {
    .rs-picker-toggle {
      border-color: var(--color-negative);
    }
  }
}
