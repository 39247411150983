.bf-numberic-slider {
  .slider-with-handle {
    margin-top: var(--m-9);

    .rs-slider-handle {
      top: -6px;
      background-color: var(--background-color-light-base);
      text-align: center;
      padding: var(--p-2);
      margin-left: -16px;
      cursor: pointer;
      transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;

      &:hover {
        box-shadow: 0 0 0 8px var(--shadow-numeric-slider-hover);
      }

      &::before {
        display: none;
      }
    }

    &.rs-slider-dragging .rs-slider-handle,
    &.rs-slider-dragging .rs-slider-handle:hover {
      box-shadow: none;
      transform: scale(1.2);
    }
  }
}
