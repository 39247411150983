.ap-task-construction-diaries-entries {
  .image-list-container {
    .cb-entry {
      padding-bottom: var(--p-5);
      .title {
        border-bottom: solid 1px var(--border-color-primary);
        display: flex;
        align-items: center;
      }
      .images {
        display: flex;
        padding-top: var(--p-3);
        gap: 5px;
        flex-wrap: wrap;
        > .image {
          position: relative;
          .info-indicator {
            position: absolute;
            top: 0;
            left: 0;
            background: var(--background-color-light-base);
            color: var(--text-color-contrast);
            border-bottom-right-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            .bf-icon {
              width: 10px;
              height: 10px;
            }
          }
        }
      }
    }
  }
}
