.mail-outgoing-status {
  display: flex;
  justify-content: center;
  align-items: center;
  .bf-icon {
    margin-right: var(--m-2);
    height: 18px;
    width: 18px;
  }
  .status-label {
    font-size: var(--font-size-sm);
  }
}
