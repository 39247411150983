.validation-warning-below {
  margin-top: var(--m-3);
  padding: 5px 10px 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-big);
  border: solid 1px var(--border-color-contrast);

  &.warning {
    color: var(--text-color-contrast);
    background-color: var(--color-warning);
  }

  .message {
    padding-left: var(--p-5);
  }
}
.validation-below {
  margin-top: var(--m-3);
}
.validation-popover {
  &.behind {
    display: flex;
  }
}

.rs-popover.validation-popover {
  pointer-events: none;
  // z-index: 5;
  padding: 2px 10px 2px 10px;
  //opacity: 0.4 !important;
  //margin-top: -3px !important;
  margin-left: 3px !important;

  &.error {
    background: var(--color-negative);
    color: var(--text-color-contrast);

    .rs-popover-arrow::after {
      border-bottom-color: var(--color-negative);
    }
  }

  &.warning {
    background: var(--color-warning);
    color: var(--text-color-contrast);

    .rs-popover-arrow::after {
      border-bottom-color: var(--color-warning);
    }

    overflow: hidden;

    &:hover {
      .message {
        display: block;
      }
    }

    .message {
      display: none;
    }
  }
}
