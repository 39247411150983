.bf-asset-chooser-select {
  .bf-asset-chooser-select-button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    border: 1px solid var(--rs-border-primary);
    background: var(--rs-input-bg);
    &.rs-btn-disabled {
      background: var(--background-color-primary);
    }
    .select-value {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      color: var(--rs-text-primary);
      &.placeholder {
        color: var(--text-color-secondary);
      }

      .sub-label {
        font-size: var(--font-size-sm);
        color: var(--text-color-secondary);
        padding-right: var(--p-3);
      }
    }
  }
}
.bf-asset-chooser-select-overlay {
  max-width: 500px;
}
