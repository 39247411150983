.user-modal-dialog {
  display: flex;
  height: 100%;
  .nav-bar {
    height: 100%;
    .rs-nav {
      height: 100%;
      .rs-nav-item {
        display: flex;
      }
    }
  }
  .profile-content {
    flex: 1 0 0;
    overflow: hidden;
  }
}
