.cb-tenant-rental-agreements {
  height: 100%;
  display: flex;
  padding-bottom: var(--p-10);
  flex-direction: column;
  .filter-row {
    padding-bottom: var(--p-5);
    border-bottom: solid 1px var(--border-color-tertiary);
  }
  .list-container {
    flex-grow: 1;
    overflow: hidden;
    .list-entry {
      margin-top: var(--m-10);
      padding: var(--p-10);
    }
  }
}
