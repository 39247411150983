.energy-efficiency-class-indicator {
  width: 30px;
  height: 22px;
  line-height: 16px;
  font-size: var(--font-size-md);
  font-weight: bold;
  color: var(--text-color-strong);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-base);
  border: solid 1px var(--border-color-contrast-inverse);
  &.xs {
    width: 18px;
    height: 16px;
    line-height: 12px;
    font-size: var(--font-size-sm);
  }
  &.sm {
    width: 22px;
    height: 18px;
    line-height: 14px;
    font-size: var(--font-size-base);
  }
}
