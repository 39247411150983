.ap-activity-details {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .foto-attachments {
    height: 380px;
    position: relative;
    flex-shrink: 0;
    .gallery-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--background-color-contrast);
      .swiper-button-prev,
      .swiper-button-next {
        color: var(--text-color-contrast);
      }

      .image-overlay {
        padding: 4px 18px;
        background: var(--background-color-contrast-inverse);
        color: var(--text-color-contrast);
        display: flex;
        font-size: var(--font-size-sm);
        justify-content: center;
      }
    }
  }

  .subtitle {
    border-top: solid 1px var(--border-color-primary);
    padding-top: var(--p-3);
  }

  .action-row {
    padding: var(--p-5);
    display: flex;
    .bf-button {
      margin-right: var(--m-3);

      &.decline-button {
        color: var(--color-negative);
        border-color: var(--color-negative);
      }
      &.archive-button {
        // color: var(--color-negative);
        // border-color: var(--color-negative);
      }
    }
  }
  .meta-container {
    display: flex;
    justify-content: center;
    padding: var(--p-5);
  }
}
