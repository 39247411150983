.ra-invoice-entry-view {
  display: flex;
  // overflow: hidden;
  &.display-beneath {
    flex-direction: column-reverse;
  }
  &[max-width~="650px"] {
    flex-direction: column-reverse;
    .left {
      padding-top: var(--p-10);
    }
  }

  .right {
    flex-grow: 2;
    padding-right: var(--p-10);
    padding-left: var(--p-5);

    .indicators-container {
      display: flex;
    }
    .invoice-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: var(--p-5);
      padding-top: var(--p-3);

      .invoice-date {
        padding-right: var(--p-5);
      }
      .title {
        font-size: var(--font-size-lg);
      }
      .invoice-id {
        color: var(--text-color-secondary);
        // padding-top: var(--p-3);
      }
    }
    .simple-view-container {
      .button-container {
        display: flex;
        justify-content: center;
      }
    }
    .main-data {
      display: flex;
      padding-bottom: var(--p-5);
      flex-grow: 1;
      overflow: hidden;

      .bigger {
        font-size: var(--font-size-md);
      }
      .smaller {
        font-size: var(--font-size-base);
        color: var(--text-color-secondary);
      }
      .invoice {
        padding-right: var(--p-10);
        flex: 1 0 50%;
        * {
          text-align: left;
        }
      }

      .receiver {
        flex: 1 0 50%;
        overflow: hidden;
        * {
          text-align: right;
        }
      }

      .linked-activitis {
        max-width: 100%;
        overflow: hidden;
        button.bf-button {
          justify-content: flex-start;
          text-align: left;
          .activity-label {
          }
        }
      }
    }
    .amount-to-pay {
      font-size: var(--font-size-xl);
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .amount-subvalues {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: flex-end;
        .amount-subvalue {
          .label {
            color: var(--text-color-secondary);
            font-size: var(--font-size-sm);
          }
          .value {
            // text-align: right;
            font-size: var(--font-size-base);
          }
        }
      }
    }
    .small {
      font-size: var(--font-size-sm);
    }
  }
  .left {
    flex: 2 0 40%;
    border-right: solid 1px var(--border-color-primary);
    padding-right: var(--p-5);
    margin-right: var(--m-5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.other-main-fields,
.info-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    width: 100%;
    max-width: 700px;
  }
}
.tags {
  padding-top: var(--p-3);
}
.amount-to-pay {
  .suffix {
    display: flex;
    align-items: center;
    color: var(--text-color-secondary);
  }
}

.ra-invoice-view-field {
  display: flex;
  .field-value {
    transition: transform 0.2s ease-in-out;
    white-space: break-spaces;
    text-align: right;
  }

  .field-label {
    color: var(--text-color-secondary);
    flex-grow: 1;
    padding-right: var(--p-8);
  }
  .suffix {
    padding-left: var(--p-3);
  }

  &.editable {
    .field-value {
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

.ra-invoice-entry-suffix-amount {
  .amount {
    font-size: var(--font-size-md);
  }
  .info {
    font-size: var(--font-size-sm);
  }
}
