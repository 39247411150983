.bf-placeholder {
  &.loading {
    .placeholder-entry {
      border-radius: var(--border-radius-base);
      background: var(--background-color-secondary);
      position: relative;
      overflow: hidden;

      color: transparent !important;
      .anim-container {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        animation-name: bf-placeholder-animation;
        animation-duration: 3s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        .anim {
          background: transparent;
          position: absolute;
          width: 0px;
          height: 100%;
          left: 0px;
          box-shadow: -1px -1px 150px 120px var(--shadow-contrast-7);
        }
      }
    }
  }
}

@keyframes bf-placeholder-animation {
  from {
    transform: translateX(-250px);
  }
  to {
    transform: translateX(calc(100% + 250px));
  }
}
