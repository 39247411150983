.bf-icon-picker-modal {
  height: 80vh;

  display: flex;
  .list-container {
    overflow: hidden;
    display: flex;
    flex: 1;
    flex-direction: column;
    .family-icons {
      flex: 1;
      overflow: hidden;
      display: flex;

      .category-filter {
        height: 100%;
        overflow: hidden;
        border-right: solid 1px var(--border-color-tertiary);
      }
      .list {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .list-header {
          display: flex;
          border-bottom: solid 1px var(--border-color-tertiary);
          .left {
            flex: 1 0;
            .breadcrumb {
              padding: var(--p-5);
              .bf-breadcrumb {
                margin-bottom: var(--m-0);
              }
            }
            .tags {
              padding: 0px 10px 10px 10px;
              display: flex;
              flex-wrap: wrap;
              gap: 5px;
              .bf-button.remove-all {
                margin-left: var(--m-5);
              }
            }
          }
          .right {
            padding: var(--p-3);
            display: flex;
            justify-content: center;
            align-items: flex-start;
            .search-input {
              min-width: 300px;
            }
          }
        }
        .list-container {
          flex: 1;
          overflow: hidden;
          .icon-entry {
            display: inline-block;
            // width: 90px;
            // height: 90px;
            padding: 1px;
            .bf-button {
            }
            &.selected {
              .bf-button {
                background: var(--list-row-background-selected);
              }
            }
          }
        }
      }
      .selected-details {
        border-left: solid 1px var(--border-color-tertiary);
        width: 300px;
        display: flex;
        flex-direction: column;
        .item-content {
          flex: 1;
          .no-selection {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding: var(--p-5);
            text-align: center;
            color: var(--text-color-secondary);
          }
        }
        .actions {
          display: flex;
          padding: var(--p-5);
          margin-top: var(--m-5);
          justify-content: flex-end;
          gap: 10px;
        }
      }
    }
  }
}
