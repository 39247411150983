.comment-container {
  display: flex;
  > .user {
    margin-right: var(--m-4);
    flex-shrink: 0;
  }
  > .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: var(--base-color);
    color: var(--text-color-contrast);
    border-radius: var(--border-radius-rounded);
    margin-right: var(--m-4);
    flex-shrink: 0;
  }

  .bubble {
    border-radius: var(--border-radius-base);
    flex-grow: 1;
    border: solid 1px var(--border-color-secondary);
    overflow: hidden;
    background: var(--background-color-contrast);
    .header {
      padding: 6px 8px;
      border-bottom: solid 1px var(--border-color-secondary);
      background: var(--background-color-primary);
      border-top-left-radius: var(--border-radius-base);
      border-top-right-radius: var(--border-radius-base);
      .header-main {
        display: flex;
        overflow-x: clip;
        .collapse-button {
          flex-grow: 1;
          text-align: initial;

          .chevron {
            padding-right: var(--p-3);
            transition: transform 0.2s ease-in-out;
            &.expanded {
              transform: scaleY(-1);
            }
          }
        }
        .left {
          flex-grow: 1;
          padding-right: var(--p-5);
          .header-text {
            font-size: var(--font-size-base);
          }
          .sub-text {
            font-size: var(--font-size-sm);
          }
          .date {
            color: var(--text-color-secondary);
            font-size: var(--font-size-sm);
          }
        }
        .right {
          display: flex;
          align-items: center;
          .actions {
            display: flex;
            .bf-icon {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      .tag-view-container {
        border-top: solid 1px var(--border-color-primary);
        padding-top: var(--p-3);
        margin-top: var(--m-3);
        padding-bottom: var(--p-3);
      }
    }
    .content-wrapper {
      position: relative;
      .content {
        padding: var(--p-8);
        max-height: 30vh;
        // overflow: auto;
        img {
          max-width: 100%;
        }
      }
      .scroll-info {
        opacity: 0.6;
        background: var(--background-color-deep);
        color: var(--text-color-contrast);
        padding: var(--p-5);
        position: absolute;
        bottom: 4px;
        width: 250px;
        left: calc(50% - 125px);
        text-align: center;

        border-radius: var(--border-radius-base);
      }
    }
  }
  &:focus,
  &:focus-within {
    .comment-container {
      .bubble {
        .content-wrapper {
          .content {
          }
        }
      }
    }
  }

  &.outgoing-mail {
    .mail-data {
      border-top: solid 1px var(--border-color-primary);
      margin-top: var(--m-3);
      padding-top: var(--p-3);
      .field {
        display: flex;
        align-items: center;
        .label {
          min-width: 90px;
          text-align: right;
          color: var(--text-color-secondary);
          padding-right: var(--p-5);
        }
        .value {
          .bf-status {
            margin: var(--m-2) var(--m-2) var(--m-2) var(--m-0);
          }
        }
        &.subject {
          padding-top: var(--p-2);
          .value {
            font-weight: 600;
          }
        }
      }
    }
    .date {
      color: var(--text-color-secondary);
      font-size: var(--font-size-sm);
    }
  }

  &.incoming-mail {
    .mail-data {
      border-top: solid 1px var(--border-color-primary);
      margin-top: var(--m-3);
      padding-top: var(--p-3);
      .field {
        display: flex;
        align-items: center;
        .label {
          min-width: 90px;
          text-align: right;
          color: var(--text-color-secondary);
          padding-right: var(--p-5);
        }
        .value {
          .bf-status {
            margin: var(--m-2) var(--m-2) var(--m-2) var(--m-0);
          }
        }
        &.subject {
          padding-top: var(--p-2);
          .value {
            font-weight: 600;
          }
        }
      }
    }
    .date {
      color: var(--text-color-secondary);
      font-size: var(--font-size-sm);
    }
  }

  &.mode-slim {
    > .icon-container,
    > .user {
      display: none;
    }
    .bubble {
      border: none;
    }
  }
}

.comment-container-fullscreen {
  position: fixed;
  width: 100%;
  top: 0;
  height: 100%;
  background: var(--background-opacity-strong);
  z-index: 1049;
  .comment-container-fullscreen-wrapper {
    padding: var(--p-10);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .comment-container {
      flex: 1 0 0;
      overflow: hidden;
      .bubble {
        max-height: 100%;
        display: flex;
        flex-direction: column;
        .content-wrapper {
          flex: 1 0 0;
          overflow: auto;
          .content {
            max-height: initial;
          }
        }
      }
    }
  }
}

.archived-comment-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .archived-info-container {
    padding-top: var(--p-4);
    padding-bottom: var(--p-4);
    .archived-description {
      display: flex;
      justify-content: center;
      gap: 5px;
      .bf-button {
        margin-left: var(--m-3);
      }
    }
    .reason {
      font-size: var(--font-size-sm);
      color: var(--text-color-secondary);
      text-align: center;
    }
  }
}
