.action-sheet-drawer {
  height: auto !important;

  .rs-drawer-dialog {
    .rs-drawer-content {
      position: initial;
      padding-top: 1px;

      .rs-drawer-header {
      }

      .rs-drawer-body {
        max-height: 250px;
        height: auto !important;
        //height: 220px !important;
        margin: var(--m-10) var(--m-0) var(--m-0) var(--m-0);
        padding: 0px 20px 0px 20px;
      }
    }
  }
}

.action-sheet-modal {
}
