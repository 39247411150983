.plan-table-group-maanage {
  .plan-table-group-manage-add-entry {
    display: flex;
    justify-content: center;
    ul.rs-dropdown-menu {
      margin-left: -100px;
      margin-top: -28px;
    }
  }
  .error {
    color: var(--color-negative);
    padding-top: var(--p-5);
  }

  .manage-group-actions {
    .rs-dropdown-toggle {
      margin-right: var(--m-2);
      padding: var(--p-2);
    }
  }
}

.plan-table-add-entry-form {
  display: flex;
  width: 350px;
  .add-button {
  }
}

.plan-table-group-manage-add-entry-dropdown {
  .bf-overlay-container {
    overflow: visible;
  }
}

.plan-table-entry {
  display: flex;
  overflow: hidden;
  .name {
    overflow: hidden;
    flex-grow: 1;
    text-overflow: ellipsis;
  }
  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    .bf-button {
      padding: var(--p-2);
    }
  }
}
