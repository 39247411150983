.ap-budget-view {
  // height: 100%;
  display: flex;
  flex-direction: column;
  .ap-budget-view-header {
    display: flex;
    gap: 10px;
  }

  .ap-budget-content {
    flex: 1;
    overflow: hidden;

    .ap-budget-page {
      // height: 100%;
    }
  }
}

.ap-budget-view-no-budget-yet {
  display: flex;
  justify-content: center;
  .box {
    padding: var(--p-10);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: var(--background-color-primary);
    max-width: 500px;
    border-radius: var(--border-radius-base);
    .description {
      margin-bottom: var(--m-5);
    }
  }
}
