.currency-label-overlay {
  padding: var(--p-5);

  .label {
    font-size: var(--font-size-sm);
    color: var(--text-color-secondary);
  }
  .value-entry {
    .value {
      font-size: var(--font-size-md);
      font-weight: 600;
    }
  }
  .info {
    display: flex;
    gap: 12px;
    .info-entry {
      .value {
        font-size: var(--font-size-sm);
      }
    }
  }
}
