.bf-form-section {
  --section-border: solid 1px var(--border-color-secondary);
  //   border-bottom: var(--section-border);

  //   --fixed-inset-box-shadow: inset 0 0 6px var(--shadow-contrast-inverse-4);
  //   --fixed-inset-bottom-clip-path: inset(6px 6px 0px 6px);

  //   --fixed-inset-right-clip-path: inset(0px 0px 0px 6px);

  //   box-shadow: var(--fixed-inset-box-shadow);
  //   clip-path: var(--fixed-inset-bottom-clip-path);
  position: relative;
  border-radius: var(--border-radius-base);

  &.open {
    > .section-title {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      button.bf-button {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      .state-indicator {
        .bf-icon {
          transform: rotate(180deg);
        }
      }
    }
  }
  > .section-title {
    width: 100%;
    display: flex;
    background: var(--background-color-primary);
    border-radius: var(--border-radius-base);
    transition: border-radius 0.2s;
    .state-indicator {
      padding-right: var(--p-5);
      .bf-icon {
        transition: transform 0.2s;
        width: 14px;
        height: 14px;
      }
    }
    > button.bf-button {
      flex: 1;
      background: transparent;
      font-size: var(--font-size-md);
      justify-content: flex-start;
      transition: border-radius 0.2s;
    }
    > .right {
      padding: 0px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  > .collapse-container {
    > .section-content {
      border-top: var(--section-border);
      // border-top: var(--section-border);
      padding: var(--p-6);
    }
  }
  > .outer-border {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius-base);
    border: var(--section-border);
    pointer-events: none;
  }
}
