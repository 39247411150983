.ap-assign-message-to-activity {
  padding: var(--p-10);
  overflow: auto;
  height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > .title {
    padding-bottom: var(--p-5);
  }

  .description {
    padding-bottom: var(--p-5);
  }

  .ap-assignment-content {
    height: 100%;
    display: flex;
    gap: 15px;
    flex-direction: row;
    overflow: hidden;

    .activity-container {
      flex: 2 0 0;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .filter-container {
        padding-bottom: var(--p-5);
      }

      .list-container {
        flex: 1 1;
        overflow: hidden;
        border-top: solid 1px var(--border-color-contrast-inverse);
        border-bottom: solid 1px var(--border-color-contrast-inverse);
        .activity-entry {
          border-bottom: solid 1px var(--border-color-secondary);
          width: 100%;
          button.bf-button.activity-entry-button {
            width: 100%;
            border-radius: var(--border-radius-none);
            background: transparent;
            .button-content {
              width: 100%;
            }
            box-shadow: none !important;
          }
          .assign-action-row {
            display: flex;
            justify-content: flex-end;
            padding-top: var(--p-3);
            padding-bottom: var(--p-3);
          }
        }
      }
    }

    .mail-container {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      flex: 3 0 0;
      height: 100%;

      .comment-entry {
        height: 100%;
        display: flex;
        padding-bottom: var(--p-0);
        flex-direction: column;
        .comment-container {
          flex: 1 0 0;
          overflow: hidden;
          height: 100%;

          .bubble {
            height: 100%;
            display: flex;
            flex-direction: column;
            .content-wrapper {
              flex: 1 0 0;
              height: 100%;
              overflow: auto;
              .content {
                max-height: initial;
              }
            }
          }
        }
      }
    }
  }

  .actions {
    padding-top: var(--p-5);
    margin-left: auto;
  }
}
