.bf-currency-select {
  .dropdown-button-text {
    width: 100%;
  }
  .placeholder {
    color: var(--text-color-secondary);
  }
  .advanced-button {
    .dropdown-button-text {
      display: flex;
      justify-content: center;
      color: var(--rs-btn-link-text);
    }
  }
  .currency-item {
    display: flex;
    width: 100%;
    align-items: center;
    overflow: hidden;
    .currency-name {
      flex-grow: 1;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .currency-code {
      font-size: var(--font-size-sm);
      padding-left: var(--p-3);
      color: var(--text-color-secondary);
    }
  }
  .search-currency-panel {
    // width: 300px;
    .empty {
      padding: var(--p-8);
      text-align: center;
      color: var(--text-color-secondary);
      font-size: var(--font-size-sm);
    }
    .items {
      max-height: 300px;
      overflow: auto;
      .item.bf-button {
        width: 100%;
        text-align: left;
        &.active {
          color: var(--rs-dropdown-item-text-active);
          background-color: var(--rs-dropdown-item-bg-active);
        }
      }
    }
  }
  &.appearance-input {
    width: 100%;
    button.rs-dropdown-toggle {
      width: 100%;
      text-align: left;
      justify-content: flex-start;
      border: 1px solid var(--rs-border-primary);
      background: var(--rs-input-bg);
      color: var(--rs-text-primary);
    }
    .dropdown-click-wrapper > .dropdown-button-text {
      max-width: initial;
    }
  }
}
