.dashboard-notifications {
  .notification-entry {
    border-radius: var(--border-radius-none);
    box-shadow: none;
  }
  .empty-text {
    padding: var(--p-10);
    color: var(--text-color-secondary);
    text-align: center;
  }
}
