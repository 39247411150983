.supply-unit-list {
  padding: var(--p-3);
  flex-grow: 1;
  overflow: hidden;

  .supply-unit-entry {
    margin-top: var(--m-5);
    margin-bottom: var(--m-5);
    padding: var(--p-5);
    // display: flex;
    .id {
      textarea {
        color: var(--text-color-secondary);
        font-size: var(--font-size-base);
        height: 28px !important;
      }
    }
    .displayName {
      flex-grow: 5;
      flex-shrink: 0;
      font-weight: bold;
    }
    .entry-action-row {
      display: flex;
      justify-content: flex-end;
      padding-top: var(--p-5);
    }
  }
}
