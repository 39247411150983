.ap-activity-data {
  .header {
    padding-top: var(--p-3);
    display: flex;
    // align-items: center;
    .back-button {
      padding-top: var(--p-17);
    }
    .title {
      flex-grow: 1;
      padding-right: var(--p-5);

      .title-input {
        flex-grow: 1;
      }
      .first-row {
        display: flex;
        align-items: flex-end;
        .actions {
          display: flex;
          .follow-button {
            margin-right: var(--m-3);
          }
        }
        .activity-id {
          flex-grow: 1;

          color: var(--text-color-secondary);
          padding-right: var(--p-5);
          padding-top: var(--p-5);
          padding-left: var(--p-2);

          font-size: var(--font-size-lg);
          @media screen and (max-width: 2000px) {
            font-size: var(--font-size-md);
          }
          @media screen and (max-width: 1200px) {
            font-size: var(--font-size-base);
          }
        }
      }
      .display-name {
        font-size: var(--font-size-2xl);
        @media screen and (max-width: 2000px) {
          font-size: var(--font-size-lg);
        }
        @media screen and (max-width: 1200px) {
          font-size: var(--font-size-md);
        }
      }
    }
    .action-right {
    }
  }
  .content {
    padding: var(--p-5);
    flex-grow: 1;

    .status-bar {
      display: flex;
      flex-wrap: wrap;
      & > div {
        padding-bottom: var(--p-3);
        padding-right: var(--p-3);
      }
      .ez-select {
        padding-top: var(--p-0);
        padding-bottom: var(--p-0);
      }
    }
    .supervisors-container {
      padding-bottom: var(--p-3);
    }
    .linked-data {
      padding-bottom: var(--p-3);

      .group-container {
        display: inline-flex;
        gap: 10px;
        flex-direction: column;
        width: 100%;
        & > div {
          .ez-select-query {
            width: 100%;
            .select-dropdown {
              width: 100%;
              .rs-dropdown-toggle {
                width: 100%;
              }
              .select-dropdown {
                min-width: 100%;
                width: auto;
              }
            }
          }
          .ez-select {
            width: 100%;
            .rs-picker {
              width: 100% !important;
            }
          }
        }
      }
      // .ez-select,
      // .ez-select-query {
      //   padding-right: var(--p-3);
      //   padding-bottom: var(--p-3);
      // }
      // .ez-asset-select-multiple {
      //   margin-right: var(--m-3);
      //   margin-bottom: var(--m-3);
      // }

      .renter-item {
        .sub {
          font-size: var(--font-size-sm);
          color: var(--text-color-secondary);
        }
      }
    }
    .data-fields {
      padding: 10px 5px 5px 5px;
      .field {
        padding-bottom: var(--p-5);
      }
    }
    .tags {
      padding-bottom: var(--p-5);
    }
  }
  .collapse-action {
    display: flex;
    justify-content: center;
    padding-bottom: var(--p-5);
  }
}
