.plan-table-group-timespan {
  .warning-box {
    margin-bottom: var(--m-5);
    background: var(--background-color-contrast);
    color: var(--color-warning);
    padding: var(--p-3);
    border-radius: var(--border-radius-base);
    font-size: var(--font-size-sm);
  }
}
