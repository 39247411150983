.table-loader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: var(--table-header-height);
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--background-opacity-secondary);
  opacity: 0;
  transition: opacity 0.5s, transform 0.1s ease-in-out;
  // transform: translateY(5px);
  pointer-events: none;
  &.active {
    opacity: 1;
    // transform: translateY(0px);
    pointer-events: all;
  }
}
