.bf-entries {
  width: 100%;
  .bf-entry {
    padding: var(--p-5);
    border-radius: var(--border-radius-base);
    transition: background-color 0.2s;
    display: flex;
    align-items: center;

    border-bottom: solid 1px var(--border-color-primary);
    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      background: var(--background-opacity-secondary);
    }

    .entry-content {
      flex-grow: 1;
    }

    .entry-actions {
      display: flex;
      // gap: var(--p-2);
    }
  }
  &.ignore-hover {
    .bf-entry {
      &:hover {
        background: none;
      }
    }
  }
}
