.ra-incoming-invoices-list {
  .urgent-row {
    background: var(--background-color-primary);
  }
  .ra-invoices-list-header {
    display: flex;
  }
  .activity-label-container {
    overflow: clip;
    button.bf-button {
      align-items: flex-start;

      .activity-label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .tags-list {
    display: flex;
    gap: 5px;
    overflow: hidden;
  }

  .amount-info {
    max-width: 100%;
    display: flex;
    overflow: hidden;
    .invoice-payment-plan {
      margin-right: var(--m-3);
    }
  }
}
