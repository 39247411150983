.cb-rental-agreement-accounting {
  min-height: calc(100% - 20px);
  // padding: var(--p-10);
  display: flex;
  position: relative;
  flex-direction: column;

  .split-pane-top,
  .split-pane-outer {
    width: 100%;
    height: 100%;
  }

  .open-amounts {
    height: 100%;
    width: 100%;
    overflow: clip;
  }
  .split-pane-container {
    height: 100%;
    // overflow: hidden;
    position: relative;

    .sub-header {
      background: var(--background-color-primary);
      display: flex;
      .label {
        flex-grow: 1;
      }
    }
    .debit-positions,
    .open-demands {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .debit-positions-container,
      .open-debit-positions {
        flex-grow: 1;
        overflow: auto;
      }
    }
  }
  .sub-header {
    padding: 8px 0px 8px 8px;
    font-size: var(--font-size-lg);
  }
  .form-container {
    height: 100%;
    .form-struct {
      height: 100%;
      > form {
        height: 100%;
      }
    }
  }
}
