.celebration-container {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;

	z-index: 9999999;
	.celebration-elem {
		font-family: Consolas, "courier new";
		position: absolute;
		top: 0px;
		animation-fill-mode: forwards;
		transform: translateY(-100%);
		animation-name: celebration-animation;
		z-index: 9999999;
	}
}
@keyframes celebration-animation {
	from {
		transform: translateY(-100%);
		opacity: 1;
	}
	to {
		transform: translateY(1000px);
		opacity: 0;
	}
}
