.ra-invoice-payment-label {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: var(--p-5);
  .type-of-payment {
    font-size: var(--font-size-sm);
    color: var(--text-color-secondary);
    padding-right: var(--p-3);
  }
}

.payment-label-overlay {
  padding: var(--p-5);
}
