.pdf-constructor--block-input--modal {
  .content {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;

    gap: 0.5rem;

    .submit-button {
      width: 100%;
      background: transparent;
      transition: background-color 0.3s;
      border-radius: 0.25rem;
      padding: 0.25rem 0.5rem;

      &:hover {
        background-color: var(--background-color-primary);
      }
    }
  }
}

.pdf-constructor--block-input--dropdown {
  width: 100%;

  & > button {
    width: 100%;
  }
}

.pdf-constructor--select-menus {
  z-index: 3000;
}
