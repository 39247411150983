$height-of-entry: 64px;

.vertical-process-history-entry {
  padding-right: var(--p-10);

  .content {
    display: flex;
    align-items: center;
    z-index: 2;

    .labels {
      padding: 3px 10px 3px 3px;
      text-align: left;
      margin-left: var(--m-1);
      margin-right: var(--m-3);
      .first-row {
        font-size: var(--font-size-sm);
        display: flex;
        .username {
          flex: 1 1 0;
        }
        .date-field {
          padding-left: var(--p-8);
          font-size: var(--font-size-sm);
          color: var(--text-color-secondary);
        }
      }
      .main-row {
        font-weight: bold;
        margin-left: var(--m-2);
      }
      .sub-row {
        font-size: var(--font-size-sm);
      }
    }
  }
  .vertical-line {
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    right: 0px;
    height: 2px;
    background: var(--background-color-primary);
    z-index: -1;
  }
}
