.cb-technical-unit-icon {
  width: 30px;
  height: 30px;
  border-radius: var(--border-radius-large);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .bf-icon {
    width: 20px;
    height: 20px;
  }

  &.size-xs {
    width: 24px;
    height: 24px;
    .bf-icon {
      width: 16px;
      height: 16px;
    }
  }
  &.size-xxs {
    width: 20px;
    height: 20px;
    .bf-icon {
      width: 12px;
      height: 12px;
    }
  }
}

.cb-technical-unit-icon-dropdown {
  button.bf-button.rs-dropdown-toggle {
    padding: var(--p-0);
    border-radius: var(--border-radius-large);
  }
}
