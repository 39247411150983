.object-supply-units {
  height: 100%;
  display: flex;
  flex-direction: column;

  .supply-unit-floor-plan {
    height: 300px;
    flex-shrink: 0;
  }
  .supply-unit-list {
    flex-grow: 1;
    overflow: hidden;

    .supply-unit-entry {
      margin-top: var(--m-5);
      margin-bottom: var(--m-5);
      padding: var(--p-5);
      // display: flex;
      .id {
        textarea {
          color: var(--text-color-secondary);
          font-size: var(--font-size-base);
          height: 28px !important;
        }
      }
      .displayName {
        flex-grow: 5;
        flex-shrink: 0;
        font-weight: bold;
      }
      .entry-action-row {
        display: flex;
        justify-content: flex-end;
        padding-top: var(--p-5);
      }
    }
  }
  > .action-row {
    display: flex;
    justify-content: flex-end;
    padding-bottom: var(--p-5);
    border-bottom: solid 1px var(--border-color-secondary);
    .bf-button {
      margin-left: var(--m-5);
    }
  }
}

.cb-create-portfolio-supply-unit {
  padding: var(--p-10);
  .title {
    font-size: var(--font-size-md);
    padding-bottom: var(--p-5);
  }
  .description {
    margin-bottom: var(--m-5);
  }
  .field {
    margin-bottom: var(--m-5);
  }
}
