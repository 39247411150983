$header-height: 60px;

.global-cdn-image-gallery {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: var(--background-opacity-strong);
  flex-direction: column;
  z-index: 1100;
  .head {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: $header-height;
    padding-right: var(--p-10);
  }
  .content {
    padding-bottom: var(--p-10);
    height: calc(100% - $header-height);
    .cdn-image {
      margin: var(--m-50);
      //   width: calc(100% - 200px);
      //   height: calc(100% - 200px);
    }
  }
  .swiper.bf-swiper-component .swiper-button-prev,
  .swiper.bf-swiper-component .swiper-button-next {
    color: var(--text-color-contrast);
    margin-right: var(--m-5);
    margin-left: var(--m-5);
  }
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    .swiper-zoom-container {
      // max-width: 100%;
      // max-height: 100%;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .cdn-image-entry.image-img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
      }
    }
  }
  .swiper.bf-swiper-component {
    .swiper-pagination {
      .swiper-pagination-bullet {
        --swiper-pagination-bullet-width: 15px;
        --swiper-pagination-bullet-height: 15px;
        @media screen and (max-width: 1200px) {
          --swiper-pagination-bullet-width: 10px;
          --swiper-pagination-bullet-height: 10px;
        }
        @media screen and (max-width: 800px) {
          --swiper-pagination-bullet-width: 6px;
          --swiper-pagination-bullet-height: 6px;
        }
        border: solid 1px var(--border-color-accent);
        background: var(--background-color-contrast);
        z-index: 1;
        &.swiper-pagination-bullet-active {
          background: var(--base-color);
        }
      }
    }
  }
}
