.bf-color-picker {
  .bf-button {
    border: var(--border-color-secondary) solid 1px;
  }
  &.sm {
    .bf-button {
      width: 30px;
      height: 30px;
    }
  }

  &.md {
    .bf-button {
      width: 40px;
      height: 40px;
    }
  }

  &.lg {
    .bf-button {
      width: 50px;
      height: 50px;
    }
  }

  &.xl {
    .bf-button {
      width: 60px;
      height: 60px;
    }
  }

  &.xxl {
    .bf-button {
      width: 80px;
      height: 80px;
    }
  }
}

.bf-overlay-container {
  .bf-color-picker-overlay {
    display: flex;
    flex-direction: column;

    .sketch-picker {
      box-shadow: none !important;
    }
    .button-footer {
      display: flex;
      margin-left: auto;
      .bf-button {
        padding-top: var(--p-0);
      }
    }
  }
}
