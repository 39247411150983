.vertical-process-history {
  .swiper-slide {
    width: fit-content;
    padding-top: var(--p-5);
    padding-bottom: var(--p-5);
    &:first-of-type {
      margin-left: var(--m-4);
    }
  }
}
