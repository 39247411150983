.rs-date-tag {
  width: fit-content;

  &.due {
    .BFTag {
      background: var(--color-positive);
      .text {
        color: var(--text-color-contrast);
      }
    }
  }
  &.overdue {
    .BFTag {
      background: var(--color-negative);
      .text {
        color: var(--text-color-contrast);
      }
      .icon {
        background: var(--color-negative);
        svg {
          color: var(--icon-color-contrast);
        }
      }
    }
  }
}
