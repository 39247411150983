.cb-rental-agreements-list {
  .agreements-header {
    padding-bottom: var(--p-3);
    display: flex;
    > .__h1 {
      flex-grow: 1;
    }
  }
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .unit-type-group-container {
    display: flex;
    gap: 5px;
  }
  .sum-balanace-row {
    border-top: solid 1px var(--border-color-secondary);
    margin-top: var(--m-1);
    padding-top: var(--p-1);
  }
}
