.nav-layout {
  display: flex;

  .nav-bar {
    .rs-nav-item > .rs-nav-item-content {
      color: var(--text-color-strong);
    }
    .rs-nav-item.rs-nav-item-active > .rs-nav-item-content {
      color: var(--text-color-contrast);
    }
    .rs-nav-item.hide-text {
      .rs-nav-item-content {
        .rs-icon {
          margin-right: var(--m-0);
        }
        .rs-nav-item-text {
          padding-right: 0px !important;
        }
      }
    }
  }

  .content {
    -webkit-overflow-scrolling: touch;
    flex-grow: 1;
    max-height: 100%;
  }
}
