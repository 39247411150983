.ra-upload-invoice-container {
  height: 100%;
  width: 100%;

  .dropzone-upload-invoice {
    width: 100%;
    height: 100%;
  }
}

.ra-upload-invoice-progress {
  position: fixed;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--overlay-background);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .text {
    color: var(--text-color-contrast);
    font-size: var(--font-size-lg);
    margin-bottom: var(--m-5);
  }
  .progress-entry-container {
    height: 212px;
    overflow: hidden;
    .entries {
      transition: transform 0.2s ease-in-out;

      .progress-entry {
        width: 400px;
        border: solid 1px var(--border-color-secondary);
        border-radius: var(--border-radius-base);
        padding: var(--p-5);
        color: var(--text-color-contrast);
        position: relative;
        margin: var(--m-5);
        display: flex;
        .file-name {
          flex-grow: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .indicator {
          display: flex;
          justify-content: center;
          align-items: center;
          .finish-icon {
            color: var(--icon-color-contrast);
            width: 18px;
            height: 18px;
          }
        }

        .progress {
          position: absolute;
          background: var(--background-color-accent);
          top: 0;
          left: 0;
          bottom: 0;
        }
      }
    }
  }
}
