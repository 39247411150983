.bf-button-group {
  &.no-wrap {
    flex-wrap: nowrap;
    display: inline-flex;
  }
  .bf-button {
    &.active {
      background: var(--base-color);
      color: var(--base-color-foreground);
    }
  }
}
