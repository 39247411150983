.object-form-feature-address {
  display: flex;
  gap: 10px;
  .map-component {
    flex: 2 0;
  }
  .addresses {
    flex: 3 0;
    .field {
      padding-bottom: var(--p-5);
    }
    .address-entry {
      border-bottom: solid 1px var(--border-color-primary);
      padding-bottom: var(--p-5);
      margin-bottom: var(--m-5);
      .field-row {
        display: flex;
        gap: 10px;
      }
      &:last-of-type {
        border-bottom: none;
      }

      .address-actions {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
      }
    }

    .add-button {
      display: flex;
      justify-content: center;
    }
  }
}
