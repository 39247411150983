.rs-list-details-component {
  display: flex;
  flex-direction: column;
  height: calc(100% - 20px);
  .rs-list-container {
    padding: var(--p-5);
    height: 100%;
    flex: 1 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .rs-list-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .rs-list-content {
      flex: 1 0;
      padding: 5px 0;
      overflow: hidden;
    }
  }

  .rs-details-container {
    flex: 2 0;
    margin-top: var(--m-8);
    overflow: hidden;
    height: 20%;

    .rs-details-component {
      overflow: auto;
      height: 100%;
      padding-bottom: var(--p-10);
    }
  }
}
