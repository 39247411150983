.ez-asset-select-multiple {
  display: inline-block;
  border: solid 1px var(--border-color-primary);
  border-radius: var(--border-radius-base);
  background: var(--background-color-primary) !important;
  &.block {
    display: block;
  }
  .open {
    border-color: var(--rs-input-focus-border);
    box-shadow: var(--rs-state-focus-shadow);
  }
  &:hover {
    border-color: var(--rs-input-focus-border);
  }
  .asset-loader.error {
    padding: var(--p-0);
  }
  .render-value {
    display: flex;
    color: var(--text-color-primary);
    overflow-x: clip;
    align-items: center;
    padding: var(--p-3);
    border-bottom: solid 1px var(--border-color-primary);
    .prefix-icon {
      padding-right: var(--p-3);
    }
    .asset-select-label {
      overflow-x: clip;
      flex-grow: 1;
    }
    .delete-button {
    }
  }
  .select-dropdown {
    min-width: 100%;
    &.rs-dropdown-disabled {
      opacity: 0.5;
    }
    button.rs-dropdown-toggle {
      display: flex;
      justify-content: center;
      width: 100%;
      text-decoration: none;

      svg.rs-dropdown-toggle-caret {
        position: initial;
      }
    }

    .asset-selection {
      .rs-picker-toggle {
        display: flex;
        align-items: center;
        padding-left: var(--p-3);
      }
    }
  }
  &.appearance-bf {
    border: 1px solid var(--rs-border-primary);
    background: var(--background-color-contrast) !important;
    .select-dropdown {
      button.rs-dropdown-toggle {
      }
    }
  }
  &.appearance-clear {
    background: var(--background-color-contrast) !important;
    border: none;
    .select-dropdown {
      button.rs-dropdown-toggle {
      }
    }
  }
}
