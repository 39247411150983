.cb-vacancy-current-activity-card {
  height: 450px;
  width: 100%;

  .page-header {
    padding: var(--p-0);
  }
  .ap-list {
    padding: var(--p-0);
  }
  margin-bottom: var(--m-10);
}
