$nav-height: 65px;
$padding: 20px;
/* TODO @Anton fix to variable? */
.object-detail-view {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;

  .main-view {
    flex: 1 0 40%;
    height: 100%;
    padding: $padding;
    max-width: 40%;
  }
  .tab-view {
    position: relative;
    flex: 1 0 60%;
    max-width: 60%;
    overflow: hidden;
    // padding-left: $padding;
    .outfader {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      height: $nav-height + $padding + 15;
      z-index: 4;
      pointer-events: none;
      background: linear-gradient(
        0deg,
        var(--background-color-contrast-inverse-0) 0%,
        var(--app-background) 100%
      );
      &.bottom {
        bottom: 0px;
        top: initial;
        transform: rotate(180deg);
        height: $padding;
      }
    }
    .tab-nav {
      position: absolute;
      top: $padding;
      left: 0;
      right: $padding;
      height: $nav-height;
      z-index: 6;
      .rs-nav.rs-nav-tabs {
        display: inline-block;
        box-shadow: var(--card-default-box-shadow);
        border-top-right-radius: var(--border-radius-base);
        border-bottom-right-radius: var(--border-radius-base);
        border-top-left-radius: var(--border-radius-base);
        border-bottom-left-radius: var(--border-radius-base);
        .rs-nav-bar {
          border-top: none;
        }
        .rs-nav-item {
          background: var(--rs-btn-default-bg);
          font-size: var(--font-size-lg);
          @media screen and (max-width: 2000px) {
            font-size: var(--font-size-md);
          }
          @media screen and (max-width: 1500px) {
            font-size: var(--font-size-base);
          }
        }
        & > .rs-nav-item {
          // border-top-right-radius: var(--base-radius-base);
          // border-top-left-radius: var(--base-radius-base);
          // border-bottom-right-radius: var(--base-radius-base);
          // border-bottom-left-radius: var(--base-radius-base);
          border-radius: var(--border-radius-none);
          // overflow: hidden;

          &.rs-nav-item-active {
            border: none;
            background: var(--base-color);
            color: var(--base-color-foreground);
          }
          &:last-of-type {
            border-top-right-radius: var(--border-radius-base);
            border-bottom-right-radius: var(--border-radius-base);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
          &:first-of-type {
            border-top-left-radius: var(--border-radius-base);
            border-bottom-left-radius: var(--border-radius-base);
          }
        }
        .rs-dropdown {
          & > .rs-nav-item {
            //small fix to prevent border at last one
            margin-left: -5px;
            padding-left: -5px;
            height: initial;
            border-top-right-radius: var(--border-radius-base);
            border-bottom-right-radius: var(--border-radius-base);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
          .rs-dropdown-menu {
            .rs-dropdown-item {
              font-size: var(--font-size-lg);
            }
          }
        }
      }
      ul.rs-dropdown-menu {
        z-index: 8888;
      }
    }
    .tab-content {
      height: 100%;
      overflow-y: auto;
      padding-top: $nav-height + $padding;
      padding-right: $padding;
      position: relative;
    }
  }
  &.mobile {
    $padding: 10px;
    .tab-view {
      max-width: 100%;
      .tab-nav {
        left: $padding;
        top: $padding;
        right: $padding;
      }
      .tab-content {
        padding-left: $padding;
        padding-top: $nav-height + $padding;
        padding-right: $padding;
      }
    }
  }

  &.integrated {
    .outfader {
      display: none;
    }
    .tab-content {
    }
  }
}
