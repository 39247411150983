.ds-upload-entry {
  padding: var(--p-5);
  border-bottom: solid 1px var(--border-color-primary);
  &:last-of-type {
    border-bottom: none;
  }
  display: flex;

  .form {
    flex-grow: 1;
    padding-left: var(--p-5);

    .row-1 {
      display: flex;
      padding-bottom: var(--p-5);
      .field {
        flex-grow: 1;
        padding-right: var(--p-5);
        &:last-of-type {
          padding-right: var(--p-0);
        }
      }
    }
    .actions {
      display: flex;
      justify-content: flex-end;
    }
  }
}
