.bf-input-table {
  $border-width: 1px;
  border: solid var(--border-color-secondary) $border-width;
  .label-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .table-header {
    display: flex;
    align-items: center;
    .row-label-header {
    }
    .table-column-header {
      padding: 5px 10px;
      font-weight: 600;
      border-left: solid var(--border-color-secondary) $border-width;
    }
  }
  .table-row {
    display: flex;
    align-items: center;
    border-top: solid var(--border-color-secondary) $border-width;
    .row-label {
      padding: 2px 10px;
      font-weight: 600;
      text-align: right;
    }
    .table-column {
      border-left: solid var(--border-color-secondary) $border-width;
      //   flex-shrink: 0;
      //   flex-grow: 0;
      overflow-x: clip;
    }
  }
}
