.bf-gantt-chart {
  .gantt-properties {
    padding: var(--p-3);
    display: flex;
  }
  .gantt-chart {
    .no-task-yet {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: var(--p-5);
      .info-text {
        font-size: var(--font-size-sm);
        text-align: center;
        color: var(--text-color-secondary);
      }
    }
    .bf-gantt-chart-header {
      border-bottom: var(--border-color-primary) 1px solid;
      display: flex;

      .col {
        font-weight: 500;
        display: flex;
        align-items: flex-end;
        height: 100%;
        padding-left: var(--p-1);
        padding-bottom: var(--p-1);
      }
    }

    .col {
      box-sizing: border-box;
      .rs-plaintext {
        padding-left: var(--p-6);
      }
      &.name-wrapper {
        width: 250px;
        padding-left: var(--p-13);
      }
      &.from {
        width: 100px;
      }
      &.days {
        width: 50px;
        input {
          padding-left: var(--p-2);
          padding-right: var(--p-2);
          text-align: center;
        }
        .plaintext {
          text-align: center;
          width: 100%;
          box-sizing: border-box;
          padding-right: var(--p-6);
        }
      }
      &.to {
        width: 110px;
      }
    }
    .bf-gantt-chart-table {
      border-right: solid 1px var(--border-color-secondary);
      .bf-gantt-chart-row {
        position: relative;
        display: flex;
        align-items: center;

        position: relative;

        .droppable {
          position: absolute;
          height: 18px;
          background: var(--background-color-focus);
          display: none;
          overflow: hidden;
          justify-content: center;
          align-items: center;
          transition: height 0.2s, top 0.2s, bottom 0.2s, opacity 0.2s;
          border-radius: var(--border-radius-big);
          opacity: 0.4;
          .text {
            opacity: 0;
            text-align: center;
            transition: opacity 0.2s;
          }

          &.disabled {
            background-color: var(--background-color-accent);
            color: var(--text-color-secondary);
          }
          &.dragging {
            opacity: 0.8;
            .text {
              opacity: 1;
            }
            height: 36px;
          }

          &.dropzone-above {
            top: -5px;
            left: 10%;
            right: 10px;
            z-index: 5;

            &.dragging {
              top: -10px;
            }
          }
          &.dropzone-below {
            bottom: -5px;
            left: 10%;
            right: calc(50% + 3px);
            z-index: 5;
            &.dragging {
              bottom: -10px;
            }
          }
          &.dropzone-child {
            bottom: -5px;
            left: calc(50% + 3px);
            right: 10px;
            z-index: 5;
            &.dragging {
              bottom: -10px;
            }
          }
        }

        &.selfDragging {
          opacity: 0.5;
        }
        &.otherDragging.draggingOver {
          .droppable {
            display: flex;
          }
        }

        .hover-action {
          position: absolute;
          // display: flex;
          justify-content: center;
          align-items: center;
          display: none;
          flex-direction: column;
          z-index: 6;
          left: 100%;
          top: 0px;
          width: auto;
          height: fit-content;
          padding: var(--p-3);
          background: var(--background-color-contrast-inverse);
          border-radius: var(--border-radius-base);

          gap: 5px;

          .delete {
            margin-top: var(--m-3);
          }
        }
        &:nth-of-type(even) {
          background-color: var(--background-color-primary);
        }
        &:hover {
          background-color: var(--list-row-background-hover);

          .hover-action {
            display: flex;
          }
        }

        .name-wrapper {
          height: 100%;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          padding-left: var(--p-5);
          .depth-line {
            // position: absolute;
            width: 2px;
            height: 100%;
            background: var(--background-color-secondary);
          }

          .reorder-knob {
            padding: var(--p-3);
            border-radius: var(--border-radius-base);
            cursor: grab;
          }
          .name {
            flex-grow: 1;
            padding-left: var(--p-5);
          }
        }

        &.readonly {
          .name-wrapper {
            padding-left: var(--p-5);
          }
        }
      }
    }
  }
}
