.ap-finished-approval {
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ap-activity-offer-approval {
  .ra-urgent-indicator.bubble {
    margin-bottom: var(--m-5);
  }
  padding: var(--p-5);
  .name {
    font-size: var(--font-size-lg);
    padding-bottom: var(--p-5);
  }

  .approved {
    .approved-title {
      font-weight: bold;
      padding-bottom: var(--p-3);
    }
  }

  .declined {
    .decline-reason {
      .decline-reason-description {
        display: flex;
        align-items: center;

        .user-label {
          display: inline-block;
          margin-right: var(--m-3);
          font-weight: bold;

          .avatar {
            float: left;
          }
        }
      }

      .decline-reason-text {
        position: relative;
        width: fit-content;
        padding: 8px 10px;
        border-radius: var(--border-radius-base);
        border: solid 1px var(--border-color-secondary);
        margin-top: var(--m-6);
        font-size: var(--font-size-md);
        .empty {
          padding: 0px 10px;
          color: var(--text-color-secondary);
          text-align: center;
          font-size: var(--font-size-base);
          font-style: italic;
        }

        .bubble-indicator {
          position: absolute;
          top: -6px;
          left: 20px;
          width: 10px;
          height: 10px;
          background: var(--background-color-contrast);
          transform: skewY(45deg);
          border-top: solid 1px var(--border-color-secondary);
          border-left: solid 1px var(--border-color-secondary);
        }
      }
    }
  }
  .declined,
  .approved {
    .show-more {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: var(--p-5);
    }
    .offers {
      position: relative;
      .__empty {
        padding: var(--p-8);
        text-align: center;
        color: var(--text-color-secondary);
      }
      .offer-wrapper {
        border-bottom: solid 1px var(--border-color-primary);
        background: transparent;
        width: 100%;
        padding: var(--p-8);
        .ap-activity-offer {
          background: transparent;
          textarea {
            background: transparent;
          }
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
      .inset-shadow {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: var(--default-inset-shadow);
      }
    }
  }

  .approval-form {
    .offer-title {
      font-size: var(--font-size-md);
      padding-bottom: var(--p-5);
    }
    > .description {
      color: var(--text-color-secondary);
      padding-bottom: var(--p-5);
    }
    .bf-message {
      margin-bottom: var(--m-5);
    }
    .assignees {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      .label {
        font-weight: bold;
      }
    }
    .offers {
      position: relative;
      .offer-entry {
        border-bottom: solid 1px var(--border-color-primary);
        background: transparent;
        width: 100%;
        text-align: left;
        > div:not(.radio-item) {
          width: 100%;
          .ap-activity-offer {
            background: transparent;
            textarea {
              background: transparent;
            }
          }
        }
        &:last-of-type {
          border-bottom: none;
        }
        display: flex;
        .radio-item {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .offer-wrapper {
          flex-grow: 1;
        }
      }
      .inset-shadow {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: var(--default-inset-shadow);
      }
    }
    .task-action-row {
      display: flex;
      justify-content: flex-end;
      padding-top: var(--p-5);
      .bf-button {
        margin-left: var(--m-5);
      }
    }
  }
}
