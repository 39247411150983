.rs-priority-tag {
  .BFTag {
    width: fit-content;
    .text {
      color: var(--text-color-contrast);
    }
    .icon {
      color: var(--icon-color-contrast);
    }
  }
  &.low {
    .BFTag {
      background: var(--color-prio-low);
      .icon {
        background: var(--color-prio-low);
      }
    }
  }
  &.medium {
    .BFTag {
      background: var(--color-prio-medium);
      .icon {
        background: var(--color-prio-medium);
      }
    }
  }
  &.high {
    .BFTag {
      background: var(--color-prio-high);
      .icon {
        background: var(--color-prio-high);
      }
    }
  }
  &.critical {
    .BFTag {
      background: var(--color-negative);
      .icon {
        background: var(--color-negative);
      }
    }
  }
}
