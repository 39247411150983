.bf-message-box {
  &.inline {
    display: inline-block;
  }
  &.small {
    .rs-message-container {
      padding: 5px 10px;
      .rs-message-content {
        .rs-message-body {
          font-size: var(--font-size-sm);
        }
      }
    }
  }
}
