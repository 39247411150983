.ap-schedule-task-progress {
  width: 120px;

  &.content {
    padding-left: var(--p-3);

    .task-progress {
      display: flex;
      align-items: center;
      gap: 5px;

      .days-misssed {
        color: var(--color-positive);
        &.negative {
          color: var(--color-negative);
        }
        .value {
          font-size: var(--font-size-lg);
          line-height: var(--font-size-lg);
        }
        .label {
          font-size: var(--font-size-sm);
          line-height: 12px;
        }
      }
      .comment-bubble {
        width: 26px;
        height: 26px;
        background: var(--background-color-light-base);
        color: var(--text-color-contrast);
        border-radius: var(--border-radius-large);
        margin-left: var(--m-3);
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: var(--p-1);
        padding-top: 1px;
      }
      // width: 100%;
      // justify-content: center;
    }
  }
}

.ap-task-progress-overlay {
  .title {
    font-size: var(--font-size-md);
    padding-bottom: var(--p-5);
  }
  .description {
    padding-bottom: var(--p-5);
  }
  .panel-content {
    padding: var(--p-5);

    .actions {
      padding-top: var(--p-5);
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }
  }
}

.ap-schedule-task-comment-overlay {
  padding: var(--p-5);
  .head {
    display: flex;
    padding-bottom: var(--p-5);
    justify-content: space-between;
    align-items: center;
    .date {
      padding-left: var(--p-5);
      font-size: var(--font-size-sm);
      color: var(--text-color-secondary);
    }
  }
}
