.register-mobile-device-qr {
  .title {
    font-size: 1.2em;
    margin-bottom: var(--m-5);
  }
  .description {
    margin-bottom: var(--m-5);
  }
  .qr-code {
    padding: var(--p-10);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .actions {
    display: flex;
    justify-content: center;
  }
}
