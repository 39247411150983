.overlay-title {
  display: flex;
  align-items: center;
  .title {
    padding: var(--p-5);
    flex-grow: 1;
    font-size: var(--font-size-md);
    font-weight: 500;
  }
  .action {
  }
}
