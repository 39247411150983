.object-stackingplan {
  height: calc(100% - 20px);
  position: relative;
  // display: flex;
  // flex-direction: column;
  .plan {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .entries {
    height: 100%;
    width: 100%;
    // border-top: solid 1px var(--border-color-secondary);
    display: flex;
    flex-direction: column;
    .actions {
      display: flex;
      justify-content: flex-end;
    }
    .column {
      padding: var(--p-2);
    }
    .content {
      flex-grow: 1;
      overflow: hidden;
    }
  }
}
