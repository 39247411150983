.cashbudget-cell-overlay {
  background-color: var(--background-color-contrast);
  border-radius: var(--border-radius-base);
  //   color: var(--text-color-contrast);
  box-shadow: 0px 3px 9px 0px var(--shadow-contrast-inverse-5);
  padding: 5px 10px;
  opacity: 1 !important;

  .header {
    padding-bottom: var(--p-3);
    display: flex;
    align-items: center;
    .title {
      font-size: var(--font-size-lg);
      flex-grow: 1;
    }
  }
  .main-entries {
    display: flex;
    .entry {
      display: flex;
      flex-direction: column;
      padding-right: var(--p-10);
      justify-content: flex-end;
      .label {
        color: var(--text-color-secondary);
        font-size: var(--font-size-base);
        align-items: flex-end;
      }
      .value {
        font-weight: 18px;
        &.grey {
          color: var(--text-color-primary);
          font-size: var(--font-size-base);
        }
      }
    }
  }
  .type-entries {
    border-top: solid 1px var(--border-color-primary);
    margin-top: var(--m-5);
    padding-top: var(--p-5);
    .entry {
      display: flex;
      width: 100%;
      .label {
        font-weight: 500;
        flex-grow: 1;
      }
      .value {
      }
    }
  }
}
