.cb-booking-footer {
  display: flex;
  border-top: solid 1px var(--border-color-secondary);
  .bf-button {
    border-radius: var(--border-radius-none);
  }
  .actions-left {
    display: flex;
    .bf-button:first-of-type {
      border-bottom-left-radius: var(--border-radius-base);
    }
    .bf-button {
      border-right: solid 1px var(--border-color-secondary);
    }
  }
  .actions-right {
    display: flex;
    .bf-button:last-of-type {
      border-bottom-right-radius: var(--border-radius-base);
    }
    .bf-button {
      border-left: solid 1px var(--border-color-secondary);
    }
  }
}
.cb-category-change-footer-overlay.form-struct {
  form {
    .form-content {
      padding: var(--p-0);
    }
    .form-action-row {
      padding: var(--p-5);
    }
    .current-category {
    }
    .categories {
      max-height: 400px;
      overflow: auto;
      border-top: solid 1px var(--border-color-primary);
      border-bottom: solid 1px var(--border-color-primary);
      .category-entry {
        &:hover {
          background: var(--table-hover-background-color);
        }
        &.selected {
          background: var(--table-hover-background-color);

          font-weight: bold;
        }
      }
      .empty {
        text-align: center;
        padding: var(--p-8);
        color: var(--text-color-secondary);
        font-size: var(--font-size-sm);
      }
    }
  }
}
