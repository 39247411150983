.comment-count-bubble-overlay {
  max-height: 70vh;
  max-width: 460px;
  overflow: hidden;
  padding-left: var(--p-3);
  background: var(--background-color-primary);
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  .comment-list {
    height: inherit;
    overflow: hidden;
    display: flex;
    .list-scroll {
      padding-right: var(--p-3);
    }
  }
}
