.bf-template-chooser {
  .search {
    padding-bottom: var(--p-10);
  }

  .table-content {
    height: 400px;
    border: solid 1px var(--rs-border-primary);
    border-radius: var(--border-radius-base);
    overflow: hidden;

    .template-button-container {
      width: 100%;
      display: flex;
      background: var(--background-color-contrast);
      border-bottom: solid 1px var(--border-color-primary);

      &:hover {
        background: var(--list-row-background-hover);
      }
      &.selected {
        background: var(--list-row-background-hover);
      }
      .template-button {
        flex-grow: 1;
        background: transparent;
        border-radius: var(--border-radius-none);
        flex-direction: column;
        align-items: flex-start;

        .first-row {
          width: 100%;
          color: var(--text-color-secondary);
          font-size: 10pz;
          display: flex;

          .for-whom {
            font-size: var(--font-size-xs);
          }

          .identifier {
          }
        }
      }

      .more-button {
        background: transparent;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
