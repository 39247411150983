.labeled-input {
  display: flex;
  padding: var(--p-0);
  box-shadow: none;
  transition: box-shadow 0.2s ease, padding 0.2s ease;
  border-radius: var(--border-radius-base);
  &.highlight {
    box-shadow: 0px 0px 10px 2px var(--shadow-labeled-input-highlight);
    padding: var(--p-3);
  }
  > .label {
    display: flex;
    > span {
      flex-grow: 1;
    }
    .info-container {
      padding-left: var(--p-3);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      .info-indicator {
        color: var(--background-color-light-base);
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }
  .suffix {
    padding-left: var(--p-5);
    > * {
      display: inline-flex;
    }
  }
  &.error-label {
    color: var(--color-negative);
  }

  &.label-position-top {
    flex-direction: column;

    .label {
      margin-bottom: var(--m-2);
    }
  }
  &.label-position-left {
    flex-direction: row;
    .label {
      display: flex;
      align-items: center;
      padding-right: var(--p-5);
    }
  }
  &.label-position-right {
    flex-direction: row-reverse;
    align-items: center;
    .label {
      padding-left: var(--p-5);
    }
  }
  &.label-position-bottom {
    flex-direction: column-reverse;
  }

  .label {
    &.grow {
      flex-grow: 1;
    }
  }

  &.type-sub {
    .label {
      padding-bottom: 1px;
      padding-left: var(--p-3);
      color: var(--text-color-secondary);
      font-size: var(--font-size-sm);
      letter-spacing: 1px;
    }
  }
}

.labeled-input-info-overlay {
  padding: var(--p-5);
  max-width: 400px;
}
