.resubmission-dashboard-component {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--p-5);

  .card-scroller {
    overflow: hidden;
    .card-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .dashboard-resubmission-kpis {
        padding: var(--p-3);
        display: flex;
        flex-direction: row;
        gap: 5px;
        overflow: hidden;
        flex-shrink: 0;

        .feedback-done-kpi-card {
          margin-left: auto;
        }
      }

      // TODO SJ discuss with benni, im not happy with this 100px stuff
      .dashboard-resubmission-content {
        flex: 1 0;
        height: calc(100% - 100px);
      }
    }
  }
}
