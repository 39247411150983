.cb-idly-connection-suggestion-hints {
  .suggestion-hint {
    display: flex;
    align-items: center;
    overflow: hidden;
    font-size: var(--font-size-sm);
    padding-bottom: var(--p-2);
    .bf-icon {
      display: inline-block;
      margin-right: var(--m-2);
    }
    &.none {
      color: var(--color-negative);
    }
    &.fully {
      color: var(--color-positive);
    }
    &.almostFully {
      color: var(--color-positive);
    }
    &.partially {
      color: var(--color-prio-medium);
    }
  }
}
