.ez-date {
  .readonly-tag {
    width: 100%;
    background: var(--background-color-primary);
    padding: 8px 12px;

    .rs-tag-text {
      .render-value {
        font-size: var(--font-size-base);
        display: flex;
        flex-direction: row;
        gap: 6px;
      }
    }
  }

  .rs-picker-toggle-wrapper {
    background: transparent !important;
    .rs-input-group.rs-input-group-inside {
      background: var(--background-color-primary) !important;
      input.rs-input {
        background: var(--background-color-primary) !important;
      }
    }
  }
  .date-toggle-button {
    position: relative;
    .prefix-icon {
      padding-right: var(--p-3);
    }
    .hidden-input {
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      overflow: hidden;
      pointer-events: none;
      opacity: 0;
    }
  }
  &.size-xs {
    .readonly-tag {
      padding: 3px 8px;
      .bf-icon {
        padding-top: var(--p-1);
        width: 15px;
        height: 15px;
      }
    }
    .date-toggle-button {
      padding: 3px 8px;
      .prefix-icon {
        .bf-icon {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
