.bf-slider {
  .rs-slider-bar {
    background: var(--background-color-secondary);

    &:hover {
      background: var(--background-color-accent);
    }
  }

  .rs-slider-handle {
    &::before {
      width: 20px;
      height: 20px;
      margin-left: -10px;
      margin-top: -3px;
    }

    .rs-tooltip {
    }

    &:hover {
      .rs-tooltip {
        //animation-name:  fade-in-rsuite;
        //animation-fill-mode: forwards;
        //animation-duration: 0.25s;
        ////animation-delaay: 1s;
        //animation-iteration-count: 1;
      }
    }
  }
}
