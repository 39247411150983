.bf-pdf-marker {
  --border-color: var(--border-color-secondary);
  .action-container {
    display: flex;
    padding-bottom: var(--p-5);
    .marker-title {
    }
    .pdf-marker-comp {
      &.error {
        --border-color: var(--color-negative);
      }
      &.has-value {
        --border-color: var(--base-color);
        .bf-icon {
          color: var(--border-color);
        }
      }
      display: flex;
      border: solid 2px var(--border-color);
      border-radius: var(--border-radius-base);

      .icon-container {
        border-right: solid 2px var(--border-color);
        .bf-button {
          height: 100%;
          width: 100%;
          border-top-left-radius: var(--border-radius-base);
          border-bottom-left-radius: var(--border-radius-base);
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }

        &.readonly {
          border-right: none;
          .bf-button {
            border-top-right-radius: var(--border-radius-base);
            border-bottom-right-radius: var(--border-radius-base);
          }
        }
      }
    }
  }
}
