.list-view-pdf-drawer {
  .rs-drawer-header {
    .rs-drawer-header-close {
      top: 5px;
    }
  }
  .pdf-view-container {
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }
}
.old-list-view-pdf {
  position: relative;
  display: flex;
  height: 100%;

  --pdf-viewer-max-width: 900px;
  --pdf-viewer-width: 50%;
  &.drawer-view {
    --pdf-viewer-max-width: 100%;
    --pdf-viewer-width: 100%;
    .form-wrapper {
      padding: var(--p-10);
    }
  }
  > .form-wrapper {
    width: 100%;
    flex-grow: 2;
    padding: 20px 0px 0px 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    > .header {
      width: var(--pdf-viewer-width);
      max-width: var(--pdf-viewer-max-width);
      padding-bottom: var(--p-5);
      display: flex;
    }

    .forms-table {
      flex-grow: 1;
      & > .bf-table {
        .show-new-data-warning-wrapper {
          right: initial;
          width: var(--pdf-viewer-width);
          left: 0;
          max-width: var(--pdf-viewer-max-width);
        }
        .no-data-component,
        .table-loader {
          width: var(--pdf-viewer-width);
          max-width: var(--pdf-viewer-max-width);
          z-index: 2;
        }
        .ReactTable.table {
          // overflow: unset;
          // overflow-x: unset;
          // overflow-y: unset;
          //   overflow-y: auto;
          //   overflow: auto;

          .rt-table {
            // overflow: unset;
            // overflow-x: unset;
            // overflow-y: unset;
            //     overflow-y: auto;
            //     overflow: initial;
            .rt-tbody.scroll-box {
              // overflow: unset;
              // overflow-x: unset;
              // overflow-y: unset;
              //       overflow-y: auto;
              //       overflow: initial;
              .rt-td {
                overflow: visible;
                //         overflow-y: auto;
                //         overflow: initial;

                .list-view-pdf-entry {
                  position: relative;
                  width: 100%;
                  margin-bottom: var(--m-10);
                  background: var(--list-row-background);

                  &.selected {
                    z-index: 1;
                  }
                  &.type-highlight {
                    cursor: pointer;
                    :hover {
                      background: var(--background-color-contrast);
                    }
                    &.selected {
                      background: var(--table-row-color-hover);
                      :hover {
                        background: var(--table-row-color-hover);
                      }
                    }
                  }
                  > .transition-to-pdf {
                    position: absolute;
                    left: calc(100% - 10px);
                    width: 40px;
                    z-index: 2;
                    top: 0px;
                    bottom: 0px;
                    background: var(--background-color-contrast);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  > .pdf-wrapper {
    position: absolute;
    top: 0px;
    margin-right: var(--m-5);
    right: 0px;
    bottom: 0px;
    min-width: calc(50% - 10px);
    width: calc(100% - 930px);
    padding: var(--p-10);
    display: flex;
    flex-direction: column;
    .pdf-card {
      padding: var(--p-10);
      display: flex;
      flex-grow: 1;
      height: 0;
      justify-content: center;
      align-items: center;
      .scroll-wrapper {
        width: 100%;
        height: 100%;
        border: solid 1px var(--border-color-tertiary);

        .empty-text-container {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20%;
          .message {
            font-size: var(--font-size-lg);
            color: var(--text-color-secondary);
            text-align: center;
          }
        }
      }
    }
  }
}
