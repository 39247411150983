.cb-rental-agreement-rent-change {
  .cb-rental-agreement-rental-unit-entry {
    padding-top: var(--p-5);
    align-items: center;
    &.header-row,
    &.footer-row {
      padding-right: var(--p-18);
      align-items: flex-end;
    }
    .column.floor,
    .column.type,
    .column.building,
    .column.usage-type {
      display: none;
    }
    // .column.from,
    // .column.to {
    //   overflow: visible;
    //   width: 151px;
    // }
  }
  .field-row {
    display: flex;
    gap: 20px;
  }

  .bf-input-table {
    .suggest-button {
      max-width: 126px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: right;
      direction: rtl;
    }
    .info {
      padding: 2px 5px;
      overflow: hidden;
      max-width: 100%;
      .info-1 {
        font-size: var(--font-size-sm);
        color: var(--text-color-primary);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .info-2 {
        font-size: var(--font-size-xs);
        color: var(--text-color-secondary);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
