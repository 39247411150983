.users-labeled {
  display: flex;
  .user-avatars {
    display: flex;
    .avatar {
      margin-left: -6px;
      &:first-of-type {
        margin-left: var(--m-0);
      }
    }
  }
  .label {
    padding-left: var(--p-2);
  }
}

.users-labeled-overlay {
  .user-list {
    padding: var(--p-5);
  }
}
