.cf-attachment {
  .attachment-header {
    display: flex;
    padding-bottom: var(--p-3);
    .title {
      padding-right: var(--p-5);
    }
  }
  .attachments {
    border: 1px solid var(--rs-border-primary);
    border-radius: var(--border-radius-base);
    .no-attachment {
      padding: var(--p-3);
      text-align: center;
      color: var(--text-color-secondary);
      font-size: var(--font-size-sm);
    }

    .upload-button {
      display: flex;
      justify-content: center;
      padding: var(--p-3);
    }
  }
}

.cf-attachment-entry {
  padding: var(--p-3);
  border-bottom: solid 1px var(--border-color-primary);

  display: flex;
  align-items: center;
  overflow: hidden;
  .infos {
    flex-grow: 1;
    padding-right: var(--p-3);
    padding-left: var(--p-3);
    overflow: hidden;

    .filename {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .action {
      padding-top: var(--p-3);
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .progress-bar {
        flex-grow: 1;
        height: 9px;
        overflow: hidden;
        border-radius: var(--border-radius-base);
        margin-right: var(--m-5);
        border: solid 1px var(--border-color-secondary);
        position: relative;

        .progress {
          position: absolute;
          left: 0px;
          top: 0px;
          bottom: 0px;
          background-color: var(--base-color);
          transition: width 0.3s ease-in;
        }
      }
    }
  }
}
