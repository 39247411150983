.context-menu-item.has-entries {
  cursor: pointer;
  &:hover {
    color: var(--base-color);
  }
}
.context-menu-popover-container {
  padding: var(--p-0);
  background: var(--background-color-accent);

  .arrow {
    padding: var(--p-10);
    &::after {
      border-bottom-color: var(--background-color-accent) !important;
    }
  }
  .context-menu-popover {
    display: flex;
    flex-direction: column;
  }
}
