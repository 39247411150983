.pdf-common-input {
  h5 {
    margin-bottom: 0.5rem;
  }
}

.margin-input {
  display: grid;
  gap: 1rem;

  /* Default: 1 item per row */
  grid-template-columns: 1fr;

  /* Switch to 2x2 layout after 24rem */
  @media (min-width: 50rem) and (max-width: 90rem) {
    grid-template-columns: repeat(2, 1fr);
  }

  /* Switch to 4 items in one row after 90rem */
  @media (min-width: 90rem) {
    grid-template-columns: repeat(4, 1fr);
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
