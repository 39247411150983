.LogComponent.scroll-container {
  height: 100%;
  overflow: auto;
  position: relative;

  .scroll-content {
    -webkit-overflow-scrolling: touch;

    position: relative;
    .day-separator {
      margin-top: var(--m-5);
      border-bottom: solid 1px var(--border-color-accent);
      margin-bottom: var(--m-5);
      margin-right: var(--m-5);
      margin-left: var(--m-5);
    }
    .loader {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: var(--p-5);
    }
    .no-more-entries {
      padding: 20px 0px 50px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--text-color-secondary);
    }
  }
  .replace-loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
