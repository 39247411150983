.cb-rental-agreement-position-info {
  display: flex;
  gap: 5px;
  .slot {
    width: 20px;
  }
}

.cb-rental-agreement-position-info__note {
  .head {
    background: var(--background-color-primary);
    display: flex;
    padding: var(--p-5);
    .user-container {
      flex-grow: 1;
      padding-right: var(--p-10);
    }
  }
  .content {
    padding: var(--p-5);
  }
}

.cb-rental-agreement-position-info__activities {
  padding: var(--p-5);
}

.cb-rental-agreement-position-info__attachments {
  padding: var(--p-5);
}
