.form-struct {
  // padding: var(--p-10);

  form {
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    .form-title {
      font-size: var(--font-size-md);
      padding: var(--p-10);
      &.no-title {
        display: none;
      }
      background: var(--background-color-primary);
      border-top-left-radius: var(--border-radius-base);
      border-top-right-radius: var(--border-radius-base);
      border-bottom: solid 1px var(--border-color-secondary);
    }
    .form-content {
      display: flex;
      flex-grow: 1;
      overflow: hidden;
      .form-center {
        padding: var(--p-10);
        overflow: auto;
        flex: 1;
        max-height: 100%;
        .form-description {
          padding-bottom: var(--p-5);
          color: var(--text-color-secondary);
        }
      }
    }
    .form-action-row {
      padding: var(--p-10);
      background: var(--background-color-primary);
      border-top: solid 1px var(--border-color-secondary);
      border-bottom-left-radius: var(--border-radius-base);
      border-bottom-right-radius: var(--border-radius-base);
    }
  }
  &.not-modal {
    form {
      max-height: initial;
      .form-title {
        border-bottom: none;
        background: none;
        padding: 0px 0px 20px 0px;
      }
      .form-content {
        padding: var(--p-0);
      }
      .form-action-row {
        background: none;
        border-top: none;
        padding: 20px 0px 0px 0px;
      }
    }
  }
  &.allow-overflow {
    form {
      .form-content {
        overflow: visible;
      }
    }
  }
  // &.no-padding {
  //   padding: var(--p-0);

  //   .title {
  //     padding: 0px 0px 10px 0px;
  //   }
  //   .form-action-row {
  //     padding: 20px 0px 0px 0px;
  //   }
  // }
}
