.notification-entry {
  position: relative;
  padding: var(--p-5);
  background: var(--background-color-contrast);
  box-shadow: 0px 2px 8px 0px var(--shadow-contrast-inverse-4);
  margin: var(--m-4);
  //   margin: 7px 5px 7px 5px;
  border-bottom: solid 1px var(--border-color-secondary);
  border-radius: var(--border-radius-base);

  display: flex;

  &.dense {
    padding: 5px 10px;
    margin: var(--m-0);
    box-shadow: 0px 2px 8px 0px var(--shadow-contrast-inverse-4);
    .content {
      .head {
        .title {
        }
        .header-actions {
          .action {
            border-radius: var(--border-radius-base);
            font-size: 0.75em;
            padding: var(--p-2);
          }
        }
      }
    }
  }
  &:hover {
    .delete-action {
      opacity: 1;
    }
  }
  .delete-action {
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }
  .seen-indicator-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 15px 6px 0px;
    .seen-indicator {
      padding: var(--p-0);
      width: 10px;
      height: 10px;
      border-radius: var(--border-radius-big);
      background: var(--base-color-hover);
      border: solid 1px var(--base-color);
    }
    &.seen {
      .seen-indicator {
        background: var(--background-color-contrast);
        border: solid 1px var(--border-color-primary);
      }
    }
  }
  .icon {
  }
  .content {
    flex-grow: 1;
    .head {
      display: flex;
      align-items: center;

      .title {
        flex-grow: 1;
        font-size: 1.15em;
        color: var(--text-color-strong);
        // font-weight: bold;
      }
      .date {
        text-align: right;
      }
      .header-actions {
        display: flex;
        .action {
          border-radius: var(--border-radius-rounded);
          font-size: 0.9em;
          padding: var(--p-5);
        }
        .expand {
          .expand-action {
            .bf-icon {
              transition: transform 0.5s ease-out;
            }
            &.rotated {
              .bf-icon {
                transform: rotateX(180deg);
              }
            }
          }
        }
      }
    }
    .message {
      margin-top: var(--m-3);
    }
    .progress {
      margin-top: var(--m-3);
    }
  }
  .actions {
    padding-top: var(--p-3);
    display: flex;
    justify-content: flex-end;
  }
  &:last-of-type {
    border-bottom: none;
  }
}
