.idly-suggestion-entry {
  display: flex;
  padding-bottom: var(--p-5);
  padding-top: var(--p-5);
  border-bottom: solid 1px var(--border-color-primary);
  // &:last-of-type {
  //   border-bottom: none;
  // }

  .suggestion-data {
    flex: 1;
  }
  .suggestion-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: var(--p-10);
    .suggestion-action-button {
      margin-top: var(--m-4);
    }
    .ruleset-action-button {
      margin-top: var(--m-4);
    }
    .rule-created-label {
      margin-top: var(--m-4);
      color: var(--color-positive);
    }
  }
  @media screen and (max-width: 1600px) {
    flex-direction: column;
    .suggestion-action {
      margin-top: var(--m-5);
      flex-direction: row;

      .suggestion-hints {
        // display: flex;
        flex-grow: 1;
        gap: 10px;
      }
      .suggestion-action-button {
        margin-top: var(--m-0);
        flex-shrink: 0;
      }
    }
  }
}
