.list-entry-component {
  display: flex;
  flex-direction: row;
  border-radius: var(--border-radius-none);
  border-bottom: solid 1px var(--border-color-secondary);
  background: var(--list-row-background);

  &:has(.selected) {
    background: var(--list-row-background-selected);
  }

  &:hover {
    background: var(--table-row-color-hover);
  }

  .list-entry-component-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    &.bf-button {
      background: transparent;
      padding: 5px 5px;
      border-radius: var(--border-radius-none);

      &.no-indicator {
        padding-left: var(--p-8);
      }
      &.with-prefix {
        padding-left: var(--p-3);
      }
    }

    .list-entry-indicator-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px 5px 6px 0px;
      .list-entry-indicator {
        padding: var(--p-0);
        width: 10px;
        height: 10px;
        border-radius: var(--border-radius-big);
        background: var(--base-color-hover);
        border: solid 1px var(--base-color);
      }
      &.outlined {
        .list-entry-indicator {
          background: var(--background-color-contrast);
          border: solid 1px var(--border-color-primary);
        }
      }
    }
  }
  .entry-actions-trigger {
    align-self: stretch;
    margin-left: var(--m-1);

    .bf-button {
      border-radius: var(--border-radius-none);
      height: 100%;
      background: transparent;
      padding: 8px 0;
    }
  }

  &:has(.entry-actions-trigger) {
    .directory-entry {
      &.bf-button {
        padding-right: var(--p-5);
      }
    }
  }
}

.list-entry-action-overlay {
  .bf-overlay-container {
    overflow: visible;

    ul.dropdown-item-menu.rs-dropdown-menu {
      background: var(--rs-bg-overlay);
      background: var(--background-color-contrast);
      border-radius: var(--border-radius-base);
      box-shadow: var(--card-default-box-shadow);
    }
  }
}
