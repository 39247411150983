.ap-activity-maintenance-appointments-tab {
  .__card {
    margin-bottom: var(--m-10);
    padding: var(--p-5);
    .card-title {
      font-size: var(--font-size-lg);
      padding-bottom: var(--p-3);
      margin-bottom: var(--m-8);
      border-bottom: solid 1px var(--border-color-primary);
    }
  }
  .graphs {
    height: 220px;
  }
  .next-appointments {
    .appointments {
      .no-appointments-yet {
        padding: var(--p-5);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .info {
          color: var(--text-color-secondary);
          padding-bottom: var(--p-3);
          font-size: var(--font-size-sm);
        }
      }
      .appointment-date {
        .automatic-appointment {
          font-size: var(--font-size-sm);
          color: var(--text-color-secondary);
        }
      }
      .actions {
        .action-container {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          gap: 5px;
        }
      }
    }
  }
}
