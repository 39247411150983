.cb-rental-agreement-rental-unit-entry {
  display: flex;
  gap: 10px;
  .column {
    padding-left: var(--p-5);
    font-size: var(--font-size-sm);
    &:first-of-type {
      border-left: none;
      padding-left: var(--p-0);
    }
    overflow: hidden;
    &.object {
      flex: 1 0;
    }
    &.displayName {
      flex: 2 0;
    }
    &.rentalStatus {
      flex: 1 0;
    }
    &.usage-type {
      flex: 1 0;
    }
    &.type {
      flex: 1 0;
    }
    &.building {
      flex: 1 0;
    }
    &.floor {
      flex: 1 0;
    }
    &.quantity {
      flex: 1 0;
    }
    &.rentGross {
      flex: 1 0;
    }
    &.rentNet {
      flex: 1 0;
    }
    &.operatingCostGross {
      flex: 1 0;
    }
    &.operatingCostNet {
      flex: 1 0;
    }
  }

  &.header-row {
    // font-weight: bold;
    border-bottom: 1px solid var(--border-color-secondary);
    font-size: var(--font-size-sm);
    color: var(--text-color-secondary);
    // letter-spacing: 1px;
    .column {
      display: flex;
      align-items: flex-end;
      padding-bottom: var(--p-2);
    }
  }
  &.footer-row {
    // font-weight: bold;
    border-top: 2px solid var(--border-color-secondary);
    font-size: var(--font-size-sm);
    font-weight: 800;

    // letter-spacing: 1px;
    .column {
      display: flex;
      align-items: flex-end;
      padding-bottom: var(--p-2);
    }
  }

  &[max-width~="1000px"] {
    .column.hide-if-too-small {
      display: none;
    }
  }
  &[max-width~="800px"] {
    .column.floor,
    .column.building {
      display: none;
    }
  }
  &[max-width~="600px"] {
    .column.usage-type,
    .column.type {
      display: none;
    }
  }
}
