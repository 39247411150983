.rs-preview-activity {
  width: 100%;
  .activity-container {
    width: 100%;
    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-bottom: var(--m-8);

      .activity-title {
        flex: 1 0 50%;
      }
      .activity-description {
        flex: 1 0 50%;
      }
    }
  }
}
