.cb-portfolio-loan-detail-view {
  .object-detail-main-page {
    overflow-y: auto;
    height: 100%;
    .head {
      display: flex;
      align-items: center;
      padding: var(--p-5);
      overflow-y: auto;
      @media screen and (max-width: 1000px) {
        padding: var(--p-0);
      }
      .action-left {
        margin-right: var(--m-3);
      }
      .title {
        font-size: var(--font-size-2xl);
        flex-grow: 1;
        padding: 5px 10px;
        @media screen and (max-width: 2000px) {
          font-size: var(--font-size-lg);
        }
        @media screen and (max-width: 1200px) {
          font-size: var(--font-size-md);
        }
      }
    }
    .content {
      padding: var(--p-10);
      .cb-region {
        .region-content {
          .value-entry {
            display: flex;
            padding: 3px 0px;
            border-bottom: solid 1px var(--border-color-primary);
            .label {
              flex-grow: 1;
            }
            .value {
            }
            &:last-of-type {
              border-bottom: none;
            }
          }

          .object-assignment {
            display: flex;
            align-items: center;
            padding: 7px 0px;
            .object-data {
              font-size: var(--font-size-md);
              flex-grow: 1;
              display: flex;
              align-items: center;
              .bf-button {
                margin-left: var(--m-3);
              }
            }
            .share {
              color: var(--text-color-secondary);
            }
          }
        }
      }
    }
  }

  .object-detail-payplan {
    height: calc(100% - 20px);
    padding: var(--p-5);
    position: relative;
    overflow: hidden;
    .bf-loan-data {
      height: 100%;
      .values {
        height: 100%;
        .bf-virtualized {
          height: calc(100% - 35px) !important;
        }
      }
    }
  }
}
