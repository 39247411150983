.persistent-split-pane {
  &.hide-secondary {
    .Pane2 {
      display: none;
    }
    .Pane1 {
      width: 100% !important;
      height: 100% !important;
    }
    .Resizer {
      display: none;
    }
  }
}

.Resizer {
  background: var(--background-color-contrast-inverse);
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid var(--border-color-accent);
  border-bottom: 5px solid var(--border-color-accent);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid var(--border-color-contrast-inverse);
  border-bottom: 5px solid var(--border-color-contrast-inverse);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid var(--border-color-accent);
  border-right: 5px solid var(--border-color-accent);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid var(--border-color-contrast-inverse);
  border-right: 5px solid var(--border-color-contrast-inverse);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.SplitPane > .Pane {
  overflow: hidden;
}
