.configurable-dashboard-edit-bar {
  display: flex;
  justify-content: flex-end;
  color: var(--text-color-primary);
  padding: var(--p-5);
  .bf-button {
    margin-left: var(--m-5);
  }
  &.bright {
    color: var(--text-color-contrast);
  }
}
