.cb-idly-connect-rental-suggestion {
  .data-row {
    display: flex;
    gap: 15px;
    padding-bottom: var(--p-3);
  }

  .entry {
    .label {
      font-size: var(--font-size-sm);
      color: var(--text-color-secondary);
    }
    .value {
      white-space: normal;
      button {
        white-space: normal;
        text-align: left;
        justify-content: flex-start;
      }
    }
  }
}
