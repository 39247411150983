@import "./vars.scss";

//@import '~rsuite/styles/less/index.less'; //The default style file.

.rs-drawer-backdrop.fade.in {
  animation-name: fade-in-rsuite-opacity;
}

.rs-drawer-backdrop.fade.out {
  animation-name: fade-out-rsuite-opacity;
}
.rs-dropdown-menu {
  padding: var(--p-0);
}

@keyframes fade-in-rsuite-opacity {
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

@keyframes fade-out-rsuite-opacity {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  99% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes fade-in-rsuite {
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fade-out-rsuite {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  99% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.rs-dropdown-item-panel {
  .rs-checkbox {
    .rs-checkbox-checker {
      padding-right: var(--p-10);
    }
  }
}

$hover-background: var(--table-row-color-hover);
.rs-table {
  flex-grow: 1;

  &.striped {
    .rs-table-body-row-wrapper {
      .rs-table-row {
        background: var(--table-row-color);

        &:nth-of-type(even) {
          background: var(--table-row-color-striped);

          &:hover {
            background: $hover-background;
          }

          &.selected {
            background: var(--table-row-color-selected);
            color: var(--table-row-color-selected-color);

            &:hover {
              background: var(--table-row-color-selected-hover);

              .rs-table-cell-group {
                .rs-table-cell {
                  background: var(--table-row-color-selected-hover) !important;
                }
              }
            }

            .rs-table-cell-group {
              .rs-table-cell {
                background: var(--table-row-color-selected) !important;
              }
            }
          }
        }
      }
    }
  }

  .rs-table-body-row-wrapper {
    .rs-table-row {
      background: var(--table-row-color);

      transition: background-color 0s, color 0s;

      &:hover {
        background: $hover-background;
      }

      .rs-table-cell {
        transition: background-color 0s, color 0s;
        background: none !important;
      }

      // rs-table-cell-group-fixed-left rs-table-cell-group-left-shadow
      &.selected {
        background: var(--table-row-color-selected);
        color: var(--table-row-color-selected-color);

        &:hover {
          background: var(--table-row-color-selected-hover);

          .rs-table-cell-group {
            .rs-table-cell {
              background: var(--table-row-color-selected-hover) !important;
            }
          }
        }

        .rs-table-cell-group {
          .rs-table-cell {
            background: var(--table-row-color-selected) !important;
          }
        }
      }
    }
  }
}

// .rs-modal-wrapper {
//   .rs-modal {
//     max-width: 90%;
//   }
// }
// .rs-modal-wrapper {
//   .rs-modal-xl {
//     max-width: 1300px;
//   }
// }
// .rs-modal-wrapper {
//   .rs-modal-xxl {
//     max-width: 1600px;
//   }
// }

.rs-sidenav .rs-nav-item .rs-nav-item-content > .rs-icon,
.rs-sidenav .rs-dropdown .rs-dropdown-toggle > .rs-icon {
  font-size: var(--font-size-2xl) !important;
  top: 12px;
  left: 17px;
}

.rs-alert.rs-alert-container {
  left: 0;
  right: 0;

  .rs-alert-fade-entered {
    animation: fade-in-rsuite 0.2s ease-in;
  }
  .rs-alert-item-wrapper {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;

    .rs-alert-item-content {
      // padding: 0 !important;
    }
  }
}

.rs-dropdown-open ul.rs-dropdown-menu {
  box-shadow: 0px 10px 35px -7px var(--shadow-contrast-inverse-6);
}
// .rs-dropdown-open .rs-btn.rs-btn-subtle.rs-dropdown-toggle {
//   pointer-events: none;
// }

.textarea.rs-input {
  min-width: unset;
}
.rs-nav.rs-nav-tabs {
  ul {
    li.rs-nav-item {
      a.rs-nav-item-content {
        display: flex;
        align-items: center;
      }
    }
  }
}
.rs-calendar-show-month-dropdown .rs-calendar-header-title-date {
  color: var(--base-color);
}
.rs-calendar-panel {
  .rs-calendar-table-cell-content {
    border-radius: var(--border-radius-base);
  }
  .rs-calendar-month-dropdown-cell-content:hover,
  .rs-calendar-table-cell-content:hover {
    background-color: var(--table-row-color-hover);
  }
  .rs-calendar-month-dropdown-cell-active
    .rs-calendar-month-dropdown-cell-content {
    background-color: var(--base-color);
    border-color: var(--base-color);
  }
  .rs-calendar-month-dropdown-year-active {
    color: var(--base-color);
  }
}
.rs-check-tree {
  .rs-check-item:not(.rs-checkbox-disabled):hover,
  .rs-check-item-focus {
    background-color: var(--table-row-color-hover);
  }
}
.rs-picker-select-menu-item:not(.rs-picker-select-menu-item-disabled):hover,
.rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
  background-color: var(--table-row-color-hover);
}

.rs-dropdown-menu.pull-left {
  right: 100%;
  left: initial;
}

.rs-dropdown-item.rs-dropdown-item-submenu {
  > .rs-dropdown-item-toggle {
    width: 100%;
  }
}

.rs-drawer-body {
  padding: var(--p-0);
}

// .rs-modal-full {
//   max-width: 90%;
//   height: 90%;
//   margin-left: 5%;
//   margin-top: 5%;
// }

.rs-modal-dialog {
  position: relative;
}
