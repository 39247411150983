.bf-card-component {
  background: var(--background-color-contrast);
  padding: var(--p-5);
  box-shadow: var(--shadow-contrast-inverse-5) 0px 4px 6px;
  width: 100%;
  overflow: hidden;
  border-radius: var(--border-radius-big);

  &.no-padding {
    padding: var(--p-0);
  }
}
