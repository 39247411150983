.bf-asset-chooser {
  display: flex;
  flex-direction: column;
  padding: var(--p-3);
  max-height: 100%;
  width: 350px;
  .description {
    padding-bottom: var(--p-5);
  }
  // .no-entries {
  //   text-align: center;
  //   color: var(--text-color-secondary);
  //   padding: var(--p-10);
  // }
  .search-content {
    background: var(--background-color-contrast);
    border: solid 1px var(--border-color-primary);
    border-radius: var(--border-radius-base);
    margin-top: var(--m-10);
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .selection {
      padding: var(--p-3);
      border-bottom: solid 1px var(--border-color-primary);
      background: var(--background-color-primary);
      display: flex;
      .selection-content {
        flex-grow: 1;
        .selection-label {
          font-size: var(--font-size-sm);
          color: var(--text-color-secondary);
        }
        .selection-value {
          font-size: var(--font-size-sm);
        }
      }
    }
    .bf-asset-chooser-list {
      height: 100%;
    }

    .groups-container {
      position: relative;
      height: 100%;
      width: 100%;
      overflow: hidden;

      .group-selection {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: -100%;
        transition: 0.3s ease-in-out;
        transform: translateX(0%);
        &.active {
          transform: translateX(100%);
        }

        button.bf-button.group-button {
          .group-button__label {
            flex-grow: 1;
          }
        }
      }
      .group-list {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 100%;
        transition: 0.3s ease-in-out;
        transform: translateX(0%);
        display: flex;
        flex-direction: column;

        .group-header {
          display: flex;
          align-items: center;
          border-bottom: solid 1px var(--border-color-primary);
          background: var(--background-color-primary);
          .group-title {
            padding-left: var(--p-5);
            font-size: var(--font-size-base);
            font-weight: 600;
          }
        }

        &.active {
          transform: translateX(-100%);
        }
      }
    }
    .list-button {
      display: flex;
      background: transparent;
      border-radius: var(--border-radius-none);
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      width: 100%;
      border-bottom: solid 1px var(--border-color-primary);
      &:hover {
        background: var(--table-row-color-hover);
      }
      &.selected {
        background: var(--table-row-color-hover);
      }

      &:focus:not(.rs-btn-subtle) {
        box-shadow: 0 -5px 5px -5px var(--base-color),
          0 5px 5px -5px var(--base-color);
      }
    }
    // .bf-asset-chooser-group {
    //   .bf-asset-chooser-group-button {
    //     background: transparent;
    //     gap: 10px;

    //     .bf-asset-chooser-group-label {
    //       text-transform: uppercase;
    //       letter-spacing: 1px;
    //     }
    //     .bf-asset-chooser-group-icon {
    //       transform: rotate(0deg);
    //       transition: 0.2s ease;
    //       &.open {
    //         transform: rotate(180deg);
    //       }
    //     }
    //   }
    // }
    .bf-asset-chooser-entry {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      .bf-asset-chooser-entry-label {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .bf-asset-chooser-entry-sub-label {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: var(--font-size-sm);
        color: var(--text-color-secondary);
      }
    }
  }

  &.dense {
    padding: var(--p-0);

    .search {
      .bf-input {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }

    .search-content {
      margin-top: var(--m-0);
      border-radius: var(--border-radius-none);
    }
  }
}
