.autosize-textarea {
  width: 100%;
  display: flex;
  flex-direction: column;
  .label {
    padding-bottom: var(--p-3);
  }
  textarea.textarea {
    padding: var(--p-3);
    border-radius: var(--border-radius-base);
    border-color: var(--border-color-primary);
    box-sizing: border-box;
    resize: none;
    width: 100%;
  }
  &.error {
    .label {
      color: var(--color-negative);
    }
    textarea.textarea {
      border-color: var(--color-negative);
    }
  }
}
