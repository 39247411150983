.ra-cost-center-view {
  --border-color: var(--border-color-primary);
  .open-action {
    display: flex;
    justify-content: center;
    button.bf-button {
      .bf-icon {
        margin-right: var(--m-3);
        width: 16px;
        height: 16px;
        transition: transform 0.4s ease;
      }
      &.collapsed {
        .bf-icon {
          transform: scaleY(-1);
        }
      }
    }
  }
  .table-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    .action {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .cost-centers {
      width: 100%;
      .empty-text {
        width: 100%;
        text-align: center;
        color: var(--text-color-secondary);
        padding: var(--p-5);
      }
      .ra-cost-center-entry {
        display: flex;
        width: 100%;
        align-items: center;
        border-bottom: solid 1px var(--border-color-primary);
        padding-bottom: var(--p-5);
        padding-top: var(--p-5);
        &:last-of-type {
          border-bottom: none;
        }
        .count-indicator {
          width: 90px;
          font-size: var(--font-size-sm);
          color: var(--text-color-secondary);
        }
        .cost-center-data {
          flex-grow: 1;
          white-space: normal;
          word-break: normal;
          display: flex;
          .data-entry {
            .label {
              font-size: var(--font-size-sm);
              color: var(--text-color-secondary);
              margin-bottom: var(--m-0);
            }
            .value {
              white-space: normal;
              word-break: normal;
            }
          }
          .cost-center {
            width: 20%;
            overflow: hidden;
            padding: 0px 10px;
          }
          .amount {
            width: 15%;
            padding: 0px 10px;
            overflow: hidden;
          }
          .object {
            width: 30%;
            padding: 0px 10px;
            overflow: hidden;
          }
          .info {
            width: 35%;
            padding: 0px 10px;
            overflow: hidden;
          }
        }
      }
    }

    .cost-center-table {
      width: 100%;
      position: relative;
      .inset {
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        right: 0;
        pointer-events: none;
        box-shadow: var(--default-inset-shadow);
      }
      .header {
        border-bottom: solid 1px var(--border-color);
        font-weight: 500;
      }
      .ra-cost-center-entry {
        &:nth-child(odd) {
          background-color: var(--background-color-primary);
        }
      }
      .header,
      .ra-cost-center-entry {
        display: flex;
        width: 100%;
        white-space: normal;
        word-break: normal;

        .cost-center {
          width: 25%;
          overflow: hidden;
          padding: 5px 10px;
          border-right: solid 1px var(--border-color);
        }
        .amount {
          width: 15%;
          padding: 5px 10px;
          overflow: hidden;
          text-align: right;
          border-right: solid 1px var(--border-color);
        }
        .object {
          width: 25%;
          padding: 5px 10px;
          overflow: hidden;
          border-right: solid 1px var(--border-color);
        }
        .info {
          width: 35%;
          padding: 5px 10px;
          overflow: hidden;
        }
      }
    }
  }
}
