.loader_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: var(--background-opacity-tertiary-main);

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 9999;
}
