.ez-autocomplete {
  padding-right: var(--p-5);
  position: relative;
  --search-width-expanded: 360px;
  &.error {
    textarea::placeholder {
      color: var(--color-negative);
    }
  }
  .autosize-textarea {
    textarea.textarea {
      padding: 7px 11px;
    }
  }
  .bf-input {
    transition: width 0.2s ease 0.1s;
    width: 150px;

    .suffix {
      padding: 0px 2px 0px 0px;
      height: 30px;

      background: transparent;
      color: var(--text-color-secondary);
      transition: color 0.2s ease;

      .bf-button {
        padding: 3px 8px;
        height: 100%;
        .bf-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 12px;
          height: 12px;
        }
      }
    }
    .prefix {
      padding: 4px 8px;
      height: 100%;
      background: transparent;
      color: var(--text-color-secondary);
      transition: color 0.2s ease;
      .bf-icon {
        width: 16px;
        height: 16px;
      }
    }
    input {
      padding-left: var(--p-0);
    }
  }

  &.expanded {
    .bf-input {
      width: var(--search-width-expanded);
      .prefix {
        color: var(--text-color-primary);
      }
    }
  }
}

.search-autocomplete-wrapper {
  // position: absolute;
  // top: 100%;
  width: var(--search-width-expanded);
  z-index: 5;
  background: var(--background-color-contrast);
  .auto-complete-entries {
    background: var(--background-color-contrast);
    max-height: 400px;
    //   min-height: 100px;
    overflow: auto;
    box-shadow: var(--card-default-box-shadow);
    border-bottom-left-radius: var(--border-radius-base);
    border-bottom-right-radius: var(--border-radius-base);

    .ez-autocomplete-entry {
      width: 100%;
      padding: 10px 8px;
      background: var(--background-color-contrast);
      color: var(--text-color-primary);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.focused {
        background: var(--table-row-color-hover);
      }
      text-align: left;
      .type-label {
        font-size: var(--font-size-sm);
        color: var(--text-color-secondary);
        font-weight: 500;
        padding-right: var(--p-3);
      }
      .value-label {
      }
    }
  }
}
