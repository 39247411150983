.cdn-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &.error {
  }
  &.shadow {
    box-shadow: var(--card-default-box-shadow);
  }

  div.image-div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-position: center center;
    background-size: cover;
    // &:hover {
    // 	transform: scale(4);
    // 	z-index: 100;
    // }
  }
  img.image-img {
  }
}
.cdn-image-entry {
  &.clickable {
    cursor: pointer;
  }
}

.image-description-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  .image-description {
    padding: 10px 20px;
    background: var(--background-opacity-primary);
    color: var(--text-color-contrast);
    border-radius: var(--border-radius-base);
    border: solid 1px var(--border-color-secondary);
  }
}

.cdn-image-hover {
  opacity: 0;
  animation-fill-mode: forwards;
  animation-name: image-hover-fade-in;
  animation-delay: 1s;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  transform: scale(0.8);
  img {
    box-shadow: 0px 0px 8px 0px var(--shadow-contrast-inverse-6);
  }
}

@keyframes image-hover-fade-in {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
