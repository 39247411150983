@import "../../styles/vars.scss";
.view-modal-route.rs-modal {
  .rs-modal-dialog {
    .rs-modal-content {
      //   max-height: 95vh;
      //   overflow: auto;
      .rs-modal-body {
        padding: var(--p-0);
      }
    }
  }
}

.view-modal-route {
  .rs-modal-dialog {
    .rs-modal-content {
      .rs-modal-body {
        max-height: 100% !important;
        .nav-layout {
          .content {
            overflow: auto;
          }
        }
      }
    }
  }
}
.view-modal-route.rs-modal.rs-modal-full {
  // overflow: hidden;
  // margin: var(--m-0);
  // height: 100%;
  // width: 100%;
  // max-width: none;
  // display: flex !important;

  // align-items: center;
  // justify-content: center;

  .rs-modal-dialog {
    // margin: var(--m-0);
    // height: fit-content;
    // width: 90%;
    // max-width: 1700px;
    // max-height: 90%;
    .rs-modal-content {
      // overflow: auto;
      height: 100%;
    }
    //   padding: var(--p-0);
    //   height: 100%;
    //   display: flex;
    //   flex-direction: column;

    //   .rs-modal-header {
    //     padding: var(--p-5);
    //     .rs-modal-header-close {
    //       top: 10px;
    //       right: 10px;
    //     }
    //   }
    //   .rs-modal-body {
    //     flex-grow: 1;
    //     max-height: 100%;
    //     height: 0;
    //     padding: var(--p-0);
    //     margin-top: var(--m-0);

    //     .nav-layout {
    //       height: 100%;

    //       .rs-nav.rs-nav-tabs.rs-nav-vertical {
    //         height: 100%;
    //         ul {
    //           li {
    //             a {
    //               .rs-nav-item-text {
    //                 padding-right: var(--p-13);
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }
}

.rs-modal-wrapper {
  //   overflow: hidden;
}

@media screen and (max-width: 800px) {
  .view-modal-route.rs-modal.rs-modal-full {
    width: 100%;
    max-width: 100%;
    margin: var(--m-0);
    .rs-modal-dialog {
      height: 100%;
      width: 100%;
    }
  }
}
.rs-modal-wrapper {
  .view-modal-route.max {
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    height: calc(100% - 40px);
    max-height: calc(100% - 40px);
    overflow: hidden;
    margin: var(--m-10);

    @media screen and (max-width: 1200px) {
      width: calc(100% - 20px);
      max-width: calc(100% - 20px);
      height: calc(100% - 20px);
      max-height: calc(100% - 20px);
      margin: var(--m-5);
    }
    @media screen and (max-width: 800px) {
      width: calc(100%);
      max-width: calc(100%);
      height: calc(100%);
      max-height: calc(100%);
      margin: var(--m-0);
    }
    .rs-modal-dialog {
      height: 100%;
      .rs-modal-content {
        max-height: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: var(--border-radius-none);

        .rs-modal-body {
          margin: var(--m-18) var(--m-0) var(--m-0) var(--m-0);
          flex-grow: 1;
          overflow: auto;
        }
      }
    }
  }
}
