.ap-activity-offers {
  height: 100%;
  .bf-dropzone {
    height: 100%;
  }
  .dropzone-content {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .header {
    }

    .offer-list {
      overflow: auto;
      flex-grow: 1;
      padding-top: var(--p-10);
      .no-offers {
        text-align: center;
        padding: var(--p-10);
      }
    }
  }
}

.ap-activity-offer {
  background: var(--background-color-contrast);
  border-radius: var(--border-radius-base);
  margin-bottom: var(--m-5);
  padding: var(--p-5);
  .offer-form {
    display: flex;
    .checkbox-wrapper {
      padding: 10px 10px 10px 0px;
    }
    .offer-action {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 10px 10px 0px;
    }
    .offer-content {
      flex-grow: 1;
      &.selectable {
        cursor: pointer;
      }
      .row-1 {
        display: flex;
        font-size: var(--font-size-md);
        overflow: hidden;
        .vendor {
          flex-grow: 1;
          padding-right: var(--p-5);
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .value-input {
          input {
            padding-right: var(--p-1);
            font-size: var(--font-size-sm);
          }
          .suffix {
            padding-left: var(--p-2);
            min-width: 30px;
          }
        }
      }
      .row-2 {
        .note {
          width: 100%;
        }
      }
      .row-comparison {
        .rs-checkbox {
          display: block;
        }
        .comparison-entries {
          .comparison-entry {
            display: flex;
            padding-bottom: var(--p-3);

            gap: 10px;
            align-items: center;
            .label {
              color: var(--text-color-secondary);
              font-size: var(--font-size-xs);
            }
            input {
              padding-right: 2px 2px 2px 10px;
              font-size: var(--font-size-sm);
              background: var(--background-color-primary);
            }
            .suffix {
              display: flex;
            }
            .remove-action {
              padding-top: var(--p-9);
            }
          }
        }
        .add-action {
          display: flex;
          justify-content: center;
        }
      }
      .row-3 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-top: var(--p-3);
        gap: 10px;
        .entry {
        }
        .user-selections {
          display: flex;
          > .description {
            padding-right: var(--p-2);
          }
          // flex-wrap: wrap;
          .selection-entry {
            margin: var(--m-0) var(--m-0) var(--m-0) var(--m-2);
          }
        }
      }
    }
  }
}
.ap-activity-upload-offer-dialog {
  .rs-modal-content {
    .rs-modal-body {
      max-height: initial !important;
    }
  }
}

.ap-activity-user-select {
  padding: var(--p-4);
  .description {
    padding-bottom: var(--p-2);
    font-style: italic;
    color: var(--text-color-secondary);
  }
  .user-name {
    font-weight: 600;
    text-align: right;
  }
  .date {
    color: var(--text-color-secondary);
    text-align: right;
  }
}
