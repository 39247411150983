.notifications-menu {
  background: var(--background-color-primary);
  height: 100%;
  &.embedded {
  }
  width: 400px;
  overflow: hidden;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
  position: relative;
  display: flex;
  flex-direction: column;

  & > .header {
    background: var(--background-color-contrast);
    box-shadow: 0 3px 4px -2px grey;
    display: flex;
    flex-shrink: 0;
    border-bottom: solid 1px var(--border-color-secondary);
    .title {
      flex-grow: 1;
      font-size: 1.4em;
      padding: var(--p-5);
    }
  }
  & > .changelog-notification {
    width: 100%;
    background: var(--background-color-focus);
    padding: var(--p-5);
    display: flex;
    align-items: center;
    .changelog-text {
      flex-grow: 1;
    }
  }
  & > .content {
    overflow: auto;
    flex-grow: 1 1 auto;

    // height: 0;
    .empty-label {
      padding: 15px 5px 15px 5px;
      text-align: center;
    }
  }
  & > .footer {
    &.active {
      display: block;
    }
    display: none;
    min-height: 30px;
    box-shadow: 0 -3px 4px -2px grey;
  }
}
