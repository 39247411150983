.ganttTable {
  display: table;
  border-bottom: var(--border-color-primary) 1px solid;
  border-top: var(--border-color-primary) 1px solid;
  border-left: var(--border-color-primary) 1px solid;
}

.ganttTable_Header {
  display: table-row;
  list-style: none;
}

.ganttTable_HeaderSeparator {
  border-right: 1px solid var(--border-color-secondary);
  opacity: 1;
  margin-left: -2px;
}

.ganttTable_HeaderItem {
  display: table-cell;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
}
