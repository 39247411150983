.bf-checktree {
  position: relative;
  border: 1px solid var(--rs-border-primary);
  border-radius: var(--border-radius-base);
  .rs-picker-none {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.striped {
    .rs-check-tree-node {
      border-bottom: solid 1px var(--border-color-primary);
    }
  }
  &.item-full-width {
    .rs-check-tree {
      .rs-check-tree-node {
        .rs-check-item {
          width: calc(100% - 1px);
        }
      }
    }
  }

  .rs-check-tree-view {
    height: fit-content !important;
    max-height: fit-content !important;
    overflow-y: visible;
  }
}
