.bf-dropdown-content,
.bf-dropdown {
  .info-text {
    font-size: var(--font-size-sm);
    color: var(--text-color-secondary);
    text-wrap: initial;
    padding: 0px 12px 8px 12px;
  }
  .rs-dropdown-item {
    padding: var(--p-0);
  }
  .dropdown-click-wrapper {
    padding: 8px 12px;
    width: 100%;
    display: flex;
    align-content: center;
    &.with-info {
      padding-bottom: var(--p-2);
    }
    > .bf-icon {
      margin-right: var(--m-5);
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    > .dropdown-button-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    }
    > .dropdown-suffixed-button {
      margin-left: auto;
    }
  }
  //   .dropdown-item-button {
  //     display: flex;
  //     align-items: center;
  //   }
  .dropdown-item-check {
    padding: var(--p-0);
    display: flex;
    align-items: center;
    .rs-checkbox-checker {
      label {
        padding-right: var(--p-10);
        white-space: nowrap;
      }
    }
  }

  .dropdown-item-menu {
    > .dropdown-item-menu.rs-dropdown-item {
      &.active {
        background-color: var(--rs-dropdown-item-bg-active);
        color: var(--rs-dropdown-item-text-active);
      }
      display: flex;
      align-items: center;
      > .bf-icon {
        margin-right: var(--m-5);
        width: 16px;
        height: 16px;
      }
    }
  }

  .dropdown-item-menu {
    &.rs-dropdown-item-open {
      .dropdown-item-menu.rs-dropdown-menu {
        display: block;
      }
    }
  }
}
