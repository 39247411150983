.bf-list {
  .refresh-data-node {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--p-10);
    width: 100%;
    flex-direction: column;
    .info {
      padding-bottom: var(--p-3);
      color: var(--text-color-secondary);
      font-size: var(--font-size-sm);
      font-style: italic;
    }
  }
}
