.load-template-modal {
  padding: var(--p-10);
  .header {
    padding-bottom: var(--p-5);
    font-size: var(--font-size-md);
  }
  .actions {
    padding-top: var(--p-10);
  }
}
