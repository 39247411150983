.cb-booking-card {
  width: 100%;
  padding: 5px 8px;

  .__card {
    position: relative;
  }
  &.forecast {
    .__card {
      background: var(--cashbudget-color-prognose);
    }
  }
  &.sharepoint {
    .__card {
      background: var(--cashbudget-color-sharepoint);
    }
  }
  &.fixed-comparison {
    .__card {
      background: var(--cashbudget-color-fixed-comparison);
    }
  }

  .category-changed-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--background-opacity-primary);
    color: var(--text-color-contrast);
  }
}
