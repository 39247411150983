.contact-detail-view {
  background: var(--background-color-contrast);
  box-shadow: var(--card-default-box-shadow);
  border-radius: var(--border-radius-big);
  height: 90vh;

  // .tab-bar {
  //   .tab-bar-nav {
  //     font-size: 16px;
  //   }
  // }
  .scroll-view-with-padding {
    overflow: auto;
    padding: var(--p-10);
    height: 100%;
  }
  .view-with-padding {
    padding: var(--p-10);
    height: 100%;
  }
}
