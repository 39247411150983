.application-drawer-button.rs-btn {
  border-radius: var(--border-radius-extra-large);
  transform: scale(1);
  transition: transform 0.2s, background-color 0.2s;
  padding: var(--p-0);
  height: 150px;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.small {
    height: 100px;
    width: 100px;

    .application-logo {
      height: 50px;
      width: 50px;
      border-radius: 10px;
    }

    .application-title {
      font-size: 0.8em;
    }
  }

  &.active {
    transform: scale(1.1);
    opacity: 0.5;
  }

  &:hover {
    transform: scale(1.2);
    background-color: transparent !important;
  }

  &:focus {
    transform: scale(1.2);
    background-color: transparent !important;
  }

  .application-logo {
    height: 80px;
    width: 80px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 15px;
  }

  .application-title {
    margin-top: var(--m-5);
    font-size: 1em;
    color: var(--text-color-strong);
    // text-transform: uppercase;
  }
  &.rs-btn-loading {
    .application-drawer-icon {
      opacity: 0.5;
    }

    .rs-btn-spin {
      &::before {
        top: -30px;
        width: 50px;
        height: 50px;
      }

      &::after {
        top: -30px;
        width: 50px;
        height: 50px;
      }
    }
  }
}
