.pdf-constructor--block {
  background: white;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color-primary);
  border-radius: 0.25rem;

  header {
    padding: 0.5rem 1.25rem;
    font-family: bold;
    display: flex;
    align-items: center;
    background-color: var(--background-color-primary);
    gap: 0.5rem;

    button.pdf-constructor--block-title {
      display: flex;
      gap: 0.5rem;
      flex: 1;
      width: 100%;
      justify-content: space-between;
    }
  }

  main {
    padding: 0.75rem 1rem;

    & > div {
      gap: 0.5rem;
      display: flex;
      flex-direction: column;
    }
  }

  footer {
    display: flex;
    gap: 0.5rem;
    justify-content: end;
  }

  .grid-col-2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    .col-span-2 {
      grid-column: span 2 / span 2;
    }
  }
}
