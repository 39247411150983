.error-boundary {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .smiley {
    padding: var(--p-0);
    font-size: 5em;
    transform: rotate(90deg);
  }
  .msg {
    font-size: 1.8em;
    text-align: center;
    max-width: 70%;
  }
  .restart {
    font-size: 1.7em;
    margin-top: var(--m-5);
  }
  .error {
    padding: var(--p-5);
    font-family: monospace;
    font-size: var(--font-size-sm);
    .error_name {
      font-weight: bold;
      padding-bottom: var(--p-5);
    }
    .error_stack {
      max-height: 150px;
      overflow: auto;
      padding: var(--p-3);
      border: solid 1px var(--border-color-primary);
    }
  }
}
