.assignment-label {
  display: inline-flex;
  align-items: center;
  .user-label-container {
    padding-left: var(--p-2);
    display: inline-flex;
    align-items: center;
  }
  .team-label-container {
    padding-left: var(--p-2);
    display: inline-flex;
    align-items: center;
  }
  .futher-entries {
    padding-left: var(--p-2);
  }

  &.as-list {
  }
}

.assignment-label-tooltip {
  .section-title {
    padding-top: var(--p-5);
    padding-bottom: var(--p-3);
    font-weight: bold;
    letter-spacing: 1px;
  }
}
