.cb-idly-connect {
  .suggestions {
    .no-suggestions {
      display: flex;
      gap: 10px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .no-suggestion-text {
        text-align: center;
        color: var(--text-color-secondary);
      }
    }
    .info-text {
      color: var(--text-color-secondary);
    }
    .suggestion-list {
      .show-further-button {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: var(--p-8);
      }
    }
    .manual-action {
      padding: 10px 10px 0px 10px;
      display: flex;
      gap: 10px;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .manual-text {
        text-align: center;
        color: var(--text-color-secondary);
      }
    }
  }
  .action-footer {
  }

  // &.cb-idly-connect-invoice {
  //   > .content {
  //     .invoices {
  //       .invoice-entry {
  //         padding: var(--p-5);
  //         border-bottom: solid 1px var(--border-color-primary);
  //         &:last-of-type {
  //           border-bottom: none;
  //         }
  //         .invoice-data {
  //           display: flex;
  //           .ra-invoice-entry-view {
  //             flex: 1px;
  //           }
  //         }
  //         .actions {
  //           display: flex;
  //           gap: 10px;
  //           justify-content: flex-end;
  //         }
  //       }
  //     }
  //   }
  // }
}
