.list_item {
  max-width: 1100px;

  .entry {
    width: 100%;
    overflow: hidden;
    text-align: left;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
  }

  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }
}
