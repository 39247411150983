.ap-budget-form {
  // height: 100%;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  form {
    // height: 100%;
    display: flex;
    flex-direction: column;
    .form-fields {
      flex: 1;
      .budget-plan {
        // height: 100%;
      }
    }
    .further-fields {
      display: flex;
      gap: 20px;
      padding-bottom: var(--p-10);
      .field.start-year input {
        width: 110px;
      }
      .field.turnus input {
        width: 80px;
      }
    }
  }
  .send-to-approval {
    margin-bottom: var(--m-10);
    display: flex;
    justify-content: flex-end;
  }
  .bf-message {
    margin-bottom: var(--m-10);
  }
  .gantt-card {
    .title {
      padding: var(--p-5);
    }
  }
}
