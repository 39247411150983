.ap-budget-invoices {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .filter-header {
    display: flex;
    gap: 10px;
  }
  .invoice-list {
    margin-top: var(--m-10);
    margin-bottom: var(--m-10);
    flex-grow: 1;
    overflow: hidden;

    .amount-info {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      .invoice-payment-plan {
        margin-right: var(--m-3);
      }
    }
  }
}
