.kitchen-sink {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  --rs-navbar-default-bg: #343434;
  --rs-navbar-default-hover-bg: #1c1c1c;
  --rs-navbar-default-text: #eeecec;
  --rs-navbar-default-selected-text: #fff;
  --rs-navbar-default-hover-text: #fff;
  --rs-text-link-active: rgb(199, 254, 255);
  .page-content {
    flex-grow: 1;
    overflow: auto;
  }
}
