.bf-loan-data {
  .validation {
    padding-top: var(--p-5);
    &.error {
      color: var(--color-negative);
    }
  }
  .information {
    &.valid {
      .message-content {
        align-items: center;
        display: flex;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }
  .values {
    box-shadow: var(--default-inset-shadow);

    .header-wrapper {
      overflow: hidden;
    }
    .row-entry {
      display: flex;
      width: 100%;
      min-width: fit-content;
      &.edited {
        .column {
          font-weight: 700;
        }
      }
      &.confirmed {
        .column {
          color: var(--color-positive);
        }
      }
      &.year-switch {
        border-top: solid 1px var(--border-color-tertiary);
      }
      &.odd {
        background: var(--background-opacity-tertiary-weak);
      }

      &.header {
        padding-right: var(--p-4);
        border-bottom: solid 1px var(--border-color-secondary);
        .column {
          user-select: none;
          // display: flex;
          padding: 7px 5px;
          border-right: solid 1px var(--border-color-primary);
          &:last-of-type {
            border-right: none;
          }
        }
      }
      .column {
        user-select: text;
        border-right: solid 1px var(--border-color-primary);
        padding: 7px 5px;
        &.edit {
          padding: var(--p-0);
        }
        // height: 100%;
        &:last-of-type {
          border-right: none;
        }

        &.center {
          text-align: center;
        }
        &.left {
          text-align: left;
        }
        &.right {
          text-align: right;
        }
        &.paymentNumber {
          width: 80px;
          min-width: 80px;
        }
        &.date {
          width: 115px;
          min-width: 115px;
        }
        &.startBalance {
          flex: 140 0 auto;
          width: 140px;
        }
        &.totalPayment {
          flex: 140 0 auto;
          width: 140px;
        }
        &.payout {
          flex: 140 0 auto;
          width: 140px;
        }
        &.otherCost {
          flex: 140 0 auto;
          width: 140px;
        }
        &.repaymentAmount {
          flex: 140 0 auto;
          width: 140px;
        }
        &.repaymentInPercent {
          width: 100px;
          min-width: 100px;
        }
        &.interestAmount {
          flex: 140 0 auto;
          width: 140px;
        }
        &.endBalance {
          flex: 140 0 auto;
          width: 140px;
        }
        &.accumulatedInterest {
          flex: 140 0 auto;
          width: 140px;
        }
        &.comment {
          flex: 250 0 auto;
          width: 250px;
          min-width: 250px;
        }
        &.actions {
          width: 100px;
          display: flex;
          button.bf-button {
            padding: 2px 5px;
          }

          .further-actions.rs-dropdown-menu {
            .rs-dropdown-item {
              .icon-entry {
                display: flex;
                .bf-icon {
                  margin-right: var(--m-5);
                }
              }
            }
          }
        }
      }
    }
  }
}
