.cdn-file-entry {
  display: flex;
  align-items: center;
  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    background: transparent;
    padding: 8px 0px;
    cursor: pointer;
    &:hover {
      background: var(--background-color-primary);
    }

    .filetype {
      padding-right: var(--p-5);
    }
    .name {
      flex-grow: 1;
      text-align: left;
    }
  }
}
