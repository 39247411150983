.cashbudget-notification-container {
  display: flex;
  justify-content: center;
  margin-top: var(--m-3);
}

.entity-entry {
  .type-ident {
    font-size: var(--font-size-xs);
    padding-left: var(--p-2);
    color: var(--text-color-secondary);
  }
}
