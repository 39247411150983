.cb-rental-units-overview-table {
  .status-cell {
    display: flex;
    gap: 5px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    .cell {
      overflow: hidden;
    }
  }

  .ListItemOdd,
  .ListItemEven {
    .table-row {
      border-bottom: solid 1px var(--border-color-secondary);
    }
  }
  .table-row.vacant {
    background: var(--background-color-light-error);
    .row-columns {
      font-weight: bold;
      background: var(--background-color-light-error);
    }
    &.selected {
      .row-columns {
        background-color: var(--table-row-color-selected);
      }
    }
  }
  .sub-rows {
    border-top: solid 1px var(--border-color);
    .unit-row {
      display: flex;
      gap: 10px;
      align-items: center;
      padding-left: var(--p-10);

      &:last-child {
        border-bottom: none;
      }
      .line {
        height: 100%;
        width: 1px;
        background-color: var(--border-color);
        position: relative;
        padding-right: var(--p-5);
      }

      .name {
        min-width: 200px;
      }
      .building {
        min-width: 100px;
      }
      .area-quantity {
        min-width: 100px;
      }
      .status {
        min-width: 200px;
      }
      .plan-rent {
        min-width: 150px;
        .prefix {
          font-size: var(--font-size-sm);
          color: var(--text-color-secondary);
        }
        .gross {
          font-size: var(--font-size-sm);
        }
      }
    }
  }

  .footer-averages {
    max-width: 100%;
    overflow: hidden;
    .entry {
      text-align: right;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      .label {
        color: var(--text-color-secondary);
        padding-right: var(--p-2);
        font-size: var(--font-size-sm);
        text-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .value {
        text-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
