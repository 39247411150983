.object-related-select {
  .object-related-menu {
    .main {
      text-align: left;
    }
    .sub {
      text-align: left;
      font-size: var(--font-size-sm);
      color: var(--text-color-secondary);
    }
  }
  .object-related-value {
    text-overflow: ellipsis;
    overflow: hidden;
    .main {
    }
    .sub {
      font-size: var(--font-size-sm);
      color: var(--text-color-secondary);
    }
  }
}

.object-related-value.rental-agreement {
  .no-data {
    color: var(--color-negative);
  }
}
