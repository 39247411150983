.contact-invoice-kpis {
  display: flex;
  gap: var(--p-10);
  justify-content: flex-end;
  align-items: center;

  .kpi-value {
    .label {
      font-size: var(--font-size-sm);
      text-align: right;
    }
    .value {
      font-size: var(--font-size-lg);
      text-align: right;
    }
  }
}
