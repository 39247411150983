.bf-tabs {
  .rs-nav.rs-nav-tabs.rs-nav-horizontal {
    .rs-nav-bar {
      border-top: none;
    }
    .rs-nav-item.error {
      color: var(--color-negative);
    }
  }
  .tab-content {
    margin-top: -1px;
    padding: var(--p-5);
    border-left: 1px solid var(--rs-navs-tab-border);
    border-bottom: 1px solid var(--rs-navs-tab-border);
    border-right: 1px solid var(--rs-navs-tab-border);
    border-top: 1px solid var(--rs-navs-tab-border);
    border-bottom-left-radius: var(--border-radius-base);
    border-bottom-right-radius: var(--border-radius-base);
    border-top-right-radius: var(--border-radius-base);
  }
}
