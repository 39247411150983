.ap-offer-approvals {
  height: 100%;
  display: flex;
  flex-direction: column;
  .actions {
    padding-bottom: var(--p-10);
    display: flex;
  }
  .approvals {
    flex-grow: 1;
    overflow: auto;
    .approval-entry.__card {
      margin-bottom: var(--m-10);

      .rs-radio-tile-body {
        width: 100%;
      }
    }
  }
}
