.ra-invoice-direction-indicator {
  display: inline-flex;
  align-items: center;
  padding: 1px;

  border-radius: var(--border-radius-big);
  .icon {
    display: flex;
    border-radius: var(--border-radius-big);
    width: 22px;
    height: 22px;
    justify-content: center;
    align-items: center;
    .bf-icon {
      padding-left: var(--p-1);
      padding-top: 1px;
    }
  }
  .label {
    padding-left: var(--p-3);
    padding-right: var(--p-5);
    font-size: var(--font-size-base);
  }
  &.incoming {
    background: var(--invoice-indicator-incoming-icon-background);
    color: var(--invoice-indicator-incoming-icon-foreground);
    .icon {
      background: var(--invoice-indicator-incoming-icon-background);
      .bf-icon {
        padding-top: var(--p-1);
      }
    }
  }
  &.outgoing {
    background: var(--invoice-indicator-outgoing-icon-background);
    color: var(--invoice-indicator-outgoing-icon-foreground);
    .icon {
      background: var(--invoice-indicator-outgoing-icon-background);
    }
  }
}
