.object-stacking-plan-overlay {
  .overlay-title {
    font-size: var(--font-size-base);
    font-weight: 600;
    .id {
      color: var(--text-color-secondary);
      font-size: var(--font-size-sm);
      padding-right: var(--p-3);
    }
  }
  .entry {
    display: flex;
    font-size: var(--font-size-sm);
    .key {
      flex-grow: 1;
      font-weight: 600;
      color: var(--text-color-secondary);
      font-size: var(--font-size-xs);
      padding-right: var(--p-5);
    }
  }
}
