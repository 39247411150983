.rs-categorized-status-list {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px 0;
  .rs-status-container {
    .rs-entry-container {
      border-left: 1px solid var(--border-color-secondary);
      margin-left: var(--m-10);
      .rs-categorized-list-entry {
        margin-left: var(--m-13);
      }
    }
  }
}
