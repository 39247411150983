.restrict-component {
  .restrict-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background: var(--background-opacity-secondary);
    z-index: 4;
    background: var(--background-opacity-weak);
    opacity: 0;
    transition: opacity 0.1s;
    pointer-events: none;
    &.active {
      pointer-events: all;
      opacity: 1;
    }
  }
  .restrict-container {
    z-index: 5;
    .current-restrictions {
      display: flex;
      flex-wrap: wrap;
    }
    .add-restrictions {
      margin: var(--m-3) var(--m-5) var(--m-3) var(--m-3);
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 32px;
      overflow: hidden;
    }
  }

  .add-form {
    position: relative;
    background: var(--background-color-contrast);
    box-shadow: 0px 2px 5px var(--shadow-restrict-component-add-form);
    z-index: 5;
    * {
      z-index: 5;
    }
    border: solid 1px var(--border-color-secondary);
    .padding-wrapper {
      padding: var(--p-3);

      .search-bar {
      }
      .search-entries {
        position: relative;
        width: 100%;
        min-height: 140px;
        .loader {
          position: absolute;
          min-height: 124px;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: var(--background-opacity-secondary);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .results {
          display: flex;
          overflow: auto;
          .no-results {
            width: 100%;

            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 124px;
            text-align: center;
          }
          .user-result {
            z-index: 5;
            position: relative;
            display: inline-flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin: var(--m-3);
            padding: var(--p-3);
            width: 100px;
            width: 100px;
            .name {
              text-align: center;
            }
            .hover {
              transition: opacity 0.4s;
              opacity: 0;
              border-radius: var(--border-radius-big);
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              bottom: 0;
              background: var(--background-opacity-primary);
              display: flex;
              justify-content: center;
              align-items: center;
              color: var(--text-color-contrast);
            }
            &:hover {
              .hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  .user-entry {
    z-index: 5;
    margin: var(--m-3) var(--m-4) var(--m-3) var(--m-3);
    display: inline-flex;
    align-items: center;
    border-radius: 32px;
    position: relative;
    background: var(--background-color-primary);
    transition: margin 0.2s;

    &.added {
      animation-name: action-fade-in;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
    }
    &.deleted {
      animation-name: action-fade-out;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
    }

    .avatar {
    }
    .name {
      padding-left: var(--p-3);
      padding-right: var(--p-6);
      transition: padding 0.2s, transform 0.2s;
      height: 32px;
      line-height: 32px;
      background: var(--background-color-primary);
      border-top-right-radius: 32px;
      border-bottom-right-radius: 32px;
      z-index: 1;
    }

    &:hover {
      .name {
        padding-left: var(--p-0);
        padding-right: var(--p-3);
        transform: scale(0.9);
      }
      margin: var(--m-3) var(--m-10) var(--m-3) var(--m-3);
      .delete-action {
        transform: translateX(20px);
      }
    }
    .delete-action {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 40px;
      border-top-right-radius: 32px;
      border-bottom-right-radius: 32px;
      background: var(--background-color-secondary);
      z-index: 0;
      transition: transform 0.2s;
      overflow: hidden;
      .bf-button {
        height: 100%;
        width: 100%;
        padding-left: var(--p-10);
        font-size: 0.6em;
        background: transparent;
      }
    }
  }
}

@keyframes action-fade-in {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes action-fade-out {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.8);
  }
}
