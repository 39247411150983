.cb-idly-connect-invoice-suggestion {
  display: flex;
  .pdf-preview-container {
    padding-top: var(--p-20);
  }
  .ra-invoice-entry-view {
    flex: 1;
    .right {
      padding-right: var(--p-0);
    }
  }
}
