.cb-rental-agreement-details {
  position: relative;
  height: 100%;
  overflow: hidden;

  .message-box-title {
    font-size: var(--font-size-md);
    font-weight: bold;
    padding-bottom: var(--p-3);
  }
  .object-stacking-plan-wrapper {
    height: 100%;
    width: 100%;
    border-bottom: solid 1px var(--border-color-primary);
  }
  .page-content {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    .header {
      display: flex;
      align-items: center;
      .bf-button.back-button {
      }
      .title {
        flex: 1;
        padding-left: var(--p-2);
        .id {
          color: var(--text-color-secondary);
          padding-top: var(--p-5);
          padding-left: var(--p-2);
          padding-right: var(--p-3);
          font-size: var(--font-size-md);
        }
        .name {
          color: var(--text-color-strong);
          padding-left: var(--p-2);
          padding-right: var(--p-3);
          font-size: var(--font-size-lg);
        }
        .object {
          display: flex;
          gap: 10px;
          align-items: center;
          .entity {
            font-size: var(--font-size-sm);
          }
        }
      }
    }
    .content {
      padding: var(--p-5);

      .option-entry {
        .title {
          margin-top: var(--m-5);
          font-weight: bold;
          padding-bottom: var(--p-3);
        }
      }
      .deposit,
      .rental-units,
      .rent-data,
      .tenant-data {
        padding-top: var(--p-3);
      }
      .deposit {
        .__empty {
          padding: 5px 5px 5px 0px;
          text-align: left;
        }
      }
      .rental-unit-header,
      .rent-header {
        display: flex;
        align-items: center;
        padding-bottom: var(--p-3);
        .bf-button {
          margin-left: var(--m-5);
        }
        .__h3 {
          padding-bottom: var(--p-0);
        }
      }
      .__h3 {
        padding-bottom: var(--p-3);
      }

      .bf-value-display__value {
        .plan {
          font-size: var(--font-size-sm);
          color: var(--text-color-secondary);
        }
      }
      .rental-agreement-section {
        padding-bottom: var(--p-10);
        .section-title {
          display: flex;
          align-items: center;
          padding-bottom: var(--p-3);
          gap: 10px;
          .__h3 {
            padding-bottom: var(--p-0);
          }
        }
        .__empty {
          padding: 5px 5px 5px 0px;
          text-align: left;
        }
      }
      .cb-rental-agreement-rental-unit-entry {
        .column {
          padding: var(--p-2);
        }
      }
    }
  }
}
