.cb-loan-chart {
  height: 600px;
  &.empty {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    .empty-text {
      color: var(--text-color-secondary);
    }
  }
}

.cb-loan-chart-tooltip {
  font-family: "Roboto";
  .head {
    margin-bottom: var(--m-5);
  }
  .divider {
    margin: var(--m-4);
    height: 1px;
    background: var(--divider-color);
  }
  .entry {
    display: flex;
    .label {
      flex-grow: 1;
      &.bold {
        font-weight: 500;
      }
    }
    .value {
      padding-left: var(--p-10);
    }
  }
}
