.object-detail-view {
  .detail-container {
    height: 100%;
  }
  .tab-view {
    .tab-nav {
      .rs-nav {
        width: auto;
      }
    }
  }
}
