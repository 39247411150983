.preview_text {
  margin-bottom: var(--m-2);
}

.preview_wrapper {
  border: 1px solid var(--rs-border-primary);
  width: min-content;
  border-radius: var(--border-radius-base);
}

.preview_wrapper :global(.file-preview-component .image) {
  border-radius: var(--border-radius-base);
}
