@import "../../../../styles/vars";

.bf-select {
  flex-grow: 1;

  .rs-picker-select-menu-items .rs-picker-menu-group-title {
    letter-spacing: 1px;
    font-weight: 600;
  }
  .loading-indicator {
    position: absolute;
    top: calc(50% - 10px);
    right: 10px;
  }
  .popup-footer {
    border-top: solid 1px var(--border-color-secondary);
    .type-change {
      display: flex;
      justify-content: flex-end;
      button {
        font-size: var(--font-size-xs);
      }
    }
  }
  &.error {
    .rs-picker-toggle {
      border-color: var(--color-negative);
    }
    .rs-picker {
      border-color: var(--color-negative);
    }
  }
  &.no-placeholder {
    .rs-picker-toggle-placeholder {
      opacity: 0;
    }
  }

  .rs-picker-input,
  .rs-picker-toggle,
  .rs-picker-select,
  .rs-picker-check {
    .rs-stack-item {
      max-width: 100%;
    }
    .rs-picker-toggle-placeholder {
    }
    .rs-picker-toggle-placeholder {
      // padding: 7px 0px 7px 11px;
      display: flex;
      align-items: center;
    }
    &.rs-picker-focused {
      box-shadow: var(--base-color) 0px 0px 5px;
    }
    .rs-picker-toggle {
      // padding: 0px 32px 0px 0px;
      max-width: 100%;
      .value-presentation-container {
        display: flex;
        max-width: 100%;
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 11px 0px 11px;
          background: var(--background-color-secondary);
          color: var(--icon-color-contrast-inverse);
        }
        .value-presentation {
          // padding: 7px 0px 7px 11px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .rs-picker-toggle.active {
      box-shadow: var(--base-color) 0px 0px 5px;
    }

    &.rs-picker-tag {
      .rs-picker-tag-wrapper .rs-tag {
        z-index: 0;
      }
    }
  }
  .item-value {
    .sub-label {
      font-size: var(--font-size-sm);
      color: var(--text-color-secondary);
    }
  }

  .rs-picker-menu {
    margin-bottom: var(--m-10);
    top: initial !important;
    min-width: 100% !important;
  }
  .bf-select .rs-picker-default .rs-picker-toggle {
    z-index: 1;
  }
  .rs-picker-default .rs-picker-toggle,
  .rs-picker-default .rs-picker-toggle-custom {
    z-index: 2;
  }

  .rs-picker-select-menu-item {
    border: solid 1px transparent;
    &.rs-picker-select-menu-item-focus {
      border: dashed 1px var(--border-color-tertiary);
      background: var(--background-color-contrast);
    }
    &.rs-picker-select-menu-item-active {
      background: var(--rs-listbox-option-selected-bg);
    }
  }

  .rs-picker-toggle-value {
    overflow: hidden;
    .value-presentation-container {
      overflow: hidden;
      .value-presentation {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
