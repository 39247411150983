.container {
  height: 400px;
  width: 100%;
  overflow-x: auto;
}

.buttonsContainer {
  position: absolute;
  top: -40px;
  right: 0;
}
