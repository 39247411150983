.cb-rental-agreement-open-debit-positions {
  table {
    width: 100%;
    // max-width: 600px;
    position: relative;

    td {
      vertical-align: top;
    }
    th {
      background: var(--background-color-primary);
      position: sticky;
      z-index: 1;
      top: 0;
    }
    .position-quick-actions {
      display: flex;
      padding-left: var(--p-3);
      padding-right: var(--p-3);
      width: 50px;
      gap: 8px;
    }
    .footer-value {
      padding: 8px 66px 8px 10px;
      text-align: right;

      &.negative {
        color: var(--color-negative);
      }
      &.positive {
        color: var(--color-positive);
      }
    }
    .value {
      &.negative {
        color: var(--color-negative);
      }
      &.balanced {
        color: var(--color-positive);
      }
    }
    td,
    th {
      padding-left: var(--p-3);
      padding-right: var(--p-3);

      &:first-of-type {
        padding-left: var(--p-0);
      }
      &:last-of-type {
        padding-right: var(--p-3);
      }
    }
    tr {
      &:nth-child(odd) {
        background: var(--background-color-contrast);
      }
      &:hover {
        background: var(--table-row-color-hover);
      }
      &.head-row {
        border-bottom: solid 1px var(--border-color-secondary);
        &:hover {
          background: none;
        }
      }
      td.no-debit-position {
        text-align: center;
        padding: var(--p-5);
        color: var(--text-color-secondary);
      }
    }
    th {
      text-align: left;
    }

    tr.debit-position {
      border-top: solid 1px var(--border-color-primary);
      font-weight: 500;
      td {
        padding-top: var(--p-3);
        padding-bottom: var(--p-3);
      }
    }
    tr.debit-booking {
      &.last-booking {
        td {
          padding-bottom: var(--p-5);
        }
      }
    }
    .currency-column {
      text-align: right;
    }
    .booking-type {
      padding-left: var(--p-3);
    }
    .position-name {
      display: flex;
      .used-for {
        color: var(--text-color-primary);
      }
      .position-parent-indicator {
        width: 20px;
        position: relative;
        .position-border {
          background: var(--background-color-primary);
          width: 2px;
          height: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
        }
        .position-indicator {
          background: var(--background-color-primary);
          height: 2px;
          position: absolute;
          top: calc(50% - 1px);
          left: 0px;
          right: 5px;
        }
        &.last {
          .position-border {
            height: 50%;
          }
        }
      }
    }
    .collapse-button {
      .bf-button {
        padding: var(--p-2);
        border-radius: var(--border-radius-large);
        .bf-icon {
          transition: transform 0.2s;
          transform: rotate(-90deg);
          width: 14px;
          height: 14px;
          &.open {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}
