.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;

  position: relative;

  background-color: var(--background-color-contrast);
}
