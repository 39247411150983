.cb-rental-agreement-periods {
  height: calc(100% - 20px);
  &.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--p-10);
    text-align: center;
    color: var(--color-negative);
  }

  .cb-rental-agreement-period {
    .__h4 {
      padding-bottom: var(--p-3);
    }
    .entry-list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px 20px;
      padding-bottom: var(--p-5);
      .entry {
        .label {
          font-size: var(--font-size-sm);
          color: var(--text-color-secondary);
        }
        .value {
          font-size: var(--font-size-md);
          .plan {
            font-size: var(--font-size-sm);
            color: var(--text-color-secondary);
          }
        }
      }
    }
  }
}
