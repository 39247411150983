.app-drawer-order-list {
  .bf-order-list {
    max-height: 70vh;
    overflow: auto;
    border-top: solid 1px var(--border-color-tertiary);
    border-bottom: solid 1px var(--border-color-tertiary);

    .bf-order-list-item {
      border-bottom: solid 1px var(--border-color-primary);
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}

.app-drawer-order-list-app-entry {
  display: flex;
  align-items: center;
  padding: var(--p-5);
  background: var(--list-row-background);
  .application-logo {
    width: 40px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 10px;
  }
  .name {
    padding-left: var(--p-5);
  }
}
