.cash-budget-category-detail-entry {
  margin: var(--m-5) var(--m-5) var(--m-3) var(--m-5);
  box-shadow: 0px 1px 5px var(--color-neutral);
  width: calc(100% - 20px);
  position: relative;
  outline: none !important;
  border-radius: var(--border-radius-base);

  &:hover {
    .additional-infos {
      display: block;
    }
  }
  &.planned {
    background: var(--cashbudget-color-planned-booking);
    .planned-by {
      justify-content: center;
      display: flex;
      padding: 5px 5px 0px 5px;
      text-transform: uppercase;
      color: var(--text-color-secondary);
    }
  }
  &.forecast {
    background: var(--cashbudget-color-prognose);
  }
  &.sharepoint {
    background: var(--cashbudget-color-sharepoint);
  }
  &.fixed-comparison {
    background: var(--cashbudget-color-fixed-comparison);
  }
  &.category-changed {
    // opacity: 0.5;
  }
  .planned-by {
    // display: none;
    justify-content: center;
    display: flex;
    padding: 5px 5px 0px 5px;
    text-transform: uppercase;
    color: var(--text-color-secondary);
  }

  .action-row {
    display: flex;
    border-top: solid 1px var(--border-color-primary);
    @media screen and (max-width: 535px) {
      button {
        font-size: 0.8em;
      }
    }
    @media screen and (max-width: 450px) {
      flex-direction: column;
    }
    button {
      border-radius: var(--border-radius-none);
      &.toggled {
        background: var(--background-color-secondary);
      }
    }
  }
  .comment-form,
  .category-form,
  .object-form {
    padding: var(--p-5);
    .description {
      max-width: 100%;
      white-space: normal;
      margin-bottom: var(--m-3);
    }
    .comment-actions,
    .category-actions,
    .object-actions {
      display: flex;
      justify-content: flex-end;
      padding-top: var(--p-5);
      button {
        margin-left: var(--m-5);
      }
    }
  }

  .category-changed-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--background-opacity-primary);
    color: var(--text-color-contrast);
  }

  &.dragging {
    .overlay-drop-indicator {
      display: flex;
    }
  }
  .overlay-drop-indicator {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: var(--background-opacity-secondary);
    justify-content: center;
    align-items: center;
    i.bf-icon {
      font-size: 2.8em;
      color: var(--icon-color-contrast);
      font-weight: bold;
    }
    &.reject {
      background: var(--color-negative);
    }
  }
}
