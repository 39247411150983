.ap-dashboard-activity-entry {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  .infos {
    font-size: var(--font-size-sm);
    color: var(--text-color-secondary);
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    span {
      text-overflow: ellipsis;
      margin-right: var(--m-4);
      &:last-of-type {
        margin-right: var(--m-0);
      }
      &.entity,
      &.object {
        // font-weight: bold;
        font-size: var(--font-size-sm);
      }
    }
  }
  .name-container {
    display: flex;
    width: 100%;
    overflow: hidden;
    .name {
      text-align: left;
      flex-grow: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: var(--font-size-base);
      color: var(--text-color-strong);
    }
    .value-entry {
      span {
        color: var(--text-color-secondary);
        font-size: var(--font-size-sm);
      }
    }
  }
  .status {
    padding-top: var(--p-3);
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .field {
      margin-right: var(--m-3);
    }
    .creation-date {
      flex-grow: 1;
      text-align: right;
      color: var(--text-color-secondary);
      font-size: var(--font-size-sm);
    }
  }
}
