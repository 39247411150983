.contract-fields {
  .field-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-bottom: var(--p-5);

    &.nowrap {
      flex-wrap: nowrap;
    }
  }
  .toggle-group {
    padding-top: var(--p-5);
    display: flex;
    gap: 20px;
    input {
      width: 70px;
    }
  }
  .price-field {
    flex-grow: 1;
  }
  .consumption-field {
    flex: 1 0;
  }

  .insurance-list {
    padding-bottom: var(--p-5);
    .title {
      padding-bottom: var(--p-3);
      font-weight: bold;
      color: var(--text-color-secondary);
    }
    .insurance-position {
      display: flex;
      align-items: center;
      border-bottom: solid 1px var(--border-color-primary);
      &:last-of-type {
        border-bottom: none;
      }
      .checkbox {
        flex: 1;
      }
      .input-field {
        flex: 1;
      }
    }
  }
}
