.object-details-lq-plan {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .more-objects-info {
    display: flex;
    flex-wrap: wrap;
    .bf-button.jump {
      margin-left: var(--m-3);
    }
  }
  .lq-plan-container {
    flex-grow: 1;
    overflow: hidden;
  }
}
