.bf-datefield {
  .suffix-buttons {
    display: flex;
    align-items: center;
    button.rs-dropdown-toggle {
      padding: var(--p-4);
    }
    .clear-button {
      display: flex;
      padding: var(--p-0);
      align-items: center;
      justify-content: center;
      border-radius: var(--border-radius-large);
      width: 22px;
      height: 22px;
      .bf-icon {
        width: 12px;
        height: 12px;
        margin-bottom: var(--m-1);
      }
    }
  }
}

.bf-datefield-datepicker {
  .rs-calendar-table-cell-selected {
    background: var(--table-row-color-hover);
  }
  .rs-calendar-panel.rs-calendar-compact
    .rs-calendar-table-row:not(.rs-calendar-table-header-row)
    .rs-calendar-table-cell-content {
    height: 38px;
  }
}
