.cash-budget-category-detail {
  // padding: var(--p-5);
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .cashbudget-type-tab {
    a {
      font-size: var(--font-size-lg);
    }
  }
  .cash-budget-category-detail-table-container {
    height: 100%;
    .missing-entries {
      text-align: center;
      padding: var(--p-10);
      font-size: 1.1em;
      color: var(--text-color-secondary);
    }
  }
}
