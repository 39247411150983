.epost-pdf-comment-entry {
  border-radius: var(--border-radius-base);
  // flex-grow: 1;
  border: solid 1px var(--border-color-secondary);
  // overflow: hidden;
  background: var(--background-color-contrast);

  .header {
    padding: 6px 8px;
    border-bottom: solid 1px var(--border-color-secondary);
    background: var(--background-color-primary);
    border-top-left-radius: var(--border-radius-base);
    border-top-right-radius: var(--border-radius-base);
    .header-main {
      font-size: var(--font-size-base);
      font-weight: bold;
    }
    .date {
      color: var(--text-color-secondary);
      font-size: var(--font-size-sm);
    }
  }
  .content {
    padding: var(--p-10);
    .failed {
      color: var(--color-negative);
    }
  }
}
