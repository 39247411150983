.bf-message {
  &.squished {
    .rs-message-container {
      padding: 8px 10px 8px 18px;
    }
  }

  .bf-message-content {
    display: flex;
    align-items: center;
    .bf-icon.size-xl {
      font-size: 2em;
    }
    .text-content {
      flex-grow: 1;
      padding-left: var(--p-10);
      .title {
        font-size: 1.15em;
        font-weight: 500;
      }
      .message-description {
        white-space: normal;
        word-wrap: break-word;
      }
    }
  }
}
