.ck-body-wrapper {
  .ck.ck-body.ck-rounded-corners {
    .ck.ck-balloon-panel.ck-balloon-panel_caret_se.ck-balloon-panel_visible {
      border-radius: var(--border-radius-base);
      border: none;
      .ck-balloon-rotator__content {
        border-radius: var(--border-radius-base);

        .ck-mentions {
          border-radius: var(--border-radius-base);

          .ck.ck-list__item {
            span.mention.ck-button {
              display: inline-block;
              width: 100%;
              min-width: 100%;
              &.ck-on {
                background: var(--base-color);
                margin-right: -20px;
              }
            }
            span.variable.ck-button {
              display: inline-block;
              width: 100%;
              min-width: 100%;
              &.ck-on {
                background: var(--base-color);
                margin-right: -20px;
              }
            }
          }
        }
      }
    }
  }
}
.ck.ck-sticky-panel__content.ck-sticky-panel__content_sticky {
  top: 0px !important;
  position: absolute;
}
.ck.ck-sticky-panel {
  position: relative;
}
.ck.ck-sticky-panel__placeholder {
}

.ck.ck-powered-by {
  display: none;
}

span.mention,
.ck-content span.mention {
  color: var(--base-color);
  font-size: var(--font-size-sm);
  padding: 2px 5px;
  border-radius: var(--border-radius-base);
  background: var(--background-color-secondary);
}

span.mention-variable,
.ck-content span.mention-variable {
  color: var(--text-color-strong);
  // font-size: var(--font-size-sm);
  padding: 2px 5px;
  border-radius: var(--border-radius-base);
  background: var(--background-color-focus);
}
.ck-content span.mention-variable::first-letter {
  opacity: 0.5;
}
span.mention-variable::first-letter {
  opacity: 0.5;
}

.ck-dropdown-list {
  .ck-dropdown__panel {
    bottom: initial;
    top: 0;
    .ck-list {
      max-height: 400px;
      overflow: auto;
    }
  }
}
