.cb-idly-booking-entry {
  padding: 10px 20px;
  .__card {
    display: flex;

    > .left {
      flex: 2 0;
      .booking-container {
        display: flex;
        min-height: 100%;
        flex-direction: column;
        .card-content {
          padding: var(--p-3);
        }
        .cashbudget-detail-entry-card-content {
          .head {
            .entity-object-ident {
              .object {
                white-space: nowrap;
              }
            }
          }
        }
      }
    }

    > .right {
      flex: 3 0;
      padding: var(--p-5);
      border-left: solid 1px var(--border-color-primary);

      .cb-idly-booking-connection {
        .suggestions {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
        > .actions {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: var(--p-8);
        }
      }
    }
  }
}
