.ap-construction-diaries-form {
  .meta {
    display: flex;
    gap: 10px;
    .__field {
      flex: 1 0 33%;
    }
  }
  .weather {
    display: flex;
    gap: 10px;
    .__field {
      flex: 1 0 33%;
    }
  }
}
