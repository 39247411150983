.ap-unassigned-message-details {
  height: 100%;
  display: flex;
  flex-direction: column;

  .action-row {
    padding: 0px 0px 10px 45px;
    display: flex;
  }
  .details-content {
    flex: 1 0 0;
    overflow: hidden;
    .comment-entry-container {
      height: 100%;
      display: flex;
      padding-bottom: var(--p-0);
      flex-direction: column;
      .comment-container {
        flex: 1 0 0;
        overflow: hidden;
        .bubble {
          max-height: 100%;
          display: flex;
          flex-direction: column;
          .content-wrapper {
            flex: 1 0 0;
            overflow: auto;
            .content {
              max-height: initial;
            }
          }
        }
      }
    }
  }
  .comment-input-wrapper {
    padding-top: var(--p-5);
    min-height: 200px;
  }
}

.rs-drawer-wrapper {
  .rs-drawer-content {
    .rs-drawer-body {
      .ap-unassigned-message-details {
        overflow: hidden;
        height: 100%;
        padding: var(--p-0);
      }
    }
  }
}
