.d-kpi-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .kpi-value {
    font-size: 46px;
  }
  .meta-text {
    font-size: var(--font-size-lg);
    padding-bottom: var(--p-10);
    color: var(--text-color-secondary);
  }
  .sub-title {
    padding-top: var(--p-10);
    font-size: var(--font-size-lg);
    word-wrap: break-word;
    white-space: normal;
  }

  &[max-width~="400px"] {
    .kpi-value {
      font-size: 32px;
    }
    .meta-text {
      font-size: var(--font-size-md);
      padding-bottom: var(--p-5);
    }
    .sub-title {
      padding-top: var(--p-5);
      line-height: 18px;
      font-size: var(--font-size-md);
    }
  }

  &[max-width~="300px"] {
    .kpi-value {
      font-size: 26px;
    }
    .meta-text {
      font-size: var(--font-size-base);
      padding-bottom: var(--p-5);
    }
    .sub-title {
      padding-top: var(--p-5);
      line-height: 16px;
      font-size: var(--font-size-base);
    }
  }

  &[max-width~="200px"] {
    .kpi-value {
      font-size: var(--font-size-2xl);
    }
    .meta-text {
      font-size: var(--font-size-sm);
      padding-bottom: var(--p-5);
    }
    .sub-title {
      padding-top: var(--p-5);
      line-height: 14px;
      font-size: var(--font-size-sm);
    }
  }
}
