.risk-scenario-form {
  .form {
    .scenario-fields {
      padding-bottom: var(--p-5);
    }
    .chart {
      padding-bottom: var(--p-5);
    }
    .field-data-sum {
      margin-bottom: var(--m-10);
      .data-sum-description {
        color: var(--text-color-primary);
        padding-bottom: var(--p-5);
      }
      .subtitle {
        font-size: var(--font-size-md);
        padding-bottom: var(--p-3);
        span.count {
          padding-left: var(--p-5);
          font-size: var(--font-size-base);
          color: var(--text-color-secondary);
        }
      }
      .change-fields {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .bf-input {
          width: 240px;
        }
        .object-data {
          border: solid 1px var(--border-color-secondary);
          border-radius: var(--border-radius-base);
          padding: var(--p-5);
          .fields {
            flex-wrap: wrap;
            display: flex;
            gap: 10px;
          }
        }
        .loan-data {
          border: solid 1px var(--border-color-secondary);
          border-radius: var(--border-radius-base);
          padding: var(--p-5);
          .fields {
            flex-wrap: wrap;
            display: flex;
            gap: 10px;
          }
        }
      }
    }
    .objects {
      max-height: 500px;
      overflow: auto;
      border-bottom: 1px solid var(--border-color-secondary);
      border-top: 1px solid var(--border-color-secondary);
      padding-top: var(--p-5);
      padding-bottom: var(--p-5);
      .object-wrapper {
        display: flex;
        border-top: 1px solid var(--border-color-secondary);
        border-bottom: 1px solid var(--border-color-secondary);
        margin-bottom: var(--m-5);
        .image {
          width: 130px;
        }
        .object-form {
          flex: 1;
          padding-left: var(--p-5);

          .fields-of-object {
            padding: 5px 0px;
            .data-of-object {
              .object-name {
                font-size: var(--font-size-md);
                padding-bottom: var(--p-3);
              }
              .object-data {
                display: flex;
                padding-bottom: var(--p-3);
              }
              .fields {
                gap: 10px;
                display: flex;

                .object-field {
                  input {
                    width: 100px;
                  }
                }
              }
            }
          }

          .data-of-object {
            .object-name {
            }
            .object-data {
              .object-data-item {
              }
            }
          }
          .loan-title {
            font-size: var(--font-size-md);
            padding-top: var(--p-5);
            padding-bottom: var(--p-3);
          }
          .loans-of-object {
            // flex: 1;
            // display: flex;
            // gap: 10px;
            // flex-wrap: wrap;
            margin-bottom: var(--m-5);
            border: solid 1px var(--border-color-secondary);
            border-radius: var(--border-radius-base);
            .loan {
              padding: var(--p-5);
              border-bottom: solid 1px var(--border-color-secondary);
              &:last-of-type {
                border-bottom: none;
              }
              .data-of-loan {
                .loan-name {
                }
                .loan-bankName {
                  color: var(--text-color-secondary);
                  font-size: var(--font-size-sm);
                }
                .loan-data {
                  padding-top: var(--p-3);
                  display: flex;
                }
              }
              .fields-title {
                padding-top: var(--p-5);
                font-weight: bold;
                padding-bottom: var(--p-3);
              }
              .fields-loan {
                display: flex;
                gap: 10px;

                input {
                  width: 100px;
                }
              }
            }
          }
        }
      }
    }
  }
}
