.asset-document-store {
  height: 70vh;
  display: flex;
  flex-direction: column;
  .content {
    flex-grow: 1;
    overflow: hidden;
  }
  .actions {
    padding: var(--p-5);
    display: flex;
    justify-content: flex-end;
  }
}
