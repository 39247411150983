.bf-status {
  display: inline-flex;
  align-items: center;
  background: var(--background-color-primary);
  color: var(--text-color-strong);
  font-weight: 600;
  padding: 3px 8px;
  border-radius: var(--border-radius-big);

  .bf-icon {
    margin-right: var(--m-3);
  }

  &.sm {
    font-size: var(--font-size-sm);
  }
  &.xs {
    font-size: var(--font-size-xs);
    font-weight: 400;
    letter-spacing: 1px;
    .bf-icon {
      width: 14px;
      height: 14px;
    }
  }
  &.xxs {
    font-size: var(--font-size-xs);
    font-weight: 400;
    padding: 1px 5px;
    letter-spacing: 1px;
  }

  .remove-button {
    background: none;
    padding: var(--p-0);
    margin-left: var(--m-4);
    .bf-icon {
      margin-right: var(--m-0);
      width: 14px;
      height: 14px;
      font-size: var(--font-size-sm);
    }
  }
  &.as-button {
    cursor: pointer;
    position: relative;
    .hover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius-big);
      background: var(--background-opacity-weak);
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s ease-in-out;
    }
    &:hover {
      .hover {
        opacity: 1;
      }
    }
  }
}
