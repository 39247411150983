.extract-field-overlay-container {
  .extract-field-overlay {
    background-color: var(--background-color-deep) !important;
    padding: 5px 8px;
    min-width: 150px;
    .title {
      color: var(--text-color-disabled);
      font-size: var(--font-size-xs);
    }
    .value {
      color: var(--text-color-contrast);
      size: 12px;
    }

    .action {
      display: flex;
      justify-content: flex-end;
      padding-top: var(--p-2);
      .bf-button {
        color: var(--text-color-contrast);
        font-size: var(--font-size-sm);
      }
    }
  }
}
