.cash-budget-connections-invoice-sum {
  display: flex;
  gap: 5px;
  margin-left: var(--m-3);
  margin-bottom: var(--m-3);
  .label,
  .difference-label {
    font-size: var(--font-size-sm);
    color: var(--text-color-secondary);
    padding-right: var(--p-4);
  }
  .difference-label {
    margin-left: var(--m-10);
  }
}
