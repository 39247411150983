.cb-rental-agreement-payment-positions.bf-input-table {
  .suggest-button {
    max-width: 126px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    direction: rtl;
  }
  .info {
    padding: 2px 5px;
    overflow: hidden;
    max-width: 100%;
    .info-1 {
      font-size: var(--font-size-sm);
      color: var(--text-color-primary);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .info-2 {
      font-size: var(--font-size-xs);
      color: var(--text-color-secondary);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .footer-sum {
    padding: 8px 8px 8px 40px;
    font-weight: bold;

    &.no-left-padding {
      padding-left: var(--p-4);
    }
  }
}
