.ez-input {
  .bf-input.appearance-clear {
    border: solid 1px transparent;
    transition: background-color 0.2s;
    outline: none;

    &.readonly.rs-input-group {
      &:hover {
        border: solid 1px transparent;
      }
    }
    &:not(.readonly) {
      &:hover {
        background: var(--ez-hover-color);
        border-color: transparent;
      }
      &:focus-within {
        // border: solid 1px var(--border-color-accent);
        background: transparent;
        border: solid 1px var(--base-color);
      }
    }
    input {
      padding: var(--p-3);
    }
  }
}
