.bf-assignment {
  width: 100%;
  .bf-assignment__label {
    padding-bottom: var(--p-3);
  }
  &.error {
    .bf-assignment__label {
      color: var(--color-negative);
    }
    .bf-assignment__add-assignment {
      color: var(--color-negative);
    }
    > .bf-assignment__content {
      border-color: var(--color-negative);
    }
  }
  > .bf-assignment__content {
    transition: box-shadow 0.2s;
    padding: var(--p-0);
    border-radius: var(--border-radius-base);
    background: var(--background-color-contrast);
    border: solid 1px var(--border-color-primary);
    .bf-assignment__assignments {
      display: flex;
      flex-direction: column;
      padding-top: var(--p-3);
      .value-element {
        width: 100%;
        display: flex;
        gap: 5px;
        align-items: center;
        .assignment-entry {
          flex-grow: 1;
        }
        border-bottom: solid 1px var(--border-color-primary);
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }

  &.apperance-ez {
    > .bf-assignment__content {
      background: var(--background-color-primary);
      > .bf-assignment-search-field {
        > div.bf-dropdown.bf-assignment-search-field-as-overlay {
          display: inline-flex;
          justify-content: center;
        }
      }
    }
  }
}
