.ap-activity-tenant-data {
  height: 100%;
  overflow: auto;
  .activity-tenant-entry {
    .action-row {
      padding: 20px 10px 10px 10px;
      display: flex;
      justify-content: flex-end;
    }
  }
}
